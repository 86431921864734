import styled from '@emotion/styled'
import ProfileCard from '../../components/ProfileCard'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const ExampleProfileCard = () => {
  return (
    <Layout>
      <ProfileCard onClick={() => console.log('test onclick')} />

      <ProfileCard size="medium" onClick={() => console.log('test onclick')} />
    </Layout>
  )
}
export default ExampleProfileCard
