import { Collapse } from 'antd'
import color from '../../constant/colors'
import Text from '../../components/common/Text'

import { CSSProperties, ReactNode } from 'react'
import styled from '@emotion/styled'

const CustomCollapse = styled(Collapse)`
  border-radius: 8px;
  background-color: ${color.WHITE};
  box-shadow: '0px 0px 6px 6px rgba(242, 222, 223, 0.4)';
  padding: 12px;
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 8px;
  }
`

type CollapeProps = {
  children: ReactNode
  style?: CSSProperties
}

const CollapseWellbeing = (props: CollapeProps) => {
  const { children, style } = props
  return (
    <CustomCollapse expandIconPosition={'right'} style={style}>
      {children}
    </CustomCollapse>
  )
}
export default CollapseWellbeing
