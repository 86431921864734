import React, { useState, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'

import Steps from '../../components/common/Steps'
import Text from '../../components/common/Text'
import LeftCard from './LeftCard'
import RightCard from './RightCard'
import { useScreen } from '../../utils/responsive-helper'
import SuccessPage from './SuccessPage'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { shop } from '../../constant/path'

const ShopRewardClaimLayout = styled.div`
  padding: 40px 0 40px 0;
`
const StepsLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 50px;
`
const HeaderText = styled(Text)`
  margin: 24px 0 24px 0;
  text-align: center;
`
const MainLayout = styled.div<{ screen: any }>`
  display: flex;
  flex-direction: ${({ screen }) => (screen.isMobile ? 'column' : 'row')};
`
const SpaceBetweenLeftAndRight = styled.div`
  width: 24px;
`

const ShopRewardClaim = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const screen = useScreen()
  const { isMobile } = screen
  const { t } = useTranslation()
  const history = useHistory()

  const stepWidth = useMemo(() => {
    if (isMobile) {
      return 180
    }
  }, [isMobile])
  const onSuccessOkClick = useCallback(() => {
    setCurrentStep(1)
    history.replace(shop())
  }, [history])

  return (
    <ShopRewardClaimLayout>
      <StepsLayout>
        <Steps currentStep={currentStep} maxStep={2} width={stepWidth} />
      </StepsLayout>

      {currentStep === 1 ? (
        <HeaderText size={28} type="Bold">
          {t('shopRewardClaim.header')}
        </HeaderText>
      ) : (
        <HeaderText size={28} type="Bold">
          {t('shopRewardClaim.successHeader1')}
          <div />
          {t('shopRewardClaim.successHeader2')}
        </HeaderText>
      )}

      <MainLayout screen={screen}>
        {currentStep === 1 ? (
          <>
            <LeftCard />
            <SpaceBetweenLeftAndRight />
            <RightCard setCurrentStep={setCurrentStep} />
          </>
        ) : (
          <SuccessPage onSuccessOkClick={onSuccessOkClick} />
        )}
      </MainLayout>
    </ShopRewardClaimLayout>
  )
}

export default ShopRewardClaim
