import styled from '@emotion/styled'
import { Dropdown, Menu } from 'antd'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { useCallback, useMemo } from 'react'
import AzureAuthenticationContext from '../../../services/authAzure/azure-authentication-context'
import IconDown from '../../../assets/images/base/icons-bar-next-normal.svg'
import { useGetUser, logout } from '../../../services/user/user-query'
import Avatar from '../../common/Avatar'
import Text from '../../common/Text'
import * as paths from '../../../constant/path'
import color from '../../../constant/colors'
import { useTranslation } from 'react-i18next'
import Image from '../../common/Image'
import ThaiFlag from '../../../assets/images/base/thai-flag.svg'
import UsaFlag from '../../../assets/images/base/usa-flag.png'
import LogoutIcon from '../../../assets/images/base/icon-login.png'
import { Link } from 'react-router-dom'
import config from '../../../config'
import { setLanguage } from '../../../utils/helper'

const Layout = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0px 0 20px;
  :hover {
    cursor: pointer;
  }
`
const Icon = styled.img`
  margin-left: 5px;
  width: 18px;
`
const MenuTextStyled = styled(Text)`
  color: ${color.FONT_BLACK};
`

const MenuStyled = styled(Menu)`
  position: relative;
  min-width: 234px;
  padding: 14px 24px;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
`
const MenuItemStyled = styled(Menu.Item)`
  padding: 8px 0;
`
const MenuDividerStyled = styled(Menu.Divider)`
  margin: 8px 0;
  background-color: #dee2e6;
`
const MenuItemLanguageStyled = styled(MenuItemStyled)`
  display: flex;
  flex-flow: row;
  align-items: center;

  cursor: default !important;
  :hover {
    background-color: unset;
  }

  .icon-lang {
    cursor: pointer;
    :hover {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    }
    :nth-of-type(1) {
      margin-left: auto;
      margin-right: 10px;
    }
  }
`
const LanguageIcon = styled(Image)`
  width: 30px;
  height: 20px;
  object-fit: cover;
`
const LogoutIconStyle = styled(Image)`
  width: 14px;
  height: 18px;
  margin-left: auto;
  object-fit: fill;
`

const Row = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`

type MenuListProps = {
  menuPaths: {
    profile: string
    about: string
    termConditions: string
    app: string
    contact: string
  }
  onLogoutClick: MenuClickEventHandler
  onChangeLanguageToThaiClick: () => void
  onChangeLanguageToEngClick: () => void
  t: any
}

const getMenuList = (props: MenuListProps) => () => {
  return (
    <MenuStyled>
      <MenuItemStyled>
        <Link to={props.menuPaths.profile}>
          <MenuTextStyled size={16} type="Medium" style={{ lineHeight: '27px' }}>
            {props.t('app.navbar.profile')}
          </MenuTextStyled>
        </Link>
      </MenuItemStyled>
      <MenuDividerStyled />
      <MenuItemStyled>
        <Link to={props.menuPaths.app}>
          <MenuTextStyled size={16} type="Medium" style={{ lineHeight: '27px' }}>
            {props.t('app.navbar.app')}
          </MenuTextStyled>
        </Link>
      </MenuItemStyled>
      <MenuItemStyled>
        <Link to={props.menuPaths.termConditions}>
          <MenuTextStyled size={16} type="Medium" style={{ lineHeight: '27px' }}>
            {props.t('app.navbar.termConditions')}
          </MenuTextStyled>
        </Link>
      </MenuItemStyled>
      <MenuItemStyled>
        <Link to={props.menuPaths.about}>
          <MenuTextStyled size={16} type="Medium" style={{ lineHeight: '27px' }}>
            {props.t('app.navbar.about')}
          </MenuTextStyled>
        </Link>
      </MenuItemStyled>
      <MenuItemStyled>
        <Link to={props.menuPaths.contact}>
          <MenuTextStyled size={16} type="Medium" style={{ lineHeight: '27px' }}>
            {props.t('app.navbar.contact')}
          </MenuTextStyled>
        </Link>
      </MenuItemStyled>
      <MenuDividerStyled />
      <MenuItemLanguageStyled>
        <MenuTextStyled size={16} type="Medium" style={{ lineHeight: '27px' }}>
          {props.t('app.navbar.language')}
        </MenuTextStyled>
        <LanguageIcon
          className="icon-lang thai-flag"
          src={ThaiFlag}
          onClick={props.onChangeLanguageToThaiClick}
        />
        <LanguageIcon
          className="icon-lang usa-flag"
          src={UsaFlag}
          onClick={props.onChangeLanguageToEngClick}
        />
      </MenuItemLanguageStyled>
      <MenuItemStyled onClick={props.onLogoutClick}>
        <Row>
          <MenuTextStyled size={16} type="Medium" style={{ lineHeight: '27px' }}>
            {props.t('app.navbar.logout')}
          </MenuTextStyled>
          <LogoutIconStyle src={LogoutIcon} />
        </Row>
      </MenuItemStyled>
      <MenuItemStyled
        disabled
        style={{
          paddingBottom: 0,
        }}
      >
        <MenuTextStyled size={12} type="Medium" style={{ textAlign: 'center' }}>
          {`${props.t('app.navbar.version')} ${'1.0'}`}
        </MenuTextStyled>
      </MenuItemStyled>
    </MenuStyled>
  )
}

const Profile = () => {
  const { data } = useGetUser()
  const { t, i18n } = useTranslation()
  const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext()

  // Azure Logout
  const onLogoutAzure = useCallback(() => {
    console.log('vv', data)
    // onSubmit(undefined);
    // authenticationModule.logout(data);
  }, [])

  const onLogoutClick = useCallback(() => {
    logout()
  }, [])

  const onChangeLanguageToThaiClick = useCallback(() => {
    setLanguage('th')
    i18n.changeLanguage('th')
  }, [i18n])

  const onChangeLanguageToEngClick = useCallback(() => {
    setLanguage('en')
    i18n.changeLanguage('en')
  }, [i18n])

  const menuPaths = useMemo(
    () => ({
      profile: paths.profile(),
      about: paths.about(),
      termConditions: paths.termAndCondition(),
      app: paths.app(),
      contact: paths.contact(),
    }),
    [],
  )

  const menu = useMemo(() => {
    return getMenuList({
      menuPaths,
      onLogoutClick,
      onChangeLanguageToThaiClick,
      onChangeLanguageToEngClick,
      t,
    })
  }, [menuPaths, onLogoutClick, onChangeLanguageToThaiClick, onChangeLanguageToEngClick, t])

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" arrow={true}>
      <Layout>
        <Avatar size={40} src={`${config.apiHost}${data?.profileImagePath}`} />
        <Icon src={IconDown} />
      </Layout>
    </Dropdown>
  )
}

export default Profile
