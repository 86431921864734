import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import { mobile, useScreen } from '../../utils/responsive-helper'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import Badge from '../../components/common/Badge'
import { Tooltip } from 'antd'
import * as paths from '../../constant/path'
import { useHistory } from 'react-router'
import { useReadNotification } from '../../services/notification/notification-query'
import config from '../../config'

const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  margin-bottom: 24px;
  max-height: 125px;
  padding: 28px 20px;
  
  ${mobile} {
    padding: 12px;
  }
`

const LayoutImage = styled.div`
  .ant-badge-dot {
    top: 8px;
    right: 8px;
  }
`

const LayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
`

const LayoutTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`

const LayoutBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const LayoutDate = styled.div`
  margin-right: 24px;
  justify-content: flex-end;

  ${mobile} {
    margin-right: 0;
  }
`

const CustomStyleImage = styled(Image)`
  border-radius: 50%;
  width: 72px;
  height: 72px;
`

const CustomStyleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

type NotificationCardProps = {
  id: number
  badge: boolean
  imagePath: string
  title: string
  description: string
  date: string
  newsId: number
  onCardClick: () => void
}

const NotificationCard = (props: NotificationCardProps) => {
  const { isMobile } = useScreen()
  const { onCardClick, badge, imagePath = '', title, description, date, newsId } = props

  return (
    <CustomStyleCard onClick={onCardClick}>
      <LayoutImage>
        <Badge dot={!badge}>
          <CustomStyleImage src={`${config.apiHost}${imagePath}`} />
        </Badge>
      </LayoutImage>
      <LayoutContent>
        <LayoutTitle>
          <Text ellipsis maxLine={1} size={isMobile ? 18 : 20} type="Medium">
            {title}
          </Text>
          {isMobile ? (
            <Text maxLine={1} size={14} type="Medium" color={color.LIGHT_GRAY}>
              {date}
            </Text>
          ) : (
            <Text size={16} type="Medium" color={color.LIGHT_GRAY}>
              {date}
            </Text>
          )}
        </LayoutTitle>
        <LayoutBody>
          <Tooltip title={description}>
            <CustomStyleText size={isMobile ? 14 : 16} type="Medium" color={color.LIGHT_GRAY}>
              {description}
            </CustomStyleText>
          </Tooltip>
        </LayoutBody>
      </LayoutContent>
    </CustomStyleCard >
  )
}

export default NotificationCard
