import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export type EClaimInf = {
  claimInfo: {
    id?: number
    name: string
    paymentDate: moment.Moment
    amount: number
    citizenFileUrl: string
    receiptFile: string
    remark?: string
    userRemark?: string
    relativeTypeId: number
    allpayRef?: string
    allpayReason?: string
    allpayRemark?: string
    relativeTitle?: string
    relativeFirstName?: string
    relativeLastName?: string
    slipDate: moment.Moment
    amountClaim: number
    nameShop: string
    slipNo: string
  }
}

export const initState = {
  claimInfo: {
    name: '',
    paymentDate: moment(),
    amount: 0,
    citizenFileUrl: '',
    receiptFile: '',
    relativeTypeId: 0,
    slipDate: moment(),
    amountClaim: 0,
    nameShop: '',
    slipNo: '',
  },
}

const eClaimSlice = createSlice({
  name: 'eClaim',
  initialState: initState,
  reducers: {
    setClaimInfo: (state, { payload }: PayloadWithType<EClaimInf['claimInfo']>) => {
      state.claimInfo = payload
    },
    clearClaimInfo: (state) => {
      state.claimInfo = initState.claimInfo
    },
  },
})

export const eClaimReducer = eClaimSlice.reducer

export const { setClaimInfo, clearClaimInfo } = eClaimSlice.actions
