import styled from '@emotion/styled'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import IconSuccess from '../../assets/images/base/infographic-reward-success.svg'
import Breadcrumb from '../../components/common/Breadcrumb'
import Button from '../../components/common/Button'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { eClaim, eClaimDetail, home } from '../../constant/path'
import { useGetEClaimById } from '../../services/e-claim/e-claim-query'
import { numberWithCommas, useRouter } from '../../utils/helper'
import { mobile, useScreen } from '../../utils/responsive-helper'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const RowCenter = styled(Row)`
  text-align: center;
  justify-content: center;
  padding-bottom: 12px;
`

const RowSpaceBetween = styled(Row)`
  margin-top: 24px;
  justify-content: space-between;
`

const RowSpaceBetweenWithBorder = styled(Row)`
  margin-top: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #E5E5E5;
`

const LayoutImage = styled(RowCenter)`
  width: 100%;
  height: 430px;

  ${mobile} {
    height: 343px;
  }
`

const LayoutCard = styled(Card)`
  padding: 32px;
  margin-bottom: 80px;
  width: 100%;

  ${mobile} {
    padding: 16px;
    margin-bottom: 40px;
  }
`

const PaddingText = styled.div`
  padding-top: 16px;
`

const PaddingImage = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`

const TextNotWrap = styled(Text)`
  white-space: nowrap;
`

const MarginTop = styled.div`
  margin-top: 64px;

  ${mobile} {
    margin-top: 24px;
  }
`

const ButtonStyle = styled(Button)`
  width: 267px;
  margin-top: 50px;
  margin-bottom: 40px;
`

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  width: 100%;
  min-height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-height: 266px;
  margin: 22px auto 0;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`

const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding :16px;  
  margin-bottom: 32px;
`

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  z-index: 1;

  ${mobile} {
    margin: 24px 16px;
  }
`

const BoxContent = styled.div`
  max-width: 329px;
  display: flex;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E7E2D8;
  background: #FAF9F6;
`

const IconImage = styled(Image)`
  border-radius: 4px;
  object-fit: scale-down;
  margin-right: 16px;
`
const LayoutContent = styled.div`
  display: flex;
  text-align: start;
  position: relative;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`

const EClaimComplete = () => {
  const { eClaimName, eClaimPrice } = useRouter().query
  const { query } = useRouter<{ eClaimId: number }>()

  const { data: eClaimItem } = useGetEClaimById({ claimId: query.eClaimId })

  const { isMobile } = useScreen()
  const history = useHistory()
  const onClickBackToEnrollment = useCallback(() => {
    history.push(eClaim())
  }, [history])


  return (
    <Layout>
      <BackgroundHeader />
      <FlexRow>
        <BreadcrumbContainer>
          <Breadcrumb
            overide={(paths) => {
              paths[0] = {
                label: 'Home',
                path: home(),
              }
              paths[1] = {
                label: 'D-Claims',
                path: eClaim(),
              }
              paths[2] = {
                label: `${eClaimName}`,
                path: eClaimDetail(),
              }
              return paths
            }}
          />
        </BreadcrumbContainer>

        <RowCenter>
          <Text size={isMobile ? 20 : 24} type={'Bold'} color={color.FONT_BLACK} style={{ zIndex: 1 }}>
            {eClaimName}
          </Text>
        </RowCenter>

        <Column>
          <CustomStyleCard>
            <PaddingImage>
              <LayoutImage>
                <Image src={IconSuccess} />
              </LayoutImage>
            </PaddingImage>
            <RowCenter>
              <Text size={26} type={'Bold'} color={color.FONT_BLACK}>
                ส่งเรื่องเบิกเรียบร้อยแล้ว
              </Text>
            </RowCenter>
            <PaddingText>
              <RowCenter>
                <BoxContent>
                  <Text size={16} color={color.FONT_BLACK}>
                    รอเจ้าหน้าที่ตรวจสอบเพื่ออนุมัติ  และสามารถตรวจสอบรายการเบิกที่ ประวัติการเบิก
                  </Text>
                </BoxContent>
              </RowCenter>
            </PaddingText>

            <RowCenter>
              <ButtonStyle onClick={onClickBackToEnrollment}>กลับไปหน้าแรก</ButtonStyle>
            </RowCenter>
          </CustomStyleCard>

          <Row>
            <Text size={24} type={'Bold'} color={color.FONT_BLACK}>
              รายละเอียดสวัสดิการที่ยื่นคำขอ{' '}
            </Text>
          </Row>
          <LayoutCard>
            <Column>
              <RowSpaceBetweenWithBorder>
                {
                  eClaimItem?.claimIcon && <IconImage src={eClaimItem?.claimIcon} width={48} height={48} />
                }

                <LayoutContent>
                  <Text size={16} type={'Medium'} color={color.FONT_BLACK}>
                    {eClaimName}
                  </Text>
                </LayoutContent>

              </RowSpaceBetweenWithBorder>
              <RowSpaceBetween>
                <Text size={16} type={'Medium'} color={color.FONT_BLACK}>
                  ยอด
                </Text>
                <TextNotWrap size={16} type={'Bold'} color={color.FONT_BLACK}>
                  {numberWithCommas(eClaimPrice)} เหรียญ
                </TextNotWrap>
              </RowSpaceBetween>
            </Column>
          </LayoutCard>
        </Column>
      </FlexRow>

    </Layout>
  )
}

export default EClaimComplete
