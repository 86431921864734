import React, { useCallback, useMemo, Dispatch, SetStateAction, useState } from 'react'
import styled from '@emotion/styled'

import Card from '../../components/common/Card'
import Text from '../../components/common/Text'
import PointLabel from '../../components/common/PointLabel'
import Modal from '../../components/common/Modal'
import {
  productRedeem,
  useClaimShopReward,
  useGetProductDetail,
} from '../../services/shop/shop-query'
import { useGetUser, useGetUserCoin, useGetListUserAdress, useCreateAddressUser, useEditAddressUser, useEditUserPhone } from '../../services/user/user-query'
import Button from '../../components/common/Button'
import { useRouter } from '../../utils/helper'
import EditAddressForm from '../Profile/EditAddressForm'

import { useTranslation } from 'react-i18next'
interface RightCardProps {
  setCurrentStep: Dispatch<SetStateAction<number>>
}

const RightCard = styled(Card)`
  flex: 1;
  padding: 32px 24px 40px 24px;
`
const PointLayout = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`
const FlexFlow = styled.div`
  display: flex;
  flex-direction: row;
`
const FlexChild = styled.div`
  flex: 1;
`
const ButtonPanel = styled(FlexFlow)`
  justify-content: center;
  margin-top: 40px;
`

const RightCardComp = (props: RightCardProps) => {
  const { data: user } = useGetUser()
  const { userId } = user || {}
  const { mutate: editUser } = useEditUserPhone(userId)
  const { setCurrentStep } = props
  const { query } = useRouter()
  const { data: userCoin } = useGetUserCoin()
  const { mutate: claimReward } = useClaimShopReward()
  const { mutate: createAddress } = useCreateAddressUser()
  const { mutate: editAddress } = useEditAddressUser()
  const { t } = useTranslation()
  const [modalAdress, setModalAddress] = useState(false)
  const [loading, setLoading] = useState(false)
  const { data: address } = useGetListUserAdress()
  const { data: productDetail } = useGetProductDetail(query.rewardId)
  const { specialCoin, remain, expireDate, normalCoin } =
    productDetail || {}

  const differentPoint = useMemo(() => {
    const needCoint = specialCoin !== null ? specialCoin : normalCoin

    return ((userCoin || 0) - (needCoint || 0)).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })
  }, [specialCoin, userCoin, normalCoin])

  const canRedeem = useMemo(() => {
    return Number(differentPoint) < 0
      ? false
      : true && productRedeem({ userCoin, specialCoin, normalCoin, remain, expireDate })
  }, [differentPoint, userCoin, specialCoin, normalCoin, remain, expireDate])

  const onClaimRewardSuccess = useCallback(() => {
    setCurrentStep(2)
  }, [setCurrentStep])

  const onSubmitAddress = useCallback(
    (objectForm) => {
      setLoading(true)
      setModalAddress(false)

      editUser({ id: userId, phoneNumber: objectForm?.phoneNumber }, {
        onSuccess: () => {
          if (address?.length !== 0) {
            const newForm = {
              ...address?.[0], ...objectForm
            }
            editAddress(newForm)
            claimReward(
              {
                idCard: user?.idCard,
                userId: user?.userId,
                productId: query.rewardId,
                coin: user?.point,
                firstName: user?.firstName,
                lastName: user?.lastName,
                phoneNumber: newForm.phoneNumber,
                email: user?.email,
                street: newForm.street,
                countryName: newForm.country,
                provinceName: newForm.province,
                subDistrictName: newForm.subDistrict,
                districtName: newForm.district,
                postCode: newForm.postcode
              },
              { onSuccess: onClaimRewardSuccess },
            )
          }
          else {
            createAddress(objectForm)
            claimReward(
              {
                idCard: user?.idCard,
                userId: user?.userId,
                productId: query.rewardId,
                coin: user?.point,
                firstName: user?.firstName,
                lastName: user?.lastName,
                phoneNumber: objectForm.phoneNumber,
                email: user?.email,
                street: objectForm.street,
                countryName: objectForm.country,
                provinceName: objectForm.province,
                subDistrictName: objectForm.subDistrict,
                districtName: objectForm.district,
                postCode: objectForm.postcode
              },
              { onSuccess: onClaimRewardSuccess },
            )
          }
        }
      })
    },
    [createAddress, editUser, userId, claimReward, user, onClaimRewardSuccess, query, address, editAddress],
  )

  const initialAddressValues = useMemo(() => {
    if (address?.length !== 0) {
      return {
        street: address?.[0].street,
        subdistrict: address?.[0].subDistrict,
        district: address?.[0].district,
        province: address?.[0].province,
        postcode: address?.[0].postcode,
        country: address?.[0].country,
        provinceId: address?.[0].provinceId,
        districtId: address?.[0].districtId,
        subdistrictId: address?.[0].subDistrictId,
        countryCode: address?.[0].countryCode,
        provinceCode: address?.[0].provinceCode,
        amphoeCode: address?.[0].amphoeCode,
        subdistrictCode: address?.[0].subdistrictCode,
        countryId: address?.[0].countryId,
        createdDate: address?.[0].createdDate,
        createdBy: address?.[0].createdBy,
        updatedDate: address?.[0].updatedDate,
        updatedBy: address?.[0].updatedBy,
        phoneNumber: user?.phoneNumber,
        idCard: user?.idCard,
        isPhoneNumber: true,
        isComfirm: true
      }
    }
    else {
      return {
        phoneNumber: user?.phoneNumber,
        isPhoneNumber: true,
        isComfirm: true
      }
    }
  }, [address, user])

  return (
    <>
      <RightCard>
        <PointLayout>
          <Text size={22}>{t('shopRewardClaim.currentUserPoint')}</Text>
          <FlexChild />
          <PointLabel label={userCoin} />
        </PointLayout>

        {/* <div style={{ minHeight: 300 }}>
        <Text size={22} type="Bold">
          เลือกธนาคารเพื่อชำระส่วนต่าง
        </Text>
        <Radio
          options={[
            { label: 'ชำระผ่าน SCB Easy', value: 1, image: ScbImage },
            { label: 'ชำระผ่าน KBank', value: 2, image: KbankImage },
          ]}
        />
      </div> */}

        <FlexFlow>
          <Text size={22}>{t('shopRewardClaim.pointOfProduct')}</Text>
          <FlexChild />
          <Text size={22} type="Bold">
            {
              productDetail?.specialCoin !== null ? productDetail?.specialCoin.toLocaleString(undefined, { maximumFractionDigits: 2 }) : productDetail?.normalCoin.toLocaleString(undefined, { maximumFractionDigits: 2 })
            }{' '}
            {t('app.coin')}
          </Text>
        </FlexFlow>

        <FlexFlow>
          <Text size={22}>{t('shopRewardClaim.pointLeft')}</Text>
          <FlexChild />
          <Text size={22} type="Bold">
            {differentPoint} {t('app.coin')}
          </Text>
        </FlexFlow>

        <ButtonPanel>
          <Button disabled={!canRedeem || loading} onClick={() => setModalAddress(true)}>
            {t('shopRewardClaim.claimButtonText')}
          </Button>
        </ButtonPanel>
      </RightCard>

      <Modal
        visible={modalAdress}
        onCancel={() => setModalAddress(false)}
        style={{
          padding: 0,
        }}
        hideFooter
      >
        <EditAddressForm onSubmit={onSubmitAddress} initialValues={initialAddressValues} />
      </Modal>
    </>
  )
}

export default RightCardComp
