import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Form from 'antd/lib/form/Form'
import { useTranslation } from 'react-i18next'
import { reduxForm } from 'redux-form'
import Button from '../../components/common/Button'
import { InputField, SelectField } from '../../components/common/fields'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { EDIT_USER_FORM_NAME } from '../../services/user/user-query'
import { EditUserAddressFormValues } from '../../services/user/user-typed'
import { mobile } from '../../utils/responsive-helper'
import { useGetCountry, useGetProvince, useGetDistrict, useGetSubDistrict } from '../../services/address/address-query'
import { Select } from 'antd'

const FormLayout = styled(Form)`
  width: 752px;
  height: fit-content;
  padding: 48px;

  ${mobile} {
    width: calc(100vw - 32px);
    padding: 16px;
    .header {
      padding-right: 32px;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .phoneNumber {
    max-width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .email {
    max-width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .address {
    flex: 100%;
    margin-bottom: 24px;
  }.phone {
    flex: 100%;
    margin-bottom: 24px;
  }
  .country {
    flex: 100%;
  }
  .selected {
    max-width: calc(50% - 12px);
  }

  .phoneNumber,
  .email,
  .selected,
  .country,
  .phone
  .address {
    align-items: baseline;
    > div {
      :nth-of-type(1) {
        white-space: nowrap;
      }
    }
  }
  ${mobile} {
    flex-flow: column;
    justify-content: flex-start;

    .phoneNumber,
    .email,
    .selected,
    .country,
    .phone,
    .address {
      width: unset;
      max-width: unset;
      margin-bottom: 16px;
    }

    .address {
      margin-bottom: 16px;
    }
    .phone {
      margin-bottom: 16px;
    }
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`
const InputFieldStyled = styled(InputField)`
  flex: 1;
  
  div {
    font-size: 18px;
    margin-bottom: 0;
  }

  input {
    padding: 5px 17px;
    font-size: 16px;
    line-height: 16px;
  }
`
const SelectContainer = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
    height: 40px; !important;
    margin-bottom: 30px;
  }
`

const enchancer = reduxForm<EditUserAddressFormValues>({
  form: EDIT_USER_FORM_NAME,
  validate: (values) => {
    const error: Partial<EditUserAddressFormValues> = {}
    Object.entries(values).forEach(([k, v]) => {
      const key = k as keyof EditUserAddressFormValues
      if (!v) {
        error[key] = 'Require'
      }
    })
    return error
  },
})

const EditAddressForm = (props: any) => {
  const { initialValues } = props
  const { data: countries } = useGetCountry()
  const [listCountry, setListCountry] = useState([{ label: "", value: "" }])
  const [listProvince, setListProvice] = useState([{ label: "", value: "" }])
  const [listDistrict, setListDistrict] = useState([{ label: "", value: "" }])
  const [listSubDistrict, setListSubDistrict] = useState([{ label: "", value: "" }])

  const [selectedZipCode, setSelectedZipCode] = useState("")
  const [selectedCounrty, setSelectedCounrty] = useState(163)
  const [selectedProvince, setSelectedProvince] = useState(initialValues?.provinceId || 0)
  const [selectedDistrict, setSelectedDistrict] = useState(initialValues?.districtId || 0)
  const [isComfirm] = useState(initialValues?.isComfirm || false)
  const [objectForm, setObjectForm] = useState(initialValues)
  const [isValid, setIsValid] = useState(false)

  const { data: province } = useGetProvince(selectedCounrty)
  const { data: districts } = useGetDistrict(selectedProvince)
  const { data: subDistricts } = useGetSubDistrict(selectedProvince, selectedDistrict)

  const { t } = useTranslation()

  useEffect(() => {
    if (selectedCounrty !== 0 && selectedProvince !== 0 && selectedDistrict !== 0) {
      if (initialValues?.isPhoneNumber && (objectForm?.phoneNumber !== undefined && objectForm?.phoneNumber !== null && objectForm?.phoneNumber !== '')) {
        return setIsValid(true)
      }
      else if (initialValues?.isPhoneNumber && (objectForm?.phoneNumber === undefined || objectForm?.phoneNumber === null || objectForm?.phoneNumber === '')) {
        return setIsValid(false)
      }
      else {
        setIsValid(true)
      }
    }
    else {
      return setIsValid(false)
    }
  }, [objectForm, selectedCounrty, selectedProvince, selectedDistrict, initialValues?.isPhoneNumber])

  useEffect(() => {
    let valueCountry: any = [];

    countries?.map((country) => {
      valueCountry.push({
        label: country.countryName,
        value: country.id,
      });
    });
    setListCountry(valueCountry);

    setObjectForm({
      ...objectForm,
      countryId: 163,
      countryCode: "THA",
      country: "Thailand"
    })

  }, [countries])

  useEffect(() => {
    let valueProvince: any = [];

    province?.map((data) => {
      valueProvince.push({
        label: data.provinceName,
        value: data.id,
      });
    });
    setListProvice(valueProvince);
  }, [province])

  useEffect(() => {
    let valueDistrict: any = [];

    districts?.map((data) => {
      valueDistrict.push({
        label: data.districtName,
        value: data.id,
      });
    });
    setListDistrict(valueDistrict);
  }, [districts])

  useEffect(() => {
    let valueSubDistrict: any = [];

    subDistricts?.map((data) => {
      valueSubDistrict.push({
        label: data.subdistrictName,
        value: data.id,
      });
    });
    setListSubDistrict(valueSubDistrict);
  }, [subDistricts])

  const handleSelectCountry = (countryId: number) => {
    let countryObject = countries?.find(x => x.id === countryId)
    setSelectedCounrty(countryId)

    setObjectForm({
      ...objectForm,
      countryId: countryObject?.id,
      countryCode: countryObject?.countryCode,
      country: countryObject?.countryName
    })
  }

  const handleSelectProvince = (provinceId: number) => {
    let provinceObject = province?.find(x => x.id === provinceId)
    setSelectedProvince(provinceId)

    setObjectForm({
      ...objectForm,
      provinceId: provinceObject?.id,
      provinceCode: provinceObject?.provinceCode,
      province: provinceObject?.provinceName
    })
  }

  const handleSelectDistrict = (districtId: number) => {
    let districtObject = districts?.find(x => x.id === districtId)
    setSelectedDistrict(districtId)

    setObjectForm({
      ...objectForm,
      districtId: districtObject?.id,
      amphoeCode: districtObject?.amphoeCode,
      district: districtObject?.districtName
    })
  }

  const handleSelectSubDistrict = (subDistricteId: number) => {
    let subDistrictObject = subDistricts?.find(x => x.id === subDistricteId)

    setObjectForm({
      ...objectForm,
      districtId: subDistrictObject?.id,
      subdistrictCode: subDistrictObject?.subdistrictCode,
      subDistrict: subDistrictObject?.subdistrictName
    })
  }

  const handlePostCode = (postcode: string) => {
    setObjectForm({
      ...objectForm,
      postcode
    })
  }

  const handleStreet = (street: string) => {
    setObjectForm({
      ...objectForm,
      street
    })
  }

  const handlePhone = (phoneNumber: string) => {
    setObjectForm({
      ...objectForm,
      phoneNumber
    })
  }

  const hanldeValue = (value: any) => {
    props.onSubmit(objectForm)
  }

  return (
    <FormLayout>
      <Text className="header" size={30} type="Bold" color={color.FONT_BLACK}>
        {isComfirm ? 'ยืนยันข้อมูลที่อยู่' : t('setting.editContactHeader')}
      </Text>

      <Content>
        <FlexRow className="phoneNumber">
          <InputFieldStyled
            maxLength={10}
            name="phoneNumber"
            label={t('setting.phoneNumber')}
            onChange={(value: any) =>
              handlePhone(value)
            } />
        </FlexRow>
        <FlexRow className="email">
          <InputFieldStyled name="email" label={t('setting.email')} disabled />
        </FlexRow>
        <FlexRow className="country">
          <div style={{ width: '100%' }}>
            <Text size={18}>{t('setting.contactAddress.country')}</Text>
            {/* <SelectField
              name="country"
              placeholder={t('setting.address')}
              options={listCountry}
              defaultValue={163}
              onChange={(value: any) =>
                handleSelectCountry(value)
              }
            /> */}
            <SelectContainer>
              <Select
                placeholder={t('setting.address')}
                options={listCountry}
                defaultValue={163}
                onChange={(value: any) =>
                  handleSelectCountry(value)
                }
                style={{ width: "100%" }}
              >
                {listCountry.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.value}>
                      <Text size={18} type="Medium">
                        {item.label}
                      </Text>
                    </Select.Option>
                  )
                })}
              </Select>
            </SelectContainer>
          </div>
        </FlexRow>
        <FlexRow className="selected">
          <div style={{ width: '100%' }}>
            <Text size={18}>{t('setting.contactAddress.province')}</Text>
            <SelectField
              name="province"
              options={listProvince}
              disabled={selectedCounrty === 0}
              onChange={(value: any) =>
                handleSelectProvince(value)
              }
            />
          </div>
        </FlexRow>
        <FlexRow className="selected">
          <div style={{ width: '100%' }}>
            <Text size={18}>{t('setting.contactAddress.district')}</Text>
            <SelectField
              name="district"
              options={listDistrict}
              disabled={selectedProvince === 0}
              onChange={(value: any) =>
                handleSelectDistrict(value)
              }
            />
          </div>
        </FlexRow>
        <FlexRow className="selected">
          <div style={{ width: '100%' }}>
            <Text size={18}>{t('setting.contactAddress.subdistrict')}</Text>
            <SelectField
              name="subdistrict"
              disabled={selectedDistrict === 0}
              options={listSubDistrict}
              onChange={(value: any) =>
                handleSelectSubDistrict(value)
              }
            />
          </div>
        </FlexRow>
        <FlexRow className="selected">
          <div style={{ width: '100%' }}>
            <InputFieldStyled
              name="postcode"
              label={t('setting.contactAddress.postcode')}
              onChange={(value: any) =>
                handlePostCode(value)
              }
            />
          </div>
        </FlexRow>
        <FlexRow className="address">
          <InputFieldStyled
            name="street"
            label={t('setting.address')}
            onChange={(value: any) =>
              handleStreet(value)
            }
          />
        </FlexRow>
        {/* {
          initialValues?.isPhoneNumber && (
            <FlexRow className="phone">
              <InputFieldStyled
                name="phoneNumber"
                label={t('setting.phoneNumber')}
                maxLength={10}
                onChange={(value: any) =>
                  handlePhone(value)
                }
              />
            </FlexRow>
          )
        } */}

      </Content>
      <FlexRow>
        <Button onClick={(value) => hanldeValue(value)} disabled={!isValid}>
          {isComfirm ? t('setting.confirmbutton') : t('setting.submitButton')}
        </Button>
      </FlexRow>
    </FormLayout>
  )
}

export default enchancer(EditAddressForm)
