import styled from '@emotion/styled'
import Form from 'antd/lib/form/Form'
import { useTranslation } from 'react-i18next'
import { InjectedFormProps, reduxForm } from 'redux-form'
import Button from '../../components/common/Button'
import { InputField } from '../../components/common/fields'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { EDIT_USER_FORM_NAME } from '../../services/user/user-query'
import { EditUserFormValues } from '../../services/user/user-typed'
import { mobile } from '../../utils/responsive-helper'

const FormLayout = styled(Form)`
  width: 752px;
  height: fit-content;
  padding: 48px;

  ${mobile} {
    width: calc(100vw - 32px);
    padding: 16px;
    .header {
      padding-right: 32px;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .phoneNumber {
    max-width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .email {
    max-width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .address {
    flex: 100%;
    margin-bottom: 48px;
  }

  .phoneNumber,
  .email,
  .address {
    align-items: baseline;
    > div {
      :nth-of-type(1) {
        white-space: nowrap;
      }
    }
  }
  ${mobile} {
    flex-flow: column;
    justify-content: flex-start;

    .phoneNumber,
    .email,
    .address {
      width: unset;
      max-width: unset;
      margin-bottom: 16px;
    }

    .address {
      margin-bottom: 24px;
    }
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`
const InputFieldStyled = styled(InputField)`
  flex: 1;
`

const enchancer = reduxForm<EditUserFormValues>({
  form: EDIT_USER_FORM_NAME,
  validate: (values) => {
    const error: Partial<EditUserFormValues> = {}
    Object.entries(values).forEach(([k, v]) => {
      const key = k as keyof EditUserFormValues
      if (!v) {
        error[key] = 'Require'
      }
    })
    return error
  },
})

type EditContactFormProps = InjectedFormProps<EditUserFormValues>

const EditContactForm = (props: EditContactFormProps) => {
  const { handleSubmit } = props
  const { t } = useTranslation()
  return (
    <FormLayout>
      <Text className="header" size={30} type="Bold" color={color.FONT_BLACK}>
        {t('setting.editContactHeader')}
      </Text>
      <Content>
        <FlexRow className="phoneNumber">
          <InputFieldStyled name="phoneNumber" label={t('setting.phoneNumber')} maxLength={10} />
        </FlexRow>
        <FlexRow className="email">
          <InputFieldStyled name="email" label={t('setting.email')} disabled />
        </FlexRow>
        {/* <FlexRow className="address">
          <InputFieldStyled name="address" label={t('setting.address')} />
        </FlexRow> */}
      </Content>
      <FlexRow>
        <Button onClick={handleSubmit}>{t('setting.submitButton')}</Button>
      </FlexRow>
    </FormLayout>
  )
}

export default enchancer(EditContactForm)
