import styled from '@emotion/styled'
import Image from '../../components/common/Image'
import { mobile } from '../../utils/responsive-helper'
import { Link } from 'react-router-dom'

const ImageStyle = styled(Image)`
  height: 300px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 4px;

  ${mobile} {
    height: 100px;
  }
`

export interface PromotionCategoryImageProps {
  path?: string
  image?: string
}

const PromotionCategoryImage = (props: PromotionCategoryImageProps) => {
  const { image, path } = props

  return (
    <Link to={path || ''}>
      <ImageStyle src={image || ''} />
    </Link>
  )
}

export default PromotionCategoryImage
