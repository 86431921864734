import { InfoCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Divider, Tooltip } from 'antd'
import dayjs from 'dayjs'
import moment, { Moment } from 'moment'
import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form'
import ImageInfographicQuizFalse from '../../assets/images/base/infographic-quiz-false.svg'
import FooterContent from '../../components/FooterContent'
import Button from '../../components/common/Button'
import Image from '../../components/common/Image'
import { InputLabel } from '../../components/common/Input'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import {
  DatePickerField,
  InputField,
  SelectField,
  UploadfileField
} from '../../components/common/fields'
import color from '../../constant/colors'
import * as paths from '../../constant/path'
import { EClaimInf, setClaimInfo } from '../../redux-store/e-claim-reducer'
import {
  useCreateUserClaim,
  useGetRelativeOptionDentsu, useUploadFile
} from '../../services/e-claim/e-claim-query'
import { EClaimFormParams, EClaimItem } from '../../services/e-claim/e-claim-types'
import { useGetUser, useGetUserCoin, useGetUserCoinBenefitYear } from '../../services/user/user-query'
import { useVisibility } from '../../utils/custom-hook'
import { numberWithCommas, useRouter } from '../../utils/helper'
import { mobile, mobileVerySmall } from '../../utils/responsive-helper'
import { useGetPeriodDate } from '../../services/enrollment/enrollment-query'

const ClaimFormContainer = styled.div`
  padding: 0px 16px;
  .layout-upload {
    ${mobile} {
      width: 100%;
    }
    ${mobileVerySmall} {
      width: 100%;
    }
  }
  ${mobile} {
    padding: 16px;
  }
  ${mobileVerySmall} {
    padding: 16px;
  }
`

const Spacer = styled.div`
  width: 20px;
  height: 20px;

  ${mobile} {
    display: none;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const InputRelative = styled.div`
  padding: 13px 17px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 16px;
  line-height: 18px;
  height: 100%;
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
  font-family: IBMPlexSansThai-Medium;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const CustomInput = styled(InputField)`
  width: 100%;

  input.ant-input {
    width: 100%;
  }
`
const CustomDivField = styled.div`
  width: 100%;
  margin-bottom: 36px;
`

const CustomEditableFooter = styled(FooterContent)`
  height: 80;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  ${mobile} {
    justify-content: center;
  }
`

const CustomUnEditableFooter = styled(FooterContent)`
  height: 80;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  ${mobile} {
    flex-direction: column;
    justify-content: center;
  }
`

const SumPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  ${mobile} {
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const SubmitButton = styled(Button)`
  width: 267px;
`
const TextModal = styled(Text)`
  text-align: center;
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 30%;
  /* border: 1px solid; */
  margin-bottom: 8px;
  padding-bottom: 0;

  ${mobile} {
    width: 100%;
  }
`

const ModalContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 18px;
`


const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;

  ${mobile} {
    flex-direction: column;
  }
`

const EClaimStatusContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 2px 6px;
  margin-bottom: 10px;
  width: fit-content;
`

const HistoryContainer = styled.div`
  margin-bottom: 24px;
  border-bottom: 2px solid #C6C6C6;
`

export const ECLAIM_FORM_NAME = 'ECLAIM_FORM'
type EClaimParam = {
  eClaimItem?: EClaimItem
  editable?: boolean
  eClaimFormValue?: EClaimInf['claimInfo']
  isPreview?: boolean
  allpayStatus?: boolean
  isHistory?: boolean
}
const required = (value: string) => (value ? undefined : 'error.required')
const requiredDate = (value: Moment) => {
  const x = moment(value).isValid() ? undefined : 'error.required'
  return x
}
const EClaimForm: ComponentType<
  EClaimParam & InjectedFormProps<EClaimFormParams, EClaimParam, string>
> = (props) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    invalid,
    eClaimItem,
    editable = true,
    eClaimFormValue,
    isPreview = false,
    allpayStatus,
    isHistory = false,
    initialValues
  } = props

  const { data: relativesOptions, refetch: refetchGetRelative } = useGetRelativeOptionDentsu()
  const formValuesSelector = getFormValues(ECLAIM_FORM_NAME)
  const formValues = useSelector<any, EClaimFormParams>(
    (state) => formValuesSelector(state) as EClaimFormParams,
  )

  const [visible, modalAction] = useVisibility()
  const [imageErrorVisible, imageErrorAction] = useVisibility()
  const [errorMessage, setErrorMessage] = useState('')
  const { data: user } = useGetUser()
  const { firstName, lastName } = user || {}

  const fileName = `${firstName} ${lastName}`
  const history = useHistory()
  const { query } = useRouter<{ eClaimId: number }>()
  const { data: enrollmentPeriod } = useGetPeriodDate()
  const { benefitYearId = 0 } = enrollmentPeriod || {}
  // const { data: userCoin = 0 } = useGetUserCoin()
  const { data: userCoin } = useGetUserCoinBenefitYear(benefitYearId)

  const { mutate: createUserClaim, isLoading: isSubmitting } = useCreateUserClaim()
  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFile()

  useEffect(() => { refetchGetRelative() }, [history])

  const getPoint = useMemo((code = 'dentsu_coin') => {
    if (!userCoin || userCoin.length === 0) return 0
    const coin = userCoin.find(v => v.code === code)

    if (!coin?.balance) return 0;

    return coin?.balance
  }, [userCoin])

  const claimRequired = useMemo(() => [2, 7], []) // ?? : claim id is required document
  const amountFormated = useMemo(() => {
    return eClaimFormValue?.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })
  }, [eClaimFormValue?.amount])

  const userRestCoinFormated = useMemo(() => {
    const restCoin = getPoint - (eClaimFormValue?.amount || 0)
    return restCoin.toLocaleString(undefined, { maximumFractionDigits: 2 })
  }, [getPoint, eClaimFormValue])

  const convertBase64ToFile = useCallback(async (imageBase64: string) => {
    const res = await fetch(imageBase64)
    const blobFile = await res.blob()
    const file = new File([blobFile], `${Date.now()}.${blobFile.type.split('/')[1].split(';')[0]}`, { type: 'image/png' })
    return file
  }, [])

  const onSubmit = useCallback(
    (form: EClaimFormParams) => {
      const hasSomeImage = formValues?.receiptFile

      if (!hasSomeImage) {
        imageErrorAction.show()
      } else {
        dispatch(
          setClaimInfo({
            id: eClaimItem?.id,
            name: form.name,
            paymentDate: moment(form.paymentDate),
            amount: form.amount,
            citizenFileUrl: form.citizenFileUrl,
            receiptFile: form.receiptFile,
            userRemark: form.userRemark,
            relativeTypeId: form.relativeTypeId,
            relativeTitle: form.relativeTitle,
            relativeFirstName: form.relativeFirstName,
            relativeLastName: form.relativeLastName,
            allpayRef: form.allpayRef,
            slipDate: form.slipDate,
            amountClaim: form.amountClaim,
            nameShop: form.nameShop,
            slipNo: form.slipNo,
          }),
        )
        if (editable === true) {
          history.push(paths.eClaimSummary({ routeParam: { eClaimId: eClaimItem?.claimId || 0 } }))
        }
      }
    },
    [
      imageErrorAction,
      eClaimItem,
      formValues,
      dispatch,
      editable,
      history
    ],
  )

  const getImageUploadUrl = useCallback(async () => {
    const citizenImage = await convertBase64ToFile(eClaimFormValue?.citizenFileUrl || '')
    const receiptImage = await convertBase64ToFile(eClaimFormValue?.receiptFile || '')
    const citizenImageURL = uploadFile(citizenImage)
    const receiptImageURL = uploadFile(receiptImage)

    return await Promise.all([citizenImageURL, receiptImageURL])
  }, [
    convertBase64ToFile,
    eClaimFormValue?.citizenFileUrl,
    eClaimFormValue?.receiptFile,
    uploadFile,
  ])

  const isValidUrl = (urlString: string | undefined) => {
    try {
      if (!urlString) return false
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }

  const onSubmitApplication = useCallback(async () => {
    //uploadfile
    try {
      let receiptFile;
      let citizenFileUrl;

      if (isValidUrl(receiptFile) || isValidUrl(citizenFileUrl)) {
        receiptFile = receiptFile;
        citizenFileUrl = citizenFileUrl;
      } else {
        const [citizenImageURL, receiptImageURL] = await getImageUploadUrl()
        receiptFile = eClaimFormValue?.receiptFile ? receiptImageURL.link : '';
        citizenFileUrl = eClaimFormValue?.citizenFileUrl ? citizenImageURL.link : '';
      }

      const req = {
        id: eClaimFormValue?.id,
        claimId: eClaimItem?.claimId || 0,
        claimDate: eClaimFormValue?.paymentDate!,
        claimName: fileName,
        citizenFileUrl,
        receiptFile,
        exchangeCoin: eClaimFormValue?.amount!,
        userRemark: eClaimFormValue?.userRemark || '',
        relativeTypeId: eClaimFormValue?.relativeTypeId === 0 ? undefined : eClaimFormValue?.relativeTypeId,
        relativeTitle: eClaimFormValue?.relativeTitle,
        relativeFirstName: eClaimFormValue?.relativeFirstName,
        relativeLastName: eClaimFormValue?.relativeLastName,
        allpayRef: eClaimFormValue?.allpayRef || '',
        slipDate: eClaimFormValue?.slipDate!,
        amountClaim: eClaimFormValue?.amountClaim!,
        nameShop: eClaimFormValue?.nameShop || '',
        slipNo: eClaimFormValue?.slipNo || ''
      }

      createUserClaim(
        req,
        {
          onSuccess: () => {
            history.push(
              paths.eClaimCompleted({
                routeParam: { eClaimId: eClaimItem?.claimId || 0 },
                queryParam: {
                  eClaimName: eClaimItem?.claimName || '',
                  eClaimPrice: eClaimFormValue?.amount || 0,
                },
              }),
            )
          },
          onError: (error) => {
            setErrorMessage(error.message)
            modalAction.show()
          },
        },
      )
    } catch (error) {
      console.log(error)
    }
  }, [getImageUploadUrl, eClaimFormValue?.id, eClaimFormValue?.paymentDate, eClaimFormValue?.receiptFile, eClaimFormValue?.amount, eClaimFormValue?.userRemark, eClaimFormValue?.relativeTypeId, eClaimFormValue?.relativeTitle, eClaimFormValue?.relativeFirstName, eClaimFormValue?.relativeLastName, eClaimFormValue?.allpayRef, eClaimFormValue?.slipDate, eClaimFormValue?.amountClaim, eClaimFormValue?.nameShop, eClaimFormValue?.slipNo, eClaimItem?.claimId, eClaimItem?.claimName, fileName, createUserClaim, history, modalAction])

  const validateClaimAmount = useCallback(
    (value) => {
      if (eClaimItem?.claimPrice && value > eClaimItem?.claimPrice) return 'eClaim.claimPriceError'
    },
    [eClaimItem?.claimPrice],
  )

  const isEnoughCoin = useCallback(
    (value) => {
      if (value > getPoint) return 'eClaim.PointError'
    },
    [getPoint])

  const isNotZero = useCallback(
    (value) => {
      if (value === '0') return 'error.zero'
    }
    , [])

  const amountMoreThanAmountClaim = useCallback(
    (value) => {
      const amount = formValues?.amount || 0
      if (Number(value) < Number(amount)) return 'error.amountMoreThanAmountClaim'
    }
    , [formValues])

  const amountClaimLessThanAmount = useCallback(
    (value) => {
      const amountClaim = formValues?.amountClaim || 0
      if (Number(value) > Number(amountClaim)) return 'error.amountMoreThanAmountClaim'
    }
    , [formValues])

  const isClaimRequired = useMemo(() => {
    return claimRequired.some(val => val == query.eClaimId)
  }, [claimRequired, query.eClaimId])

  const validateImage = useCallback((isRequired) => {
    if (isPreview === false && isRequired && isClaimRequired) {
      return [required]
    } else {
      return undefined
    }
  }, [isClaimRequired, isPreview])

  const isClose = useMemo(() => isHistory || isPreview, [isHistory, isPreview])

  const isFamily = useMemo(() => eClaimItem?.claimTypeGroupId === 3 || (initialValues?.relativeTypeId && initialValues.relativeTypeId > 0) ? true : false, [])

  const relativeLists = useMemo(() => {
    let optionRelative: {
      label: string
      value: number
    }[] = []

    if ((isFamily) && relativesOptions)
      optionRelative.push({ label: 'โปรดเลือก', value: 0 }, ...relativesOptions)
    else if (!isFamily)
      optionRelative = [{ label: 'ตัวเอง', value: 0 }]

    return optionRelative;
  }, [isFamily, relativesOptions])

  const nameRelative = useCallback(
    (relativeId) => {
      const data = relativeLists?.find(({ value }) => relativeId === value)
      return data ? data.label : 'ตัวเอง'
    }, [relativeLists])

  const disbleField = allpayStatus ? true : false

  const statusCode = useCallback((text: string) => {
    let statusCode = ''
    switch (text) {
      case 'Approved':
        statusCode = '#98CF6D'
        break;
      case 'Rejected':
        statusCode = '#DF795E'
        break;
      case 'Waiting for Approve':
        statusCode = '#F8F2DB'
        break;
      default: statusCode = text
        break;
    }
    return statusCode
  }, [])

  const TextColor = useCallback((text: string) => {
    let TextColor = ''
    switch (text) {
      case 'Approved':
        TextColor = '#FFF'
        break;
      case 'Rejected':
        TextColor = '#FFF'
        break;
      case 'Waiting for Approve':
        TextColor = '#DFC35F'
        break;
      default: TextColor = text
        break;
    }
    return TextColor
  }, [])

  return (
    <>
      <Modal
        visible={visible}
        onCancelText="ตกลง"
        onCancel={() => {
          modalAction.hide()
        }}
      >
        <div style={{ margin: '30px' }}>
          <TextModal size={24} type="Bold">
            {errorMessage}
          </TextModal>
          <TextModal>กรุณาตรวจสอบอีกครั้ง</TextModal>
          <Divider />
        </div>
      </Modal>

      <Modal
        onCancelText="ตกลง"
        visible={imageErrorVisible}
        onCancel={() => {
          imageErrorAction.hide()
        }}
      >
        <ModalContainer>
          <Image src={ImageInfographicQuizFalse} />
          <Text size={16} type="Bold">กรุณาแนบใบกำกับภาษี/ใบเสร็จ/ใบแทนใบเสร็จ</Text>
        </ModalContainer>
      </Modal>


      <ClaimFormContainer>
        <div style={{ backgroundColor: color.WHITE, borderRadius: '5px', padding: 8 }}>
          {isHistory &&
            <HistoryContainer>
              <Text size={24} type="Bold">
                รายละเอียดการเบิก
              </Text>

              <InputContainer>
                <FlexCol>
                  <InputLabel>วันที่ขอเบิก</InputLabel>
                  <Text>{dayjs(eClaimItem?.createdDate).format('DD MMM YYYY')}</Text>
                </FlexCol>
              </InputContainer>

              <InputContainer>
                <FlexCol>
                  <InputLabel>สถานะ</InputLabel>
                  <EClaimStatusContainer backgroundColor={statusCode(eClaimItem?.acceptName || '')}>
                    <Text size={16} color={TextColor(eClaimItem?.acceptName || '')}>{eClaimItem?.acceptName}</Text>
                  </EClaimStatusContainer>
                </FlexCol>

              </InputContainer>


              <InputContainer>
                <FlexCol>
                  <InputLabel>วันที่อนุมัติ/ปฏิเสธ</InputLabel>
                  <Text>{eClaimItem?.acceptName && eClaimItem?.acceptName === 'Waiting for Approve' ? '-' : `${dayjs(eClaimItem?.approveDate || eClaimItem?.refundDate).format('DD MMM YYYY')}`}</Text>
                </FlexCol>

                <FlexCol>
                  <InputLabel>เหตุผลที่ปฏิเสธ</InputLabel>
                  <Text>{eClaimItem?.remark || '-'}</Text>
                </FlexCol>
              </InputContainer>
            </HistoryContainer>

          }

          <Text size={24} type="Bold">
            {editable ? 'กรอกรายละเอียดการเบิกสวัสดิการ' : 'ตรวจสอบรายละเอียด '}
          </Text>
          <InputContainer>
            {!editable && (
              <FlexCol>
                <InputLabel>ชื่อ - นามสกุล ผู้ขอเบิก</InputLabel>
                <Text type="Bold">{fileName}</Text>
              </FlexCol>
            )}
            {editable && (
              <CustomDivField>
                <InputLabel>ชื่อ - นามสกุล ผู้ขอเบิก</InputLabel>
                {/* <CustomInput
                  name="fileName"
                  label="ชื่อ - นามสกุล ผู้ขอเบิก"
                  placeholder="ชื่อ - นามสกุล ผู้ขอเบิก"
                  disabled
                  value={fileName}
                /> */}
                <InputRelative>{fileName}</InputRelative>
              </CustomDivField>
            )}

            <Spacer />

            {!editable && (
              <FlexCol>
                <InputLabel>เบิกให้</InputLabel>
                {
                  isHistory ? <Text type="Bold">{eClaimItem?.goodReceiver || 'ตัวเอง'}</Text> : <Text type="Bold">   {nameRelative(eClaimFormValue?.relativeTypeId || initialValues?.relativeTypeId) || 'ตัวเอง'}</Text>
                }
              </FlexCol>
            )}

            {editable && (
              <CustomDivField>
                {eClaimItem?.claimTypeGroupId === 3 ? (
                  <InputLabel>
                    เบิกให้
                    <Tooltip
                      title='กรณีต้องการเพิ่มรายชื่อครอบครัว ให้คลิกรายชื่อครอบครัวที่ด้านบน'
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </InputLabel>
                ) : (
                  <InputLabel>เบิกให้</InputLabel>
                )}
                {
                  eClaimItem?.claimTypeGroupId === 3 ?
                    <div style={{ height: 48 }}>
                      <SelectField
                        name="relativeTypeId"
                        placeholder="เบิกให้"
                        validate={eClaimItem?.claimTypeGroupId !== 3 ? [] : [required, isNotZero]}
                        defaultValue={0}
                        options={relativeLists?.filter(value => value.value !== null) || []}
                        disabled={disbleField || eClaimItem?.claimTypeGroupId !== 3}
                      />
                    </div>
                    :
                    <>
                      <InputRelative>
                        {nameRelative(eClaimFormValue?.relativeTypeId || initialValues?.relativeTypeId) || 'ตัวเอง'}
                      </InputRelative>
                    </>
                }
              </CustomDivField>
            )}
          </InputContainer>


          <InputContainer>
            <FlexCol>
              {editable && (
                <DatePickerField
                  name="paymentDate"
                  label="วันที่เบิก"
                  validate={[requiredDate]}
                  disabled={disbleField}
                />
              )}
              {!editable && <InputLabel>วันที่เบิก</InputLabel>}
              {!editable && (
                <Text type="Bold">{!isHistory ? eClaimFormValue?.paymentDate.format('DD/MM/YYYY') : dayjs(eClaimItem?.claimDate).format('DD MMM YYYY')}</Text>
              )}
            </FlexCol>

            <Spacer />

            <FlexCol>
              {editable && (
                <CustomInput
                  name="amount"
                  label="จำนวนเงินที่เบิก"
                  placeholder="ใส่จำนวนเงินที่เบิก"
                  validate={[required, validateClaimAmount, isEnoughCoin, isNotZero, amountClaimLessThanAmount]}
                  inputType="number"
                  disabled={disbleField}
                />
              )}
              {!editable && <InputLabel>จำนวนเงินที่เบิก</InputLabel>}
              {!editable && (
                <Text type="Bold">
                  {isHistory ? numberWithCommas(Number(eClaimItem?.userClaimPrice)) || 0 : (eClaimFormValue?.amount ? numberWithCommas(Number(eClaimFormValue?.amount)) : 0)}
                </Text>
              )}
            </FlexCol>

          </InputContainer>

          <InputContainer>
            <FlexCol>
              {editable && (
                <DatePickerField
                  name="slipDate"
                  label="วันที่ในใบเสร็จ"
                  validate={[requiredDate]}
                  disabled={disbleField}
                />
              )}
              {!editable && <InputLabel>วันที่ในใบเสร็จ</InputLabel>}
              {!editable && (
                <Text type="Bold">{!isHistory ? moment(eClaimFormValue?.slipDate).format('DD/MM/YYYY') : moment(eClaimItem?.slipDate).format('DD MMM YYYY')}</Text>
              )}
            </FlexCol>
            <Spacer />

            <FlexCol>
              {editable && (
                <CustomInput
                  name="amountClaim"
                  label="จำนวนเงินในใบเสร็จ"
                  placeholder="ใส่จำนวนเงินในใบเสร็จ"
                  validate={[required, isNotZero, amountMoreThanAmountClaim]}
                  inputType="number"
                  disabled={disbleField}
                />
              )}
              {!editable && <InputLabel>จำนวนเงินในใบเสร็จ</InputLabel>}
              {!editable && (
                <Text type="Bold">
                  {isHistory ? numberWithCommas(Number(eClaimItem?.amountClaim)) || 0 : (eClaimFormValue?.amountClaim ? numberWithCommas(Number(eClaimFormValue?.amountClaim)) : 0)}
                </Text>
              )}
            </FlexCol>
          </InputContainer>

          <InputContainer>
            <FlexCol>
              {editable && (
                <CustomInput
                  name="nameShop"
                  label="ชื่อร้านค้า"
                  placeholder="ใส่ชื่อร้านค้า"
                  validate={[required]}
                  inputType="default"
                  disabled={disbleField}
                />
              )}
              {!editable && <InputLabel>ชื่อร้านค้า</InputLabel>}
              {!editable && (
                <Text type="Bold">
                  {!isHistory ? eClaimFormValue?.nameShop || '-' : eClaimItem?.nameShop || '-'}
                </Text>
              )}
            </FlexCol>
            <Spacer />

            <FlexCol>
              {editable && (
                <CustomInput
                  name="slipNo"
                  label="เลขที่ในใบเสร็จ"
                  placeholder="ใส่เลขที่ในใบเสร็จ"
                  validate={[required]}
                  inputType="default"
                  disabled={disbleField}
                />
              )}
              {!editable && <InputLabel>เลขที่ในใบเสร็จ</InputLabel>}
              {!editable && (
                <Text type="Bold">
                  {!isHistory ? eClaimFormValue?.slipNo || '-' : eClaimItem?.slipNo || '-'}
                </Text>
              )}
            </FlexCol>
          </InputContainer>

          <InputContainer>
            <FlexCol>

              {editable && (
                <CustomInput
                  name="userRemark"
                  label="หมายเหตุ"
                  placeholder="รายละเอียด"
                  disabled={disbleField}
                />
              )}
              {!editable && <InputLabel>หมายเหตุ</InputLabel>}
              {!editable && (
                <Text type="Bold">{isHistory ? eClaimItem?.userRemark || '-' : eClaimFormValue?.userRemark ? eClaimFormValue?.userRemark : '-'} </Text>
              )}
            </FlexCol>

          </InputContainer>

          <Text size={24} type="Bold">
            {isHistory ? 'เอกสารแนบ' : 'แนบเอกสาร'}
          </Text>
          <FileUploadContainer>
            {(eClaimFormValue?.receiptFile || isPreview === false) && (
              <UploadfileField
                name="receiptFile"
                icon={'receiptIcon'}
                title={`1${isClaimRequired ? "(*)" : ""}. ใบกำกับภาษี/ใบเสร็จ/ใบแทนใบเสร็จ`}
                desc={'(jpg, png, pdf ขนาดไม่เกิน 10 MB)'}
                value={eClaimFormValue?.receiptFile || eClaimItem?.receiptFile}
                isPreview={isClose}
                validate={validateImage(true)}
                maximumFileSize={10}
                disabled={disbleField}
              />
            )}
          </FileUploadContainer>
          <FileUploadContainer>
            {(eClaimFormValue?.citizenFileUrl || isPreview === false) && (
              <UploadfileField
                name="citizenFileUrl"
                icon={'receiptIcon'}
                title={`2${isClaimRequired ? "(*)" : ""}. เอกสารประกอบอื่น ๆ ตามเงื่อนไข เช่น ใบรับรองแพทย์`}
                desc={'(jpg, png, pdf ขนาดไม่เกิน 10 MB)'}
                value={eClaimFormValue?.citizenFileUrl || eClaimItem?.citizenFileUrl}
                isPreview={isClose}
                validate={validateImage(true)}
                maximumFileSize={10}
                disabled={disbleField}
              />
            )}
          </FileUploadContainer>
        </div>
      </ClaimFormContainer>
      {
        !isHistory && (
          <>
            {editable ? (
              <CustomEditableFooter>
                <SubmitButton onClick={handleSubmit(onSubmit)} disabled={invalid || disbleField}>
                  ถัดไป
                </SubmitButton>
              </CustomEditableFooter>
            ) : (
              <CustomUnEditableFooter>
                <SumPointContainer>
                  <Text type="Bold">รวมใช้เหรียญ</Text>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text type="Bold" color={color.INPUT_ERROR}>
                      -{amountFormated}
                    </Text>
                    &nbsp;
                    <Text color={color.FONT_BLACK}>เหรียญ</Text>&nbsp;
                    <Text color={color.FONT_LIGHT_GRAY}>{`(คงเหลือ ${userRestCoinFormated} เหรียญ)`}</Text>
                  </div>
                </SumPointContainer>

                <SubmitButton onClick={onSubmitApplication} disabled={isSubmitting || isUploading}>
                  ยื่นคำขอ
                </SubmitButton>
              </CustomUnEditableFooter>
            )}</>
        )
      }

    </>
  )
}

export default reduxForm<EClaimFormParams, EClaimParam, string>({
  form: ECLAIM_FORM_NAME,
  // initialValues: {
  //   relativeTypeId: 'ตัวเอง',
  // },
})(EClaimForm)
