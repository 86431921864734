import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

export const mobileVerySmall = '@media(max-width: 320px)'
export const mobile = '@media(max-width: 767px)'
export const iphoneXLandscape = '(max-width: 850px) and (orientation: landscape)'
export const tablet = '@media(max-width: 1024px)'
export const desktop = '@media(min-width: 1025px)'
export const ipadPro = `@media (min-width: 1024px) and (orientation: portrait)`

export const useScreen = () => {
  const isMobileVerySmall = useMediaQuery({ maxWidth: 320 })
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isIphoneXLandscape = useMediaQuery({ maxWidth: 812, orientation: 'landscape' })
  const isTablet = useMediaQuery({ maxWidth: 1024 })
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  const isIpadPro = useMediaQuery({ minWidth: 1024, orientation: 'portrait' })
  
  const screen = useMemo(
    () => ({
      isMobileVerySmall,
      isMobile,
      isIphoneXLandscape,
      isTablet,
      isDesktop,
      isIpadPro,
    }),
    [isMobileVerySmall, isMobile, isIphoneXLandscape, isTablet, isDesktop, isIpadPro],
  )
  return screen
}

export const hideScrollStyle = `
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
