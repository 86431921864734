import { useCallback, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import Breadcrumb from '../../../components/common/Breadcrumb'
import Text from '../../../components/common/Text'
import Loading from '../../../components/common/Loading'
import Button from '../../../components/common/Button'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import BenefitsCard from './BenefitsCard'
import Image from '../../../components/common/Image'
import SelfBenefits from './SelfBenefits'
import RelativeBenefits from './RelativeBenefits'
import LifeStyleBenefits from './LifeStyleBenefits'
import SummaryExchangeBenefits from './SummaryExchangeBenefits'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { enrollmentInsuranceFamily, enrollmentLeave } from '../../../constant/path'
import color from '../../../constant/colors'
import { useGetUser, useGetUserCoinBenefitYear } from '../../../services/user/user-query'
import {
  useGetAllSelectedHealthCheckupBenefits,
  useGetPeriodDate,
} from '../../../services/health-checkup/health-checkup-query'

import {
  useGetGroupNumber,
} from '../../../services/e-claim/e-claim-query'
import dayjs from 'dayjs'
import { pullAt } from 'lodash'
import {
  getShowModalDownloadHealthCheckupDetail,
  isScg,
  setShowModalDownloadHealthCheckupDetail,
  useCanEditable,
  useExpat,
  useProbation,
} from '../../../utils/helper'
import { Modal } from '../../../components/common/Modal'
import { useVisibility } from '../../../utils/custom-hook'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Footer } from '../ModalAcceptPrivacy'
import { useEffect } from 'react'
import { useGetEnrollmentYear } from '..'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux-store'

const Layout = styled.div`
  margin-bottom: 40px;
`

const BreadcrumbLayout = styled.div`
  margin-top: 24px;

  ${mobile} {
    margin: 24px 16px;
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row;
`

const PaddingTextTop = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  align-self: center;
`

const LayoutTitle = styled(Row)`
  justify-content: center;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 27px;
`

const LayoutContentModal = styled.div`
  width: 500px;
  padding: 12px;
  ${mobile} {
    width: auto;
  }
`

const FooterModal = styled(Footer)`
  .check-box {
    margin-bottom: 0px;
  }
`

const ButtonStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${color.WHITE};
  border-radius: 5px;
  padding: 16px 32px 16px 32px;
`

const BenefitSelectButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  min-width: 165px;
`
const ContentSubmitInsuranceModal = styled.div`
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`
const FooterSubmitInsuranceModal = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  padding: 24px;
`

const Benefits = () => {
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const history = useHistory()
  const {
    startDate: enrollmentStartEnrollmentDate = 0,
    endDate: enrollmentEndEnrollmentDate = 0,
    startPeriodDate: enrollmentStartPeriodDate = 0,
    endPeriodDate: enrollmentEndPeriodDate = 0,
    benefitYearId: enrollmentBenefitYearId = 0,
    benefitYear: enrollmentBenefitYear = 0
  } = useSelector((state: RootState) => state.enrollment.enrollment,)
  const { data: periodDate, isLoading: isLoadPeriodDate, isError: isErrorPeriod } = useGetPeriodDate()

  const {
    startDate: periodStartEnrollmentDate = 0,
    endDate: periodEndEnrollmentDate = 0,
    startPeriodDate: periodStartPeriodDate = 0,
    endPeriodDate: periodEndPeriodDate = 0,
    benefitYearId: periodBenefitYearId = 0,
    benefitYear: periodBenefitYear = 0,
  } = periodDate || {}

  const benefitYearId = useMemo(() => enrollmentBenefitYearId || periodBenefitYearId, [enrollmentBenefitYearId, periodBenefitYearId])
  const startDate = useMemo(() => enrollmentStartEnrollmentDate || periodStartEnrollmentDate, [enrollmentStartEnrollmentDate, periodStartEnrollmentDate])
  const endDate = useMemo(() => enrollmentEndEnrollmentDate || periodEndEnrollmentDate, [enrollmentEndEnrollmentDate, periodEndEnrollmentDate])
  const startPeriodDate = useMemo(() => enrollmentStartPeriodDate || periodStartPeriodDate, [enrollmentStartPeriodDate, periodStartPeriodDate])

  const { data: user } = useGetUser()
  const { data: groupNumber } = useGetGroupNumber()
  const { data: userCoin } = useGetUserCoinBenefitYear(benefitYearId)
  const { data: selectedHealthCheckupBenefits, isLoading } = useGetAllSelectedHealthCheckupBenefits(benefitYearId)

  const age = user?.age ? `${user.age}` : '-'
  const name = `${user?.firstName} ${user?.lastName} (${age})`

  // TODO:เช็คว่า user เป็นชาวต่างชาติหรือไม่
  const isExpat = useExpat()
  // TODO:เช็คว่า user ผ่านโปรหรือยัง
  const isProbation = useProbation()
  // TODO:เช็คว่าอยู่ในช่วงการเลือกแผนประกันหรือไม่
  const canEditable = useCanEditable(startDate, endDate)
  // TODO:เช็คว่า user ผ่านโปรหลัง ช่วง benefit year หรือไม่
  const isProbationStartPeriod = useMemo(() => user?.probationDate >= startPeriodDate, [startPeriodDate, user?.probationDate])

  const [modalInsuranceDetail, setModaInsuranceDetail] = useVisibility()
  const [hideModalInsuranceDetailAgain, setHideModalInsuranceDetailAgain] = useState(
    getShowModalDownloadHealthCheckupDetail() === 'hide' ? true : false,
  )
  const [modalInsuranceSubmit, setModalInsuranceSubmit] = useState(false)


  const getPoint = useMemo((code = 'dentsu_coin') => {
    if (!userCoin || userCoin.length === 0) return 0
    const coin = userCoin.find(v => v.code === code)

    if (!coin?.balance) return 0;

    return coin?.balance
  }, [userCoin])

  const onClickToInsuranceFamily = useCallback(() => {
    if (benefitYearId) {
      history.push(
        enrollmentInsuranceFamily({
          routeParam: { benefitYearId },
        }),
      )
    }
  }, [benefitYearId, history])

  const onClickToExchangeLeave = useCallback(() => {
    if (benefitYearId) {
      history.push(enrollmentLeave({ routeParam: { benefitYearId } }))
    }
  }, [benefitYearId, history])

  useEffect(() => {
    if (
      (getShowModalDownloadHealthCheckupDetail() === 'show' ||
        !getShowModalDownloadHealthCheckupDetail()) &&
      !isProbation && !isExpat
    ) {
      setModaInsuranceDetail.show()
    }
  }, [])

  const onCloseModalInsuranceDetail = useCallback(() => {
    setModaInsuranceDetail.hide()
  }, [setModaInsuranceDetail])

  const onClickDownloadInsuranceDetail = useCallback(() => {
    window.open(`https://v14dentsu4uat.blob.core.windows.net/v1-flex-media/dentsu/Annual Health check up_Program ${groupNumber?.healthCheckupGroup?.substring(2, 3)}.pdf`)
  }, [groupNumber])

  const onClickShowModalInsuranceDetail = useCallback(() => {
    setModalInsuranceSubmit(true)
  }, [])
  const onClickHideModalInsuranceDetail = useCallback(() => {
    setModalInsuranceSubmit(false)
  }, [])

  const onChangeInsurance = useCallback(() => { }, [])

  return (
    <Layout>
      <Modal visible={modalInsuranceDetail} onCancel={onCloseModalInsuranceDetail} hideFooter>
        <LayoutContentModal>
          <Text style={{ textAlign: 'center' }}>
            {t('healthCheckup.selfBenefits.modalInsuranceDetail.detail')}
          </Text>
          <Row style={{ marginTop: '36px', marginBottom: '18px' }}>
            <Image
              imageName="iconPDF"
              width={73}
              height={73}
              style={{ margin: 'auto', cursor: 'pointer' }}
              onClick={onClickDownloadInsuranceDetail}
            />
          </Row>
          {/* <Text style={{ textAlign: 'center', marginBottom: '36px' }}>
            {t('healthCheckup.selfBenefits.modalInsuranceDetail.title')}
          </Text> */}

          <FooterModal>
            <Checkbox
              className="check-box"
              onChange={(v) => {
                var isHide = !hideModalInsuranceDetailAgain
                setHideModalInsuranceDetailAgain((prev) => !prev)
                if (isHide) {
                  setShowModalDownloadHealthCheckupDetail('hide')
                } else {
                  setShowModalDownloadHealthCheckupDetail('show')
                }
              }}
              defaultChecked={hideModalInsuranceDetailAgain}
              checked={hideModalInsuranceDetailAgain}
              autoFocus={true}
            >
              <Text size={18} color={color.FONT_BLACK}>
                {t('healthCheckup.selfBenefits.modalInsuranceDetail.notShowAgain')}
              </Text>
            </Checkbox>
          </FooterModal>
        </LayoutContentModal>
      </Modal>
      {/* {!isMobile && ( */}
      <BreadcrumbLayout>
        <Breadcrumb
          overide={(paths) => {
            paths.unshift({ label: 'Home', path: '/' })
            paths[1].label = t('app.breadcrumb.myWelFare')
            paths[2].label = t('app.breadcrumb.myHealthCheckup')
            pullAt(paths, 4)
            return paths
          }}
        />
      </BreadcrumbLayout>
      {/* )} */}
      {/* <LayoutTitle>
        <Text size={28} type="Bold" color={color.FONT_BLACK}>
          แผนประกันของคุณประจำปี {benefitYear}
        </Text>
      </LayoutTitle> */}
      {/* <Row style={{ justifyContent: 'space-between' }}>
        <PaddingTextTop></PaddingTextTop>
        <PaddingTextTop>
          <div
            style={{
              height: '18px',
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
            onClick={setModaInsuranceDetail.show}
          >
            <Row>
              <Image
                imageName="iconEye"
                width={20}
                height={15}
                style={{ margin: 'auto', marginRight: '8px' }}
              />
              <div hidden={isMobile}>
                <Text size={18}>ดูรายละเอียดกรมธรรม์</Text>
              </div>
            </Row>
          </div>
        </PaddingTextTop>
      </Row> */}
      {/* <BenefitsCard icon={'iconUserWhite'} title={'ประกันตัวเอง'} desc={name}> */}

      <Loading loading={isLoading}>
        {selectedHealthCheckupBenefits && (
          <SelfBenefits
            selectedHealthCheckupBenefits={selectedHealthCheckupBenefits}
            canEditable={canEditable}
            endDate={endDate}
            isProbationStartPeriod={isProbationStartPeriod}
            onClickShowModalInsuranceDetail={onClickShowModalInsuranceDetail}
          />
        )}
      </Loading>
      {/* </BenefitsCard> */}
      {/* <Loading loading={isLoading}>
        {relativeBenefits && (
          <RelativeBenefits relativeBenefits={relativeBenefits} canEditable={canEditable} />
        )}
      </Loading> */}
      {canEditable === true && isScg === false && (
        <BenefitsCard
          icon={'iconUserGroupWhite'}
          title={'ประกันสุขภาพเพิ่มเติมให้ครอบครัว'}
          desc={'สำหรับ คู่สมรส บุตร บิดา มารดา เท่านั้น'}
          isDesc={true}
        >
          <ButtonStyle>
            <Button onClick={onClickToInsuranceFamily}>+แก้ไขประกันให้ครอบครัว</Button>
          </ButtonStyle>
        </BenefitsCard>
      )}
      {/* {isScg === false && (
        <BenefitsCard
          icon={'iconTripWhite'}
          title={'สวัสดิการเพื่อไลฟ์สไตล์'}
          desc={'คุณสามารถแลกสวัสดิการแบบเลือกเองได้'}
          isDesc={true}
        >
          <Loading loading={isLoading}>
            {lifeStyleBenefits && (
              <LifeStyleBenefits lifeStyleBenefits={lifeStyleBenefits} canEditable={canEditable} />
            )}
          </Loading>
        </BenefitsCard>
      )} */}
      {/* {isScg && (
        <BenefitsCard
          icon={'iconCoinWhite'}
          title={'แลกเป็นเหรียญ '}
          desc={'คุณสามารถแลกวันลาเป็นเหรียญได้ '}
        >
          <BenefitsCategory icon={'iconCoin'} title={'แลกวันลา'}>
            <BenefitsCategoryList userSelectedList={[]}>
              {canEditable === true && <Button onClick={onClickToExchangeLeave}>แลกวันลา</Button>}
            </BenefitsCategoryList>
          </BenefitsCategory>
        </BenefitsCard>
      )} */}
      <Row style={{ marginBottom: 16 }}>
        {/* <Image imageName={'iconCoin'} width={32} height={32} />
        <Text size={24} type={'Bold'} style={{ marginLeft: 16 }}>
          แลกเป็นเหรียญ{' '}
        </Text> */}
      </Row>
      {selectedHealthCheckupBenefits && (
        <SummaryExchangeBenefits
          // totalAvailableCoins={totalAvailableCoins}
          // totalUsedCoins={totalUsedCoins}
          // totalEarnedCoins={totalEarnedCoins}
          selectedBenefits={selectedHealthCheckupBenefits}
          totalRemainingCoins={getPoint}
          benefitYearId={benefitYearId}
        />
      )}

      <Modal
        visible={modalInsuranceSubmit}
        onCancel={onClickHideModalInsuranceDetail}
        footerComponent={
          <FooterSubmitInsuranceModal>
            <BenefitSelectButton
              fontColor={color.PRIMARY}
              style={{ background: color.WHITE }}
              onClick={onClickHideModalInsuranceDetail}
            >
              ยกเลิก
            </BenefitSelectButton>
            <BenefitSelectButton onClick={onChangeInsurance}>ยืนยัน</BenefitSelectButton>
          </FooterSubmitInsuranceModal>
        }
      >
        <ContentSubmitInsuranceModal>
          <Text size={32} type="Bold">
            คุณยืนยันแผนประกันภัยสำหรับปี 2566 หรือไม่ ?
          </Text>
          <div>
            <Text>แผนประกันชีวิต: แผน 1</Text>
            <Text>แผนประกันสุขภาพ: แบบผู้ป่วยในแผน 1 , แบบผู้ป่วยนอกแผน 1, แบบทันตกรรมแผน 1</Text>
          </div>
        </ContentSubmitInsuranceModal>
      </Modal>

      {/* {endDate ? (
        <Row
          style={{
            width: '100%',
            justifyContent: 'center',
            marginTop: 16,
            // marginBottom: 40,
          }}
        >
          <Text size={20} color={color.FONT_BLACK}>
            {`(คุณยังสามารถแก้ไขได้ถึง วันที่ ${dayjs(Number(endDate))
              .add(543, 'year')
              .format('DD MMM YYYY')})`}
          </Text>
        </Row>
      ) : null} */}
    </Layout >
  )
}

// type BenefitsCategoryListProp = {
//   children: ReactNode
// }
// export const BenefitsCategoryList = (props: BenefitsCategoryListProp) => {
//   const { children } = props
//   return (
//     <>
//       <div
//         style={{
//           minHeight: 75,
//           borderStyle: 'solid',
//           borderColor: '#f5f7fa',
//           borderWidth: '0 1px 1px 1px',
//           padding: 24,
//         }}
//       >
//         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//           <Text size={22} type={'Bold'}>
//             เสียชีวิตรับเงิน 12 เท่า (แผน A)
//           </Text>

//           <Text size={22} type={'Bold'} color={INPUT_ERROR} style={{ whiteSpace: 'nowrap' }}>
//             -200
//           </Text>
//         </div>
//       </div>

//       <div
//         style={{
//           minHeight: 109,
//           borderStyle: 'solid',
//           borderColor: '#f5f7fa',
//           borderWidth: '0 1px 1px 1px',
//           padding: 32,
//         }}
//       >
//         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
//           {children}
//         </div>
//       </div>
//     </>
//   )
// }

export default Benefits
