import React, { CSSProperties, useCallback, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Avatar as AntdAvatar } from 'antd'
import { AvatarProps } from 'antd/lib/avatar'
import color from '../../constant/colors'
import Mask from '../../assets/images/base/mask.jpg'

const CustomAntAvatar = styled.div<{ isBorder: boolean; borderWidth: number }>`
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: ${({ isBorder, borderWidth }) => (isBorder === true ? borderWidth + 'px' : 'unset')};
  background-color: ${color.WHITE};
`

type CustomAvatarProps = {
  src: string | undefined
  size: number
  isBorder?: boolean
  borderWidth?: number
  style?: CSSProperties
  onClick?: () => void
} & Pick<AvatarProps, 'alt' | 'gap' | 'icon' | 'shape' | 'size' | 'src' | 'srcSet' | 'onError'>

const Avatar = (props: CustomAvatarProps) => {
  const { src, size, isBorder = false, borderWidth = 12, style, ...resProps } = props
  const customStyle = { width: size, height: size, ...style }
  const imgSize = isBorder === true ? size - borderWidth * 2 : size
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    typeof src === 'object' ? setIsError(true) : setIsError(false)
  }, [src])

  const onLoadImageError = useCallback(() => {
    setIsError(true)
    return true
  }, [])

  return (
    <CustomAntAvatar
      isBorder={isBorder}
      borderWidth={borderWidth}
      style={customStyle}
      {...resProps}
    >
      <AntdAvatar src={isError ? Mask : src} size={imgSize} onError={onLoadImageError} />
    </CustomAntAvatar>
  )
}

export default Avatar
