import React, { CSSProperties, ReactNode } from 'react'
import styled from '@emotion/styled'
import { Button as AntdButton } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import color from '../../constant/colors'
import Text from './Text'
import Image from './Image'

const CustomStyledAntdButton = styled(AntdButton)<{
  background: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ color }) => color};
  background: ${({ background }) => background};
  width: 100%;
  max-width: 364px;
  height: 45px;
  border-radius: 5px;
  border-color: ${color.PRIMARY};

  :hover,
  :active,
  :focus {
    background: ${({ background }) => background};
    background: #c1c1c1;
  }

  :disabled {
    background: #c1c1c1;

    :hover,
    :focus,
    :active {
      background: #c1c1c1;
    }
  }
`
type CustomButtonProps = {
  children: ReactNode
  style?: CSSProperties
  background?: string
  textProps?: {}
  iconImage?: string
  id?: string
} & Pick<
  ButtonProps,
  | 'block'
  | 'danger'
  | 'disabled'
  | 'ghost'
  | 'href'
  | 'htmlType'
  | 'icon'
  | 'loading'
  | 'shape'
  | 'size'
  | 'target'
  | 'type'
  | 'onClick'
>
const OutlineButton = (props: CustomButtonProps) => {
  const {
    id,
    children,
    style,
    onClick,
    disabled = false,
    textProps,
    iconImage,
    ...resProps
  } = props
  const backgroundColor = color.WHITE
  const fontColor = color.PRIMARY

  return (
    <CustomStyledAntdButton
      disabled={disabled}
      onClick={onClick}
      background={backgroundColor}
      style={style}
      id={id}
      {...resProps}
    >
      {iconImage ? <Image src={iconImage} style={{ marginRight: 8 }} /> : null}
      <Text size={18} type="Bold" color={disabled ? backgroundColor : fontColor} {...textProps}>
        {children}
      </Text>
    </CustomStyledAntdButton>
  )
}

export default OutlineButton
