import React, { ReactNode, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { tablet, mobile } from '../../../utils/responsive-helper'
import ShopCard from '../../../components/ShopCard'
import Button from '../../../components/common/Button'
import { useGetShopCategroyProductList } from '../../../services/shop/shop-query'
import { categoryReward } from '../../../constant/path'
import Loading from '../../../components/common/Loading'
import { useTranslation } from 'react-i18next'

const Layout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 267px);
  grid-gap: 24px;

  ${tablet} {
    grid-template-columns: repeat(auto-fill, 343px);
    grid-gap: 24px;
    justify-content: center;
    .shop-card {
      width: 343px;
      height: 421px;
    }

    .shop-card-img {
      width: 343px;
      height: 243px;
    }
  }

  ${mobile} {
    grid-template-columns: unset;
    grid-gap: unset;
    .shop-card {
      width: 100%;
      height: 421px;
    }

    .shop-card-img {
      width: 100%;
      height: 243px;
    }
  }
`

const LoadingStyle = styled.div`
  display: flex;
  justify-content: center;
`

type CategoryProp = {
  categoryId: number
  categoryName: string
  children?: ReactNode
}

const Category = (props: CategoryProp) => {
  const { categoryId, categoryName } = props
  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } = useGetShopCategroyProductList(
    categoryId,
  )

  const { t } = useTranslation()

  const getPath = useCallback(
    (productId: number) => {
      return categoryReward({
        routeParam: { rewardId: productId, categoryId: categoryId },
        queryParam: { title: categoryName },
      })
    },
    [categoryId, categoryName],
  )

  const onClickLoading = useCallback(() => {
    fetchNextPage()
  }, [fetchNextPage])

  const products = useMemo(() => {
    return data?.pages.map(({ content: contents }) => {
      return contents.map((content: any, index: number) => {
        const { id, specialCoin, normalCoin, title, thumbnailImage, remain } = content
        return (
          <ShopCard
            key={index}
            image={thumbnailImage}
            title={title}
            point={specialCoin !== null ? specialCoin : normalCoin}
            remain={remain}
            path={getPath(id)}
          />
        )
      })
    })
  }, [data?.pages, getPath])

  return (
    <Loading style={{ height: 100 }} loading={isLoading}>
      <Layout>{products}</Layout>
      <LoadingStyle>
        <Loading loading={!isLoading && isFetching}>
          <Button disabled={!hasNextPage} onClick={onClickLoading}>
            {t('shop.seeMore')}
          </Button>
        </Loading>
      </LoadingStyle>
    </Loading>
  )
}

export default Category
