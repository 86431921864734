import { AiOutlineClose } from 'react-icons/ai'
import { useRouter } from '../../../utils/helper'
import { useScreen } from '../../../utils/responsive-helper'
import CouponInformation from '../../../components/CouponInformation'
import TitleHeader from '../../../components/app-layout/AppHeader/TitleHeader'

const CouponDetail = () => {
  const { isMobile } = useScreen()
  const { couponId, page } = useRouter().query

  return (
    <>
      {isMobile && (
        <TitleHeader title="เงื่อนไขการใช้คูปอง" icon={<AiOutlineClose />} path={page} />
      )}
      <CouponInformation />
    </>
  )
}

export default CouponDetail
