import styled from '@emotion/styled'
import Text from '../../components/common/Text'
import CategoryGroup from '../../components/common/CategoryGroup'
import imageLeave from '../../assets/images/base/group.png'
import imageOt from '../../assets/images/base/group-4.png'
import imageFlex from '../../assets/images/base/group-6-copy.png'
import imageWell from '../../assets/images/base/group-5-copy.png'

const StyleImg = styled.img`
  margin-left: -22px !important;
  cursor: pointer;
`

const LifeStyle = () => {
  return (
    <CategoryGroup label="Lifestyle" contentStyle={{ marginLeft: -5 }}>
      {/* <div style={{ position: 'relative', marginRight: -24 }}>
        <Text type='Medium' style={{ position: 'absolute', marginLeft: 16, marginTop: 24 }}>Leave Request</Text>
        <StyleImg src={imageLeave} alt="leave" />
      </div>
      <div style={{ position: 'relative', marginRight: -24 }}>
        <Text type="Medium" style={{ position: 'absolute', marginLeft: 16, marginTop: 24 }}>
          OT Request
        </Text>
        <StyleImg src={imageOt} alt="ot" />
      </div> */}
      <div style={{ position: 'relative', marginRight: -24 }}>
        <Text type="Medium" style={{ position: 'absolute', marginLeft: 16, marginTop: 24 }}>
          Flex Benefits
        </Text>
        <StyleImg src={imageFlex} alt="flex" />
      </div>
      <div style={{ position: 'relative', marginRight: -24 }}>
        <Text type="Medium" style={{ position: 'absolute', marginLeft: 16, marginTop: 24 }}>
          Well Being
        </Text>
        <StyleImg src={imageWell} alt="well" />
      </div>
    </CategoryGroup>
  )
}

export default LifeStyle
