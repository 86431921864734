import React, { useMemo, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import Headbar from '../../components/common/HeadBar'
import * as paths from '../../constant/path'
import colors from '../../constant/colors'
import Text from '../../components/common/Text'
import { Avatar, List } from 'antd';
import Button from '../../components/common/Button'
import {
  useGetRelative,
  useDeleteRelativeById,
  useCreateRelative,
  useUpdateRelativeById
} from '../../services/relative/relative-query'
import { UpdateRelative, CreateRelative, DeleteRelative } from './component'
const Layout = styled.div`
  display: flex;
  flex-direction: column;
`
const ContentLayout = styled.div`
  background-color: ${colors.WHITE};
  padding: 16px;
  border-radius: 16px;
  min-height: 500px;
`
const UpdateButton = styled.div`
  color: ${colors.SECONDARY};
  cursor: pointer;
`
const DeleteButton = styled.div`
  color: ${colors.INPUT_ERROR};
  cursor: pointer;
`
const CreateButton = styled(Button)`
  align-self: center;
`

type ModalType = 'CREATE' | 'UPDATE' | 'DELETE'
type RelativeDetailType = {
  title: string,
  firstname: string,
  lastname: string,
  relativeTypeId: number
}

const Relative = () => {
  const { data: relativeList, refetch } = useGetRelative()
  const { mutate: deleteRelative } = useDeleteRelativeById()
  const { mutate: createRelative } = useCreateRelative()
  const { mutate: updateRelative } = useUpdateRelativeById()
  const [selectedRelativeId, setSelectedRelativeId] = useState<number>(0)

  const [visibleCreate, setVisibleCreate] = useState(false)
  const [visibleUpdate, setVisibleUpdate] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)

  const handleOpenModal = useCallback((type: ModalType) => {
    switch (type) {
      case 'CREATE':
        setVisibleCreate(true);
        break;
      case 'UPDATE':
        setVisibleUpdate(true);
        break;
      case 'DELETE':
        setVisibleDelete(true);
        break;
      default:
        break;
    }
  }, [])

  const handleCloseModal = useCallback((type: ModalType) => {
    switch (type) {
      case 'CREATE':
        setVisibleCreate(false);
        break;
      case 'UPDATE':
        setVisibleUpdate(false);
        setSelectedRelativeId(0);
        break;
      case 'DELETE':
        setVisibleDelete(false);
        break;
      default:
        break;
    }
  }, [])

  const onCreateRelative = useCallback((relativeDetail: RelativeDetailType) => {
    createRelative(relativeDetail, {
      onSuccess: () => {
        console.log('Create success')
        refetch()
      },
      onError: () => {
        console.log('Create error')
      }
    })
  }, [createRelative, refetch])

  const onUpdateRelative = useCallback((relativeDetail: RelativeDetailType) => {
    updateRelative(relativeDetail, {
      onSuccess: () => {
        console.log('Create success')
        refetch()
      },
      onError: () => {
        console.log('Create error')
      }
    })
  }, [updateRelative, refetch])

  const onDeleteRelative = useCallback((id: number) => {
    deleteRelative(id, {
      onSuccess: () => {
        console.log('Delete success')
        setSelectedRelativeId(0)
        refetch()
      },
      onError: () => {
        console.log('Delete error')
      }
    })
  }, [deleteRelative, refetch])

  return (
    <Layout>
      <CreateRelative
        visible={visibleCreate}
        handleCloseModal={handleCloseModal}
        handleAction={onCreateRelative}
        title='เพิ่มครอบครัว'
      />
      <UpdateRelative
        visible={visibleUpdate}
        handleCloseModal={handleCloseModal}
        handleAction={onUpdateRelative}
        title='แก้ไขครอบครัว'
        id={selectedRelativeId}
      />
      <DeleteRelative
        visible={visibleDelete}
        handleCloseModal={handleCloseModal}
        handleAction={onDeleteRelative}
        title='ยืนยันลบรายการนี้'
        id={selectedRelativeId}
      />
      <Headbar title='รายละเอียดครอบครัว' backWard={paths.eClaim()} />
      <ContentLayout>
        <Text size={24} type='Bold'>รายชื่อครอบครัว</Text>
        <List
          itemLayout="horizontal"
          dataSource={relativeList}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <UpdateButton onClick={() => {
                  setSelectedRelativeId(item.id)
                  handleOpenModal('UPDATE')
                }}>
                  แก้ไข
                </UpdateButton>,
                <DeleteButton onClick={() => {
                  setSelectedRelativeId(item.id)
                  handleOpenModal('DELETE')
                }}>ลบ</DeleteButton>
              ]}
            >
              <List.Item.Meta
                title={<Text size={18} type='Bold'>{`${item.title}${item.firstname} ${item.lastname}`}</Text>}
              />
            </List.Item>
          )}
        />
      </ContentLayout>
      <CreateButton onClick={() => handleOpenModal('CREATE')}>เพิ่มรายชื่อครอบครัว</CreateButton>
    </Layout >
  )
}

export default Relative