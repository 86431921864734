import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import ImageInfographicCoinSuccess from '../../assets/images/base/infographic-coin-success.svg'
import ImageInfographicQuizFalse from '../../assets/images/base/infographic-quiz-false.svg'
import Comment from '../../components/Comment'
import { ItemProps } from '../../components/common/Breadcrumb'
import Button from '../../components/common/Button'
import Image from '../../components/common/Image'
import Loading from '../../components/common/Loading'
import Modal from '../../components/common/Modal'
import Radio from '../../components/common/Radio'
import Text from '../../components/common/Text'
import config from '../../config'
import color from '../../constant/colors'
import {
  useGetNewsDetail,
  useReadNewsDetail,
  useSentQuizAnswer,
  useVote,
} from '../../services/digital-news/digital-news-query'
import { TypeNews } from '../../services/digital-news/digital-news-types'
import { useGetUser } from '../../services/user/user-query'
import { useRouter } from '../../utils/helper'
import { mobile, mobileVerySmall, useScreen } from '../../utils/responsive-helper'
import NewsItems from './NewsItems'

dayjs.locale('th')

const HeaderText = styled(Text)`
  margin-top: 24px;
  margin-bottom: 16px;
`
const BodyLayout = styled.div<{ screen: any }>`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  ${mobile} {
    iframe {
      width: 100%;
    }
  }
`
const ImageBanner = styled(Image)`
  width: 100%;
  height: 500px;

  ${mobile} {
    height: 300px;
  }
`
const CommentLayout = styled.div`
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  padding: 32px;
`
const CommentContent = styled.div`
  background: white;
  padding-top: 32px;
  max-width: 752px;
  margin: 0 auto;
`
const NewsLayout = styled.div`
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  .image-slider {
    width: 100%;
    height: 420px;
    object-fit: contain;
  }
`
const NewsContent = styled.div`
  background: white;
  padding-top: 32px;
  border-radius: 8px 8px 0px 0px;
  transform: translateY(-40px);
  
  .width-content {
    max-width: 752px;
    margin: 0 auto;

    .dynamic-content-div div {
      max-width: 100%;
      overflow: auto;
    }
    
    .dynamic-content-div img {
      max-width: 100%;
    }
  }

  ${mobile} {
    padding: 32px 16px 0px;
  }
`
const UpperContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  padding-bottom: 20px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${color.BORDER};
`
const CalendarGroup = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`
const IconImage = styled(Image)`
  width: 16px;
  height: 15px;
  margin-left: 16px;
`
const ReadButton = styled(Button)`
  width: 267px;
`
const CoinSuccessImagePanel = styled.div`
  text-align: center;
  margin-bottom: 64px;
`
const ModalButton = styled(Button)`
  margin: 32px;
`
const ModalButtonPanel = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
`
const CenteredText = styled(Text)`
  text-align: center;
`
const ResponseModal = styled(Modal)`
  min-width: calc(40vw);
  max-width: calc(90vw);
`

const ModalContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ButtonStyle = styled(Button)`
  width: 100%;
`
const WrapButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  padding: 16px 24px;
  width: 100%;

  ${mobileVerySmall} {
    flex-direction: column;
    row-gap: 10px;
    padding: 16px 0;
  }
`

const TitleItem = styled.div`
  p {
    font-weight: bold;
    padding-left: 10px;
  }
`

const NewsDetail = () => {
  const [radioValue, setRadioValue] = useState('')
  const [radioArrValue, setRadioArrValue] = useState([] as any)
  const { data: user } = useGetUser()
  const { query } = useRouter<{ newsId: string }>()
  const { data, isLoading } = useGetNewsDetail({ id: query.newsId, userId: user?.userId })
  const { digitalNews, isInform } = data || {}
  const screen = useScreen()
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { isMobile } = screen
  const thaiNewsDate = useMemo(() => {
    return `${dayjs(data?.digitalNews?.createDate).format('DD MMM')} ${Number(dayjs(data?.digitalNews?.createDate).format(`YYYY`)) + 543
      }`
  }, [data?.digitalNews?.createDate])
  const NewsDate = useMemo(() => {
    return `${dayjs(data?.digitalNews?.createDate).locale(language).format('DD MMM YYYY')}`
  }, [data?.digitalNews?.createDate, language])
  const [modalVisible, setModalVisible] = useState(false)
  const [isAnswer, setIsAnswer] = useState(false)
  const [modalVisibleFaild, setModalVisibleFaild] = useState(false)
  const [modalVisibleFaildCustom, setModalVisibleFaildCustom] = useState(false)
  const [modalJoin, setModalJoin] = useState(false)
  const [isInputComment, setIsInputComment] = useState(false)
  const [choiceVoteScore, setChoiceVoteScore] = useState({
    correct: 0,
    incorrect: 0,
    score: 0
  })
  const [responseModalDataFaildCustom, setResponseModalDataFaildCustom] = useState({
    image: ImageInfographicQuizFalse,
    title: '',
    description: '',
    buttonText: t('newsDetail.modalButtonText'),
  })

  // const responseModalData = useMemo(() => {
  //   if (isModalSuccess) {
  //     return {
  //       image: ImageInfographicCoinSuccess,
  //       // type assertion that the value isn’t null or undefined
  //       text: digitalNews!?.rewardCoinAmountLeft > 0 && digitalNews!?.rewardCoin > 0
  //         ? t('newsDetail.modalText')
  //         : t('newsDetail.modalTextNoCoin'),
  //       buttonText: t('newsDetail.modalButtonText'),
  //     }
  //   } else {
  //     return {
  //       image: ImageInfographicCoinSuccess,
  //       text: t('newsDetail.modalTextFail'),
  //       buttonText: t('newsDetail.modalButtonText'),
  //     }
  //   }
  // }, [isModalSuccess, digitalNews, t])

  const responseModalData = useMemo(() => {
    if (data?.digitalNews.type === TypeNews.QUIZ || data?.digitalNews.type === TypeNews.VOTE) {
      if (isAnswer)
        return {
          image: ImageInfographicCoinSuccess,
          text: `จำนวนข้อที่ถูก ${choiceVoteScore.correct} ข้อ จำนวนข้อที่ผิด ${choiceVoteScore.incorrect} ข้อ คุณจะได้รับเหรียญ ${choiceVoteScore.score} เหรียญ`,
          buttonText: t('newsDetail.modalButtonText'),
        }
      else
        return {
          image: ImageInfographicCoinSuccess,
          text: t('newsDetail.modalTextAnswer'),
          buttonText: t('newsDetail.modalButtonText'),
        }
    } else {
      return {
        image: ImageInfographicCoinSuccess,
        text: t('newsDetail.modalTextNoCoin'),
        buttonText: t('newsDetail.modalButtonText'),
      }
    }
  }, [data?.digitalNews.type, isAnswer, t, choiceVoteScore])

  const responseModalDataFaild = useMemo(() => {
    if (data?.digitalNews.type === TypeNews.QUIZ) {
      if (isAnswer)
        return {
          image: ImageInfographicQuizFalse,
          title: t('newsDetail.modalTextFaildQuizAlreadyAnswer'),
          description: '',
          buttonText: t('newsDetail.modalButtonText'),
        }
      else
        return {
          image: ImageInfographicQuizFalse,
          title: t('newsDetail.modalTextFaildQuiz'),
          description: '',
          buttonText: t('newsDetail.modalButtonText'),
        }
    } else {
      return {
        image: ImageInfographicQuizFalse,
        title: t('newsDetail.modalTextFaildVote'),
        description: t('newsDetail.modalTextFaildVoteSecond'),
        buttonText: t('newsDetail.modalButtonText'),
      }
    }
  }, [t, isAnswer, data?.digitalNews.type])

  const onModalOk = useCallback(() => {
    setModalVisible(false)
  }, [])

  useEffect(() => {
    setRadioValue('')
  }, [])

  // useEffect(() => {
  //   if (data?.digitalNews.type === 'INFORM') {
  //     setTimeout(function () {
  //       if (data.isInform === false) {
  //         onActionClick()
  //         setModalVisible(true)
  //       }
  //     }, 3000)
  //   }
  // }, [data])

  const { mutate: read, isLoading: loadingRead } = useReadNewsDetail()
  const { mutate: answerQuiz, isLoading: loadingQuiz } = useSentQuizAnswer()
  const { mutate: vote, isLoading: loadingVote } = useVote()

  const canAction = useMemo(() => {
    return !isInform
  }, [isInform])

  const onSuccess = useCallback(
    (response) => {
      if (response?.status === 'false') {
        setResponseModalDataFaildCustom({
          image: '',
          title: response.message,
          description: '',
          buttonText: t('newsDetail.modalButtonText'),
        })
        setModalVisibleFaildCustom(true)
      } else {
        if (data?.digitalNews.type === 'QUIZ') {
          const result = response?.data?.reduce((prev: any, cur: any) => {
            const { isCorrect } = cur
            const key = isCorrect ? 'correct' : 'incorrect'
            return {
              ...prev,
              [key]: (prev[key] || 0) + 1
            }
          }, {
            correct: 0,
            incorrect: 0
          })
          setChoiceVoteScore({
            score: response?.totalScore,
            ...result
          })

          setIsAnswer(true)

          if (response?.status === "true")
            setModalVisible(true)
          else
            setModalVisibleFaild(true)

        } else {
          setIsAnswer(false)
          setModalVisible(true)
        }
      }
    },
    [data],
  )

  const overideBreadCrumb = useCallback(
    (paths: ItemProps[]) => {
      paths[1] = { ...paths[1], label: digitalNews?.type || '' }
      return paths
    },
    [digitalNews],
  )

  const onHandleInputComment = useCallback((isShow: boolean) => {
    setIsInputComment(isShow)
  }, [])

  const onActionClick = useCallback(() => {
    const { type, digitalNewsQuizList, digitalNewsVoteList } = digitalNews || {}
    const radioSize = Object.values(radioArrValue.filter((val: any) => val)).length

    if (type === 'QUIZ') {
      if (radioSize === digitalNewsQuizList.length && digitalNews) {
        answerQuiz(
          {
            userId: user?.userId,
            digitalNewsId: digitalNews.id.toString(),
            id: radioArrValue,
          },
          { onSuccess },
        )
      } else {
        setModalVisibleFaild(true)
      }
    } else if (type === 'VOTE') {
      if (radioSize === digitalNewsVoteList.length && digitalNews) {
        vote(
          {
            digitalNewsId: digitalNews.id.toString(),
            userId: user?.userId,
            voteIds: radioArrValue,
          },
          { onSuccess },
        )
      }
      else {
        setModalVisibleFaild(true)
      }
    } else if (type === 'INFORM') {
      digitalNews &&
        read(
          {
            userId: user?.userId,
            digitalNewsId: digitalNews.id.toString(),
            subType: digitalNews.subType,
          },
          { onSuccess },
        )
    } else if (type === 'REGISTER') {
      if (digitalNews) {
        read(
          {
            userId: user?.userId,
            digitalNewsId: digitalNews.id.toString(),
            subType: digitalNews.subType,
          },
          { onSuccess },
        )
        setModalJoin(false)
      } else {
        setModalJoin(true)
      }
    }
  }, [digitalNews, answerQuiz, user?.userId, radioValue, onSuccess, vote, read, radioArrValue])

  // const imagesForSlider = useMemo(() => {
  //   return digitalNews?.digitalNewsHeaderImagesList?.map((obj: any) => ({
  //     imagePath: `${config.apiHost}${obj.imagePath}`,
  //   }))
  // }, [digitalNews])

  // const radioContent = useMemo(() => {
  //   const { type, digitalNewsQuizList = [], digitalNewsVoteList = [] } = digitalNews || {}
  //   let options: BaseOptionType[] = []
  //   if (type === 'QUIZ') {
  //     options = digitalNewsQuizList.map((obj: { id: number; quizOption: string }) => ({
  //       label: obj.quizOption,
  //       value: obj.id,
  //     }))
  //     return <Radio options={options} value={radioValue} setValue={setRadioValue} />

  //   }
  // }, [digitalNews, radioValue])

  const buttonText = useMemo(() => {
    const { type } = digitalNews || {}
    if (type === 'QUIZ') {
      return t('newsDetail.sentAnswer')
    } else if (type === 'VOTE') {
      return t('newsDetail.vote')
    } else if (type === 'INFORM') {
      return t('newsDetail.readButton')
    } else if (type === 'REGISTER') {
      return t('newsDetail.register')
    }
  }, [digitalNews, t])

  // useEffect(() => {
  //   if (data?.digitalNews.type === 'INFORM') {
  //     setTimeout(function () {
  //       if (data.isInform === false) {
  //         onActionClick()
  //         setModalVisible(true)
  //       }
  //     }, 3000)
  //   }
  // }, [data, onActionClick])

  function handleSelectRadio(value: any, index: any) {
    setRadioArrValue((prev: any) => {
      prev[index] = value;
      return [...prev]
    });
  }

  const isLoadingSubmit = !loadingRead && !loadingQuiz && !loadingVote

  return (
    <Loading loading={isLoading}>
      {/* <Breadcrumb style={{ marginTop: 24 }} overide={overideBreadCrumb} /> */}
      {/* <HeaderText size={32} type="Bold">
        {digitalNews?.title || '-'}
      </HeaderText> */}
      {digitalNews && (
        <BodyLayout screen={screen}>
          <NewsLayout>
            {/* <ImageSlider
              images={imagesForSlider}
              status="Detail"
            /> */}
            <ImageBanner src={digitalNews?.thumbnailPath?.indexOf('http') > -1 ? digitalNews?.thumbnailPath : `${config.apiHost}${digitalNews?.thumbnailPath}`} />
            <NewsContent>
              <div className='width-content'>
                <UpperContent>
                  {/* <Tag label={digitalNews.type} type={digitalNews.type} fontSize={11.6} /> */}
                  {/* <IconImage imageName="iconCoinsGold" /> */}
                  {/* <FlexChild /> */}
                  <Text size={24} type="Bold">{digitalNews?.title}</Text>
                  <CalendarGroup>
                    <Image imageName='calendarIcon' width={24} height={24} />
                    <Text size={14} color={color.FONT_LIGHT_GRAY}>
                      {NewsDate}
                    </Text>
                  </CalendarGroup>
                </UpperContent>
                <div className='dynamic-content-div' dangerouslySetInnerHTML={{ __html: digitalNews.content }} />
                {/* {radioContent} */}
                {
                  digitalNews?.digitalNewsVoteList?.map((obj: any, index: any) => {
                    let content = obj?.content
                    let options: BaseOptionType[] = []
                    options = obj?.digitalNewsChoiceVote?.map((obj: { id: number, voteOption: string, voteScore: number }) => ({
                      label: obj.voteOption,
                      value: obj.id,
                      score: obj.voteScore
                    }))

                    return (
                      <div style={{ paddingBottom: 8 }}>
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                        {
                          isInform ? <div>
                            {
                              options.map((obj: any) => {
                                return <div> {obj.label} ({obj.score})</div>
                              })
                            }
                          </div> : <Radio options={options} value={radioArrValue[index]} setValue={e => handleSelectRadio(e, index)} />
                        }

                      </div>
                    )
                  })
                }

                {
                  digitalNews?.digitalNewsQuizList?.map((obj: any, index: any) => {
                    let content = obj?.content
                    let options: BaseOptionType[] = []
                    options = obj?.digitalNewsQuizItems?.map((obj: { id: number, quizOption: string, quizScore: number, isCorrect: boolean }) => ({
                      label: obj.quizOption,
                      value: obj.id,
                      score: obj.quizScore,
                      isCorrect: obj.isCorrect
                    }))

                    return (
                      <div>
                        <TitleItem dangerouslySetInnerHTML={{ __html: content }} />
                        {
                          isInform ? <div>
                            {
                              options.map((obj: any) => {
                                return <NewsItems quizItems={obj} />
                              })
                            }
                          </div> : <Radio options={options} value={radioArrValue[index]} setValue={e => handleSelectRadio(e, index)} />
                        }
                      </div>
                    )
                  })
                }
              </div>
            </NewsContent>
          </NewsLayout>

          <CommentLayout>
            <CommentContent>
              <Comment userId={user?.userId!!} digitalNewsId={digitalNews.id} isInputComment={isInputComment} />
              {digitalNews?.type === 'REGISTER' ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  columnGap: 16,
                  padding: '16px 24px',
                  width: '100%'
                }} >
                  <ReadButton onClick={() => setModalJoin(true)} disabled={!canAction}>
                    {buttonText}
                  </ReadButton>
                  {!isInputComment && (<ReadButton onClick={() => onHandleInputComment(true)} style={{ width: '100%' }}>
                    {t('comment.submitButton')}
                  </ReadButton>)}
                </div>

              ) : (
                !isInputComment && (
                  <WrapButtonStyle>
                    {
                      !isInform && (
                        <ReadButton
                          disabled={isInform}
                          background={color.WHITE}
                          fontColor={color.PRIMARY}
                          onClick={() => { if (isLoadingSubmit) onActionClick() }}
                          style={{ borderColor: color.PRIMARY, width: '100%' }}>
                          {buttonText}
                        </ReadButton>
                      )
                    }
                    <ReadButton onClick={() => onHandleInputComment(true)} style={{ width: '100%' }}>
                      {t('comment.submitButton')}
                    </ReadButton>
                  </WrapButtonStyle>
                )
              )}
            </CommentContent>
          </CommentLayout>
        </BodyLayout>
      )
      }

      {/* <FooterContent
        style={{
          height: 80,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: 752,
          margin: '0 auto',
          width: '100%',
        }}
      >

      </FooterContent> */}

      <ResponseModal visible={modalVisible} onCancel={onModalOk} hideFooter>
        <div>
          <CoinSuccessImagePanel>
            <Image src={responseModalData.image} />
          </CoinSuccessImagePanel>
          <CenteredText size={30} type="Bold">
            {digitalNews?.type === 'REGISTER' ? 'คุณได้เข้าร่วมแล้ว' : responseModalData.text}
          </CenteredText>
          <ModalButtonPanel>
            <ModalButton onClick={onModalOk}>
              <Text size={18} color="white">
                {responseModalData.buttonText}
              </Text>
            </ModalButton>
          </ModalButtonPanel>
        </div>
      </ResponseModal>

      <Modal visible={modalJoin} onCancel={() => setModalJoin(false)} hideFooter>
        <ModalContainer>
          <Text> {digitalNews?.title}</Text>
          <div style={{ display: 'flex', margin: '50px 0 0 0' }}>
            <Button
              onClick={() => setModalJoin(false)}
              style={{
                background: 'transparent',
                marginRight: 8,
                color: color.PRIMARY,
                borderColor: color.PRIMARY,
              }}
            >
              <Text size={20} type="SemiBold" color={color.FONT_BLACK}>
                ยกเลิก
              </Text>
            </Button>
            <Button onClick={onActionClick}>เข้าร่วม</Button>
          </div>
        </ModalContainer>
      </Modal>

      <Modal visible={modalVisibleFaild} onCancel={() => setModalVisibleFaild(false)} hideFooter>
        <ModalContainer>
          <Text size={30} type="Bold">
            {responseModalDataFaild.title}
          </Text>
          <Text>{responseModalDataFaild.description}</Text>
          <ModalButton onClick={() => setModalVisibleFaild(false)}>ปิด</ModalButton>
        </ModalContainer>
      </Modal>

      <Modal visible={modalVisibleFaildCustom} onCancel={() => setModalVisibleFaildCustom(false)} hideFooter>
        <ModalContainer>
          <Text size={30} type="Bold">
            {responseModalDataFaildCustom.title}
          </Text>
          <Text>{responseModalDataFaildCustom.description}</Text>
          <ModalButton onClick={() => setModalVisibleFaildCustom(false)}>ปิด</ModalButton>
        </ModalContainer>
      </Modal>
    </Loading >
  )
}

export default NewsDetail
