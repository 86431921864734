import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import {
  CreateEclaimUser,
  EClaimEnllomentItemList,
  EClaimItem,
  EClaimList,
  GetEClaimByIdProps,
  UploadFile,
  CreateUserClaimEnrollments,
  GetRelativeResponse,
  EClaimListResponse,
  EClaimsHistoryListResponse,
  GetEClaimHistoryByIdProps,
  RelativeScgResponse,
  RelativeTypeResponse,
  GroupResponse,
} from './e-claim-types'
import { GET_USER_COIN_URL } from '../user/user-query'

const APP = '/app'
export const USER_CLAIM = 'userClaim'
export const E_CLAIM_LIST = `${APP}/${USER_CLAIM}/getClaimList`
export const E_CLAIM_BY_ID = `${APP}/${USER_CLAIM}/getById`

export const E_CLAIM_LIST_SCG = `${APP}/${USER_CLAIM}/getAllUserClaimByUser`

export const E_CLAIM_HISTORY_BY_ID = `${APP}/${USER_CLAIM}/getUserClaimById`

export const CREATE_USER_CLAIM = `${APP}/${USER_CLAIM}/createUserClaim`
export const UPLOADFILE = `/fileUpload/uploadFile`
export const E_CLAIM_ENROLLMENT = `${APP}/${USER_CLAIM}/enrollment`
export const CREATE_USER_CLAIM_ENROLLMENT = `${APP}/${USER_CLAIM}/enrollment`
export const GET_RELATIVE = `${APP}/enrollments/relative`
export const GET_RELATIVE_SCG = `${APP}/enrollments/relative-list`
export const GET_RELATIVE_TYPE_SCG = `${APP}/enrollments/relative-type`
export const GET_RELATIVE_TYPE_LIST_SCG = `${APP}/enrollments/relative-type-list`
export const GET_GROUP_NUMBER = `${APP}/enrollments/self-benefits/group`

export const useGetGroupNumber = () => {
  return useQuery(GET_GROUP_NUMBER, async () => {
    return await api.belive.get<GroupResponse>(GET_GROUP_NUMBER)
  })
}

export const useGetEClaimList = () => {
  return useQuery([E_CLAIM_LIST], () => {
    return api.belive.get<EClaimListResponse>(`${E_CLAIM_LIST}`)
  })
}

export const useGetEClaimListScg = () => {
  return useQuery([E_CLAIM_LIST], () => {
    return api.belive.get<EClaimListResponse>(`${E_CLAIM_LIST}`)
  })
}

export const useGetEClaimListHistoryScg = () => {
  return useQuery([E_CLAIM_LIST_SCG], () => {
    return api.belive.get<EClaimsHistoryListResponse>(`${E_CLAIM_LIST_SCG}`)
  })
}

export const useGetEClaimById = (props: GetEClaimByIdProps) => {
  const { claimId } = props

  return useQuery([E_CLAIM_BY_ID, claimId], () => {
    return api.belive.get<EClaimItem>(`${E_CLAIM_BY_ID}`, { id: claimId })
  })
}

export const useGetEClaimHistoryById = (props: GetEClaimHistoryByIdProps) => {
  const { id } = props

  return useQuery([E_CLAIM_HISTORY_BY_ID, id], () => {
    return api.belive.get<EClaimItem>(`${E_CLAIM_HISTORY_BY_ID}`, { id: id })
  })
}
export const useCreateUserClaim = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateEclaimUser) => {
      const {
        id,
        claimId,
        claimDate,
        claimName,
        exchangeCoin = 0,
        relativeId,
        relativeTypeId,
        receiptFile,
        citizenFileUrl,
        relativeTitle = '',
        relativeFirstName = '',
        relativeLastName = '',
        userRemark = '',
        allpayRef = '',
        slipDate,
        amountClaim = 0,
        nameShop = '',
        slipNo = '',
      } = data
      return api.belive.post(
        CREATE_USER_CLAIM,
        JSON.stringify({
          id: id,
          claimId: claimId,
          exchangeCoin: exchangeCoin,
          claimName: claimName,
          claimDate: claimDate,
          receiptFile: receiptFile,
          citizenFileUrl: citizenFileUrl,
          userClaimPrice: exchangeCoin,
          relativeId: relativeId,
          relativeTypeId: relativeTypeId,
          relativeTitle: relativeTitle,
          relativeFirstName: relativeFirstName,
          relativeLastName: relativeLastName,
          userRemark: userRemark,
          slipDate: slipDate,
          amountClaim: amountClaim,
          nameShop: nameShop,
          slipNo: slipNo,
          allpayRef: allpayRef,
        }),
        { contentType: ContentType.JSON },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_LIST])
        queryClient.invalidateQueries([E_CLAIM_BY_ID])
        queryClient.invalidateQueries([E_CLAIM_LIST_SCG])
        queryClient.invalidateQueries([GET_USER_COIN_URL])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useUploadFile = () => {
  const mutate = useMutation(
    async (file: File) => {
      return api.belive.post<UploadFile>(
        UPLOADFILE,
        { file },
        { contentType: ContentType.FORMDATA },
      )
    },
    {
      onSuccess: (data) => {
        console.log('Upload File Success', data)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}

export const useGetEClaimEnrollment = (id: number | string) => {
  return useQuery([E_CLAIM_ENROLLMENT, id], () => {
    return api.belive.get<EClaimEnllomentItemList>(`${E_CLAIM_ENROLLMENT}`, { id: id })
  })
}

export const useCreateUserClaimEnrollment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateUserClaimEnrollments) => {
      return api.belive.post(CREATE_USER_CLAIM_ENROLLMENT, data, { contentType: ContentType.JSON })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_ENROLLMENT])
        queryClient.invalidateQueries([E_CLAIM_LIST])
        queryClient.invalidateQueries([E_CLAIM_BY_ID])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useGetRelative = () => {
  return useQuery([GET_RELATIVE], () => {
    return api.belive.get<GetRelativeResponse>(`${GET_RELATIVE}`)
  })
}

export const useGetRelativeOptions = () => {
  return useQuery([GET_RELATIVE, 'options'], async () => {
    const data = await api.belive.get<GetRelativeResponse>(`${GET_RELATIVE}`)
    const optionRelative: {
      label: string
      value: string | number
    }[] = []
    optionRelative.push({ label: 'ตัวเอง', value: '0' })

    data?.map((relativesGroup) => {
      return relativesGroup.relativeList.map((relative) => {
        const { firstname, lastname, relativeTypeId } = relative
        const label = `${firstname} ${lastname}`
        optionRelative.push({ label: `${firstname} ${lastname}`, value: relativeTypeId })
      })
    })
    return optionRelative
  })
}

export const useGetRelativeOptionDentsu = () => {
  return useQuery([GET_RELATIVE_SCG, 'options'], async () => {
    const optionRelative: {
      label: string
      value: number
    }[] = []

    const data = await api.belive.get<RelativeScgResponse>(`${GET_RELATIVE_SCG}`)
    data.map((relative) => {
      const { firstname, lastname, id, typeName } = relative
      optionRelative.push({ label: `${typeName}: ${firstname} ${lastname}`, value: id })
    })

    return optionRelative
  })
}

export const useGetRelativeType = (id: number | string) => {
  return useQuery([GET_RELATIVE_TYPE_SCG, id], () => {
    return api.belive.get<RelativeTypeResponse>(`${GET_RELATIVE_TYPE_SCG}`, { typeId: id }),
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      enabled: false,
    }
  })
}

export const useGetRelativeTypeOption = () => {
  return useQuery([GET_RELATIVE_TYPE_LIST_SCG, 'options'], async () => {
    const data = await api.belive.get<RelativeTypeResponse[]>(`${GET_RELATIVE_TYPE_LIST_SCG}`)
    const optionRelativeType: {
      label: string
      value: string | number
    }[] = []

    data.map((relativeType) => {
      const { name, id } = relativeType
      optionRelativeType.push({ label: `${name}`, value: id })
    })

    return optionRelativeType
  })
}