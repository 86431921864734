import styled from '@emotion/styled'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/common/Button'
import Text from '../../components/common/Text'
import { useRouter } from '../../utils/helper'

import { Upload } from 'antd'
import Form from 'antd/lib/form/Form'
import { RcFile, UploadChangeParam } from 'antd/lib/upload'
import dayjs from 'dayjs'
import moment from 'moment'
import ImageInfographicCoinSuccess from '../../assets/images/base/infographic-coin-success.svg'
import Avatar from '../../components/common/Avatar'
import Image from '../../components/common/Image'
import Loading from '../../components/common/Loading'
import Modal from '../../components/common/Modal'
import config from '../../config'
import color from '../../constant/colors'
import { useGetImageEvent, useGetPlayDetail, useJoin, useUploadImage } from '../../services/play/play-query'
import { useGetUser } from '../../services/user/user-query'
import { mobile } from '../../utils/responsive-helper'
import colors from '../../constant/colors'
import { PlayResponse } from '../../services/play/play-types'

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const CardPoint = styled.div`
  display: inline-block;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: -10px;
  padding-top: 10px;
  background-color: ${colors.WHITE}
`

const Paper = styled.div`
  margin-top: 60px;
  margin-bottom: 16px;
  display: inline-block;
  box-shadow: 0 6px 10px 5px rgba(0, 0, 0, 0.1);
`

const CustomStyleImage = styled(Image)`
  width: 100%;
  height: 400px;

  ${mobile} {
    height: 250px;
  }
`

const TextTitle = styled(Text)`
  padding-top: 24px;
  margin: 0 20%;

  ${mobile} {
    margin: 0 5%;
  }
`

const FlexRowBaseLine = styled.div`
  padding-top: 16px;
  margin: 0 20%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  
  ${mobile} {
    margin: 0 5%;
  }
`

const ImageStyle = styled(Image)`
  object-fit: none;
  align-self: center;
`

const TextPaddingLeft = styled(Text)`
  padding-left: 8px;
`
const FlexRowBaseLineWithBorder = styled(Text)`
  padding-top: 16px;
  margin: 0 20%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 2px #f0f0f0 solid;
  padding-bottom: 22px;

  ${mobile} {
    margin: 0 5%;
  }
`


const FlexRowButtonLine = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
`


const ButtonStyled = styled(Button)`
  margin-top: 16px;
  margin-bottom: 32px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  max-width: 100%;
`

const ButtonStyledWithMargin = styled(Button)`
  max-width: 30%;
`

const TitleHeader = styled(Text)``

const FormLayout = styled(Form)`
  width: 752px;
  height: fit-content;
  padding: 12px 36px;

  ${mobile} {
    width: calc(100vw - 32px);
    padding: 16px;
    .header {
      padding-right: 32px;
    }
  }
`

const CommentSectionLayout = styled.div`
  background-color: ${color.BACKGROUND_LIGHT_GRAY};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  min-height: 60px;
  align-items: flex-start;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ImageContainer = styled.div`
  border: 0px solid black;
  display: flex;
  height: 167px;
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
`
const CommentImage = styled.img`
  border: 0px solid black;
  height: 167px;
  width: 167px;
  object-fit: contain;

  ${mobile} {
    object-fit: cover;
  }
`

const AvatarContainer = styled.div`
  border: 0px solid black;
  width: 22px;
  height: 100%;
`
const FooterComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .user-name,
  .comment-at {
    line-height: 30px;
  }
  .comment-at {
    height: 100%;
    white-space: nowrap;
    align-self: flex-start;
  }
`
const NameContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

const ResponseModal = styled(Modal)`
  min-width: calc(40vw);
  max-width: calc(90vw);
`

const CoinSuccessImagePanel = styled.div`
  text-align: center;
  margin-bottom: 64px;
`

const CenteredText = styled(Text)`
  text-align: center;
`

const ModalButtonPanel = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
`

const ModalButton = styled(Button)`
  margin: 32px;
`

const PlayDetail = () => {
  const { query } = useRouter<{ playId: string }>()
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { mutate: join } = useJoin()
  const { data: imageData } = useGetImageEvent({ eventId: query.playId })
  const { mutate: uploadImage, isLoading: imageLoading } = useUploadImage()
  const { data: user } = useGetUser()
  const [modalVisible, setModalVisible] = useState(false)
  const [isJoined, setIsJoined] = useState(false)

  const [modal, setModal] = useState(false)
  const { data, isLoading } = useGetPlayDetail({ id: query.playId })
  const play = data?.playEvent
  const isAlreadyJoin = imageData?.some((image) => image.userId === user?.userId)

  const remain = useMemo(() => {
    return play?.rewardCoinAmount || 0
  }, [play])

  const amountLeft = useMemo(() => {
    return play?.rewardCoinAmountLeft || 0
  }, [play])

  const onHandleJoin = useCallback(() => {
    join({ eventId: query?.playId },
      {
        onSuccess: (data: PlayResponse) => {
          console.log('data', data)
          setModalVisible(true)
        }
      })

    setModal(false)
  }, [join, query?.playId])

  const onModalOk = useCallback(() => {
    setModalVisible(false)
  }, [])

  const validateUploadFile = useCallback(
    (image: RcFile) => {
      const isLarge = image.size / 1024 / 1024 > 2
      if (isLarge) {
        alert('ขนาด ภาพ/ไฟล์ ใหญ่เกินไป')
      }
    },
    [],
  )

  const beforeUpload = useCallback(
    (image: RcFile) => {
      validateUploadFile(image)
      return false
    },
    [validateUploadFile],
  )

  const getBase64 = useCallback(async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const onSelectedFile = useCallback(
    async (params: UploadChangeParam) => {
      const { file: image } = params
      const isLarge = image.size / 1024 / 1024 > 2

      if (!isLarge) {
        uploadImage({
          eventId: query?.playId,
          image: image
        },
          {
            onSuccess: (data: PlayResponse) => {
              console.log('data', data)
              setIsJoined(true)
              setModalVisible(true)
            }
          })
      }
    },
    [query?.playId, uploadImage],
  )

  const responseModalData = useMemo(() => {
    if (isJoined) {
      if (amountLeft > 0 && remain > 0) {
        return {
          image: ImageInfographicCoinSuccess,
          text: t('app.upload.success'),
          buttonText: t('newsDetail.modalButtonText'),
        }
      } else {
        return {
          image: ImageInfographicCoinSuccess,
          text: t('app.upload.successFullSlot'),
          buttonText: t('newsDetail.modalButtonText'),
        }
      }
    } else {
      return {
        image: ImageInfographicCoinSuccess,
        text: t('play.modalConfirmJoin.success'),
        buttonText: t('newsDetail.modalButtonText'),

      }
    }
  }, [amountLeft, isJoined, remain, t])

  let imagePath = play?.playEventImagesList?.[0]?.imagePath
  let absolutePath = ''

  if (imagePath) {
    absolutePath = imagePath.indexOf('http') > -1 ? imagePath : `${config.apiHost}${imagePath}`
  }

  return (
    <Loading loading={isLoading}>
      <Layout>
        <CustomStyleImage src={play?.playEventImagesList.length !== 0 ? `${absolutePath}` : ''} />
        <CardPoint>

          <TextTitle type="SemiBold" size={24}>
            {play?.title}
          </TextTitle>

          <FlexRowBaseLine>
            <ImageStyle width={16} height={20} imageName={'iconPin'} />
            <TextPaddingLeft size={12}>
              {play?.location}
            </TextPaddingLeft>
          </FlexRowBaseLine>

          <FlexRowBaseLineWithBorder>
            <ImageStyle width={16} height={16} imageName={'calendarIcon'} />
            <TextPaddingLeft size={12}>
              {dayjs(play?.startDate).format(`DD/MM/YY HH:mm ${language === 'th' ? 'น.' : ''}`)} - {dayjs(play?.endDate).format(`DD/MM/YY HH:mm ${language === 'th' ? 'น.' : ''}`)}
            </TextPaddingLeft>
          </FlexRowBaseLineWithBorder>
          {
            !data?.isJoin && (
              <FlexRowBaseLine>

                <ButtonStyled
                  onClick={() => setModal(true)}
                  background='#000000'
                  disabled={data?.isFull || data?.isExpire}
                  textProps={{ color: color.WHITE }}
                  style={data?.isFull || data?.isExpire ? { marginBottom: 0 } : {}}
                >

                  <Text size={16}
                    type='Bold' color={color.WHITE}>
                    {t('play.joinEvent')}
                  </Text>
                </ButtonStyled>
              </FlexRowBaseLine>

            )
          }

          {
            data?.isExpire ? (
              <FlexRowBaseLine>
                <Text size={14} color={color.DARK_RED}>
                  {t('play.eventExpire')}
                </Text>
              </FlexRowBaseLine>
            ) : (data?.isFull && !data?.isJoin) && (
              <FlexRowBaseLine>
                <Text size={14} color={color.DARK_RED}>
                  {t('play.eventFull')}
                </Text>
              </FlexRowBaseLine>
            )
          }
        </CardPoint>

        <Paper>
          <FlexRowBaseLine>
            <TitleHeader type="SemiBold" size={16}>
              {t('play.eventDetail')}
            </TitleHeader>
          </FlexRowBaseLine>

          <FlexRowBaseLineWithBorder>
            <TitleHeader size={14}>
              <div dangerouslySetInnerHTML={{ __html: play?.description }} />
            </TitleHeader>
          </FlexRowBaseLineWithBorder>

          <FlexRowBaseLineWithBorder>
            <ImageStyle width={20} height={16} imageName={'iconPicture'} />
            <TextPaddingLeft size={12}>
              {imageData?.length} {t('app.photoUnit')}
            </TextPaddingLeft>
          </FlexRowBaseLineWithBorder>
          {
            data?.isJoin && (
              <>
                <FlexRowBaseLine>
                  <Upload
                    action=""
                    listType="text"
                    accept=".jpg,.png"
                    onChange={onSelectedFile}
                    style={{ width: '100%' }}
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                  >
                    <ButtonStyled
                      background='#000000'
                      textProps={{ color: color.WHITE }}
                      disabled={isAlreadyJoin || imageLoading || data?.isExpire}
                    >
                      <Text size={16} type='Bold' color={color.WHITE}>
                        {t('play.upload.gallery')}
                      </Text>
                    </ButtonStyled>
                  </Upload>
                </FlexRowBaseLine>

              </>
            )
          }
          <FlexRowBaseLine>
            <Text size={16} type='Bold' >
              {t('play.rewardRemain')} : {amountLeft}
            </Text>
          </FlexRowBaseLine>
          {
            imageData?.map(image => {
              return (
                <FlexRowBaseLineWithBorder>
                  <CommentSectionLayout key={image?.id}>
                    <ContentContainer>
                      {image?.imagePath ? (
                        <ImageContainer>
                          <CommentImage src={`${config.apiHost}${image?.imagePath}`} alt="" />
                        </ImageContainer>
                      ) : null}
                    </ContentContainer>
                    <FooterComment>
                      <NameContainer>
                        <AvatarContainer>
                          <Avatar size={22} src={`${config.apiHost}${image?.user?.profileImagePath}`} />
                        </AvatarContainer>
                        <Text className="user-name" size={12} type="Medium" color={color.PRIMARY}>
                          {image?.updatedBy}
                        </Text>
                      </NameContainer>
                      <Text className="comment-at" size={10} color={color.FONT_LIGHT_GRAY} style={{ alignSelf: 'end' }}>
                        {dayjs(image?.imageDate).format(`DD/MM/YY HH:mm ${language === 'th' ? 'น.' : ''}`)}
                      </Text>
                    </FooterComment>
                  </CommentSectionLayout>
                </FlexRowBaseLineWithBorder>
              )
            })
          }

        </Paper>

        <ResponseModal visible={modalVisible} onCancel={onModalOk} hideFooter>
          <div>
            <CoinSuccessImagePanel>
              <Image src={responseModalData.image} />
            </CoinSuccessImagePanel>
            <CenteredText size={30} type="Bold">
              {responseModalData.text}
            </CenteredText>
            <ModalButtonPanel>
              <ModalButton onClick={onModalOk}>
                <Text size={18} color="white">
                  {responseModalData.buttonText}
                </Text>
              </ModalButton>
            </ModalButtonPanel>
          </div>
        </ResponseModal>

        <Modal
          visible={modal}
          onCancel={() => setModal(false)}
          hideCloseIcon
          style={{
            padding: 0,
          }}
          hideFooter
        >
          <FormLayout>
            <Text className="header" size={22} type="Bold" color={color.FONT_BLACK}>
              {t('play.modalConfirmJoin.title')}
            </Text>
            <Text>{t('play.modalConfirmJoin.subtitle')}</Text>
            <FlexRowButtonLine>
              <ButtonStyledWithMargin onClick={() => onHandleJoin()} >{t('play.modalConfirmJoin.submit')}</ButtonStyledWithMargin>
              <ButtonStyledWithMargin onClick={() => setModal(false)}>{t('play.modalConfirmJoin.cancel')}</ButtonStyledWithMargin>
            </FlexRowButtonLine>
          </FormLayout>
        </Modal>
      </Layout>
    </Loading>

  )
}

export default PlayDetail
