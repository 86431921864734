import React from 'react'
import styled from '@emotion/styled'
import Tag from '../../components/common/Tag'
const Layout = styled.div``

const MobileTag = styled(Tag)`
  width: 75px;
  height: 17px;
  padding: 2px 8px 0;
  border-radius: 3px;
`

const ExampleTag = () => {
  return (
    <Layout>
      <Tag label={'INFORM'} fontSize={11.6} type="INFORM" />
      <Tag label={'QUIZ'} fontSize={11.6} type="QUIZ" />

      <MobileTag label={'VOTE'} fontSize={9.6} type="VOTE" />
      <MobileTag label={'REGISTER'} fontSize={9.6} type="REGISTER" />
    </Layout>
  )
}
export default ExampleTag
