import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import MaskImage from '../../assets/images/base/mask@2x.jpg'
import { IconEClaim, IconHealthCheckUp, IconMarketplace, IconMyFamilyInsurance, IconNews, IconPlay, IconWellbing } from '../../assets/images/images-dentsu'
import CategoryGroup from '../../components/common/CategoryGroup'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import { default as color, default as colors } from '../../constant/colors'
import { useGetPeriodDate } from '../../services/enrollment/enrollment-query'
import { tablet, useScreen } from '../../utils/responsive-helper'
import moment from 'moment'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  background-color: ${colors.PALE_PURPLE};
  border: 0.5px solid ${colors.PALE_PURPLE};
  border-radius: 8px;
  padding: 8px 16px;
  user-select: none;

  :hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  ${tablet} {
    width: 100%;
  }
`

const CustomImage = styled(Image)`
  width: 40px;
  height: 40px;
`

const AddAppIconLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83px;
  height: 83px;
  border-radius: 20px;
  border: solid 2px ${color.ADD_APP_ICON};
`

const LayoutRowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 8px;
  text-align: center;
`

type ItemProps = {
  src: string
  label: string
  url: string
  type?: string
  tooltip?: string
  isDisabled?: boolean
  isTooltip?: boolean
  description?: string
}

const Item = (props: ItemProps) => {
  const history = useHistory()
  const { src, label, url, type, tooltip = label, isDisabled = false, isTooltip = false, description, ...rest } = props

  const onClickItem = useCallback(() => {
    if (!isDisabled) history.push(url)
  }, [history, isDisabled, url])

  return (
    <Layout
      {...rest}
      className={isDisabled ? "disabled" : ""}>
      <Tooltip title={tooltip} placement="bottom" trigger={isTooltip ? "hover" : "focus"}>
        <div
          onClick={onClickItem}
          style={{
            rowGap: 8,
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}>
          <CustomImage src={src || MaskImage} />
          <div
            style={{
              columnGap: 8,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Text size={16} type="Bold" style={{ marginTop: 8, whiteSpace: 'nowrap' }}>
              {label}
            </Text>
            <Text size={14}>{description}</Text>
          </div>
          {/* <Text size={14} style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            {labelEn}
          </Text> */}
        </div>
      </Tooltip>
    </Layout >
  )
}

const MyApp = () => {
  const { t } = useTranslation()
  const { isDesktop } = useScreen()
  const { data: periodDate } = useGetPeriodDate()
  const { benefitYearId, endDate } = periodDate || {}
  const tooltipTitle = `เหรียญจะสามารถใช้งานได้ตั้งแต่วันที่ ${moment(endDate).add(1, 'days').format("DD MMMM")} เป็นต้นไป`;

  const isUseCoin = useMemo(() => {
    const now = new Date().getTime();
    let isUse = false
    if (endDate) isUse = now > endDate

    return isUse
  }, [endDate])


  return (
    <>
      <CategoryGroup label={t('home.category.myApp.title')} style={{ marginBottom: 16 }}>
        <Item
          src={IconPlay}
          label={t('home.category.myApp.play.title')}
          url={`/play`}
          type={'0'}
          description={t('home.category.myApp.play.description')}
        />
        <Item
          src={IconNews}
          label={t('home.category.myApp.news.title')}
          url={'/news'}
          type={'0'}
          description={t('home.category.myApp.news.description')}
        />
        <Item
          src={IconMyFamilyInsurance}
          label={t('home.category.myApp.insurance.title')}
          url={`/enrollment`}
          type={'0'}
          description={t('home.category.myApp.insurance.description')}
        />
        <Item
          src={IconHealthCheckUp}
          label={t('home.category.myApp.healthCheckup.title')}
          url={`/health-checkup`}
          type={'0'}
          description={t('home.category.myApp.healthCheckup.description')}
        />
        <Item
          src={IconWellbing}
          label={t('home.category.myApp.wellBeing.title')}
          url={`/well-being`}
          type={'0'}
        // description={t('home.category.myApp.wellBeing.description')}
        />
        <Item
          src={IconEClaim}
          label={t('home.category.myApp.eclaim.title')}
          url={`/d_claim`}
          isTooltip={!isUseCoin}
          isDisabled={!isUseCoin}
          tooltip={tooltipTitle}
          type={'0'}
        // description={t('home.category.myApp.eclaim.description')}
        />
        <Item
          src={IconMarketplace}
          label={'Marketplace'}
          url={'/shop'}
          type={'0'}
          isTooltip={!isUseCoin}
          isDisabled={!isUseCoin}
          tooltip={tooltipTitle}
          description={t('home.category.myApp.marketplace.description')}
        />
        {/* <Item
          key={1}
          src={CalendarIcon}
          label={'Transfer Point'}
          url={'/shop'}
          type={'0'}
          description="แลกรับสิทธิประโยชน์"
        /> */}
      </CategoryGroup>
      {/* <Button background={color.WHITE} style={{ borderRadius: 8, width: '100%', maxWidth: 'none' }}>
        <Text size={14} type="SemiBold" style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>ปรับแต่งโมดูล</Text>
      </Button> */}
    </>
  )
}

export default MyApp
