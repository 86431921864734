import { useQuery } from 'react-query'
import { api } from '../../utils/api'
import {
  AllCountryDetail,
  AllProvinceDetail,
  AllDistrictDetail,
  AllSubDistrictDetail
} from './address-types'

export const ADDRESS_URL = '/manage'
export const GET_ADDRESS_URL = `${ADDRESS_URL}/userAdress/getUserAdressList`
export const EDIT_ADDRESS_URL = `${ADDRESS_URL}/userAdress/editUserAdress`
export const GET_COUNTRY_URL = `${ADDRESS_URL}/country/findAll`
export const GET_PROVINCE_URL = `${ADDRESS_URL}/province/findProvinceByCountry`
export const GET_DISTRICT_URL = `${ADDRESS_URL}/district/findDistrictByProvince`
export const GET_SUB_DISTRICT_URL = `${ADDRESS_URL}/subdistrict/findSubdistrictByProvinceAndDistrict`

export const useGetCountry = () => {
  return useQuery<AllCountryDetail | undefined, Error>(GET_COUNTRY_URL, () =>
    api.belive.get<AllCountryDetail>(GET_COUNTRY_URL),
  )
}

export const useGetProvince = (countryId: number) => {
  const GET_PROVINCE_URL_WITH_ID = `${GET_PROVINCE_URL}?countryId=${countryId}`

  return useQuery<AllProvinceDetail | undefined, Error>(GET_PROVINCE_URL_WITH_ID, () =>
    api.belive.get<AllProvinceDetail>(GET_PROVINCE_URL_WITH_ID),
  )
}

export const useGetDistrict = (proviceId: number) => {
  const GETDISTRICT_URL_WITH_ID = `${GET_DISTRICT_URL}?provinceId=${proviceId}`

  return useQuery<AllDistrictDetail | undefined, Error>(GETDISTRICT_URL_WITH_ID, () =>
    api.belive.get<AllDistrictDetail>(GETDISTRICT_URL_WITH_ID),
  )
}

export const useGetSubDistrict = (proviceId: number, districtId: number) => {
  const GETDISTRICT_URL_WITH_ID = `${GET_SUB_DISTRICT_URL}?provinceId=${proviceId}&districtId=${districtId}`

  return useQuery<AllSubDistrictDetail | undefined, Error>(GETDISTRICT_URL_WITH_ID, () =>
    api.belive.get<AllSubDistrictDetail>(GETDISTRICT_URL_WITH_ID),
  )
}
