import { useQuery, useInfiniteQuery, useMutation } from 'react-query'
import { api } from '../../utils/api'
import {
  ShopBrandType,
  GetShopRewardItemsProps,
  ProductList,
  ProductDetial,
  ClaimShopRewardProps,
  ImageSlider,
  ProductRedeemProps,
} from './shop-typed'
import image from '../../assets/images/fitness.jpg'
import { ContentType } from '../../utils/api/tools'
import { queryCache } from '../..'
import { GET_USER_COIN_URL } from '../user/user-query'
import { COIN_TRANSACTION_URL } from '../point-history/point-history-query'
import dayjs from 'dayjs'

export const SHOP_URL = '/web/shop'
export const BRAND_LIST_URL = `${SHOP_URL}/getBrandList`
export const CATEGORY_LIST_URL = `${SHOP_URL}/getCategoryList`
export const PRODUCT_LIST_URL = `${SHOP_URL}/getProductList`
export const PRODUCT_DETAIL_URL = `${SHOP_URL}/getProductDetail`
export const CLAIM_REWARD_URL = `${SHOP_URL}/redeemProduct`
export const SHOP_BANNER_URL = `${SHOP_URL}/getShopBannerList`

export const useGetShopBrand = () => {
  const pageSize = 10
  return useQuery<ShopBrandType | undefined, Error>(BRAND_LIST_URL, () =>
    api.belive.get<ShopBrandType>(BRAND_LIST_URL, { page: 1, pageSize }),
  )
}

export const useGetProductCategoryList = () => {
  const pageSize = 10
  return useQuery<ShopBrandType | undefined, Error>(CATEGORY_LIST_URL, () =>
    api.belive.get<ShopBrandType>(CATEGORY_LIST_URL, {
      page: 1,
      pageSize,
    }),
  )
}

export const useGetShopProductList = (categoryId: number) => {
  const pageSize = 4
  return useQuery<ProductList>([PRODUCT_LIST_URL, { categoryId, pageSize }], () =>
    api.belive.get<ProductList>(PRODUCT_LIST_URL, {
      page: 1,
      pageSize,
      categoryId,
    }),
  )
}

export const useGetShopCategroyProductList = (categoryId: number | string) => {
  const pageSize = 8
  return useInfiniteQuery(
    [PRODUCT_LIST_URL, { categoryId, pageSize }],
    ({ pageParam = 1 }) =>
      api.belive.get<ProductList>(PRODUCT_LIST_URL, {
        page: pageParam,
        pageSize,
        categoryId,
      }),
    {
      getNextPageParam: (lastGroup: ProductList) => {
        const { totalPages, number } = lastGroup
        const curPage = number + 1
        const nextPage = number + 2
        return curPage < totalPages ? nextPage : null
      },
    },
  )
}

export const useGetShopBrandProductList = (brandId: number) => {
  const pageSize = 8
  return useInfiniteQuery(
    [PRODUCT_LIST_URL, { brandId, pageSize }],
    ({ pageParam = 1 }) =>
      api.belive.get<ProductList>(PRODUCT_LIST_URL, {
        page: pageParam,
        pageSize,
        brandId,
      }),
    {
      getNextPageParam: (lastGroup: ProductList) => {
        const { totalPages, number } = lastGroup
        const curPage = number + 1
        const nextPage = number + 2
        return curPage < totalPages ? nextPage : null
      },
    },
  )
}

export const useGetProductDetail = (productId: string) => {
  return useQuery<ProductDetial>([PRODUCT_DETAIL_URL, productId], () =>
    api.belive.get<ProductDetial>(PRODUCT_DETAIL_URL, { productId }),
  )
}

export const useGetShopImageSlider = () => {
  const pageSize = 10
  return useQuery<ImageSlider>([SHOP_BANNER_URL, pageSize], () =>
    api.belive.get<ImageSlider>(SHOP_BANNER_URL, { page: 1, pageSize }),
  )
}

export const useGetShopCategoryImageSlider = (categoryId: string) => {
  const pageSize = 10
  return useQuery<ImageSlider>([SHOP_BANNER_URL, pageSize, categoryId], () =>
    api.belive.get<ImageSlider>(SHOP_BANNER_URL, { page: 1, pageSize, categoryId }),
  )
}

export const useGetShopBrandImageSlider = (brandId: string) => {
  const pageSize = 10
  return useQuery<ImageSlider>([SHOP_BANNER_URL, pageSize, brandId], () =>
    api.belive.get<ImageSlider>(SHOP_BANNER_URL, { page: 1, pageSize, brandId }),
  )
}

export const useClaimShopReward = (option?: any) => {
  const { onSuccess } = option || {}

  const mutate = useMutation(
    async (data: ClaimShopRewardProps) => {
      return api.belive.post<any>(CLAIM_REWARD_URL, data, { contentType: ContentType.JSON }) // อย่าลืมแก้ response type หลังจากยิงจริงได้แล้ว
    },
    {
      onSuccess: (data, variables, context) => {
        queryCache.invalidateQueries(GET_USER_COIN_URL)
        queryCache.invalidateQueries(COIN_TRANSACTION_URL)
        onSuccess && onSuccess()
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
      ...option,
    },
  )
  return mutate
}

export const productRedeem = ({
  userCoin,
  specialCoin,
  remain,
  expireDate,
  normalCoin
}: ProductRedeemProps) => {
  const needCoint = specialCoin !== null ? specialCoin : normalCoin
  const userHaveCoinForRedeem = userCoin && needCoint && userCoin >= needCoint
  const productIsRemain = remain && remain > 0
  // const productIsNotExpired = dayjs(expireDate) > dayjs()

  if (userHaveCoinForRedeem && productIsRemain) {
    return true
  } else {
    return false
  }
}
