import { useCallback, useState } from 'react'

import Select from '../../components/common/Select'

const options1 = [
  {
    label: 'จังหวัด1',
    value: 1,
  },
  {
    label: 'จังหวัด2',
    value: 2,
  },
  {
    label: 'จังหวัด3',
    value: 3,
  },
]
const options2 = [
  {
    label: 'อำเภอ1',
    value: 1,
  },
  {
    label: 'อำเภอ2',
    value: 2,
  },
  {
    label: 'อำเภอ3',
    value: 3,
  },
]
const options3 = [
  {
    label: 'ตำบล1',
    value: 1,
  },
  {
    label: 'ตำบล2',
    value: 2,
  },
  {
    label: 'ตำบล3',
    value: 3,
  },
]

const ExampleSelect = () => {
  const [valueObject, setValueObject] = useState({
    first: undefined,
    second: undefined,
    third: undefined,
  })
  const onSelect = useCallback((key, index) => {
    setValueObject((valueObject) => ({ ...valueObject, [index]: key }))
  }, [])

  return (
    <div>
      <Select
        options={options1}
        placeholder="placeholder จังหวัด"
        value={valueObject.first}
        onSelect={(key: string | number) => onSelect(key, 'first')}
      />
      <Select
        options={options2}
        placeholder="placeholder อำเภอ"
        value={valueObject.second}
        onSelect={(key: string | number) => onSelect(key, 'second')}
      />
      <Select
        options={options3}
        placeholder="placeholder ตำบล"
        value={valueObject.third}
        onSelect={(key: string | number) => onSelect(key, 'third')}
      />
    </div>
  )
}

export default ExampleSelect
