import styled from '@emotion/styled'
import { CSSProperties, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import Text from './Text'
import color from '../../constant/colors'
import Image from './Image'
import { mobile } from '../../utils/responsive-helper'

type HeadbarProps = {
  title: string
  backWard?: string
  style?: CSSProperties
  className?: string
}

export type ItemProps = {
  label: string
  path: string
}

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 42px;
  ${mobile} {
    padding: 0 40px;
  }
`
const IconBack = styled(Image)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  left: 0;
`

const CustomLink: React.FC<{ path: string; }> = (
  props,
) => {
  const { path, ...rest } = props
  const history = useHistory()

  const onTextClick = useCallback(() => {
    history.push(path)
  }, [history, path])
  return (
    <IconBack imageName='IconBack' onClick={onTextClick} {...rest} />
  )
}

const Headbar = (props: HeadbarProps) => {
  const { title, backWard, ...rest } = props

  return (
    <Layout {...rest}>
      {backWard && <CustomLink path={backWard} />}
      <Text color={color.WHITE} size={24} type='Bold'>
        {title}
      </Text>
    </Layout>
  )
}

export default Headbar
