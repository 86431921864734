import { createSlice } from '@reduxjs/toolkit'

export type EnrollmentType = {
  enrollment: {
    benefitYearId: number
    startDate: number
    endDate: number
    startPeriodDate: number
    endPeriodDate: number
    enrollmentYear: string
    benefitYear: number
  }
}

export const initState = {
  enrollment: {
    benefitYearId: 0,
    startDate: 0,
    endDate: 0,
    startPeriodDate: 0,
    endPeriodDate: 0,
    enrollmentYear: '',
    benefitYear: 0,
  },
}

const EnrollmentSlice = createSlice({
  name: 'eClaim',
  initialState: initState,
  reducers: {
    setEnrollment: (state, { payload }: PayloadWithType<EnrollmentType['enrollment']>) => {
      state.enrollment = payload
    },
    clearEnrollment: (state) => {
      state.enrollment = initState.enrollment
    },
  },
})

export const enrollmentReducer = EnrollmentSlice.reducer

export const { setEnrollment, clearEnrollment } = EnrollmentSlice.actions
