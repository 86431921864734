import styled from '@emotion/styled'
import { Space } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Card from '../../../components/common/Card'
import { ImageNameKeys } from '../../../components/common/Image'
import Text from '../../../components/common/Text'
import { default as c, default as colors } from '../../../constant/colors'
import { RootState } from '../../../redux-store'
import { useGetAllSelfBenefits } from '../../../services/enrollment/enrollment-query'
import { GetBenefitsResponse } from '../../../services/enrollment/enrollment-types'
import { numberWithCommas } from '../../../utils/helper'
import { mobile, useScreen } from '../../../utils/responsive-helper'

const LayoutCard = styled(Card)`
  padding: 32px;
  text-align: end;

  ${mobile} {
    padding: 8px;
  }
  .icon-enroll {
    margin-right: 16px;
  }
`
const SummaryTitle = styled(Text)`
  grid-column: 1/3;
  text-align: start;

  ${mobile} {
    text-align: end;
  }

`
const SummaryCoin = styled(Text)`
  width: 100%;
`
const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // flex-direction: row;
  column-gap: 20px;
  width: 100%;
`
const SummaryWrapperNote = styled.div`
  width: 50%;
`
const SummaryContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
`
const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 24px;
  text-align: left;
  column-gap: 22px;

  ${mobile} {
    flex-direction: column;
  }
`
const CoinContent = styled.div`
  display: flex;
  width: 100%;
`

const SpaceTitle = styled.div`
  flex: 1;
`

const SpaceCoin = styled(Space)`
  min-width: 115px;
  justify-content: space-between;
  align-items: flex-start;
  
  ${mobile} {
    align-items: center;
  }
`

const LayoutLastSummaryExchange = styled.div`
  border-top: solid 1px;
  border-color: ${c.BACKGROUND};
  
  ${mobile} {
    padding-left: 0px;
  }
`

type RowSummaryExchangeProps = {
  title: string
  titleChoice?: string
  totalCost: number
  remainPoint: number
  subTitle: string
  value?: number
  color: string
  icon: ImageNameKeys
}

const RowSummaryExchange = (props: RowSummaryExchangeProps) => {
  const { t, i18n } = useTranslation()
  const { title, titleChoice, totalCost, remainPoint, subTitle, value, color, icon } = props
  const { isMobile } = useScreen()
  const actuallyValue = value === undefined || value === 0 ? '0' : numberWithCommas(value)
  const minusSymbol = color === c.INPUT_ERROR && actuallyValue !== '-' ? '-' : undefined

  return (
    <RowSpaceBetween>
      <SummaryContent>
        <SummaryWrapper>
          <SummaryTitle size={14} type="Bold" style={{ gridColumn: '1/5', maxWidth: 'auto' }}>
            {t('enrollment.editSelfBenefits.summary.title')}
          </SummaryTitle>
          <SummaryTitle size={14}>
            {t('enrollment.editSelfBenefits.summary.selected')} :
          </SummaryTitle>
          <SummaryCoin size={14} style={{ gridColumn: '3/5', maxWidth: 'auto' }}>
            {titleChoice}
          </SummaryCoin>
          <SummaryTitle size={14}>
            {t('enrollment.editSelfBenefits.summary.coinAdditonal')} :
          </SummaryTitle>
          <SummaryCoin size={14}>
            {remainPoint >= 0 ? numberWithCommas(remainPoint) : 0}
          </SummaryCoin>
          <SummaryCoin size={14}>
            {t('app.coin')}
          </SummaryCoin>
          <SummaryTitle size={14}>
            {t('enrollment.editSelfBenefits.summary.payAdditional')} :
          </SummaryTitle>
          <SummaryCoin size={14} color={totalCost >= 0 ? colors.BLACK : colors.RED}>
            {totalCost >= 0 ? 0 : numberWithCommas(totalCost * -1)}
          </SummaryCoin>
          <SummaryCoin size={14}>
            {t('app.baht')}
          </SummaryCoin>
        </SummaryWrapper>
        <SummaryWrapperNote></SummaryWrapperNote>
      </SummaryContent>
      {/* <CoinContent>
        <SpaceTitle>
          <Text size={isMobile ? 16 : 20} type={'Bold'}>
            {title}
          </Text>
          <Text size={isMobile ? 12 : 14} type={'Medium'}>
            {subTitle}
          </Text>
        </SpaceTitle>
        <SpaceCoin>
          <Image
            imageName={icon}
            width={24}
            height={24}
          // style={{ objectFit: isScg === true ? 'none' : 'cover' }}
          />
          <Text size={isMobile ? 16 : 20} type={'Bold'} color={color} style={{ whiteSpace: 'nowrap' }}>
            {minusSymbol}
            {actuallyValue}
          </Text>
        </SpaceCoin>
      </CoinContent> */}
    </RowSpaceBetween>
  )
}

type SummaryExchangeBenefitsProps = {
  selectedBenefits: GetBenefitsResponse
  totalAvailableCoins?: number
  totalUsedCoins?: number
  totalEarnedCoins?: number
  totalRemainingCoins?: number
  benefitYearId?: number
}

const SummaryExchangeBenefits = (props: SummaryExchangeBenefitsProps) => {
  const { selectedBenefits, totalAvailableCoins, totalUsedCoins, totalEarnedCoins, totalRemainingCoins, benefitYearId } = props
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { userSelectedWrapperList, totalCost } = selectedBenefits

  const { data: allSelfBenefits, isStale: isAllSelfBenefitsStale } = useGetAllSelfBenefits(benefitYearId)

  const {
    startDate: periodStartEnrollmentDate = 0,
    endDate: periodEndEnrollmentDate = 0,
    startPeriodDate: periodStartPeriodDate = 0,
    endPeriodDate: periodEndPeriodDate = 0,
    benefitYearId: periodBenefitYearId = 0,
    benefitYear: periodBenefitYear = 0, } = useSelector((state: RootState) => state.enrollment.enrollment)
  const isErrorEnrollment = useMemo(() => periodBenefitYearId === 0, [periodBenefitYearId])

  const initialPoint = useMemo(() => {
    return allSelfBenefits
      ? allSelfBenefits[0].choices.reduce((sumTotalPoint, cur) => {
        if (cur?.isDefault === true) {
          const point = cur?.taggedPrice || 0
          sumTotalPoint += point
        }

        return sumTotalPoint
      }, 0)
      : 0

  }, [allSelfBenefits])

  const userSelected = useMemo(() => userSelectedWrapperList[0], [userSelectedWrapperList])

  const remainPoint = useMemo(() => {
    if (!userSelected.choicesId) return 0;

    return initialPoint - userSelected?.selectedCoinValue
  }, [initialPoint, userSelected])

  return (
    <LayoutCard>
      {/* <RowSummaryExchange
        title={'เหรียญที่มีอยู่'}
        value={totalAvailableCoins}
        color={c.BLACK}
        icon={'iconCoin'}
      />
      <RowSummaryExchange
        title={'ใช้ไป'}
        value={totalUsedCoins}
        color={c.INPUT_ERROR}
        icon={'iconCoin'}
      />
      {isScg === false && (
        <RowSummaryExchange
          title={'แลกเป็นเหรียญ'}
          value={totalEarnedCoins}
          color={c.PRIMARY}
          icon={'iconCoin'}
        />
      )} */}

      <LayoutLastSummaryExchange>
        <RowSummaryExchange
          title={Number(totalRemainingCoins) >= 0 ? t('app.coinRemain') : t('app.payAdditional')}
          titleChoice={userSelectedWrapperList[0]?.choiceName}
          totalCost={totalCost}
          remainPoint={remainPoint}
          subTitle={t('app.coinCanUse') + " " + dayjs(periodStartPeriodDate).locale(language).format('BBBB')}
          value={Number(totalRemainingCoins) >= 0 ? totalRemainingCoins : Number(totalRemainingCoins) * -1}
          color={c.PRIMARY}
          icon={'IconDentsuCoin'}
        />
      </LayoutLastSummaryExchange>
    </LayoutCard>
  )
}

export default SummaryExchangeBenefits
