import styled from '@emotion/styled'
import Card from '../../components/common/Card'
import Text from '../../components/common/Text'
import { useGetUser } from '../../services/user/user-query'
import Avatar from '../../components/common/Avatar'
import { mobile } from '../../utils/responsive-helper'
import config from '../../config'
import { useHistory } from 'react-router'
import { useCallback } from 'react'
import * as paths from '../../constant/path'

const Container = styled(Card)`
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
  float: left;
  width: 100%;
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: inherit;
  margin: 0 auto;
  z-index: 1;
  padding: 4px 16px;
}
`
const FlexColumn = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;  
`
const Item = styled.div`
  flex: 1 0 25%;             
  margin: 16px;     
`

const Menu = styled.div`
  flex: 1 0 25%;             
  margin: 16px;     
  display: flex;
  align-items: center;
  justify-content: center;              
`

const LeftCointainer = styled.div`
  display: inline-flex;
  margin: 8px;
`

const CustomeStyleAvatar = styled(Avatar)`
  margin: 0 auto;
  ${mobile} {
    margin: auto 0;
  }
`
const TextProfile = styled(Text)`
  padding-left: 16px;
`

const TextMenu = styled(Text)`
  padding-top: 16px;
`

const MenuCointainer = styled.div`
  display: inline-block;
  margin: 8px;
`

const HealthDetails = () => {
  const { data: user } = useGetUser()
  const history = useHistory()

  const onClickToLink = useCallback((path) => {
    history.push(path)
  }, [history])

  return (
    <>
      <Container>
        <FlexRow className='left'>
          <Text size={26} type="Bold">สวัสดี{`${user?.firstName} ${user?.lastName}`}</Text>
        </FlexRow>

        <FlexRow className='left'>
          <Text size={14} type="Bold">ผลตรวจปี 2022</Text>
        </FlexRow>

        <FlexColumn>
          <Item>
            <LeftCointainer>
              <CustomeStyleAvatar
                src={`${config.apiHost}${user?.profileImagePath}`}
                isBorder={true}
                size={45}
                borderWidth={1}
              />

              <div>
                <TextProfile size={16} type="Bold">45</TextProfile>
                <TextProfile size={12} >น้ำหนักตัว</TextProfile>
              </div>
            </LeftCointainer>
          </Item>
          <Item>
            <LeftCointainer>
              <CustomeStyleAvatar
                src={`${config.apiHost}${user?.profileImagePath}`}
                isBorder={true}
                size={45}
                borderWidth={1}
              />

              <div>
                <TextProfile size={16} type="Bold">170</TextProfile>
                <TextProfile size={12} >ส่วนสูง</TextProfile>
              </div>
            </LeftCointainer>
          </Item>

          <Item>
            <LeftCointainer>
              <CustomeStyleAvatar
                src={`${config.apiHost}${user?.profileImagePath}`}
                isBorder={true}
                size={45}
                borderWidth={1}
              />

              <div>
                <TextProfile size={16} type="Bold">20.22</TextProfile>
                <TextProfile size={12} >ความหนาของร่างกาย</TextProfile>
              </div>
            </LeftCointainer>
          </Item>

          <Item>
            <LeftCointainer>
              <CustomeStyleAvatar
                src={`${config.apiHost}${user?.profileImagePath}`}
                isBorder={true}
                size={45}
                borderWidth={1}
              />

              <div>
                <TextProfile size={16} type="Bold">102</TextProfile>
                <TextProfile size={12} >ความดันตัวบน</TextProfile>
              </div>
            </LeftCointainer>
          </Item>

          <Item>
            <LeftCointainer>
              <CustomeStyleAvatar
                src={`${config.apiHost}${user?.profileImagePath}`}
                isBorder={true}
                size={45}
                borderWidth={1}
              />

              <div>
                <TextProfile size={16} type="Bold">65</TextProfile>
                <TextProfile size={12} >ความดันตัวล่าง</TextProfile>
              </div>
            </LeftCointainer>
          </Item>

          <Item>
            <LeftCointainer>
              <CustomeStyleAvatar
                src={`${config.apiHost}${user?.profileImagePath}`}
                isBorder={true}
                size={45}
                borderWidth={1}
              />

              <div>
                <TextProfile size={16} type="Bold">67</TextProfile>
                <TextProfile size={12} >ชีพจร</TextProfile>
              </div>
            </LeftCointainer>
          </Item>
        </FlexColumn>

      </Container>

      <FlexRow className='left'>
        <Text size={20} type="Bold">เลือกหมวดหมู่</Text>
      </FlexRow>

      <FlexColumn>
        <Menu>
          <MenuCointainer
            onClick={() => {
              onClickToLink(
                paths.bloodCell({
                  routeParam: {
                    blood_cell_id: 1
                  }
                })
              )
            }}
          >
            <CustomeStyleAvatar
              src={`${config.apiHost}${user?.profileImagePath}`}
              isBorder={true}
              size={80}
              borderWidth={1}
            />

            <div>
              <TextMenu size={18} type="Bold">ความสมบูรณ์ของเม็ดเลือด</TextMenu>
            </div>
          </MenuCointainer>
        </Menu>

        <Menu>
          <MenuCointainer>
            <CustomeStyleAvatar
              src={`${config.apiHost}${user?.profileImagePath}`}
              isBorder={true}
              size={80}
              borderWidth={1}
            />

            <div>
              <TextMenu size={18} type="Bold">น้ำตาลในเลือด</TextMenu>
            </div>
          </MenuCointainer>
        </Menu>

        <Menu>
          <MenuCointainer>
            <CustomeStyleAvatar
              src={`${config.apiHost}${user?.profileImagePath}`}
              isBorder={true}
              size={80}
              borderWidth={1}
            />

            <div>
              <TextMenu size={18} type="Bold">ไขมันในเลือด</TextMenu>
            </div>
          </MenuCointainer>
        </Menu>



        <Menu>
          <MenuCointainer>
            <CustomeStyleAvatar
              src={`${config.apiHost}${user?.profileImagePath}`}
              isBorder={true}
              size={80}
              borderWidth={1}
            />

            <div>
              <TextMenu size={18} type="Bold">ตับและไต</TextMenu>
            </div>
          </MenuCointainer>
        </Menu>

        <Menu>
          <MenuCointainer>
            <CustomeStyleAvatar
              src={`${config.apiHost}${user?.profileImagePath}`}
              isBorder={true}
              size={80}
              borderWidth={1}
            />

            <div>
              <TextMenu size={18} type="Bold">ปัสสาวะและอุจจาระ</TextMenu>
            </div>
          </MenuCointainer>
        </Menu>

        <Menu>
          <MenuCointainer>
            <CustomeStyleAvatar
              src={`${config.apiHost}${user?.profileImagePath}`}
              isBorder={true}
              size={80}
              borderWidth={1}
            />

            <div>
              <TextMenu size={18} type="Bold">เอกซ์เรย์ทรวงอก</TextMenu>
            </div>
          </MenuCointainer>
        </Menu>
      </FlexColumn>
    </>
  )
}
export default HealthDetails
