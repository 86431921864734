import styled from '@emotion/styled'
import Breadcrumb from '../../components/common/Breadcrumb'
import Text from '../../components/common/Text'
import { eClaim, eClaimList, home } from '../../constant/path'
import { useGetEClaimList } from '../../services/e-claim/e-claim-query'
import { isScg } from '../../utils/helper'
import { mobile, useScreen } from '../../utils/responsive-helper'
import EClaimListComponent from './EClaimListComponent'
import EClaimListComponentScg from './EClaimListComponentScg'

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
  z-index: 1;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-height: 266px;
  margin: 22px auto 0 auto;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  width: 100%;
  min-height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`

const BreadcrumbContainer = styled.div`
  z-index: 1;
  ${mobile} {
    margin: 0px 16px;
  }
`

const CardContainer = styled.div`
  z-index: 1;
`

const EClaimList = () => {
  const { isMobile } = useScreen()
  const { data: eclaimList } = useGetEClaimList()

  return (
    <Layout>
      <BackgroundHeader />
      <FlexRow>
        <BreadcrumbContainer>
          <Breadcrumb
            overide={(paths) => {
              paths[0] = {
                label: 'Home',
                path: home(),
              }
              paths[1] = {
                label: 'D-Claims',
                path: eClaim(),
              }
              paths[2] = {
                label: 'ประวัติการเบิก',
                path: eClaimList(),
              }
              return paths
            }}
          />
        </BreadcrumbContainer>
        <TitleContainer>
          <Text type="Bold" size={32}>
            ประวัติการเบิก
          </Text>
        </TitleContainer>
        <CardContainer>{isScg === false ? <EClaimListComponent /> : <EClaimListComponentScg />}</CardContainer>
      </FlexRow>
    </Layout>
  )
}

export default EClaimList
