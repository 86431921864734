import { Redirect, Route, Switch } from 'react-router'

import * as paths from '../constant/path'
import AppLayout from '../components/app-layout'
import Home from '../pages/Home'
import News from '../pages/News'
import Play from '../pages/Play'
import PlayDetail from '../pages/PlayDetail'
import NewsDetail from '../pages/NewsDetail'
import ShopRewardClaim from '../pages/ShopRewardClaim'
import Shop from '../pages/Shop'
import ShopCategory from '../pages/Shop/ShopCategory'
// import ShopCart from '../pages/Shop/ShopCart'
// import ShopUserCoin from '../pages/Shop/ShopUserCoin'
import About from '../pages/About'
import TermAndCondition from '../pages/TermAndCondition'
import Contact from '../pages/Contact'
import Profile from '../pages/Profile'
import Promotion from '../pages/Promotion'
import PromotionCategoryDetail from '../pages/Promotion/PromotionCategoryDetail'
import CouponDetail from '../pages/Promotion/CouponDetail'
import Order from '../pages/Order'
import Product from '../pages/Shop/Product'
// import Product from '../pages/Product'
// import ProductDetail from '../pages/Product/ProductDetail'
// import ProductDetailDescription from '../pages/Product/ProductDetailDescription'
import ShopBrand from '../pages/Shop/ShopBrand'
// import Notification from '../pages/Home/Notification'
import AppShortcut from '../pages/AppShortcut'
import Notification from '../pages/Notification'
import PointHistory from '../pages/PointHistory'
import Enrollment from '../pages/Enrollment'
// import EditSelfBenefits from '../pages/Enrollment/Benefits/EditBenefits/EditSelfBenefits'
// import EditRelativeBenefits from '../pages/Enrollment/Benefits/EditBenefits/EditRelativeBenefits'
import EnrollmentDetail from '../pages/Enrollment/EnrollmentDetail'
// import EnrollmentLeave from '../pages/Enrollment/Benefits/EnrollmentLeave'
import EClaim from '../pages/EClaim'
import EClaimDetail from '../pages/EClaimDetail'
// import Benefits from '../pages/Enrollment/Benefits'
import EClaimSummary from '../pages/EClaimDetail/EClaimSummary'
// import EnrollmentInsuranceFamily from '../pages/Enrollment/Benefits/EnrollmentInsuranceFamily'
// import RelativeForm from '../pages/Enrollment/Benefits/EnrollmentInsuranceFamily/RelativeForm'
import EClaimComplete from '../pages/EClaimDetail/EClaimComplete'
import EClaimList from '../pages/EClaim/EclaimList'
// import EditWellBeing from '../pages/Enrollment/Benefits/WellBeing/EditWellBeing'
// import BenefitWellBeing from '../pages/Enrollment/Benefits/WellBeing'
import { isScg } from '../utils/helper'
import EClaimHistoryDetail from '../pages/EclaimDetailHistory'
import WellBeing from '../pages/WellBeing'
import HealthTips from '../pages/HealthTips'
import HealthProfile from '../pages/HealthProfile'
import BloodCell from '../pages/HealthProfile/bloodCell'
import HeathCheckupDetail from '../pages/HealthCheckup/HealthCheckupDetail'

import ComingSoon from '../pages/ComingSoon'
import HealthTipsDetail from '../pages/HealthTipsDetail'
import Relative from '../pages/Relative'

export const ProtectedRoute = () => {
  return (
    <AppLayout>
      <Switch>
        <Redirect exact from="/" to={paths.home()} />
        {/* เพิ่ม route ตรงนี้ ไม่ต้องใส่ exact */}
        <Route path={paths.notification()} component={Notification} />
        <Route path={paths.app()} component={AppShortcut} />
        <Route path={paths.pointHistory()} component={PointHistory} />
        <Route path={paths.profile()} component={Profile} />
        <Route path={paths.about()} component={About} />
        <Route path={paths.contact()} component={Contact} />
        <Route path={paths.termAndCondition()} component={TermAndCondition} />
        <Route path={paths.app()} component={AppShortcut} />
        <Route path={paths.home()} component={Home} />
        <Route path={paths.newsDetails()} component={NewsDetail} />
        <Route path={paths.news()} component={News} />
        <Route path={paths.playDetail()} component={PlayDetail} />
        <Route path={paths.play()} component={Play} />

        <Route
          path={[paths.claimCategoryReward(), paths.claimBrandReward()]}
          component={ShopRewardClaim}
        />
        <Route path={[paths.categoryReward(), paths.brandReward()]} component={Product} />

        {/* <Route path={paths.shopCart()} component={ShopCart} />
        <Route path={paths.shopUserCoin()} component={ShopUserCoin} /> */}
        <Route path={paths.shopCategory()} component={ShopCategory} />
        <Route path={paths.shopBrand()} component={ShopBrand} />
        <Route path={paths.shop()} component={Shop} />
        {/* <Route path={paths.shop()} component={ComingSoon} /> */}
        <Route path={paths.promotion()} component={Promotion} />
        <Route path={paths.promotionCategoryDetail()} component={PromotionCategoryDetail} />
        <Route path={paths.couponDetail()} component={CouponDetail} />
        <Route path={paths.order()} component={Order} />
        {/* <Route path={paths.products()} component={Product} />
        <Route path={paths.productDetailDescription()} component={ProductDetailDescription} />
        <Route path={paths.productDetail()} component={ProductDetail} /> */}
        {/* Enrollment */}
        {/* <Route path={paths.enrollmentWellBeingEdit()} component={EditWellBeing} />
        <Route path={paths.enrollmentWellBeing()} component={BenefitWellBeing} /> */}
        {/* <Route path={paths.enrollmentWellBeingEdit()} component={EditWellBeing} />
        <Route path={paths.enrollmentWellBeing()} component={WellBeing} />
        <Route path={paths.insuranceFamilyForm()} component={RelativeForm} />
        <Route path={paths.enrollmentInsuranceFamily()} component={EnrollmentInsuranceFamily} />
        <Route path={paths.editRelativeBenefits()} component={EditRelativeBenefits} />
        <Route path={paths.editSelfBenefits()} component={EditSelfBenefits} />
        <Route path={paths.enrollmentLeave()} component={EnrollmentLeave} />
        <Route path={paths.benefits()} component={Benefits} /> */}
        {/* E-Claim */}
        <Route path={paths.eClaimCompleted()} component={EClaimComplete} />
        <Route path={paths.eClaimSummary()} component={EClaimSummary} />
        <Route path={paths.eClaimDetail()} component={EClaimDetail} />
        <Route path={paths.eClaimHistoryDetail()} component={EClaimHistoryDetail} />
        <Route path={paths.eClaim()} component={Enrollment} />
        <Route path={paths.eClaimList()} component={EClaimList} />
        <Route path={paths.relative()} component={Relative} />
        {/* Enrollment */}
        <Route path={paths.enrollment()} component={EnrollmentDetail} />
        {/* Health Checkup */}
        <Route path={paths.healthCheckup()} component={HeathCheckupDetail} />

        {/* WellBeing */}
        <Route path={paths.healthTipsDetails()} component={HealthTipsDetail} />
        <Route path={paths.healthTips()} component={HealthTips} />

        <Route path={paths.heartCareDetails()} component={HealthTipsDetail} />
        <Route path={paths.heartCare()} component={HealthTips} />

        <Route path={paths.bloodCell()} component={BloodCell} />
        <Route path={paths.healthProfile()} component={HealthProfile} />
        <Route path={paths.wellBeing()} component={WellBeing} />


        <Route path={paths.home()} component={Home} />
        <Redirect to={paths.notFound()} />
      </Switch>
    </AppLayout>
  )
}
