import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import Image from '../../components/common/Image'
import ProfileCard from '../../components/ProfileCard'
import { Row } from 'antd'
import CoinBlueImage from '../../assets/images/group-16.svg'
import Text from '../../components/common/Text'
import { useTranslation } from 'react-i18next'
import { numberWithCommas, useRouter } from '../../utils/helper'
import dayjs from 'dayjs'
import Button from '../../components/common/Button'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'
import FooterContent from '../../components/FooterContent'
import Breadcrumb from '../../components/common/Breadcrumb'
import { useGetProductDetail } from '../../services/shop/shop-query'
import {
  shopCategory,
  shopBrand,
  shop,
  claimCategoryReward,
  claimBrandReward,
} from '../../constant/path'
import { useHistory } from 'react-router'
import Loading from '../../components/common/Loading'
import TagRemain from '../../components/common/TagRemain'
import { useGetUserCoin } from '../../services/user/user-query'
import { productRedeem } from '../../services/shop/shop-query'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  width: 100%;
`
const HeaderLayout = styled.div`
  display: flex;
  flex: row;
  width: 100%;
  align-items: flex-end;

  .product-image {
    width: 752px;
    height: 420px;
    margin-right: 24px;
    ${tablet} {
      width: 100%;
      height: 420px;
      margin-right: 0px;
    }

    ${mobile} {
      width: 100%;
      height: 192px;
      margin-right: 0px;
    }
  }

  ${tablet} {
    flex-direction: column-reverse;
    .profile-card {
      margin-bottom: 25px;
    }
  }
`
const ProductPointLayout = styled.div`
  display: flex;
  flex-flow: row;
  margin: 24px 0;

  .coin {
    align-items: center;
    .coin-blue-image {
      width: 33px;
      height: 33px;
    }
    .point-label {
      align-items: center;
      height: 20px;
    }
  }

  ${mobile} {
    flex-flow: column;
    .coin {
      margin-bottom: 14px;
    }
  }
`
const BreadcrumbStyled = styled<typeof Breadcrumb>(Breadcrumb)`
  margin-top: 24px;
  margin-bottom: 24px;

  ${mobile} {
    margin : 24px 16px;
  }
`
const RedeemButton = styled(Button)`
  margin-left: auto;
  ${mobile} {
    margin: auto;
  }
`

const ContentLayout = styled.div`
  padding: 0 16px;
`

type ProductParams = {
  rewardId: string
  categoryId?: string
  brandId?: string
  title: string
}

const Product = () => {
  const { query } = useRouter<ProductParams>()
  const { title: breadcrumbTitle, rewardId, brandId, categoryId } = query
  const { data: productDetail, isLoading } = useGetProductDetail(rewardId)
  const { data: userCoin } = useGetUserCoin()
  const history = useHistory()

  const { t } = useTranslation()
  const { title, thumbnailImage, specialCoin, normalCoin, remain, description, expireDate } =
    productDetail || {}
  const { isMobile } = useScreen()

  const canRedeem = useMemo(() => productRedeem({ userCoin, specialCoin, normalCoin, remain, expireDate }), [
    expireDate,
    remain,
    specialCoin,
    userCoin,
    normalCoin
  ])

  const productExpireDate = useMemo(() => {
    const epireDateBudich =
      expireDate && dayjs(expireDate).isValid()
        ? dayjs(expireDate).add(543, 'year').format('DD MMM YYYY')
        : '-'

    return `${t('shop.expired')} ${epireDateBudich}`
  }, [expireDate, t])

  const onRedeemButtonClick = useCallback(() => {
    const gotoClaim = categoryId
      ? claimCategoryReward({
        routeParam: { categoryId: Number(categoryId), rewardId: Number(rewardId) },
      })
      : claimBrandReward({
        routeParam: { brandId: Number(brandId), rewardId: Number(rewardId) },
      })
    if (canRedeem) {
      history.push(gotoClaim)
    }
  }, [categoryId, rewardId, brandId, canRedeem, history])
  return (
    <Layout id={'product-layout'}>
      <BreadcrumbStyled
        overide={(paths) => {
          const customPaths = paths
          customPaths[0].label = 'Shop'
          customPaths[0].path = shop()
          customPaths[1].label = breadcrumbTitle
          customPaths[1].path = brandId
            ? shopBrand({
              routeParam: { brandId: Number(brandId) },
              queryParam: { title: breadcrumbTitle },
            })
            : shopCategory({
              routeParam: { categoryId: Number(categoryId) },
              queryParam: { title: breadcrumbTitle },
            })
          customPaths.splice(2, 1)
          customPaths[2].label = 'Reward'
          return customPaths
        }}
      />
      <HeaderLayout>
        <Image className="product-image" src={thumbnailImage || ''} />
        <ProfileCard size="medium" />
      </HeaderLayout>
      <Loading loading={isLoading}>
        <ContentLayout>
          <ProductPointLayout>
            <Row className="coin">
              <Image className="coin-blue-image" src={CoinBlueImage} style={{ marginRight: 16 }} />
              <Row className={'point-label'}>
                <Text size={20} type="Bold" style={{ marginRight: 6, lineHeight: '20px' }}>
                  {normalCoin ? numberWithCommas(normalCoin) : '-'}
                </Text>
                <Text size={14}>{t('app.coin')}</Text>
                <TagRemain style={{ marginLeft: 8 }} remain={Number(remain)} />
              </Row>
            </Row>
            <Text
              size={18}
              type="Medium"
              color='#8c8c8c'
              style={{ marginLeft: isMobile ? 0 : 'auto', lineHeight: '18px' }}
            >
              {productExpireDate}
            </Text>
          </ProductPointLayout>
          <Text size={28} type="Bold" color='#363640' style={{ marginBottom: 16 }}>
            {title}
          </Text>
          <Text size={20} color='#363640' style={{ marginBottom: 80 }}>
            <div dangerouslySetInnerHTML={{ __html: `${description}` }} />
          </Text>
        </ContentLayout>
      </Loading>
      <FooterContent
        style={{
          height: 80,
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-end',
          alignItems: 'center',
        }}
      >
        <RedeemButton
          style={{ width: 265, height: 45 }}
          onClick={onRedeemButtonClick}
          disabled={!canRedeem}
        >
          {t('shop.redeem')}
        </RedeemButton>
      </FooterContent>
    </Layout>
  )
}

export default Product
