import React, { useEffect, useState } from 'react'
import Loading from '../../components/common/Loading'

const ExampleLoading = () => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoading((prevLoading) => {
        return !prevLoading
      })
    }, 5000)
  }, [loading])
  return (
    <>
      <Loading loading={loading}>
        <div>some component</div>
      </Loading>
    </>
  )
}
export default ExampleLoading
