import { CSSProperties, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import dentsuIcon from '../../assets/images/base/dentsu-loco.png'
import dentsu_s from '../../assets/images/base/dentsu_s.jpeg'

import {
  Calendar as CalendarScg,
  MaskImage as MaskImageScg,
  ChartIcon as ChartIconScg,
  IconPDF as IconPDFScg,
  IconHistory as IconHistoryScg,
  IconCoinWithBackground as IconCoinWithBackgroundScg,
  IconNews as IconNewsScg,
  CloseIcon as CloseIconScg,
  HamburgerIcon as HamburgerIconScg,
  LogoutIcon as LogoutIconScg,
  ThaiFlag as ThaiFlagScg,
  UsaFlag as UsaFlagScg,
  CloseIcon18 as CloseIcon18Scg,
  TrainIcon as TrainIconScg,
  IconCoinBlue as IconCoinBlueScg,
  IconCoinRed as IconCoinRedScg,
  IconCoinBlack as IconCoinBlackScg,
  IconEdit as IconEditScg,
  IconDelete as IconDeleteScg,
  IconCoinsGold as IconCoinsGoldScg,
  IconCoinRedWithBg as IconCoinRedWithBgScg,
  FitnessImage as FitnessImageScg,
  IconAll as IconAllScg,
  IconCoin as IconCoinScg,
  CalendarIcon as CalendarIconScg,
  IconUser as IconUserScg,
  IconUserGroup as IconUserGroupScg,
  IconTrip as IconTripScg,
  IconEnroll as IconEnrollScg,
  IconEnrollBag as IconEnrollBagScg,
  IconChange as IconChangeScg,
  ReceiptIcon as ReceiptIconScg,
  IdCardIcon as IdCardIconScg,
  IconAdd as IconAddScg,
  IconCoinPrimaryWithBg as IconCoinPrimaryWithBgScg,
  IconEye as IconEyeScg,
  IconUserWhite as IconUserWhiteScg,
  IconUserGroupWhite as IconUserGroupWhiteScg,
  IconTripWhite as IconTripWhiteScg,
  IconEnrollWhite as IconEnrollWhiteScg,
  IconChangeWhite as IconChangeWhiteScg,
  IconCoinWhite as IconCoinWhiteScg,
  IconMenuClaim,
  IconMenuPackage,
  IconMenuShop,
  IconPoints as IconPointsScg,
  IconNext as IconNextScg,
  IconComment as IconCommentScg,
  IconRadio as IconRadioScg,
  IconRadioMarked as IconRadioMarkedScg,
  IconBenefit as IconBenefitScg,
  IconIPD as IconIPDScg,
  IconOPD as IconOPDScg,
  IconDentalInsurance as IconDentalInsuranceScg,
  IconIdCard as IconIdCardScg,
  IconLocation as IconLocationScg,
  IconMail as IconMailScg,
  IconPhoneNumber as IconPhoneNumberScg,
  IconNotification as IconNotificationScg,
  IconSync as IconSyncScg,
} from '../../assets/images/images-scg'

import {
  Calendar,
  MaskImage,
  ChartIcon,
  IconPDF,
  IconHistory,
  IconCoinWithBackground,
  IconNews,
  CloseIcon,
  HamburgerIcon,
  LogoutIcon,
  ThaiFlag,
  UsaFlag,
  CloseIcon18,
  TrainIcon,
  IconCoinBlue,
  IconCoinRed,
  IconCoinBlack,
  IconEdit,
  IconDelete,
  IconCoinsGold,
  IconCoinRedWithBg,
  FitnessImage,
  IconAll,
  IconCoin,
  CalendarIcon,
  IconUser,
  IconUserGroup,
  IconTrip,
  IconEnroll,
  IconEnrollBag,
  IconChange,
  ReceiptIcon,
  IdCardIcon,
  IconAdd,
  IconCoinPrimaryWithBg,
  IconEye,
  IconPoints,
  IconNext,
  IconComment,
  IconPin,
  IconPicture,
  IconRadio,
  IconRadioMarked,
  IconBenefit,
  IconIPD,
  IconOPD,
  IconDentalInsurance,
  // IconIdCard,
  IconBack,
  IconLocation,
  IconMail,
  IconPhoneNumber,
  IconNotification,
  IconSync,
} from '../../assets/images/images-belive'

import { IconDentsuCoin } from '../../assets/images/images-dentsu'
import { isScg } from '../../utils/helper'

export const ImageNameBelive = {
  calendar: Calendar,
  iconCoin: IconCoin,
  calendarIcon: CalendarIcon,
  chartIcon: ChartIcon,
  iconPDF: IconPDF,
  iconEye: IconEye,
  iconHistory: IconHistory,
  iconCoinWithBackground: IconCoinWithBackground,
  iconPin: IconPin,
  iconPicture: IconPicture,
  iconNews: IconNews,
  closeIcon: CloseIcon,
  hamburgerIcon: HamburgerIcon,
  logoutIcon: LogoutIcon,
  thaiFlag: ThaiFlag,
  usaFlag: UsaFlag,
  closeIcon18: CloseIcon18,
  trainIcon: TrainIcon,
  receiptIcon: ReceiptIcon,
  idCardIcon: IdCardIcon,
  iconUser: IconUser,
  iconUserGroup: IconUserGroup,
  iconTrip: IconTrip,
  iconEnroll: IconEnroll,
  iconCoinBlue: IconCoinBlue,
  iconCoinRed: IconCoinRed,
  iconCoinBlack: IconCoinBlack,
  iconEdit: IconEdit,
  iconDelete: IconDelete,
  iconAdd: IconAdd,
  iconEnrollBag: IconEnrollBag,
  iconChange: IconChange,
  iconCoinsGold: IconCoinsGold,
  iconCoinRedWithBg: IconCoinRedWithBg,
  iconCoinPrimaryWithBg: IconCoinPrimaryWithBg,
  fitnessImage: FitnessImage,
  iconAll: IconAll,
  iconUserWhite: IconUser,
  iconUserGroupWhite: IconUserGroup,
  iconTripWhite: IconTrip,
  iconEnrollWhite: IconEnroll,
  iconChangeWhite: IconChange,
  iconCoinWhite: IconCoin,
  iconPoints: IconPoints,
  iconNext: IconNext,
  iconComment: IconComment,
  iconRadio: IconRadio,
  iconRadioMarked: IconRadioMarked,
  iconBenefit: IconBenefit,
  iconIPD: IconIPD,
  iconOPD: IconOPD,
  iconDentalInsurance: IconDentalInsurance,
  IconLocation,
  IconMail,
  IconPhoneNumber,
  IconNotification,
  IconSync,
  IconBack,
  IconDentsuCoin,
}
export const ImageNameScg = {
  iconPin: IconPin,
  iconPicture: IconPicture,
  maskImageScg: MaskImageScg,
  calendar: CalendarScg,
  iconCoin: IconCoinScg,
  calendarIcon: CalendarIconScg,
  chartIcon: ChartIconScg,
  iconPDF: IconPDFScg,
  iconEye: IconEyeScg,
  iconHistory: IconHistoryScg,
  iconCoinWithBackground: IconCoinWithBackgroundScg,
  iconNews: IconNewsScg,
  closeIcon: CloseIconScg,
  hamburgerIcon: HamburgerIconScg,
  logoutIcon: LogoutIconScg,
  thaiFlag: ThaiFlagScg,
  usaFlag: UsaFlagScg,
  closeIcon18: CloseIcon18Scg,
  trainIcon: TrainIconScg,
  receiptIcon: ReceiptIconScg,
  idCardIcon: IconIdCardScg,
  iconUser: IconUserScg,
  iconUserGroup: IconUserGroupScg,
  iconTrip: IconTripScg,
  iconEnroll: IconEnrollScg,
  iconCoinBlue: IconCoinBlueScg,
  iconCoinRed: IconCoinRedScg,
  iconCoinBlack: IconCoinBlackScg,
  iconEdit: IconEditScg,
  iconDelete: IconDeleteScg,
  iconAdd: IconAddScg,
  iconEnrollBag: IconEnrollBagScg,
  iconChange: IconChangeScg,
  iconCoinsGold: IconCoinsGoldScg,
  iconCoinRedWithBg: IconCoinRedWithBgScg,
  iconCoinPrimaryWithBg: IconCoinPrimaryWithBgScg,
  fitnessImage: FitnessImageScg,
  iconAll: IconAllScg,
  iconUserWhite: IconUserWhiteScg,
  iconUserGroupWhite: IconUserGroupWhiteScg,
  iconTripWhite: IconTripWhiteScg,
  iconEnrollWhite: IconEnrollWhiteScg,
  iconChangeWhite: IconChangeWhiteScg,
  iconCoinWhite: IconCoinWhiteScg,
  IconMenuClaim,
  IconMenuPackage,
  IconMenuShop,
  iconPoints: IconPointsScg,
  IconDentsuCoin: IconDentsuCoin,
  iconNext: IconNextScg,
  iconComment: IconCommentScg,
  iconRadio: IconRadioScg,
  iconRadioMarked: IconRadioMarkedScg,
  iconBenefit: IconBenefitScg,
  iconIPD: IconIPDScg,
  iconOPD: IconOPDScg,
  iconDentalInsurance: IconDentalInsuranceScg,
  IconLocation: IconLocationScg,
  IconMail: IconMailScg,
  IconPhoneNumber: IconPhoneNumberScg,
  IconNotification: IconNotificationScg,
  IconSync: IconSyncScg,
  IconBack,
}
const currentIcon = isScg === true ? ImageNameScg : ImageNameBelive

export type ImageType = typeof currentIcon
export type ImageNameKeys = keyof ImageType

type ImageProps<T extends ImageNameKeys | undefined> =
  | { src: string; fallBack?: string; imageName?: T }
  | { src?: string; fallBack?: string; imageName: T }

const StyleImage = styled.img`
  object-fit: cover;
`

const Image = <T extends ImageNameKeys>(
  props: ImageProps<T> &
    Omit<
      React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
      'src'
    >,
) => {
  const { fallBack, imageName, src, ...rest } = props

  const onError = useCallback((e) => {
    e.target.src = dentsu_s
  }, [])
  const ImageNameCard = useMemo(() => {
    return imageName ? currentIcon[imageName] : src
  }, [imageName, src])

  return <StyleImage onError={onError} src={ImageNameCard} {...rest} />
}

export default Image
