import styled from '@emotion/styled'
import { Collapse } from 'antd'
import { Link } from 'react-router-dom'
import IconDown from '../../assets/images/base/icons-bar-next-normal.svg'
import WellBeingCardScg from '../../components/WellBeingCardScg'
import Button from '../../components/common/Button'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import config from '../../config'
import { eClaimDetail } from '../../constant/path'
import { useGetEClaimList } from '../../services/e-claim/e-claim-query'
import { mobile, mobileVerySmall, tablet, useScreen } from '../../utils/responsive-helper'
import CollapseWellbeing from './CollapeseWellbeing'
import { useState } from 'react'
import { isArray } from 'lodash'

const WellBeingContainer = styled.div<{ isMobile: boolean }>`
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  margin-bottom: 15px;

  ${mobile} {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
  ${mobileVerySmall} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`

const BackButton = styled(Button)`
  width: 267px;
  margin-top:18px;
  margin-bottom:18px;
`
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  border: 0.5px solid #DDDDDD;
  border-radius: 8px;
  padding: 8px 16px;
  margin-top: 24px;
  width: 100%;
  user-select: none;
  :hover {
    cursor: pointer;
  }

  ${tablet} {
    width: 100%;
  }
`

const CustomImage = styled(Image)`
  width: 35x;
  height: 35px;
`
const Icon = styled.img`
  margin-left: 5px;
  width: 18px;
`

const EClaimListComponent = () => {
  const { Panel } = Collapse
  const { isMobile } = useScreen()
  const { data: eclaimList } = useGetEClaimList()
  const [rotate, setRotate] = useState<string | string[]>([])



  return (
    <>
      <CollapseWellbeing
        onChange={(e) => setRotate(e)}
        style={{
          margin: isMobile ? '0px 16px 0px' : '0px 12px 0px',
        }}
      >
        {
          eclaimList?.map((list, index) => {
            const { blcpClaimTypeWrapperList } = list

            return (
              <>
                {
                  blcpClaimTypeWrapperList?.map((claimList, index) => {
                    const { claimName, blcpClaimWrapperList, claimTypeIcon } = claimList

                    return (
                      <Panel
                        key={index}
                        header={
                          <Layout>
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              rowGap: 8,
                            }}>
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                columnGap: 8,
                              }}>
                                {
                                  claimTypeIcon && <CustomImage src={`${config.apiHost}/files${claimTypeIcon}`} />
                                }
                                <Text size={isMobile ? 18 : 24} type="Bold" style={{ textAlign: isMobile ? 'left' : 'center', marginTop: 8 }}>
                                  {claimName}
                                </Text>
                              </div>
                              <Icon style={{
                                float: 'right',
                                transitionDuration: "0.8s",
                                transitionProperty: "transform",
                                transform: isArray(rotate) && rotate.some(val => val === index.toString()) ? "rotate(-180deg)" : "rotate(00deg)"
                              }} src={IconDown} />
                            </div>
                          </Layout >
                        }
                      >
                        <div style={{ backgroundColor: '#ECECEC', padding: 16, borderRadius: '8px' }}>
                          <WellBeingContainer isMobile={isMobile}>
                            {
                              blcpClaimWrapperList?.map((data: any) => {
                                return <Link
                                  to={eClaimDetail({
                                    routeParam: {
                                      eClaimId: data.claimId
                                    }
                                  })}
                                >
                                  <WellBeingCardScg
                                    title={data.claimName}
                                    icon={data.claimIcon}
                                  // description={data.claimDetail}
                                  />

                                </Link>
                              }
                              )
                            }
                          </WellBeingContainer>
                        </div>
                      </Panel>
                    )
                  })
                }
              </>
            )
          })
        }
      </CollapseWellbeing >
    </>

  )
}

export default EClaimListComponent
