import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import FooterContent from '../../../components/FooterContent'
import { PointCardBenefit } from '../../../components/PointCard'
import Breadcrumb from '../../../components/common/Breadcrumb'
import Button from '../../../components/common/Button'
import Card from '../../../components/common/Card'
import Text from '../../../components/common/Text'
import color from '../../../constant/colors'
import * as paths from '../../../constant/path'
import { useGetPeriodDate } from '../../../services/enrollment/enrollment-query'
import { useGetUser } from '../../../services/user/user-query'
import { isScg, useCanEditable, useProbation } from '../../../utils/helper'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import Welfare from './Welfare'
import { useGetGroupNumber } from '../../../services/e-claim/e-claim-query'

const ButtonStyle = styled(Button)`
  width: 267px;
`

const Header = styled.div`
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
`

const TextFormat = styled.div`
  text-align: start;
  margin-bottom: 14px;

  ${mobile} {
    margin: 16px;
  }
`

const CustomStyleCard = styled(Card)`
  display: flex;
  /* position: relative; */
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`

const LayoutBodyWelware = styled.div`
  text-align: start;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
`
const BreadcrumbContainer = styled.div`
  ${mobile} {
    margin: 24px 16px;
  }
`
const ConditionLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const ConditionTitle = styled(Text)`
  text-decoration: underline;
`
const ConditionListLayout = styled.ul`
  text-align: left;
`
const ConditionListItem = styled.li`
  list-style-type: disc;
`

const HealthCheckupDetail = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const screen = useScreen()
  const { isMobile } = screen
  const history = useHistory()

  const { data: user } = useGetUser()
  const { data: groupNumber } = useGetGroupNumber()
  const { data: periodDate, isLoading: isLoadPeriodDate, isError: isErrorPeriod } = useGetPeriodDate()

  const {
    startDate: periodStartEnrollmentDate = 0,
    endDate: periodEndEnrollmentDate = 0,
    startPeriodDate: periodStartPeriodDate = 0,
    endPeriodDate: periodEndPeriodDate = 0,
    benefitYearId: periodBenefitYearId = 0,
    benefitYear: periodBenefitYear = 0,
  } = periodDate || {}

  // // TODO:เช็คว่า อยู่ในช่วงการเลือกประกันหรือไม่
  // const canEditable = useCanEditable(periodStartEnrollmentDate, periodEndEnrollmentDate)
  // TODO:เช็คว่า user ผ่านโปรหลังหรือยัง
  const isProbation = useProbation()
  // TODO:เช็คว่า user มีแผนประกันหรือยัง
  const isGroup = !groupNumber?.healthCheckupGroup
  // // TODO:เช็คว่า user ผ่านโปรหลัง ช่วง benefit year หรือไม่
  // const isProbationStartPeriod = useMemo(() => user?.probationDate >= periodStartPeriodDate, [periodStartPeriodDate, user?.probationDate])

  const startDate = useMemo(() => {
    if (dayjs(periodStartEnrollmentDate).isSame(periodEndEnrollmentDate, 'year') && dayjs(periodStartEnrollmentDate).isSame(periodEndEnrollmentDate, 'month')) {
      return dayjs(periodStartEnrollmentDate).locale(language).format('D')
    } else if (dayjs(periodStartEnrollmentDate).isSame(periodEndEnrollmentDate, 'year')) {
      return dayjs(periodStartEnrollmentDate).locale(language).format('D MMMM')
    } else {
      return dayjs(periodStartEnrollmentDate).locale(language).format('D MMMM BBBB')
    }
  }, [periodStartEnrollmentDate, periodEndEnrollmentDate, language])

  const endDate = dayjs(periodEndEnrollmentDate).locale(language).format('D MMMM BBBB')

  const getBenefits = useCallback(() => {
    history.push(
      paths.healthCheckupBenefits({
        routeParam: { benefitYearId: periodBenefitYearId },
      }),
    )
  }, [periodBenefitYearId, history])

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb
          overide={(paths) => {
            paths.unshift({ label: 'Home', path: '/' })
            paths[1].label = t('app.breadcrumb.myWelFare')
            return paths
          }}
        />
      </BreadcrumbContainer>
      <Header>
        <Text size={28} type="Bold" color={color.PRIMARY}>
          {t(`healthCheckup.titleHealthCheckup`)}
        </Text>
        {isErrorPeriod ?
          <Text size={28} type="Bold" color={color.PRIMARY}>
            16 - 29 กุมภาพันธ์ 2567
          </Text> :
          <Text size={28} type="Bold" color={color.PRIMARY}>
            {startDate} - {endDate}
          </Text>
        }
      </Header>
      <ConditionLayout>
        <ConditionTitle>{t('healthCheckup.condition.header')}</ConditionTitle>
        <ConditionListLayout>
          <ConditionListItem>
            <Text>
              {t('healthCheckup.condition.list1')}
            </Text>
          </ConditionListItem>
          <ConditionListItem>
            <Text>
              {t('healthCheckup.condition.list2')}
            </Text>
          </ConditionListItem>
          <ConditionListItem>
            <Text>
              {t('healthCheckup.condition.list3')}
            </Text>
          </ConditionListItem>
          <ConditionListItem>
            <Text>
              {t('healthCheckup.condition.list4')}
            </Text>
          </ConditionListItem>
          <ConditionListItem>
            <Text>
              {t('healthCheckup.condition.list5')}
            </Text>
          </ConditionListItem>
          <ConditionListItem>
            <Text>
              {t('healthCheckup.condition.list6')}
            </Text>
          </ConditionListItem>
          <ConditionListItem>
            <Text>
              {t('healthCheckup.condition.list7')}
            </Text>
          </ConditionListItem>
        </ConditionListLayout>
      </ConditionLayout>
      <TextFormat style={{ display: 'flex', alignItems: "center", columnGap: 8 }}>
        <Text size={26} type="Bold">
          {t(`healthCheckup.coinOFYear`)}
        </Text>
        {isErrorPeriod ?
          <Text size={26} type="Bold">
            {t(`enrollment.changeAnnualPlan`)}
          </Text> :
          <Text size={26} type="Bold">
            {t(`enrollment.changeAnnualPlanYear`) + " " + dayjs(periodStartPeriodDate).locale(language).format('BBBB')}
          </Text>}
      </TextFormat>
      {periodBenefitYearId && <PointCardBenefit benefitYearId={periodBenefitYearId} startPeriodDate={periodStartPeriodDate} endPeriodDate={periodEndPeriodDate} />}
      {isScg === false && (
        <Text size={20} style={{ textAlign: 'start' }}>
          คุณสามารถใช้เหรียญมากกว่าเหรียญตั้งต้นได้ โดยบริษัทจะหักเหรียญส่วนต่างจากเงินเดือน
          ปกติวันลาจะถูก Auto Convert เป็น Flow Point
        </Text>
      )}
      <TextFormat>
        <Text size={26} type="Bold" style={{ textAlign: 'start' }}>
          {t(`healthCheckup.insuranceList`)}
        </Text>
      </TextFormat>
      <CustomStyleCard>
        <LayoutBodyWelware>
          <Welfare iconPath={'iconUser'} title={t('healthCheckup.myHealthCheckup')} descriptions={[]}></Welfare>
          {/* <Welfare iconPath={'iconUser'} title={'ประกันของครอบครัว'} descriptions={[]}></Welfare> */}
          {isScg === false && (
            <div>
              <Welfare
                iconPath={'iconUserGroup'}
                title={'ประกันเพิ่มเติม ให้ครอบครัว'}
                descriptions={[
                  'สำหรับ คู่สมรส บุตร บิดา มารดา เท่านั้น',
                  'ต้องส่งเอกสารแสดงความสัมพันธ์ให้กับ HR ตรวจสอบด้วย',
                ]}
              ></Welfare>
              <Welfare
                iconPath={'iconChange'}
                title={'สวัสดิการอื่นๆ'}
                descriptions={[
                  // 'แลกเปลี่ยนวันลา',
                  'สวัสดิการเพื่อไลฟ์สไตล์',
                ]}
              ></Welfare>
            </div>
          )}
        </LayoutBodyWelware>
      </CustomStyleCard>
      <FooterContent
        style={{
          height: 80,
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-end',
          alignItems: 'center',
        }}
      >
        <ButtonStyle disabled={isGroup || isProbation} onClick={getBenefits}>{t('healthCheckup.nextButton')}</ButtonStyle>
      </FooterContent>
    </>
  )
}

export default HealthCheckupDetail
