import axios, { AxiosError, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import humps from 'humps'
import qs from 'query-string'

import https from 'https'
import { getToken } from '../helper'
import { ContentType } from './tools'
import config from '../../config'

const createClient = () => {
  const ax = axios.create({
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  })
  ax.interceptors.request.use((request: any) => {
    request.url = `${config.apiBuzzeebeesHost}${request.url}`
    // const token = getToken()
    // if (token) request.headers['X-Auth-Token'] = getToken()
    if (request.headers['Content-Type'] === ContentType.FORMDATA) {
      if (request.data) {
        const formData = new FormData()
        Object.entries(request.data).forEach(([key, value]: any[]) => {
          formData.append(key, value)
        })
        request.data = formData
      }
    } else if (request.headers['Content-Type'] === ContentType.XFORM) {
      request.params = modifyRequestData(request.params)
      if (request.data) {
        request.data = modifyRequestData(request.data)
        request.data = qs.stringify(request.data)
      }
    } else if (request.headers['Content-Type'] === ContentType.JSON) {
      request.params = modifyRequestData(request.params)
      if (request.data) {
        request.data = modifyRequestData(request.data)
      }
    }

    return request
  })
  ax.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      let data = response.data
      if (response.headers['content-type']) {
        if (response.headers['content-type'].includes('application/json')) {
          data = humps.camelizeKeys(data)
          data = modifyResponseData(data)
        }
      }
      return data
    },
    (error: any) => Promise.reject(error),
  )
  return ax
}

const modifyRequestData = (data: any): any => {
  if (dayjs.isDayjs(data)) {
    return data.format()
  }

  if (data instanceof Array) {
    return data.map((d) => modifyRequestData(d))
  }
  // if (data instanceof Object) {
  //   const formatData = {}
  //   Object.keys(data).forEach(key => {
  //     formatData[key] = modifyRequestData(data[key])
  //   })
  //   return formatData
  // }
  return data
}

const modifyResponseData = (data: any): any => {
  // 2019-12-11 or 2019-12-11T10:46:08+00:00
  // if (/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2})?$/.test(data)) {
  if (/^\d{4}-\d{2}-\d{2}/.test(data)) {
    return dayjs(data)
  }
  if (data instanceof Array) {
    return data.map((d) => modifyResponseData(d))
  }
  // if (data instanceof Object) {
  //   const formatData = {}
  //   Object.keys(data).forEach(key => {
  //     formatData[key] = modifyResponseData(data[key])
  //   })
  //   return formatData
  // }
  return data
}

export const buzzebeesClient = createClient()

export const buzzebeesApiWraper = async (method: Promise<AxiosResponse>) => {
  try {
    const res = await method
    return Promise.resolve(res)
  } catch (e) {
    const { response, message } = e as AxiosError
    const { data } = response || {}
    const { message: message2 } = data || {}
    return Promise.reject<{ message: string; error: any }>({
      message: message2 || message || e,
      error: e,
    })
  }
}