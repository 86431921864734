import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import Text from '../../components/common/Text'
import { useTranslation } from 'react-i18next'

const StyleLayouPointLabel = styled.div<{ height: number; paddingLeft: number }>`
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: ${({ height }) => height}px;
  margin-top: 4px;
  text-align: center;
  border-radius: 16px;

  padding-left: ${({ paddingLeft }) => paddingLeft}px;
  padding-right: ${({ paddingLeft }) => paddingLeft}px;
  background-image: linear-gradient(
    180deg,
    rgba(244, 81, 81, 0.7) -15%,
    rgba(237, 37, 38, 1) 172%
  ); ;
`

const StyleScoreLabel = styled(Text) <{ marginTop: number; paddingLeft: number }>`
  padding-top: ${({ marginTop }) => marginTop}px;
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
`

const StylePointLabel = styled(Text) <{ paddingTop: number }>`
  padding-top: ${({ paddingTop }) => paddingTop}px;
`

type PointLabelProps = {
  label?: string | number
  size?: 'default' | 'small'
  style?: CSSProperties
  className?: string
}

const configDefault = {
  fontSize: 20,
  paddingLeft: 24,
  paddingTop: 1.5,
  height: 32,
  textScore: { marginTop: 5, paddingLeft: 5 },
}

const configSmall = {
  fontSize: 14,
  paddingLeft: 16,
  paddingTop: 1,
  height: 23,
  textScore: { marginTop: 5, paddingLeft: 3 },
}

const PointLabel = (props: PointLabelProps) => {
  const { t } = useTranslation()
  const { label = '', size = 'default', style, ...restProps } = props
  const config = size === 'default' ? configDefault : configSmall

  return (
    <StyleLayouPointLabel
      style={style}
      height={config.height}
      paddingLeft={config.paddingLeft}
      {...restProps}
    >
      <StylePointLabel
        size={config.fontSize}
        type={'Bold'}
        color={color.WHITE}
        paddingTop={config.paddingTop}
      >
        {label.toLocaleString(undefined, { maximumFractionDigits: 2 })}
      </StylePointLabel>
      <StyleScoreLabel
        size={config.fontSize - 4}
        type={'Text'}
        color={color.WHITE}
        marginTop={config.textScore.marginTop}
        paddingLeft={config.textScore.paddingLeft}
      >
        {t('app.coin')}
      </StyleScoreLabel>
    </StyleLayouPointLabel>
  )
}

export default PointLabel
