import styled from '@emotion/styled'
import color from '../../constant/colors'
import { mobile } from '../../utils/responsive-helper'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import dentsuCoin from '../../assets/images/base/d-coin.png'
import Card from './Card'
import Text from './Text'

const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  padding :16px;
`

const LayoutContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: left;
  margin-top: 11px;
  
  ${mobile} {
    justify-content: center;
    text-align: center;
    padding: 0;
  }
`

const LayoutBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 0 0 24px;

  ${mobile} {
    justify-content: center;
    text-align: center;
    padding: 0;
  }
`

const Logo = styled.img`
  height: 60px;
  width: 60px;

  ${mobile} {
    width: 60px;
    height: auto;
    margin-top: 4px;
    align-self: flex-start;
  }
`

const LayoutBodyBottom = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  
  ${mobile} {
    padding-left: 0;
    padding-right: 8px;
    justify-content: center;
    align-items: flex-end;
    // max-width: 150px;
  }
`

const LayoutDate = styled.div`
  padding-top:  40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;

  ${mobile} {
    margin-top: 5px;   
    margin-right: 0px; 
    flex: 1;
    text-align: right;
  }
`

const RowTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const RowBottom = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const FullBudgetLayout = styled.div`
  padding-bottom: 16px;

  ${mobile} { 
    flex: 1;
  }
`

const RowPoint = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  ${mobile} { 
    justify-content: end;
  }
`

type NotificationCardProps = {
  endPeriodDate: number | undefined
  remain: string | number
}

const MainPanel = (props: NotificationCardProps) => {
  const { endPeriodDate, remain } = props
  const { t, i18n } = useTranslation()

  return (
    <>
      <CustomStyleCard>
        <RowTop>
          <LayoutContent>
            <LayoutBody>
              <Text maxLine={1} size={20} type="Bold">
                {`${t('app.coinsAvailable')}  (${dayjs(endPeriodDate).locale(i18n.language).format(i18n.language === 'th' ? 'BBBB' : 'YYYY')})`}
              </Text>
            </LayoutBody>
          </LayoutContent>
        </RowTop>

        <RowBottom>
          <LayoutContent>
            <LayoutBodyBottom>
              <FullBudgetLayout>
                <Text size={10}>
                  {`${t('app.expiredOn')} ${dayjs(endPeriodDate).locale(i18n.language).format(i18n.language === 'th' ? 'DD MMM BBBB' : 'DD MMM YYYY')}`}
                </Text>
              </FullBudgetLayout>
              <Logo src={dentsuCoin} />
            </LayoutBodyBottom>
            <LayoutDate>
              <RowPoint>
                <Text size={24} color='#7AB452' type="Bold">{remain}</Text>
                <Text size={12}>เหรียญ</Text>
              </RowPoint>

              <Text size={10} type="Medium" color={color.PRIMARY}>
                (1 เหรียญ = 1 บาท)
              </Text>
            </LayoutDate>
          </LayoutContent>
        </RowBottom>
      </CustomStyleCard>
    </>
  )
}

export default MainPanel
