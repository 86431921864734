import { api } from '../../utils/api'
import {
  GetPlayProps,
  PlayType,
  PlayWithPaging,
  GetPlayDetailProps,
  JoinProps,
  PlayResponse,
  UploadImageProps,
  GetPlayImageDetailProps,
  ImageType
} from './play-types'
import {
  useQuery,
  useMutation,
  useInfiniteQuery
} from 'react-query'
import { queryCache } from '../../index'
import { ContentType } from '../../utils/api/tools'
import { GET_USER_COIN_URL } from '../user/user-query'
import { COIN_TRANSACTION_URL } from '../point-history/point-history-query'
export const BASE_PLAY_URL = '/api/play_event'

const PLAY_LIST_URL = `${BASE_PLAY_URL}/list_explore`
const MY_PLAY_LIST_URL = `${BASE_PLAY_URL}/list_my_event`
const GET_PLAY_DETAIL_URL = `${BASE_PLAY_URL}/get_event`
const GET_IMAGE_URL = `${BASE_PLAY_URL}/show_user_join_event`
const JOIN_PLAY_EVENT_URL = `${BASE_PLAY_URL}/user_join_event`
const UPLOAD_PLAY_URL = `${BASE_PLAY_URL}/upload_join_image`


export const useGetPlay = (params: GetPlayProps) => {
  const { size } = params
  return useInfiniteQuery<PlayWithPaging>(
    [PLAY_LIST_URL],
    async ({ pageParam = 0 }) => {
      const response = await api.belive.get<Array<PlayType>>(PLAY_LIST_URL, {
        page: pageParam,
        size: 100
      })
      const responseWithPaging: PlayWithPaging = {
        data: response,
        paging: {
          page: pageParam,
          nextPage: pageParam + 1,
        },
      }
      return responseWithPaging
    },
    {
      getNextPageParam: (lastGroup: PlayWithPaging) => {
        const { nextPage } = lastGroup.paging
        return lastGroup.data.length < size ? null : nextPage
      },
    },
  )
}

export const useGetMyPlay = (params: GetPlayProps) => {
  const { size } = params
  return useInfiniteQuery<PlayWithPaging>(
    [MY_PLAY_LIST_URL],
    async ({ pageParam = 0 }) => {
      const response = await api.belive.get<Array<PlayType>>(MY_PLAY_LIST_URL, {
        page: pageParam,
        size: 100
      })
      const responseWithPaging: PlayWithPaging = {
        data: response,
        paging: {
          page: pageParam,
          nextPage: pageParam + 1,
        },
      }
      return responseWithPaging
    },
    {
      getNextPageParam: (lastGroup: PlayWithPaging) => {
        const { nextPage } = lastGroup.paging
        return lastGroup.data.length < size ? null : nextPage
      },
    },
  )
}

export const useGetPlayDetail = (props: GetPlayDetailProps) => {
  const { id } = props
  return useQuery(
    [GET_PLAY_DETAIL_URL, id],
    async () => {
      const response = await api.belive.get<PlayType>(GET_PLAY_DETAIL_URL, {
        id,
      })
      return Promise.resolve(response)
    },
  )
}

export const useJoin = (option?: any) => {
  const mutate = useMutation(
    async (data: JoinProps) => {
      return api.belive.post<PlayResponse>(JOIN_PLAY_EVENT_URL, data)
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(GET_PLAY_DETAIL_URL)) return true
            else return false
          },
        })

        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(PLAY_LIST_URL)) return true
            else return false
          },
        })

        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(MY_PLAY_LIST_URL)) return true
            else return false
          },
        })

        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(GET_IMAGE_URL)) return true
            else return false
          },
        })
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
      ...option,
    },
  )
  return mutate
}

export const useGetImageEvent = (props: GetPlayImageDetailProps) => {
  const { eventId } = props
  return useQuery(
    [GET_IMAGE_URL, eventId],
    async () => {
      const response = await api.belive.get<Array<ImageType>>(GET_IMAGE_URL, {
        eventId,
      })
      return Promise.resolve(response.reverse())
    },
  )
}

export const useUploadImage = (option?: any) => {
  const mutate = useMutation(
    async (data: UploadImageProps) => {
      return api.belive.post<PlayResponse>(UPLOAD_PLAY_URL, data,
        { contentType: ContentType.FORMDATA })
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(GET_PLAY_DETAIL_URL)) return true
            else return false
          },
        })

        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(PLAY_LIST_URL)) return true
            else return false
          },
        })

        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(MY_PLAY_LIST_URL)) return true
            else return false
          },
        })

        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(GET_IMAGE_URL)) return true
            else return false
          },
        })
        queryCache.invalidateQueries(GET_USER_COIN_URL)
        queryCache.invalidateQueries(COIN_TRANSACTION_URL)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
      ...option,
    },
  )
  return mutate
}