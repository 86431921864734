import styled from '@emotion/styled'
import ShopCard from '../../components/ShopCard'
import image from '../../assets/images/base/test-avatar.png'
import { mobile } from '../../utils/responsive-helper'

const Layout = styled.div`
  display: flex;
  flex-direction: row;

  ${mobile} {
    .shop-card {
      width: 100%;
      height: 421px;
    }

    .shop-card-img {
      width: 100%;
      height: 243px;
    }
  }
`

const ExampleShopCard = () => {
  const title = 'แลกรับส่วนลดเพิ่ม  40% เมื่อใช้คะแนนแลก'
  return (
    <Layout>
      <ShopCard image={image} title={title} point={2000000} remain={1000} path="" />
    </Layout>
  )
}
export default ExampleShopCard
