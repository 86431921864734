import styled from '@emotion/styled'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import Text from '../../components/common/Text'
import BaseButton from '../../components/common/Button'
import { Modal } from '../../components/common/Modal'
import color from '../../constant/colors'
import { mobile, useScreen } from '../../utils/responsive-helper'
import { useHistory } from 'react-router'
import * as paths from '../../constant/path'
import { useVisibility } from '../../utils/custom-hook'
import CouponInformation from '../../components/CouponInformation'

const ContainerStyle = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 16px;
`

const ScrollStyle = styled.div`
  display: flex;
  overflow-x: scroll;
`

const SliderStyle = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: 318px;
  height: 120px;
  margin-right: 10px;
  background: linear-gradient(270deg, #ffb292 0%, ${color.ORANGE} 100%);
  border-radius: 4px;

  ${mobile} {
    width: 212px;
    height: 86px;
  }
`

const DotLeftStyle = styled.div`
  position: relative;
  top: 52px;
  left: -6px;
  width: 16px;
  height: 16px;
  background: ${color.BACKGROUND};
  border-radius: 50%;
  display: inline-flex;

  ${mobile} {
    top: 35px;
    left: -6px;
  }
`

const DotRightStyle = styled.div`
  position: relative;
  top: 52px;
  left: 296px;
  width: 16px;
  height: 16px;
  background: ${color.BACKGROUND};
  border-radius: 50%;
  display: inline-flex;

  ${mobile} {
    top: 35px;
    left: 190px;
  }
`

const ContentStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-right: 16px;
  flex-direction: column;
  justify-content: center;
`

const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderTextStyle = styled(Text)`
  font-size: 20px;

  ${mobile} {
    font-size: 16px;
  }
`

const BodyStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

const DetailStyle = styled.div``

const DetailTextStyle = styled(Text)`
  font-size: 16px;

  ${mobile} {
    font-size: 12px;
  }
`

const ButtonStyle = styled(BaseButton)`
  width: 36px;
  height: 24px;
  line-height: 24px;
  border: none;
  border-radius: 36px;
  margin-left: 4px;
  padding: 0px;
`

type CouponCardSliderProps = {
  path?: string
}

const CouponCardSlider = ({ path }: CouponCardSliderProps) => {
  const { isMobile } = useScreen()
  const [modalVisiblelity, modal] = useVisibility()
  const history = useHistory()

  const handleMoreInformation = () => {
    isMobile
      ? history.push(
          paths.couponDetail({
            routeParam: {
              couponId: 1,
            },
            queryParam: {
              page: path || paths.promotion(),
            },
          }),
        )
      : modal.show()
  }

  const handleGetCoupon = () => {
    //TODO: Need to update navigation
  }

  return (
    <ContainerStyle>
      <ScrollStyle>
        <SliderStyle>
          <DotLeftStyle />
          <DotRightStyle />
          <ContentStyle>
            <HeaderStyle>
              <HeaderTextStyle color={color.WHITE} type="Bold">
                Free Delivery
              </HeaderTextStyle>
              <Text color={color.WHITE} style={{ paddingTop: 4 }} onClick={handleMoreInformation}>
                <AiOutlineInfoCircle />
              </Text>
            </HeaderStyle>
            <BodyStyle>
              <DetailStyle>
                <DetailTextStyle color={color.WHITE}>Min. spend ฿1000</DetailTextStyle>
                <DetailTextStyle color={color.WHITE}>Valid until 30/06/2021</DetailTextStyle>
              </DetailStyle>
              <ButtonStyle
                background={color.WHITE}
                fontSize={12}
                fontColor={color.DARK_GRAY}
                onClick={() => handleGetCoupon()}
              >
                GET
              </ButtonStyle>
            </BodyStyle>
          </ContentStyle>
        </SliderStyle>
        <SliderStyle>
          <DotLeftStyle />
          <DotRightStyle />
        </SliderStyle>
        <SliderStyle>
          <DotLeftStyle />
          <DotRightStyle />
        </SliderStyle>
      </ScrollStyle>
      <Modal
        visible={modalVisiblelity}
        headerComponent={
          <Text type="SemiBold" size={24}>
            เงื่อนไขการใช้คูปอง
          </Text>
        }
        closeIconSize={24}
        onOk={() => {
          modal.hide()
        }}
        onCancel={modal.hide}
        hideFooter
      >
        <CouponInformation />
      </Modal>
    </ContainerStyle>
  )
}

export default CouponCardSlider
