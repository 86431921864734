import Text from '../../components/common/Text'

const ExampleText = () => {
  return (
    <div>
      <Text size={56} type="Bold">
        Bold size=56 ตัวอย่างตัวอักษรภาษาไทย
      </Text>
      <Text size={32} type="SemiBold">
        SemiBold size=32 ตัวอย่างตัวอักษรภาษาไทย
      </Text>
      <Text size={30} type="Medium">
        Medium size=30 ตัวอย่างตัวอักษรภาษาไทย
      </Text>
      <Text size={24} type="Text">
        Text size=24 ตัวอย่างตัวอักษรภาษาไทย
      </Text>
      <Text>Regular defaultProps ตัวอย่างตัวอักษรภาษาไทย</Text>
    </div>
  )
}

export default ExampleText
