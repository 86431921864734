import { useMutation, useQuery } from 'react-query'
import { api } from '../../utils/api'
import { clearToken } from '../../utils/helper'
import {
  EditUserFormValues,
  UserType,
  AddressListResponse,
  EditUserAddressFormValues,
  UploadFile,
  CoinBenefitYearResponse,
} from './user-typed'
import { queryCache } from '../../index'
import { ContentType } from '../../utils/api/tools'

export const EDIT_USER_FORM_NAME = 'EditUserFormName'

export const USER_URL = '/app/user'
export const GET_USER_URL = `${USER_URL}/getUser`
export const EDIT_USER_URL = `${USER_URL}/edit`
export const GET_USER_COIN_URL = `${USER_URL}/getUserCoin`
export const UPLOAD_PROFILE_USER = `${USER_URL}/uploadUserProfile`
export const GET_USER_EXPIRED_COIN_URL = `${USER_URL}/getUserEXPCoin`
export const GET_USER_ADDRESS_LIST = '/manage/userAdress/getUserAdressList'
export const EDIT_USER_ADDRESS_LIST = '/manage/userAdress/editUserAdress'
export const CREATE_USER_ADDRESS_LIST = '/manage/userAdress/createUserAdress'

export const useGetUser = () => {
  return useQuery<UserType | undefined, Error>(
    GET_USER_URL,
    () => {
      return api.belive.get<UserType>(GET_USER_URL)
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        clearToken()
      },
      retry: 0,
    },
  )
}

export const useGetUserCoin = () => {
  return useQuery(GET_USER_COIN_URL, async () => {
    return await api.belive.get<number>(GET_USER_COIN_URL)
  })
}

export const useGetUserCoinBenefitYear = (benefitYearId: number) => {
  return useQuery([GET_USER_COIN_URL, { benefitYearId }], async () => {
    return await api.belive.get<CoinBenefitYearResponse>(`${GET_USER_COIN_URL}/${benefitYearId}`)
  })
}

export const useGetUserExpiredCoin = () => {
  return useQuery(GET_USER_EXPIRED_COIN_URL, async () => {
    return await api.belive.get<string>(GET_USER_EXPIRED_COIN_URL)
  })
}

export const setUser = (user: UserType) => {
  queryCache.setQueryData(GET_USER_URL, user)
}

export const logout = () => {
  queryCache.setQueryData(GET_USER_URL, undefined)
  clearToken()
  queryCache.removeQueries()
}

export const useGetListUserAdress = () => {
  return useQuery(GET_USER_ADDRESS_LIST, async () => {
    return await api.belive.get<AddressListResponse>(GET_USER_ADDRESS_LIST)
  })
}

export const useEditUser = (userId?: number) => {
  return useMutation(
    [EDIT_USER_URL, { userId }],
    async (form: EditUserFormValues) => {
      const params = {
        id: userId,
        address: form.address,
        phoneNo: form.phoneNumber,
      }
      return (
        userId !== undefined &&
        (await api.belive.post(EDIT_USER_URL, params, {
          contentType: ContentType.JSON,
        }))
      )
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(GET_USER_URL)) return true
            else return false
          },
        })
      },
    },
  )
}

export const useEditUserPhone = (userId?: number) => {
  return useMutation(
    [EDIT_USER_URL, { userId }],
    async (form: any) => {
      const params = {
        id: userId || form.id,
        phoneNo: form.phoneNumber,
        phoneNumber: form.phoneNumber,
      }
      return (
        userId !== undefined &&
        (await api.belive.post(EDIT_USER_URL, params, {
          contentType: ContentType.JSON,
        }))
      )
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(GET_USER_URL)) return true
            else return false
          },
        })
      },
    },
  )
}

export const useEditAddressUser = () => {
  return useMutation(
    [EDIT_USER_ADDRESS_LIST],
    async (form: EditUserAddressFormValues) => {
      return await api.belive.post(EDIT_USER_ADDRESS_LIST, form, {
        contentType: ContentType.JSON,
      })
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(GET_USER_ADDRESS_LIST)) return true
            else return false
          },
        })
      },
    },
  )
}

export const useCreateAddressUser = () => {
  return useMutation(
    [CREATE_USER_ADDRESS_LIST],
    async (form: EditUserAddressFormValues) => {
      return await api.belive.post(CREATE_USER_ADDRESS_LIST, form, {
        contentType: ContentType.JSON,
      })
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries({
          predicate: (query) => {
            const { queryHash } = query
            if (queryHash.includes(GET_USER_ADDRESS_LIST)) return true
            else return false
          },
        })
      },
    },
  )
}

export const useUploadUserProfile = () => {
  return useMutation(
    [UPLOAD_PROFILE_USER],
    async (image: File) => {
      return api.belive.post<UploadFile>(
        UPLOAD_PROFILE_USER,
        { image },
        { contentType: ContentType.FORMDATA },
      )
    },
    {
      onSuccess: (data) => {
        console.log('Upload File Success', data)
      },
    },
  )
}
