import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLanguage } from '../../utils/helper'
import { getTranslation } from './language'

const resources = { ...getTranslation('th'), ...getTranslation('en') }
const lang = getLanguage()

i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  // lng: 'en',
})

export default i18n
