import styled from '@emotion/styled'
import Breadcrumb from '../../components/common/Breadcrumb'

import moment from 'moment'
import { useMemo } from 'react'
import Card from '../../components/common/Card'
import Loading from '../../components/common/Loading'
import { eClaim, eClaimDetail, home } from '../../constant/path'
import { useGetEClaimHistoryById } from '../../services/e-claim/e-claim-query'
import { useRouter } from '../../utils/helper'
import { mobile, useScreen } from '../../utils/responsive-helper'
import EClaimForm from '../EClaimDetail/EClaimForm'
import EClaimInfo from '../EClaimDetail/EClaimInfo'

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  z-index: 1;
  ${mobile} {
    margin: 24px 16px;
  }
`
const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  width: 100%;
  min-height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-height: 266px;
  margin: 22px auto 0 auto;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`

const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding :16px;
  box-shadow: none;
`

const ConditionInfoContainer = styled.div`
  padding: 0px 16px;
  z-index: 1;
`

const EClaimHistoryDetail = () => {
  const { query } = useRouter<{ id: number }>()
  const { isMobile } = useScreen()
  const { data: eClaimItem, isLoading } = useGetEClaimHistoryById({ id: query.id })
  const {
    allpayRef,
    allpayReason,
    allpayRemark,
    exchangeCoin,
    claimDate,
    remark,
    citizenFileUrl,
    receiptFile,
    relativeTypeId,
    accept,
    id,
    slipDate,
    amountClaim,
    nameShop,
    slipNo
  } = eClaimItem || {}

  const initialValue = useMemo(() => {
    return {
      id,
      allpayRef,
      allpayReason,
      allpayRemark,
      amount: exchangeCoin,
      remark,
      paymentDate: moment(claimDate),
      citizenFileUrl,
      relativeTypeId,
      receiptFile,
      slipDate,
      amountClaim,
      nameShop,
      slipNo
    }
  }, [id, allpayRef, allpayReason, allpayRemark, exchangeCoin, remark, claimDate, citizenFileUrl, relativeTypeId, receiptFile, slipDate, amountClaim, nameShop, slipNo])
  const disable = useMemo(() => {
    if (!accept && (accept !== null)) {
      return false
    } else {
      return true
    }
  }, [accept])

  return (
    <Loading loading={isLoading}>
      <Layout>
        <BackgroundHeader />
        <FlexRow>
          <BreadcrumbContainer>
            <Breadcrumb
              overide={(paths) => {
                paths[0] = {
                  label: 'Home',
                  path: home(),
                }
                paths[1] = {
                  label: 'D-Claims',
                  path: eClaim(),
                }
                paths[2] = {
                  label: `${eClaimItem?.claimName}`,
                  path: eClaimDetail(),
                }
                return paths
              }}
            />
          </BreadcrumbContainer>

          <EClaimInfo eClaimItem={eClaimItem} />
          <CustomStyleCard>

            {/*   <EClaimDocument /> */}

            <EClaimForm
              eClaimItem={eClaimItem}
              initialValues={initialValue}
              allpayStatus={disable}
              editable={false}
              isHistory
            />
          </CustomStyleCard>

        </FlexRow>
      </Layout>
    </Loading>
  )
}

export default EClaimHistoryDetail
