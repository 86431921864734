import styled from '@emotion/styled'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import dentsuIcon from '../../../assets/images/base/dentsu-loco.png'
import color from '../../../constant/colors'
import * as paths from '../../../constant/path'
import { useGetPeriodDate } from '../../../services/enrollment/enrollment-query'
import { useGetNotificationCount, useReadNotificationAll } from '../../../services/notification/notification-query'
import { useRouter } from '../../../utils/helper'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import HambergerMenu from './HambergerMenu'
import Menu from './Menu'
import Noti from './Noti'
import Profile from './Profile'
import ShopMenu from './ShopMenu'

const Layout = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${color.WHITE};
  height: 80px;
  border-bottom: 1px solid ${color.BORDER};

  ${mobile} {
    height: 90px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1172px;
  padding: 0 16px;
`

const MenuGroup = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const Logo = styled.img`
  height: 79px;
  width: 116px;
`

const AppHeader = () => {
  const { t } = useTranslation()
  const { pathname } = useRouter()
  const { isMobile, isTablet } = useScreen()
  const { data: notiCount = 0, refetch: getNotificationCount } = useGetNotificationCount()
  const { mutate: setReadNotificationAll } = useReadNotificationAll()

  const currentLogo = dentsuIcon

  const isHighlightShop = useMemo(() => {
    const inShopBrands = pathname.includes(paths.shopBrand().split('/')[1])
    const inShopCategory = pathname.includes(paths.shopCategory().split('/')[1])
    const inEnrollment = pathname.includes(paths.enrollment().split('/')[1])
    return inShopBrands || inShopCategory || inEnrollment
  }, [pathname])

  // const isShopSection = useMemo(() => {
  //   return (
  //     pathname.startsWith(paths.coupon()) ||
  //     pathname.startsWith(paths.order()) ||
  //     pathname.startsWith(paths.product()) ||
  //     pathname.startsWith(paths.promotion()) ||
  //     pathname.startsWith(paths.promotionCategory()) ||
  //     pathname.startsWith(paths.shop())
  //   )
  // }, [pathname])

  const isShopSection = false
  const { data: period } = useGetPeriodDate()
  const { benefitYearId = 3, endDate } = period || {}

  useEffect(() => {
    if (pathname === paths.notification()) {
      setReadNotificationAll()
      getNotificationCount()
    }
  }, [pathname, getNotificationCount, setReadNotificationAll])

  const isUseCoin = useMemo(() => {
    const now = new Date().getTime();
    let isUse = false
    if (endDate) isUse = now > endDate

    return isUse
  }, [endDate])

  const menuListMobile = useMemo(() => (
    [
      { title: t('app.menubar.home'), path: paths.home(), isActive: pathname.startsWith(paths.home()) },
      { title: t('app.menubar.news'), path: paths.news(), isActive: pathname.startsWith(paths.news()) },
      { title: t('app.menubar.play'), path: paths.play(), isActive: pathname.startsWith(paths.play()) },
      {
        title: t('app.menubar.wellBeing'),
        path: paths.wellBeing(),
        isActive: pathname.startsWith(paths.wellBeing()),
      },
      {
        title: t('app.menubar.insurance'),
        path: paths.enrollment(),
        isActive: pathname.startsWith(paths.enrollment()),
      },
      {
        title: t('app.menubar.healthCheckup'),
        path: paths.healthCheckup(),
        isActive: pathname.startsWith(paths.healthCheckup()),
      },
      {
        title: t('app.menubar.claims'),
        path: paths.eClaim(),
        isDisabled: !isUseCoin,
        isActive: pathname.startsWith(paths.eClaim()),
      },
      {
        title: t('app.menubar.marketplace'),
        isDisabled: !isUseCoin,
        path: paths.shop(), isActive: pathname.startsWith(paths.shop())
      },
    ]), [isUseCoin, pathname, t]);

  const menuList = useMemo(() => ([
    { title: t('app.menubar.home'), path: paths.home(), isActive: pathname.startsWith(paths.home()) },
    { title: t('app.menubar.news'), path: paths.news(), isActive: pathname.startsWith(paths.news()) },
    { title: t('app.menubar.play'), path: paths.play(), isActive: pathname.startsWith(paths.play()) },
    {
      title: t('app.menubar.wellBeing'),
      path: paths.wellBeing(),
      isActive: pathname.startsWith(paths.wellBeing()),
    },
    {
      title: t('app.menubar.insurance'),
      path: paths.enrollment(),
      isActive: pathname.startsWith(paths.enrollment()),
    },
    {
      title: t('app.menubar.healthCheckup'),
      path: paths.healthCheckup(),
      isActive: pathname.startsWith(paths.healthCheckup()),
    },
    {
      title: t('app.menubar.claims'),
      path: paths.eClaim(),
      isDisabled: !isUseCoin,
      isActive: pathname.startsWith(paths.eClaim()),
    },
    {
      title: t('app.menubar.marketplace'),
      path: paths.shop(),
      isDisabled: !isUseCoin,
      isActive: pathname.startsWith(paths.shop())
    },
  ]), [isUseCoin, pathname, t]);

  const menuListItem = isMobile ? menuListMobile : menuList

  return (
    <>
      {isShopSection ? (
        <ShopMenu />
      ) : (
        <Layout>
          <Header>
            <Link to={paths.home()}>
              <Logo src={currentLogo} />
            </Link>
            <MenuGroup>
              {isTablet ? (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Link to={paths.notification()}>
                    <Noti count={notiCount} />
                  </Link>
                  <HambergerMenu count={notiCount} menuList={menuListItem} />
                </div>
              ) : (
                <>
                  {menuListItem.map((menu, index) => (
                    <Menu key={index} path={menu.path} isActive={menu.isActive} isDisabled={menu.isDisabled}>
                      {menu.title}
                    </Menu>
                  ))}
                  <Link to={paths.notification()}>
                    <Noti count={notiCount} />
                  </Link>
                  <Profile />
                </>

              )}
            </MenuGroup>
          </Header>
        </Layout>
      )}
    </>
  )
}

export default AppHeader
