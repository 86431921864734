import styled from '@emotion/styled'
import { Badge } from 'antd'

import color from '../../../constant/colors'
import NotiIcon from '../../../assets/images/base/icons-notification.svg'

type IconType = {
  count: number
}

export const BadgeStyled = styled(Badge)`
  .ant-badge-dot,
  .ant-badge-count {
    background: ${color.SECONDARY};
    border-color: ${color.SECONDARY};
    box-shadow: 0 0 0 1px ${color.SECONDARY};
  }
`

const Layout = styled.div`
  display: flex;
  justify-content: center;
  width: 60px;
  .ant-badge-count {
    top: 7px;
    right: 7px;
  }

  :hover {
    cursor: pointer;
    > :not(.active) {
      background-color: ${color.BACKGROUND};
    }
  }
`

const Icon = styled.img`
  width: 36px;
`

const Noti = (props: IconType) => {
  const { count, ...rest } = props
  return (
    <Layout {...rest}>
      <BadgeStyled count={count} size="small">
        <Icon src={NotiIcon} />
      </BadgeStyled>
    </Layout>
  )
}

export default Noti
