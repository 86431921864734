import React, { CSSProperties, forwardRef, MouseEventHandler, ReactNode } from 'react'
import styled from '@emotion/styled'
import c from '../../constant/colors'
import Truncate from 'react-truncate'

const StyledText = styled.div<{
  family: string
  size: number
  color: string
  style?: CSSProperties
}>`
  font-family: ${({ family }) => `IBMPlexSansThai${family}`};
  font-size: ${({ size }) => size}px;
  color: ${({ color }) => color};
  word-break: break-word;
`

export interface TextProps {
  onClick?: MouseEventHandler
  className?: string
  children: ReactNode
  size?: number
  type?: 'Bold' | 'SemiBold' | 'Medium' | 'Text' | 'Regular'
  color?: string
  style?: CSSProperties
  ellipsis?: boolean
  maxLine?: number
  width?: number | string
}
const Text = forwardRef((props: TextProps, ref: any) => {
  const {
    children,
    type,
    size = 16,
    color = c.BLACK,
    ellipsis = false,
    maxLine = 1,
    style,
    className,
    width,
    ...restProps
  } = props
  const family = type ? `-${type}` : '-Regular'
  return ellipsis ? (
    <Truncate
      trimWhitespace
      lines={maxLine}
      ellipsis={<span>...</span>}
      style={{ fontFamily: `IBMPlexSansThai${family}`, fontSize: size, color: color, ...style }}
      className={className}
      width={width}
    >
      {children}
    </Truncate>
  ) : (
    <StyledText
      ref={ref}
      family={family}
      size={size}
      color={color}
      style={style}
      className={className}
      {...restProps}
    >
      {children}
    </StyledText>
  )
})

export default Text
