import styled from '@emotion/styled'
import { Image } from 'antd'
import Modal from '../../components/common/Modal'
import { useVisibility } from '../../utils/custom-hook'
import ImageInfographicCoinSuccess from '../../assets/images/base/infographic-coin-success.svg'
import color from '../../constant/colors'
import BaseButton from '../../components/common/Button'
import Text from '../../components/common/Text'
const Button = styled(BaseButton)`
  width: auto;
`
const TextStyle = styled(Text)`
  color: ${color.FONT_LIGHT_GRAY};
  margin-bottom: 48px;
`

const ButtonTestModal = styled(BaseButton)`
  background-color: ${color.PRIMARY};
  max-width: 364px;
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 22px;
`
const ExampleModalLayout = styled.div`
  button {
    color: ${color.BACKGROUND};
    font-weight: bold;
    background-color: #4cbbbb;
    border-color: #4cbbbb;
    height: auto;
    margin: 10px 0;
    padding: 5px 15px;
    :not(:last-child) {
      margin-right: 10px;
    }
  }
`

const ExampleModal = () => {
  const [visibled1, modalAction1] = useVisibility()
  const [visibled2, modalAction2] = useVisibility()
  const [visibled3, modalAction3] = useVisibility()
  const [visibled4, modalAction4] = useVisibility()
  const [visibled5, modalAction5] = useVisibility()
  const [visibled6, modalAction6] = useVisibility()
  const [visibled7, modalAction7] = useVisibility()
  return (
    <ExampleModalLayout>
      <Button onClick={modalAction1.show}>Basic</Button>
      <Modal visible={visibled1} onOk={modalAction1.hide} onCancel={modalAction1.hide}>
        <div>Basic</div>
      </Modal>
      <Button onClick={modalAction2.show}>Hide Close</Button>
      <Modal
        visible={visibled2}
        onOk={modalAction2.hide}
        onCancel={modalAction2.hide}
        hideCloseIcon
      >
        <div>Hide Close</div>
      </Modal>
      <Button onClick={modalAction3.show}>Custom Footer</Button>
      <Modal
        visible={visibled3}
        onCancel={modalAction3.hide}
        hideFooter
        footerComponent={
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Button onClick={modalAction3.hide}>Click Me!!</Button>
            <div>some text...</div>
          </div>
        }
      >
        <div>Custom Footer</div>
      </Modal>
      <Button onClick={modalAction4.show}>Custom Header</Button>
      <Modal
        visible={visibled4}
        onOk={modalAction4.hide}
        onCancel={modalAction4.hide}
        headerComponent={
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <h1>Custom Header</h1>
          </div>
        }
      ></Modal>
      <Button onClick={modalAction5.show}>Hide Footer</Button>
      <Modal visible={visibled5} onOk={modalAction5.hide} onCancel={modalAction5.hide} hideFooter>
        <div>Hide Footer</div>
      </Modal>
      <Button onClick={modalAction6.show} style={{ maxWidth: 100 }}>
        DISABLE CLOSE ON CLICK OUTSIDE
      </Button>
      <Modal
        visible={visibled6}
        onOk={modalAction6.hide}
        onCancel={modalAction6.hide}
        disableCloseOnClickOutside
      >
        <div>DISABLE CLOSE ON CLICK OUTSIDE</div>
      </Modal>

      <Button onClick={modalAction7.show}>Test Modal</Button>
      <Modal
        visible={visibled7}
        onOk={modalAction7.hide}
        onCancel={modalAction7.hide}
        style={{
          width: 756,
        }}
        hideFooter
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            alt={'ImageInfographicCoinSuccess'}
            src={ImageInfographicCoinSuccess}
            style={{ marginTop: 64, marginBottom: 64 }}
          />
          <TextStyle size={30} type="Bold">
            คุณได้รับเหรียญจากข่าวนี้เรียบร้อยแล้ว
          </TextStyle>
          <ButtonTestModal onClick={modalAction7.hide}>ตกลง</ButtonTestModal>
        </div>
      </Modal>
    </ExampleModalLayout>
  )
}

export default ExampleModal
