import { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import color from '../../../constant/colors'
import Text from '../../common/Text'

type MenuType = {
  isActive: boolean
  path: string
  isDisabled?: boolean
}

const Layout = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 2px;
  border-bottom-color: transparent;

  :hover {
    cursor: pointer;
    /* border-bottom-color: ${color.PRIMARY}; */

    .menu-label {
      color: ${color.PRIMARY};
    }
  }

  &.active {
    cursor: default;
    border-bottom-color: ${color.PRIMARY};

    .menu-label {
      color: ${color.PRIMARY};
    }

    :hover {
      cursor: default;
    }
  }
  
  &.disabled  {
    opacity: 0.5;
    cursor: not-allowed;

    a {
      pointer-events: none;
    }
  }
`

const CustomText = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 38px;
  border-radius: 19px;
  color: ${color.FONT_BLACK};
`

const Menu = (props: PropsWithChildren<MenuType>) => {
  const { isActive, children, isDisabled = false, path, ...rest } = props
  return (
    <Layout {...rest} className={`${isActive ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}>
      <Link to={path}>
        <CustomText size={14} className="menu-label" type="Bold">
          {children}
        </CustomText>
      </Link>
    </Layout>
  )
}

export default Menu
