import styled from '@emotion/styled'
import PointLabel from '../../components/common/PointLabel'
const Layout = styled.div``

const ExamplePointLabel = () => {
  return (
    <Layout>
      <PointLabel label={2500} size={'small'} />
      <PointLabel label={2500} />
      <PointLabel label={2500.05} />
      <PointLabel label={2500000.05} />
    </Layout>
  )
}
export default ExamplePointLabel
