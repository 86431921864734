import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import Text from './Text'
import { useTranslation } from 'react-i18next'

const LayoutTagRemain = styled.div`
  min-width: 84px;
  min-height: 19px;
  text-align: center;
  padding: 4px 16px 2px 18px;
  border-radius: 4px;
  background-color: #ffffff;
`

type TagRemainProps = {
  remain: number | string
  style?: CSSProperties
}

const TagRemain = (props: TagRemainProps) => {
  const { t } = useTranslation()
  const { remain, ...restProps } = props

  return (
    <LayoutTagRemain className="tag-remain" {...restProps}>
      <Text size={8.8} type={'Bold'} color={color.PRIMARY}>
        {t('app.remain')}: {remain}
      </Text>
    </LayoutTagRemain>
  )
}

export default TagRemain
