import React from 'react'
import { Button, Radio } from 'antd'
import styled from '@emotion/styled'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import Text from '../../../../components/common/Text'
import color from '../../../../constant/colors'

import Image from '../../../../components/common/Image'
import { isNumber } from 'lodash'
import { useTranslation } from 'react-i18next'
import { isScg, numberWithCommas } from '../../../../utils/helper'
import { mobile, tablet, mobileVerySmall } from '../../../../utils/responsive-helper'
import HtmlWithStyled from '../../../../components/HtmlWithStyled'

const RadioLayout = styled.div`
  width: 100%;
  .second {
    margin-bottom: 16px;
  }
  .first {
    flex-direction: column;
    padding: 8px 16px 24px;
    border-bottom: 1px solid ${color.BACKGROUND_LIGHT};
  }

  ${mobile} {
    margin-left: unset;
    .second {
      margin-bottom: 8px;
    }
  }
  ${mobileVerySmall} {
    .first {
      flex-wrap: wrap;
      margin-bottom: 6px;
    }
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`

const PointLayout = styled(Row)`
  width: unset;
  margin-bottom: 24px;
  > *:not(:last-child) {
    margin-right: 8px;
  }
  .icon-coin {
    margin-right: 6px;
  }

  > *:nth-of-type(2) {
    text-align: end;
    width: 100%;
    ${mobile} {
      width: auto;
    }
  }

  ${mobile} {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  ${mobileVerySmall} {
    align-items: flex-end;
    flex-flow: row wrap;
    justify-content: flex-end;
    width: 100%;

    > *:not(:last-child) {
      margin-right: unset;
    }
    .icon-coin {
      margin-right: 4px;
    }

    > *:nth-of-type(1) {
      justify-content: flex-end;
    }
  }
`

const DescriptionLayout = styled(Row)`
  width: 100%;
  align-items: flex-end;
  > *:not(:last-child) {
    margin-right: 8px;
  }

  .benefit-description {
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
    font-family: IBMPlexSansThai-Medium;
    font-size: 20px;
    font-weight: 300;
    color: #333333;

    * {
      width: inherit;
    }
  }

  ${mobile} {
    flex-flow: column;
  }
`

const StyledGroup = styled(Radio.Group) <{ dataCount: number }>`
  width: 100%;
  .swiper-wrapper {
    display: ${(props) => (props.dataCount === 1 ? 'flex' : 'null')};
    justify-content: ${(props) => (props.dataCount === 1 ? 'center' : 'null')};
    height: calc(100% - 48px);
    padding-bottom: 48px;
  }
  .swiper-pagination-bullet-active {
    background: ${color.PRIMARY};
  }
`
const StyledRadio = styled(Radio.Button)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;

  min-height: 50px;
  margin-bottom: 8px;
  margin-right: unset;
  padding: 0px;

  border-color: ${color.BACKGROUND};
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;

  span {
    width: 100%;
  }

  span.ant-radio + * {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  span.ant-radio-checked {
    span {
      border-color: ${isScg === true ? color.PRIMARY : ''};
    }
  }

  .ant-radio {
    margin-top: 6px;
    .ant-radio-inner {
      width: 21px;
      height: 21px;
      border-color: ${isScg === true ? color.PRIMARY : ''};
      ::after {
        top: 3px;
        left: 3px;
        width: 13px;
        height: 13px;
        border-color: ${isScg === true ? color.PRIMARY : ''};
      }
    }
    .ant-radio-inner::after {
      background-color: ${isScg === true ? color.PRIMARY : ''};
    }
  }
  ${mobile} {
    padding: 16px;
    .ant-radio {
      margin-top: 6px;
    }
  }
`
type ChoicesRadioOption = BaseOptionType & {
  // point?: number
  price?: number
  description?: string
  onSeeDetailClick?: () => void
}
type ChoicesRadioProps<V = BaseOptionType['value']> = {
  value?: V
  options: ChoicesRadioOption[]
  onChange: (value: V) => void
}

const ChoicesRadio = (props: ChoicesRadioProps) => {
  const { value, options, onChange } = props
  const { t } = useTranslation()

  return (
    <StyledGroup value={value ? Number(value) : undefined} dataCount={options.length}>
      <Swiper
        spaceBetween={16}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>'
          },
        }}
        modules={[Pagination]}
      >
        {options.map((option) => (
          <SwiperSlide key={option.value} >
            <StyledRadio
              value={option.value}
              key={`${option.value}-${option.label}`}
              onClick={() => {
                onChange(option.value)
              }}
              style={{
                borderTopColor: value === option.value ? color.PRIMARY : color.LIGHT_GRAY,
                borderBottomColor: value === option.value ? color.PRIMARY : color.LIGHT_GRAY,
                borderRightColor: color.BACKGROUND_LIGHT,
                borderLeftColor: color.BACKGROUND_LIGHT,
                borderRadius: 4,
                borderTopWidth: 8,
                borderBottomWidth: 8,
                borderLeftWidth: 1,
                borderRightWidth: 1,
              }}
            >
              <RadioLayout>
                <Row className="first">
                  <Text
                    size={16}
                    style={{
                      width: '100%',
                      overflowWrap: 'normal',
                      whiteSpace: 'pre-wrap',
                      textAlign: 'center',
                      marginBottom: 8,
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: option.label }} />
                  </Text>
                  <PointLayout>
                    <Text
                      size={30}
                      type="SemiBold"
                      color={color.PRIMARY}
                      style={{ wordBreak: 'unset' }}
                    >
                      {isNumber(option.price) ? numberWithCommas(option.price) : '-'}
                    </Text>
                    <Text size={30} type="SemiBold" color={color.PRIMARY}>
                      {t('app.coin')}
                    </Text>
                  </PointLayout>
                  <Button
                    onClick={() => onChange(option.value)}
                    shape="round"
                    icon={
                      <Image
                        imageName={value === option.value ? 'iconRadioMarked' : 'iconRadio'}
                        width={24}
                        height={24}
                      />
                    }
                    style={{
                      backgroundColor: value === option.value ? color.PRIMARY : color.WHITE,
                      color: value === option.value ? color.WHITE : color.PRIMARY,
                      borderColor: color.PRIMARY,
                      width: 'auto',
                      height: 'auto',
                      textAlign: 'left',
                    }}
                  >
                    {t('healthCheckup.editSelfBenefits.select')}
                  </Button>
                </Row>
                <Row className="second"></Row>
                <DescriptionLayout>
                  {option.description && (
                    <HtmlWithStyled className="benefit-description" html={option.description} />
                  )}
                </DescriptionLayout>
                {value === option.value ? (
                  <Row
                    style={{
                      justifyContent: 'center',
                      padding: 12,
                      columnGap: 16,
                      backgroundColor: color.LIGHT_GRAY,
                      height: 54,
                    }}
                  >
                    <Image imageName="iconBenefit" width={34} />
                    <Text color={color.PRIMARY}>{t('healthCheckup.selfBenefits.healthCheckupSelected')}</Text>
                  </Row>
                ) : (
                  <div
                    style={{
                      height: 54,
                    }}
                  ></div>
                )}
              </RadioLayout>
            </StyledRadio>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledGroup >
  )
}

export default ChoicesRadio
