import { LoadingOutlined } from '@ant-design/icons'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { CSSProperties, ReactNode } from 'react'
import color from '../../constant/colors'

const visible = css`
  transition: opacity 0.3s ease-in-out, top 0.2s ease-in-out;
`
const SpinnerHidden = css`
  transition: opacity 0.25s linear;
`
const LoadingLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 140px;
`
const LoadingIcon = styled(LoadingOutlined)<{ loading: string }>`
  position: absolute;
  opacity: ${({ loading }) => (loading === 'true' ? 1 : 0)};
  color: ${color.BLACK};
  background-color: transparent;
  font-size: 40px;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`

type LoadingProps = {
  loading: boolean
  style?: CSSProperties
  children: ReactNode
}

const Loading = (props: LoadingProps) => {
  const { loading, style, children } = props
  return (
    <>
      {loading ? (
        <LoadingLayout style={style}>
          <LoadingIcon
            className={`loading-spinner ${loading ? visible : SpinnerHidden}`}
            loading={loading.toString()}
          />
        </LoadingLayout>
      ) : (
        children
      )}
    </>
  )
}

export default Loading
