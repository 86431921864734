import { Layout } from 'antd'
import { useRouter } from '../../utils/helper'
import { useScreen } from '../../utils/responsive-helper'
import * as paths from '../../constant/path'
import AppFooter from './AppFooter'
import AppHeader from './AppHeader'
import Content from './Content'
import styled from '@emotion/styled'

const AppLayoutStyled = styled(Layout)`
  min-height: 100vh;
`

const AppLayout = (props: React.PropsWithChildren<{}>) => {
  const { children } = props
  const { isMobile } = useScreen()
  const { pathname } = useRouter()
  const isHiddenFooter =
    pathname.startsWith(`${paths.product()}/`) || pathname.startsWith(`${paths.shopUser()}/`)

  return (
    <AppLayoutStyled>
      <AppHeader />
      <Content id="content-body" children={children} />
      {isMobile ? !isHiddenFooter && <AppFooter /> : <AppFooter />}
    </AppLayoutStyled>
  )
}

export default AppLayout
