import React, { useState, useCallback, useEffect, useMemo } from 'react'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import { useGetRelativeById, useGetRelativeTypeList } from '../../services/relative/relative-query'
import Input, { InputLabel } from '../../components/common/Input'
import Select from '../../components/common/Select'
import styled from '@emotion/styled'
import { mobile } from '../../utils/responsive-helper'
import { log } from 'console'

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  // width: 30%;
  /* border: 1px solid; */
  margin-bottom: 8px;
  padding-bottom: 0;

  ${mobile} {
    width: 100%;
  }
`

type Value = string | number | undefined | ''
type ModalType = 'CREATE' | 'UPDATE' | 'DELETE'
type RelativeDetailType = {
  title: string,
  firstname: string,
  lastname: string,
  relativeTypeId: number
}

type CreateRelativeProps = {
  visible: boolean
  handleCloseModal: (type: ModalType) => void
  title: string
  handleAction: (params: RelativeDetailType) => void
}

export const CreateRelative = (props: CreateRelativeProps) => {
  const { visible, handleCloseModal, title, handleAction } = props
  const [relativeDetail, setRelativeDetail] = useState<RelativeDetailType>({
    title: '',
    firstname: '',
    lastname: '',
    relativeTypeId: 1
  })

  const { data: relativeTypeList } = useGetRelativeTypeList()

  const onChangeRelativeDetail = useCallback((name: string, data: Value) => {
    setRelativeDetail(prevState => ({ ...prevState, [name]: data }))
  }, [])

  return (
    <Modal
      visible={visible}
      isDisbleOk={Object.values(relativeDetail).some((k) => k === '')}
      onCancel={() => handleCloseModal('CREATE')}
      headerComponent={<Text size={24} type='Bold'>{title}</Text>}
      onOk={() => {
        handleAction(relativeDetail)
        handleCloseModal('CREATE')
      }}
      onOkText='ยืนยัน'
    >
      <FlexCol>
        <Input label='คำนำหน้า' onChange={(data) => onChangeRelativeDetail('title', data)} />
      </FlexCol>
      <FlexCol>
        <Input label='ชื่อ' onChange={(data) => onChangeRelativeDetail('firstname', data)} />
      </FlexCol>
      <FlexCol>
        <Input label='นามสกุล' onChange={(data) => onChangeRelativeDetail('lastname', data)} />
      </FlexCol>
      <FlexCol>
        <InputLabel>ความสัมพันธ์</InputLabel>
      </FlexCol>
      <FlexCol>
        <Select
          options={relativeTypeList?.filter(value => {
            return value.value !== null
          }) || []}
          defaultValue={1}
          onChange={(data: string) => onChangeRelativeDetail('relativeTypeId', data)}
        />
      </FlexCol>
    </Modal>
  )
}

type UpdateRelativeDetailType = RelativeDetailType & { id: number }
type UpdateRelativeProps = {
  id: number
  title: string
  visible: boolean
  handleCloseModal: (type: ModalType) => void
  handleAction: (params: UpdateRelativeDetailType) => void
}
const defaultRelativeDetail: UpdateRelativeDetailType = {
  id: 0,
  title: '',
  firstname: '',
  lastname: '',
  relativeTypeId: 1
}
export const UpdateRelative = (props: UpdateRelativeProps) => {
  const { id, visible, handleCloseModal, title, handleAction } = props
  const { data, isLoading, isError } = useGetRelativeById(id)
  const { data: relativeTypeList } = useGetRelativeTypeList()
  const [relativeDetail, setRelativeDetail] = useState<UpdateRelativeDetailType>(defaultRelativeDetail)


  useEffect(() => {
    setRelativeDetail({
      id: data?.id || 0,
      title: data?.title || '',
      firstname: data?.firstname || '',
      lastname: data?.lastname || '',
      relativeTypeId: data?.relativeTypeId || 1
    })

    return () => setRelativeDetail(defaultRelativeDetail)
  }, [data, isLoading])

  const onChangeRelativeDetail = useCallback((name: string, data: Value) => {
    setRelativeDetail(prevState => ({ ...prevState, [name]: data }))
  }, [])

  if (isLoading) {
    return <div></div>
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <Modal
      visible={visible}
      isDisbleOk={Object.values(relativeDetail).some((k) => k === '')}
      onCancel={() => handleCloseModal('UPDATE')}
      headerComponent={<Text size={24} type='Bold'>{title}</Text>}
      onOk={() => {
        handleAction(relativeDetail)
        handleCloseModal('UPDATE')
      }}
      onOkText='ยืนยัน'
    >
      <Input label='คำนำหน้า' defaultValue={data?.title} onChange={(data) => onChangeRelativeDetail('title', data)} />
      <Input label='ชื่อ' defaultValue={data?.firstname} onChange={(data) => onChangeRelativeDetail('firstname', data)} />
      <Input label='นามสกุล' defaultValue={data?.lastname} onChange={(data) => onChangeRelativeDetail('lastname', data)} />
      <InputLabel>ความสัมพันธ์</InputLabel>
      <Select
        defaultValue={data?.relativeTypeId}
        onChange={(data: string) => onChangeRelativeDetail('relativeTypeId', data)}
        options={relativeTypeList?.filter(value => {
          return value.value !== null
        }) || []}
      />
    </Modal>
  )
}

type DeleteRelativeProps = {
  id: number
  visible: boolean
  handleCloseModal: (type: ModalType) => void
  title: string
  handleAction: (id: number) => void
}
export const DeleteRelative = (props: DeleteRelativeProps) => {
  const { id, visible, handleCloseModal, title, handleAction } = props
  const { data, isLoading, isError } = useGetRelativeById(id)
  const { data: relativeTypeList } = useGetRelativeTypeList()

  const relativeName = useMemo(() => {
    if (relativeTypeList && relativeTypeList.length > 0)
      return relativeTypeList.find(val => val.value === data?.relativeTypeId)?.label
  }, [data?.relativeTypeId, relativeTypeList])

  if (isLoading) {
    return <div></div>
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => handleCloseModal('DELETE')}
      headerComponent={<Text size={24} type='Bold'>{title}</Text>}
      onOk={() => {
        handleAction(id)
        handleCloseModal('DELETE')
      }}
      onOkText='ยืนยัน'
    >
      <Text size={20} type='Bold'>{`${data?.title}${data?.firstname} ${data?.lastname}`}</Text>
      <Text>ความสัมพันธ์: {relativeName}</Text>
    </Modal>
  )
}


