import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Point from '../../components/common/Point'
import Text from '../../components/common/Text'
import { useGetUser } from '../../services/user/user-query'
import { mobile, mobileVerySmall } from '../../utils/responsive-helper'

const ProfileLayout = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: inherit;
  margin-bottom: 32px;

  ${mobile} {
    row-gap: 16px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 42px;
  }

  ${mobileVerySmall} {
    row-gap: 16px;
    align-self: center;
    flex-direction: column;
  }

  .left {
    max-width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    
    ${mobile} {
      margin-right: auto;
      max-width: 100%;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    
    ${mobile} {
      margin-left: auto;
      max-width: 100%;
    }
  }
`

const Profile = () => {
  const { t } = useTranslation()
  const { data: user } = useGetUser()
  
  return (
    <ProfileLayout>
      <div className='left'>
        <Text size={32} type="Bold">{`${user?.firstName} ${user?.lastName}`}</Text>
        <Text size={14} type="Bold">{t('home.subtitle')}</Text>
      </div>
      <div className='right'>
        <Point />
      </div>
    </ProfileLayout>
  )
}

export default Profile