import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { isNumber } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import color from '../constant/colors'
import i18n from '../constant/i18n'
import { useGetPeriodDate } from '../services/enrollment/enrollment-query'
import { useGetUserCoin, useGetUserCoinBenefitYear } from '../services/user/user-query'
import { numberWithCommas, useRouter } from '../utils/helper'
import Card from './common/Card'
import Image from './common/Image'
import Text from './common/Text'

const CardPoint = styled(Card)`
  padding-top: 24px;
  padding-bottom: 8px;
  margin-top: 16px;
`
const FlexRowBaseLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
`

const ImageStyle = styled(Image)`
  // object-fit: none;
  margin-left: 8px;
  align-self: center;
`

const TextPaddingLeft = styled(Text)`
  padding-left: 8px;
`

const TextCenter = styled(Text)`
  text-align: center;
`

const ExpireDate = styled(Text)`
  width: 100%;
  text-align: center;
  padding: 4px 0;
  margin-top: 8px;
`

type PointCardProps = {
  benefitYearId?: number
  startPeriodDate?: number
  endPeriodDate?: number
}

export const PointCardBenefit = (props: PointCardProps) => {
  const { query } = useRouter()
  const { benefitYearId = 0, startPeriodDate, endPeriodDate } = props
  const { t } = useTranslation()
  const { data: userCoin } = useGetUserCoinBenefitYear(benefitYearId)

  const getPoint = useMemo((code = 'dentsu_coin') => {
    if (!userCoin || userCoin.length === 0) return 0
    const coin = userCoin.find(v => v.code === code)

    if (!coin?.balance) return 0;

    return numberWithCommas(coin?.balance)
  }, [userCoin])

  return (
    <CardPoint>
      <FlexRowBaseLine>
        <ImageStyle width={32} height={32} imageName={'IconDentsuCoin'} />

        <TextPaddingLeft type="Bold" size={32} color={color.PRIMARY}>
          {getPoint}
        </TextPaddingLeft>
        <TextPaddingLeft type="SemiBold" size={18}>
          {t('app.coin')}
        </TextPaddingLeft>
      </FlexRowBaseLine>
      <TextCenter type="SemiBold" size={18}>
        {`(1 ${t('app.coin')} = 1 ${t('app.baht')})`}
      </TextCenter>
      <ExpireDate size={12}>{t('app.expiredOn')} {dayjs(endPeriodDate).locale(i18n.language).format(i18n.language === 'th' ? 'DD MMM BBBB' : 'DD MMM YYYY')}</ExpireDate>
    </CardPoint>
  )
}

export const PointCard = (props: PointCardProps) => {
  const { data: enrollmentPeriod } = useGetPeriodDate()
  const { benefitYearId = 0, endPeriodDate } = enrollmentPeriod || {}
  // const { data: userCoin } = useGetUserCoinBenefitYear(benefitYearId)
  const { t } = useTranslation()
  const { pathname } = useRouter()
  const { data: userCoin, refetch: refetchGetUserCoin } = useGetUserCoin()

  useEffect(() => { refetchGetUserCoin() }, [pathname])

  return (
    <CardPoint>
      <FlexRowBaseLine>
        <ImageStyle width={32} height={32} imageName={'IconDentsuCoin'} />
        <TextPaddingLeft type="Bold" size={32} color={color.PRIMARY}>
          {userCoin && isNumber(userCoin) ? numberWithCommas(Number(userCoin)) : 0}{' '}
        </TextPaddingLeft>
        <TextPaddingLeft type="SemiBold" size={18}>
          {t('app.coin')}
        </TextPaddingLeft>
      </FlexRowBaseLine>

      <TextCenter type="SemiBold" size={18}>
        {`(1 ${t('app.coin')} = 1 ${t('app.baht')})`}
      </TextCenter>
      <ExpireDate size={12}>{t('app.expiredOn')} {dayjs(endPeriodDate).locale(i18n.language).format(i18n.language === 'th' ? 'DD MMM BBBB' : 'DD MMM YYYY')}</ExpireDate>
    </CardPoint>
  )
}
