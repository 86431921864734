import Image, { ImageNameKeys } from '../../../components/common/Image'
import { ChoicesRadioTitle } from './EditBenefits/shared'
import color from '../../../constant/colors'
import Text from '../../../components/common/Text'
import styled from '@emotion/styled'
import { CSSProperties, PropsWithChildren } from 'react'

const Layout = styled.div`
  margin-bottom: 24px;
`

type BenefitsCategoryProps = {
  icon?: ImageNameKeys
  title?: string
  className?: string
  style?: CSSProperties
  src?: string
  isTitle?: boolean
}
const BenefitsCategory = (props: PropsWithChildren<BenefitsCategoryProps>) => {
  const { children, title, icon, className, style, src, isTitle = true } = props
  return (
    <Layout className={className} style={style}>
      {/* {isTitle ? (
        <ChoicesRadioTitle className="title-layout">
          <div className="icon-insurance-title">
            {src ? <Image src={src} /> : icon ? <Image imageName={icon} /> : null}
          </div>
          <Text size={24} type="Medium" color={color.FONT_BLACK}>
            {title}
          </Text>
        </ChoicesRadioTitle>
      ) : null} */}
      {children}
    </Layout>
  )
}
export default BenefitsCategory
