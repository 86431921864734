import Text from '../../components/common/Text'
import { mobile } from '../../utils/responsive-helper'
import styled from '@emotion/styled'
import { useGetEClaimList } from '../../services/e-claim/e-claim-query'
import { EClaimListItem } from '../../services/e-claim/e-claim-types'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'

import { numberWithCommas } from '../../utils/helper'

const FlexRow = styled.div`
  display: flex;
  ${mobile} {
    flex-direction: column;
  }
`
const FlexRowPoint = styled.div`
  display: flex;
  justify-content: flex-end;
`
const CardContainer = styled(Card)`
  margin: 24px;
  padding: 24px;
  display: flex;
  ${mobile} {
    margin: 10px;
    padding: 5px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
`

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`
const ImageStyle = styled(Image)`
  margin: 10px;
  margintop: 0px;
  ${mobile} {
    align-self: center;
  }
`

const EClaimListComponent = () => {
  const { data: eclaimList } = useGetEClaimList()

  return (
    <div>

    </div>
  )
}

export default EClaimListComponent
