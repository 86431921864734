import styled from '@emotion/styled'
import { notification } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form'
import Breadcrumb from '../../../../components/common/Breadcrumb'
import Text from '../../../../components/common/Text'
import color from '../../../../constant/colors'
import { healthCheckupBenefits } from '../../../../constant/path'
import { RootState } from '../../../../redux-store'
import {
  BenefitChoices,
  SelfBenefitsSendParams
} from '../../../../services/enrollment/enrollment-types'
import {
  useGetAllHealthCheckupSelfBenefits,
  useHealthCheckupSelfBenefitsSend
} from '../../../../services/health-checkup/health-checkup-query'
import {
  useGetUser,
  useGetUserCoinBenefitYear
} from '../../../../services/user/user-query'
import { useVisibility } from '../../../../utils/custom-hook'
import { numberWithCommas, useCanEditable, useRouter } from '../../../../utils/helper'
import { useScreen } from '../../../../utils/responsive-helper'
import ModalAcceptPrivacy from '../../ModalAcceptPrivacy'
import ModalAcceptPrivacy2 from '../../ModalAcceptPrivacy2'
import { SubGroupBenefitsField } from './SubGroupBenefits'
import {
  BreadcrumbLayout,
  ContentSpace,
  FooterContentLayout,
  Layout,
  RedeemButton,
  Row
} from './shared'

const SummaryItems = styled(Row)`
  justify-content: space-between;
  max-width: 450px;
  width: 100%;
`
const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // flex-direction: row;
  width: 100%;
  max-width: 550px;
  column-gap: 24px;
`
const SummaryTitle = styled(Text)`
  grid-column: 1/3;
`
const SummaryWrapperNote = styled.div`
  width: 50%;
`
const SummaryContent = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`


// export type SelectedChoices = Partial<BenefitChoices> &
// Pick<BenefitsCategoryWrapperList, 'benefitsCategoryId' | 'benefitsCategoryName'>
export type SelectedChoices = Partial<BenefitChoices>

type EditSelfBenefitsFormValues = {
  [key: string]: SelectedChoices[]
}
const EDIT_SELF_BENEFITS_FORM_NAME = 'EditSelfBenefits'
const enchancer = reduxForm({ form: EDIT_SELF_BENEFITS_FORM_NAME })

type EditSelfBenefitsProps = InjectedFormProps<EditSelfBenefitsFormValues>
const EditSelfBenefits = (props: EditSelfBenefitsProps) => {
  const { handleSubmit, initialize, dirty } = props
  const [allBenefits, setAllBenefits] = useState<Array<any>>([])
  const { query } = useRouter()
  const { isMobile } = useScreen()
  const [visible, modalAction] = useVisibility()
  const [visible2, modalAction2] = useVisibility()

  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { benefitYearId, benefitYear, startDate, startPeriodDate, endDate } = useSelector(
    (state: RootState) => state.enrollment.enrollment,
  )

  const { data: user, isLoading: isUserLoading } = useGetUser()
  const { data: userCoin, isStale: isUserCoinStale } = useGetUserCoinBenefitYear(benefitYearId)
  // const { data: userPointNextYear = 0, isStale: isUserCoinNextYearStale } =
  //   useGetUserCoinBenefitYear(benefitYearNextId)
  const { data: allSelfBenefits, isStale: isAllSelfBenefitsStale } =
    useGetAllHealthCheckupSelfBenefits(benefitYearId)
  const { mutate: selfBenefitsSend, isLoading: isSubmitting } = useHealthCheckupSelfBenefitsSend()
  const canEditable = useCanEditable(startDate, endDate)
  const history = useHistory()

  const formValuesSelector = getFormValues(EDIT_SELF_BENEFITS_FORM_NAME)
  const formValues = useSelector<any, EditSelfBenefitsFormValues>((state) =>
    formValuesSelector(state),
  )

  useEffect(() => {
    let benefits: any[] = []

    allSelfBenefits?.map((SelfBenefit) => {
      benefits.push(SelfBenefit)
      return setAllBenefits(benefits)
    })
  }, [allSelfBenefits])

  useEffect(() => {
    const initailValues = allSelfBenefits
      ? allSelfBenefits.reduce((initialValues: EditSelfBenefitsFormValues, cur) => {
        // const selected = cur.benefitsCategoryWrapperList.reduce((acc: SelectedChoices[], v) => {
        //   const choiceSelected = v.choices.find((k) => k.selected === true)
        //   if (choiceSelected)
        //     acc.push({
        //       benefitsCategoryId: v.benefitsCategoryId,
        //       benefitsCategoryName: v.benefitsCategoryName,
        //       ...choiceSelected,
        //     })
        //   return acc
        // }, [])
        // initialValues[cur.benefitName] = selected

        return initialValues

      }, {})
      : {}
    initialize(initailValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelfBenefits])

  const getPoint = useMemo((code = 'dentsu_coin') => {
    if (!userCoin || userCoin.length === 0) return 0
    const coin = userCoin.find(v => v.code === code)

    if (!coin?.balance) return 0;

    return coin?.balance
  }, [userCoin])

  const initialPoint = useMemo(() => {
    return allSelfBenefits
      ? allSelfBenefits[0].choices.reduce((sumTotalPoint, cur) => {
        if (cur?.isDefault === true) {
          const point = cur?.taggedPrice || 0
          sumTotalPoint += point
        }

        return sumTotalPoint
      }, 0)
      : 0

  }, [allSelfBenefits])

  const totalSelectedChoice = useMemo(() => {
    return formValues
      ? Object.entries(formValues).reduce((selectedChoice: Partial<BenefitChoices> | undefined, current) => {
        const [key, value] = current;
        selectedChoice = value[0]
        return selectedChoice;
      }, undefined)
      : undefined;
  }, [formValues]);

  const totalSelectedPoint = useMemo(() => {
    return formValues
      ? Object.entries(formValues).reduce((sumTotalPoint, cur) => {
        const [, value] = cur
        value.forEach((choiceSelected) => {
          const point = choiceSelected?.taggedPrice || 0
          sumTotalPoint += point
        })
        return sumTotalPoint > 0 ? sumTotalPoint : initialPoint
      }, 0)
      : 0
    // return 0
  }, [formValues, initialPoint])

  const remainPoint = useMemo(() => {
    return initialPoint - totalSelectedPoint
  }, [initialPoint, totalSelectedPoint])

  const userAge = useMemo(() => {
    return user?.age ? user?.age : '-'
  }, [user])

  const isSelectAll = useMemo(() => {
    const objects = Object.entries(formValues || {})
    let selected: any[] = []

    objects?.map((object) => (selected = object[1].concat(selected)))

    if (selected.length === allBenefits.length) {
      return true
    } else {
      return false
    }
  }, [formValues, allBenefits])
  //   for (const obj of objects) {
  //     const [key, value] = obj
  //     if (value.length === 0) {
  //       return false
  //     }
  //   }
  //   return true
  // }, [formValues])

  const isApiFetched = useMemo(() => {
    return !isUserCoinStale && !isAllSelfBenefitsStale && !isSubmitting
  }, [isAllSelfBenefitsStale, isSubmitting, isUserCoinStale])

  // const isAvailable = user?.lastEnrollDate > Date.now()
  const canSubmit = useMemo(() => {
    // return (isAvailable && allSelfBenefits)
    return allSelfBenefits
  }, [allSelfBenefits])

  const totalSelectedBenefit = useMemo(() => {
    const obj = Object.entries(formValues || {}).map((value, index) => ({
      title: value[0],
      data: value[1],
    }))

    return obj
  }, [formValues])

  const onConfirmSelectedBenefits = useCallback(
    (formValues: EditSelfBenefitsFormValues) => {
      if (allSelfBenefits) {
        const selectedChoices =
          Object.keys(formValues)
            .map((key) => formValues[key][0])
            .map((v, index) => ({ selfBenefitsCategoryId: allSelfBenefits[index].benefitsCategoryId, selfBenefitsChoicesId: v.id, userConsent: true }))

        const params: SelfBenefitsSendParams = {
          benefitYearId: benefitYearId!,
          selectedChoices: selectedChoices,
        }
        if (selectedChoices) {
          selfBenefitsSend(params, {
            onSuccess: () => {
              notification.success({
                message: 'Success',
              })
              history.push(
                healthCheckupBenefits({
                  routeParam: {
                    benefitYearId: benefitYearId,
                  },
                }),
              )
            },
            onError: (response: any) => {
              if (response.message === 'No update on selected choices') {
                notification.error({
                  message: t('enrollment.editSelfBenefits.notification.error.noChange'),
                })
              } else {
                notification.error({
                  message: t('enrollment.editSelfBenefits.notification.error.detail'),
                })
              }
            },
          })
        }
        modalAction2.hide()
      }
      // const selectedChoices = 2

      // const selectedChoices = Object.entries(formValues).reduce(
      //   (acc: SelfBenefitsSendParams, [, value]) => {
      //     const temp = value.map((v) => ({
      //       selectedChoices: v.id!,
      //     }))
      //     acc = concat(acc, temp)
      //     return acc
      //   },
      //   [],
      // )


    },
    [benefitYearId, modalAction, selfBenefitsSend, history, allSelfBenefits],
  )


  function onConfirmSelectedBenefits2() {
    modalAction.hide()
    modalAction2.show()
  }

  return (
    <Layout>
      <ModalAcceptPrivacy
        visible={visible}
        onConfirmClick={
          remainPoint >= 0 ? handleSubmit(onConfirmSelectedBenefits) : onConfirmSelectedBenefits2
        }
        onCloseClick={modalAction.hide}
      />

      <ModalAcceptPrivacy2
        visible={visible2}
        onConfirmClick={handleSubmit(onConfirmSelectedBenefits)}
        onCloseClick={modalAction2.hide}
      />

      {/* {!isMobile && ( */}
      <BreadcrumbLayout>
        <Breadcrumb
          overide={(paths) => {
            paths.unshift({ label: 'Home', path: '/' })
            paths[1].label = t('app.breadcrumb.myWelFare')
            paths[2].label = t('app.breadcrumb.myHealthCheckup')
            paths[3].label = t('app.breadcrumb.changeHealthCheckup')
            return paths
          }}
        />
      </BreadcrumbLayout>
      {/* )} */}
      {/* <Header>
        <Text size={28} type="Bold" color={color.FONT_BLACK}>
          {t('healthCheckup.editSelfBenefits.header')}
        </Text>
      </Header> */}
      {/* <UserName>
        <div>
          <Image imageName="iconUser" />
        </div>
        <Loading loading={isUserLoading}>
          <Row>
            <Space className="username" direction="horizontal" size={8}>
              <Text size={26} type="Bold" color={color.FONT_BLACK}>
                {user?.firstName}
              </Text>
              <Text size={26} type="Bold" color={color.FONT_BLACK}>
                {user?.lastName}
              </Text>
            </Space>
            <Text size={26} type="Bold" color={color.FONT_BLACK}>{`(${userAge} ปี)`}</Text>
          </Row>
        </Loading>
      </UserName> */}
      <ContentSpace direction="vertical" size={10}>
        {allSelfBenefits?.map((benefit, index) => {
          return (
            <SubGroupBenefitsField
              key={`${benefit.benefitsCategoryId}`}
              name={benefit.benefitsCategoryName}
              benefit={benefit}
            />
          )
        })}
        {/* <SubGroupBenefitsField
          name={""}
          benefit={allSelfBenefits || []}
        /> */}
      </ContentSpace>
      <FooterContentLayout
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: isMobile ? 'center' : 'flex-start',
          rowGap: 4,
          padding: '16px 24px',
          border: '1px solid #E5E5E5',
        }}
      >
        <Text size={14} type="Bold" style={{ textAlign: isMobile ? 'center' : 'start' }}>
          {t('healthCheckup.editSelfBenefits.summary.title')}
        </Text>
        {/* {totalSelectedBenefit
          ? totalSelectedBenefit.map((benefit, index) => (
            <Row
              key={index}
              style={{
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <Text size={14} style={{ textAlign: isMobile ? 'center' : 'start' }}>
                {t('healthCheckup.editSelfBenefits.summary.selected')} :
              </Text>
              <Row
                style={{
                  flexWrap: 'wrap',
                }}
              >
                {benefit.data.map((data, index) => (
                  <Text key={data.id} size={14}>
                    {`${data.choicesName?.replace('<br>', '')} ${benefit.data.length === index + 1 ? '' : ','
                      } `}
                  </Text>
                ))}
              </Row>
            </Row>
          ))
          : null} */}
        <SummaryContent>
          <SummaryWrapper>
            <SummaryTitle size={14} style={{ textAlign: isMobile ? 'center' : 'start' }}>
              {t('healthCheckup.editSelfBenefits.summary.selected')} :
            </SummaryTitle>
            <Text size={14} style={{ gridColumn: '3/5', }}>
              {totalSelectedChoice ? totalSelectedChoice.choicesName?.replace('<br>', '') : ''}
            </Text>
            <SummaryTitle size={14}>
              {t('healthCheckup.editSelfBenefits.summary.coinAdditional')} :
            </SummaryTitle>
            <Text size={14}>
              {remainPoint >= 0 ? numberWithCommas(remainPoint) : 0}
            </Text>
            <Text size={14}>{t('app.coin')}</Text>
          </SummaryWrapper>
          <SummaryWrapperNote>
            {/* <Text size={14}>
              {`(${t('app.coinCanUse')} ${dayjs(startPeriodDate).locale(language).format('BBBB')})`}
            </Text> */}
          </SummaryWrapperNote>
        </SummaryContent>
        {/* <SummaryItems>
          <Text size={14}>
            {t('healthCheckup.editSelfBenefits.summary.coinAdditional')}
          </Text>
          <Text>
            {remainPoint >= 0 ? (
              numberWithCommas(remainPoint)
            ) : (
              0
            )}
          </Text>
          <Text>{t('app.baht')}</Text>
        </SummaryItems> */}
        {/* <div className="right">
          <Text
            size={16}
            color={color.FONT_BLACK}
            style={{ textAlign: isMobile ? 'center' : 'end', marginBottom: 8 }}
            type={isMobile ? 'Bold' : 'Regular'}
          >
            {t('healthCheckup.editSelfBenefits.summary.totalCoin')}
          </Text>
          <SummaryPoint
            style={{
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-end',
              marginBottom: 24,
            }}
          >
            <Text size={30} type="Bold" color={color.PRIMARY}>
              {numberWithCommas(totalSelectedPoint)}
            </Text>
            <Text size={20} color={color.FONT_LIGHT_GRAY}>
              {`(${t('app.remain')} ${numberWithCommas(remainPoint)} ${t('app.coin')})`}
            </Text>
          </SummaryPoint>

          <RedeemButton
            onClick={modalAction.show}
            disabled={!canSubmit || !isApiFetched}
            background={color.PRIMARY}
          >
            {t('app.continue')}
          </RedeemButton>
        </div> */}
        <RedeemButton
          onClick={modalAction.show}
          disabled={!canSubmit || !isApiFetched}
          background={color.PRIMARY}
        >
          {t('app.continue')}
        </RedeemButton>
      </FooterContentLayout>
    </Layout>
  )
}
export default enchancer(EditSelfBenefits)
