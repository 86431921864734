import styled from '@emotion/styled'
import Steps from '../../components/common/Steps'
const Layout = styled.div`
  flex-direction: row;
`

const ExampleSteps = () => {
  return (
    <Layout>
      <h1>สำหรับมือถือ</h1>
      <Steps currentStep={1} maxStep={2} width={180} />
      <Steps currentStep={2} maxStep={2} width={180} />
      <Steps currentStep={2} maxStep={4} width={180} />
      <h1>สำหรับทั่วไป</h1>
      <Steps currentStep={1} maxStep={2} />
      <Steps currentStep={2} maxStep={2} />
      <Steps currentStep={5} maxStep={7} />
    </Layout>
  )
}
export default ExampleSteps
