import styled from '@emotion/styled'
import Commment from '../../components/Comment'
const Layout = styled.div``

const ExampleComment = () => {
  return (
    <Layout>
      <Commment userId={1} digitalNewsId={1} />
    </Layout>
  )
}
export default ExampleComment
