import styled from '@emotion/styled'
import Button from '../../components/common/Button'
const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const ExampleButton = () => {
  return (
    <Layout>
      <Button>Button</Button>
      <Button disabled>Disabled</Button>
      <Button onClick={() => console.log('Hello World!!')}>onClick show in Console</Button>
    </Layout>
  )
}
export default ExampleButton
