import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useGetEnrollmentYear } from '..'
import { setEnrollment } from '../../../redux-store/enrollment-reducer'
import {
  useGetEnrollmentsDate
} from '../../../services/enrollment/enrollment-query'
import { useCanEditable, useRouter } from '../../../utils/helper'
import { mobile } from '../../../utils/responsive-helper'
import HealthCheckupDetailRoute from './HealthCheckupDetailRoute'

const Layout = styled.div`
  > * {
    margin-top: 24px;
  }
  text-align: center;
  
  ${mobile} {
    padding: 6px;
  }
`

const EnrollmentDetailLayout = () => {
  const dispatch = useDispatch()
  const [benefitYearId, setBenefitYearId] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const { data: enrollmentDate, isLoading: isLoadEnrollmentDate } = useGetEnrollmentsDate()
  const {
    startDate: enrollmentStartEnrollmentDate = 0,
    endDate: enrollmentEndEnrollmentDate = 0,
    startPeriodDate: enrollmentStartPeriodDate = 0,
    endPeriodDate: enrollmentEndPeriodDate = 0,
    benefitYearId: enrollmentBenefitYearId = 0,
    benefitYear: enrollmentBenefitYear = 0,
  } = enrollmentDate || {}

  const { enrollmentYear } = useGetEnrollmentYear(enrollmentStartEnrollmentDate)

  // // TODO:เช็คว่า อยู่ในช่วงการเลือกประกันหรือไม่
  // const canEditable = useCanEditable(enrollmentStartEnrollmentDate, enrollmentEndEnrollmentDate)

  useEffect(() => {
    if (enrollmentBenefitYearId) {
      setBenefitYearId(enrollmentBenefitYearId)
    }
  }, [enrollmentBenefitYearId])

  useEffect(() => {
    dispatch(
      setEnrollment({
        benefitYearId: benefitYearId,
        enrollmentYear: enrollmentYear.toString(),
        startDate: enrollmentStartEnrollmentDate,
        endDate: enrollmentEndEnrollmentDate,
        benefitYear: enrollmentBenefitYear + 543,
        startPeriodDate: enrollmentStartPeriodDate,
        endPeriodDate: enrollmentEndPeriodDate,
      }),
    )
    setIsLoading(true)
  }, [benefitYearId, enrollmentYear, dispatch, enrollmentStartEnrollmentDate, enrollmentEndEnrollmentDate, enrollmentBenefitYear, enrollmentStartPeriodDate, enrollmentEndPeriodDate])

  return (
    <>
      {!isLoadEnrollmentDate && isLoading && (
        <Layout>
          <HealthCheckupDetailRoute />
        </Layout>
      )}
    </>
  )
}

export default EnrollmentDetailLayout
