import { AndroidOutlined, AppleOutlined, TeamOutlined } from '@ant-design/icons'

import Tabs from '../../components/common/Tabs'
import { useScreen } from '../../utils/responsive-helper'

const Page1 = () => {
  return (
    <div>
      tab1 content 555 <AndroidOutlined />
    </div>
  )
}
const Page2 = () => {
  return <div>Tab2 testtttttttttttttttt</div>
}
const Page3 = () => {
  return (
    <div>
      Tab3 9999999999999999999999999999999999 Tab3 9999999999999999999999999999999999 Tab3
      9999999999999999999999999999999999 Tab3 9999999999999999999999999999999999 Tab3
      9999999999999999999999999999999999 Tab3 9999999999999999999999999999999999 Tab3
      9999999999999999999999999999999999 Tab3 9999999999999999999999999999999999
    </div>
  )
}

const ExampleTabs = () => {
  const dataSource = [
    {
      title: 'Tab1',
      key: 1,
      icon: <AndroidOutlined />,
      page: <Page1 />,
    },
    {
      title: 'Tab2',
      key: 2,
      icon: <AppleOutlined />,
      page: <Page2 />,
    },
    {
      title: 'Tab3',
      key: 3,
      icon: <TeamOutlined />,
      page: <Page3 />,
    },
  ]
  const { isDesktop } = useScreen()
  return (
    <div>
      <Tabs dataSource={dataSource} mode={isDesktop ? undefined : 'select'} />
    </div>
  )
}

export default ExampleTabs
