import ComponentImageSlider from '../../../components/common/ImageSlider'
import { useGetShopCategoryImageSlider } from '../../../services/shop/shop-query'

type ImageSliderProps = {
  categoryId: string
}

const ImageSlider = (props: ImageSliderProps) => {
  const { categoryId } = props
  const { data } = useGetShopCategoryImageSlider(categoryId)
  return <ComponentImageSlider autoplay images={data?.content} />
}

export default ImageSlider
