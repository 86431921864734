import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Button from '../../components/common/Button'
import CategoryGroup from '../../components/common/CategoryGroup'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import config from '../../config'
import color from '../../constant/colors'
import * as paths from '../../constant/path'
import { useGetNews } from '../../services/digital-news/digital-news-query'
import { DigitalNewsType, NewsWithPaging } from '../../services/digital-news/digital-news-types'
import { useGetUser } from '../../services/user/user-query'
import { mobile } from '../../utils/responsive-helper'

const NewsItemLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;
  cursor: pointer;

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-self: stretch;
    padding: 16px 0px;
  }

  .right {
    width: 176px;
    height: 100%;
    border-radius: 8px;
  }

  ${mobile} {
    flex-direction: column;
    row-gap: 16px;

    .left {    
      order: 2;
    }

    .right {
      width: 100%;
    }
  }
`
const NewsSubTitle = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;

  .date {
    display: flex;
    column-gap: 8px;
    align-items: center;
  }
`

const News = () => {
  const history = useHistory()
  const { data: user } = useGetUser()
  const { userId } = user || {}
  const { t, i18n } = useTranslation()

  const { data } = useGetNews({
    size: 3,
    category: 'all',
    subCategory: 'News',
  })

  const newsPath = useMemo(() => {
    return paths.news()
  }, [])

  const onNewsDetailClick = useCallback(
    (newsId: number) => {
      history.push(paths.newsDetails({ routeParam: { newsId } }))
    },
    [history],
  )

  return (
    <CategoryGroup
      label={t('home.activity')}
      path={newsPath}
      viewMoreText=" "
      contentStyle={{
        gridTemplateColumns: 'repeat(1, 1fr)',
      }}
      headerStyle={{
        marginBottom: 32
      }}
    >
      {data?.pages.map(({ data: page }: NewsWithPaging) =>
        page.map((news: DigitalNewsType) => (
          <NewsItemLayout key={news.id}
            onClick={() => {
              onNewsDetailClick(news.id)
            }}>
            <div className='left'>
              <Text size={14} type="Bold">{news.title}</Text>
              <NewsSubTitle>
                <div className='date'>
                  <Image imageName='calendarIcon' />
                  <Text size={10}>{dayjs(news.digitalNewsPublishDate).locale(i18n.language).format('DD MMM YYYY')}</Text>
                </div>
                <Button background={color.BACKGROUND} style={{
                  borderRadius: 16,
                  width: 'fit-content',
                  padding: '2px 10px',
                  height: 'fit-content',
                }}>
                  <Text size={12}>{news.digitalNewsType}</Text>
                </Button>
              </NewsSubTitle>
            </div>
            <Image
              className='right'
              src={news?.thumbnailPath?.indexOf('http') > -1 ? news?.thumbnailPath : `${config.apiHost}${news?.thumbnailPath}`}
            />
          </NewsItemLayout>
        )),
      )}
    </CategoryGroup>
  )
}

export default News
