import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { range } from 'lodash'
import color from '../../constant/colors'
import Text from '../../components/common/Text'

const LayoutSteps = styled.div<{ width: number }>`
  display: flex;
  flex-direction: row;
  max-width: ${({ width }) => width}px;
  text-align: center;
`

const LayoutStep = styled.div<{ width: number }>`
  display: flex;
  flex-direction: row;
  max-width: ${({ width }) => width}px;
  text-align: center;
`

const LayoutLine = styled.div<{ lineWidth: number }>`
  padding-top: 9px;
  margin-right: -20px;
  width: ${({ lineWidth }) => (lineWidth > 0 ? lineWidth : 'unset')}px;
`

const Line = styled.hr<{ color: string }>`
  border: 5px solid;
  color: ${({ color }) => color};
`

const CircleStep = styled.div<{ backGroundColor: string }>`
  position: relative;
  background-color: ${({ backGroundColor }) => backGroundColor};
  width: 40px;
  height: 40px;
  margin-right: -20px;
  text-align: center;
  border-radius: 50%;
  padding-top: 5px;
`

type CustomStepProps = {
  currentStep: number
  maxStep: number
  width?: number
}

type CustomStepsProps = {
  label?: string
  backGroundColor: string
  isLine: boolean
  lineWidth?: number
}

const Step = (props: CustomStepsProps) => {
  const { label = '', backGroundColor, isLine, lineWidth = 40 } = props
  const layoutStepWidth = lineWidth + 40
  return (
    <LayoutStep width={layoutStepWidth}>
      {isLine && (
        <LayoutLine lineWidth={lineWidth}>
          <Line color={backGroundColor} />
        </LayoutLine>
      )}
      <CircleStep backGroundColor={backGroundColor}>
        <Text size={20} color={color.WHITE} type="Text">
          {label}
        </Text>
      </CircleStep>
    </LayoutStep>
  )
}

const Steps = (props: CustomStepProps) => {
  const { currentStep, maxStep, width = 325 } = props
  const lineWidth = useMemo(() => {
    return width / (maxStep - 1)
  }, [width, maxStep])

  return (
    <LayoutSteps width={width}>
      {range(1, maxStep + 1).map((index: number) => {
        const indexString = index.toString()
        return (
          <Step
            key={index}
            label={index === 1 || index <= currentStep ? indexString : undefined}
            backGroundColor={index === 1 || index <= currentStep ? color.PRIMARY : color.LIGHT_GRAY}
            isLine={index > 1 ? true : false}
            lineWidth={index > 1 ? lineWidth : undefined}
          />
        )
      })}
    </LayoutSteps>
  )
}

export default Steps
