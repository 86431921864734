import Text from '../../../../components/common/Text'
import color from '../../../../constant/colors'
import Image from '../../../../components/common/Image'
import { useEffect } from 'react'
import {
  BenefitChoices,
  BenefitsCategoryWrapperList,
  SelfBenefit,
} from '../../../../services/enrollment/enrollment-types'
import { Content, ContentHeader, useSelectBenefitsHelper, BenefitsCategoryStyled } from './shared'
import ChoicesRadio from './ChoicesRadio'
import { makeField } from '../../../../components/common/fields/tools'
import { isScg } from '../../../../utils/helper'

export type SelectedChoices = Partial<BenefitChoices>

type SubGroupBenefitsProps = {
  benefit: BenefitsCategoryWrapperList
  price?: number
  value?: SelectedChoices[]
  onChange?: (value: SelectedChoices[]) => void
}
const SubGroupBenefits = (props: SubGroupBenefitsProps) => {
  const { value, onChange, benefit, price = 0 } = props

  const { selectedChoices, onSelectedChoices } = useSelectBenefitsHelper(benefit, price, true)

  const selectedChoicesIds = selectedChoices.map((v) => v.id)[0]

  useEffect(() => {
    onChange?.(selectedChoices)
  }, [onChange, selectedChoices])
  return (
    <Content>
      <ContentHeader style={{ marginBottom: !benefit ? 0 : undefined }}>
        {benefit ? (
          <div style={{ display: 'flex' }}>
            <div className="icon-enroll">
              <Image imageName="iconEnrollWhite" />
            </div>
            <Text size={24} type="Bold" color={isScg === true ? color.WHITE : color.FONT_BLACK}>
              {`${benefit?.benefitsCategoryName}`}
            </Text>
          </div >
        ) : (
          <Text size={24} type="Bold" color={isScg === true ? color.WHITE : color.FONT_BLACK}>
            {`ไม่พบประกันของคุณ`}
          </Text>
        )}
      </ContentHeader >
      <BenefitsCategoryStyled
        // src={benefitsCategoryIcon}
        style={{
          backgroundColor: color.BACKGROUND_LIGHT,
          padding: 24,
        }}
      >
        <ChoicesRadio
          value={selectedChoicesIds}
          options={benefit.choices.map((v) => ({
            value: v.id,
            label: v.choicesName,
            description: v.choicesDescription,
            price: v?.taggedPrice,
          }))}
          onChange={(v) => {
            onSelectedChoices(benefit.choices.find((k) => k.id === v)!)
          }}
        />
      </BenefitsCategoryStyled >
    </Content >
  )
}

export const SubGroupBenefitsField = makeField<SubGroupBenefitsProps>(SubGroupBenefits)
export default SubGroupBenefits
