import React, { useEffect } from 'react'
import './App.css'
import { Routes } from './routes'
import 'antd/dist/antd.css'
import { useLocation } from 'react-router'
import { getLanguage } from './utils/helper'

import moment from 'moment'
import 'moment/locale/th';

import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import 'dayjs/locale/th'

export const App: React.FC<{ id: string }> = () => {
  const { pathname } = useLocation()
  const lang = getLanguage()
  moment.locale(lang);
  dayjs.locale(lang)
  dayjs.extend(buddhistEra)


  useEffect(() => {
    document.getElementById('contentBody')?.scrollTo(0, 0)
  }, [pathname])

  return <Routes />
}
