import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import Text from '../../common/Text'
import * as paths from '../../../constant/path'
import color from '../../../constant/colors'

type TitleHeaderProps = {
  icon?: JSX.Element
  path?: string
  title?: string
}

const TitleLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  margin: 0 -16px;
  max-width: 1172px;
  background-color: ${color.WHITE};
  border-bottom: 1px solid ${color.BORDER};
`

const TitleText = styled(Text)`
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0 8px;
`

const TitleHeader = (props: TitleHeaderProps) => {
  const { icon, path, title } = props
  return (
    <TitleLayout>
      <Link to={path || paths.home()}>
        <Text size={18} style={{ lineHeight: '18px' }}>
          {icon}
        </Text>
      </Link>
      <TitleText type="SemiBold">{title}</TitleText>
    </TitleLayout>
  )
}

export default TitleHeader
