import styled from '@emotion/styled'
import { Skeleton, Space } from 'antd'
import { ReactNode, useState } from 'react'
import { useQuery } from 'react-query'
import Card from '../../components/common/Card'
import CategoryGroup, { CategoryGroupProps } from '../../components/common/CategoryGroup'
import { sleep } from '../../utils/helper'

const CardChildrenLayout = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 267px;
  height: 330px;
  padding: 0 0 24px;
  border-radius: 8px;

  .ant-skeleton-element,
  .children {
    flex: 1 1 60%;
  }
  .children {
    flex-grow: 0;
    padding: 20px;
  }
  .ant-skeleton-element {
    width: 100%;

    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
  }
`
type CardChildrenProps = {
  children?: ReactNode
}
const CardChildren = (props: CardChildrenProps) => {
  const { children } = props
  return (
    <CardChildrenLayout>
      <Skeleton.Image />
      <div className="children">{children}</div>
    </CardChildrenLayout>
  )
}
const CustomCategoryGroup = styled(CategoryGroup)<CategoryGroupProps>``
const Interval = 9
const ApiDelay = 4000
const ExampleCategoryGroup = () => {
  const [timer] = useState(Interval)
  const { data } = useQuery('mockLoading', () => {
    return sleep(ApiDelay).then(() => {
      let i = 0
      const data = []
      while (i < 20) {
        data.push({
          title: new Date().toISOString(),
        })
        i++
      }
      return data
    })
  })

  // useEffect(() => {
  //   if (timer <= 0 && !isFetching) {
  //     fetchMore()
  //   }
  // }, [timer, isFetching, fetchMore])

  // useEffect(() => {
  //   if (timer <= 0) {
  //     setTimer(Interval)
  //   } else if (timer > 0 && timer <= Interval) {
  //     window.setTimeout(function () {
  //       setTimer((prev) => {
  //         return prev - 1
  //       })
  //     }, 1000)
  //   }
  // }, [timer])

  return (
    <>
      <CategoryGroup label="overflow">
        <Space size={24} align="baseline" direction="horizontal">
          <Card>
            <CardChildren>1</CardChildren>
          </Card>
          <Card>
            <CardChildren>2</CardChildren>
          </Card>
          <Card>
            <CardChildren>3</CardChildren>
          </Card>
          <Card>
            <CardChildren>4</CardChildren>
          </Card>
          <Card>
            <CardChildren>5</CardChildren>
          </Card>
          <Card>
            <CardChildren>6</CardChildren>
          </Card>
          <Card>
            <CardChildren>76</CardChildren>
          </Card>
          <Card>
            <CardChildren>8</CardChildren>
          </Card>
          <Card>
            <CardChildren>9</CardChildren>
          </Card>
          <Card>
            <CardChildren>10</CardChildren>
          </Card>
          <Card>
            <CardChildren>11</CardChildren>
          </Card>
          <Card>
            <CardChildren>12</CardChildren>
          </Card>
        </Space>
      </CategoryGroup>
      <CategoryGroup label="not overflow">
        <Space size={24} align="baseline" direction="horizontal">
          <Card>
            <CardChildren>1</CardChildren>
          </Card>
          <Card>
            <CardChildren>2</CardChildren>
          </Card>
        </Space>
      </CategoryGroup>
      <CategoryGroup label={`loading: ${timer}`} loading={true}>
        <Space size={24} align="baseline" direction="horizontal">
          {data?.map((d) => {
            const { title } = d
            return (
              <Card key={title}>
                <CardChildren>{title}</CardChildren>
              </Card>
            )
          })}
        </Space>
      </CategoryGroup>
      <CustomCategoryGroup label="Upcoming Events ">
        <Space size={24} align="baseline" direction="horizontal">
          <Card>
            <CardChildren>1</CardChildren>
          </Card>
          <Card>
            <CardChildren>2</CardChildren>
          </Card>
        </Space>
      </CustomCategoryGroup>
    </>
  )
}
export default ExampleCategoryGroup
