import { useHistory } from 'react-router-dom'
import Text from '../../components/common/Text'
import { eClaim, eClaimList, home } from '../../constant/path'
// import { enrollmentDetail } from '../../constant/path'
import styled from '@emotion/styled'
import { useGetPeriodDate } from '../../services/enrollment/enrollment-query'
import { useGetUserCoinBenefitYear } from '../../services/user/user-query'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'

import dayjs from 'dayjs'
import { useMemo } from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'
import Button from '../../components/common/Button'
import MainPanel from '../../components/common/MainPanel'
import { numberWithCommas } from '../../utils/helper'
import EClaimListComponent from './EClaimListComponent'

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  flex-direction: row;
  padding: 0 12px;

  ${tablet} {
    flex-direction : column-reverse;
    gap: 12px;
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  max-width: 768px;
  min-height: 266px;
  margin: 22px auto 0 auto;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  width: 100%;
  min-height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`

const BreadcrumbContainer = styled.div`
  z-index: 1;
  ${mobile} {
    margin: 0px 16px;
  }
`
const ButtonStyle = styled(Button)`
  width: 138px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #034780;
  background: #FFF;
  height: 40px;

  div {
    color: #60607D;
  }

  :hover {
    border: 2px solid #034780;
    background: #FFF;

    div {
    color: #60607D;

    }
  }
  ${tablet} {
    width: 100%;
    position: relative;
  }

  ${mobile} {
    width: 100%;
    position: relative;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`

const ButtonContainer = styled.div`
  flex-direction: row-reverse;
  display: flex;
  flex: 1;
`

export const useGetEnrollmentYear = (startDateProp: number = 0) => {
  const { data: periodDate } = useGetPeriodDate()

  return useMemo(() => {
    let startDate
    if (startDateProp) {
      startDate = startDateProp
    } else {
      startDate = periodDate?.startDate
    }
    const enrollmentYear = dayjs(startDate).add(543, 'year').year()
    return {
      enrollmentYear: enrollmentYear || '',
    }
  }, [periodDate, startDateProp])
}

const Enrollment = () => {
  const { isMobile } = useScreen()
  const history = useHistory()
  const { data: periodDate } = useGetPeriodDate()
  const { endPeriodDate, benefitYearId = 0 } = periodDate || {}
  // const { data: userCoin } = useGetUserCoin()
  const { data: userCoin } = useGetUserCoinBenefitYear(benefitYearId)

  const getPoint = useMemo((code = 'dentsu_coin') => {
    if (!userCoin || userCoin.length === 0) return 0
    const coin = userCoin.find(v => v.code === code)

    if (!coin?.balance) return 0;

    return numberWithCommas(coin?.balance)
  }, [userCoin])

  return (
    <>
      <Layout>
        <BackgroundHeader />
        <FlexRow>
          <BreadcrumbContainer>
            <Breadcrumb
              overide={(paths) => {
                paths[0] = {
                  label: 'Home',
                  path: home(),
                }
                paths[1] = {
                  label: 'D-Claims',
                  path: eClaim(),
                }
                return paths
              }}
            />
          </BreadcrumbContainer>

          {!isMobile &&
            <Text type="Bold" size={isMobile ? 24 : 32} style={{ zIndex: 1, textAlign: 'center' }}>
              D-claims
            </Text>
          }

          <MainPanel
            key={1}
            endPeriodDate={endPeriodDate}
            remain={getPoint}
          />

          <TitleContainer>
            <FlexContainer>
              <Text type="Bold" size={isMobile ? 22 : 26} style={{ marginRight: isMobile ? '0' : '38px' }}>
                Flexi Lifestyle Benefits Catalog
              </Text>
            </FlexContainer>
            <ButtonContainer>
              <ButtonStyle onClick={() => history.push(eClaimList())}>
                ประวัติการเบิก
              </ButtonStyle>
            </ButtonContainer>
          </TitleContainer>
          <div style={{ height: '24px' }}></div>
          <EClaimListComponent />
        </FlexRow>
      </Layout>
    </>
  )
}
export default Enrollment
