import React, { CSSProperties, ReactNode } from 'react'
import styled from '@emotion/styled'
import { Button as AntdButton } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import color from '../../constant/colors'
import Text from '../../components/common/Text'
import Image from './Image'
import { isScg } from '../../utils/helper'

const CustomStyledAntdButton = styled(AntdButton) <{
  background: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ color }) => color};
  background: ${({ background }) => background};
  width: 100%;
  max-width: 364px;
  height: 45px;
  border-radius: 8px;

  :hover,
  :active,
  :focus {
    background: ${({ background }) => background};
    border-color: #1890ff;
  }

  :disabled {
    background: #c1c1c1;

    :hover,
    :focus,
    :active {
      background: #c1c1c1;
    }
  }
`

const TextStyled = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
`

type CustomButtonProps = {
  children: ReactNode
  style?: CSSProperties
  background?: string
  textProps?: {}
  iconImage?: string
  id?: string
  fontSize?: number
  fontColor?: string
} & Pick<
  ButtonProps,
  | 'block'
  | 'danger'
  | 'disabled'
  | 'ghost'
  | 'href'
  | 'htmlType'
  | 'icon'
  | 'loading'
  | 'shape'
  | 'size'
  | 'target'
  | 'type'
  | 'onClick'
>

const Button = (props: CustomButtonProps) => {
  const {
    id,
    children,
    style,
    onClick,
    disabled = false,
    textProps,
    iconImage,
    fontSize,
    fontColor,
    background = color.PRIMARY,
    ...resProps
  } = props

  return (
    <CustomStyledAntdButton
      disabled={disabled}
      onClick={onClick}
      background={background}
      style={style}
      id={id}
      {...resProps}
    >
      {iconImage ? <Image src={iconImage} style={{ marginRight: 8 }} /> : null}
      <TextStyled size={fontSize || 18} type="Bold" color={fontColor || color.WHITE} {...textProps}>
        {children}
      </TextStyled>
    </CustomStyledAntdButton>
  )
}

export default Button
