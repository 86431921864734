import UploadFile from '../../components/common/UploadFile'
import styled from '@emotion/styled'

const LayoutUploadFile = styled.div``

const ExampleUploadFile = () => {
  return (
    <LayoutUploadFile>
      <UploadFile
        icon={'idCardIcon'}
        title={'1. รูปถ่ายบัตรประจำตัวประชาชนของคุณ'}
        desc={'(jpg, png ขนาดไม่เกิน 1 MB)'}
      />
    </LayoutUploadFile>
  )
}

export default ExampleUploadFile
