import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import Text from '../../../components/common/Text'
import Image from '../../../components/common/Image'
import { Table, Select } from 'antd'
import type { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router'

const Layout = styled.div`
  > * {
    margin-top: 24px;
  }
  margin-bottom: 40px;
  position: relative;
`
const Header = styled.div`
  text-align: center;
`
const IconBack = styled(Image)`
  transform: rotate(180deg);
  position: absolute;
  left: 0;
  cursor: pointer;
`
const HeaderTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 16px;
`

const dataSource = [
  {
    key: '1',
    type: 'จำนวนเม็ดเลือดแดง',
    result: 4,
    standard_score: '4.0 5.2 10^6/mm^3',
  },
  {
    key: '2',
    type: 'รูปร่างเม็ดเลือดแดง',
    result: '-',
    standard_score: '',
  },
  {
    key: '3',
    type: 'ฮีโมโกลบิน',
    result: 12.5,
    standard_score: '12 - 16 g/dl',
  },
];

interface DataType {
  key: React.Key;
  type: string;
  result: string | number;
  standard_score: string;
}
const columns: ColumnsType<DataType> = [
  {
    title: 'ชนิดการตรวจ',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'ค่าผลตรวจ',
    dataIndex: 'result',
    key: 'result',
    render: (value: string | number) => {
      return <Text color='red'>{value}</Text>
    }
  },
  {
    title: 'ค่ามาตรฐาน',
    dataIndex: 'standard_score',
    key: 'standard_score',
  },
];

const Platelets = () => {

  const history = useHistory()

  const handleBack = useCallback(() => {
    history.goBack()
  }, [])

  const handleChange = useCallback((value) => {
    console.log(`selected ${value}`);
  }, [])

  return (
    <Layout>
      <Header>
        <IconBack imageName='iconNext' onClick={handleBack} />
        <Text>ประวัติสุขภาพ</Text>
      </Header>
      <Table
        dataSource={dataSource}
        columns={columns}
        title={() =>
          <HeaderTable>
            <Text>ปี</Text>
            <Select
              defaultValue="2020"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                {
                  value: '2020',
                  label: '2020',
                },
                {
                  value: '2021',
                  label: '2021',
                },
              ]}
            />
          </HeaderTable>
        }
      />
    </Layout>
  )
}

export default Platelets