import ComponentImageSlider from '../../../components/common/ImageSlider'
import { useGetShopBrandImageSlider } from '../../../services/shop/shop-query'

type ImageSliderProps = {
  brandId: string
}

const ImageSlider = (props: ImageSliderProps) => {
  const { brandId } = props
  const { data } = useGetShopBrandImageSlider(brandId)
  return <ComponentImageSlider autoplay images={data?.content} />
}

export default ImageSlider
