import { useMutation, MutationOptions, useQuery } from 'react-query'
import { api } from '../../utils/api'
import { UserLoginParam, UserTokenType, UserLoginParamAD, BuzzebeesLoginProps, BuzzebeesLoginResponse } from './auth-types'
import { setUser } from '../user/user-query'
import { setToken, setBuzzebeesUrlAccess } from '../../utils/helper'
import { ContentType } from '../../utils/api/tools'

export const AUTH_URL = '/api/auth'
export const LOGIN_URL = `${AUTH_URL}/login`
export const LOGIN_URL_AD = `/api/auth/mobile_msal/login`
export const LOGIN_TYPE = '/api/loginType'
export const BUZZEBEES_MARKETPLACE_URL = '/auth/login'

export const useLoginType = () => {
  return useQuery(LOGIN_TYPE, async () => {
    return await api.belive.get<number>(LOGIN_TYPE)
  })
}

export const useLoginAD = (
  option?: MutationOptions<UserTokenType, { message: string }, UserLoginParamAD>,
) => {
  const { onSuccess } = option || {}

  return useMutation(
    async (data: UserLoginParamAD) => {
      const LOGIN_URL_AD_SERVICE = `${LOGIN_URL_AD}?email=${data.username}&token=${data.tokenId}`
      return api.belive.post<UserTokenType>(LOGIN_URL_AD_SERVICE, {
        contentType: ContentType.JSON,
      })
    },
    {
      onSuccess: (data, variables, context) => {
        setToken(data.token.hash)
        setUser(data.user)
        onSuccess && onSuccess(data, variables, context)
      },
      ...option,
    },
  )
}

export const useLogin = (
  option?: MutationOptions<UserTokenType, { message: string }, UserLoginParam>,
) => {

  return useMutation(
    async (data: UserLoginParam) => {
      return api.belive.post<UserTokenType>(LOGIN_URL, { ...data, by_pass: true })
    },
    {
      ...option
    }
  )
}

export const useBuzzebeesLogin = () => {
  return useMutation(
    async (data: BuzzebeesLoginProps) => {
      return api.buzzebees.post<BuzzebeesLoginResponse>(BUZZEBEES_MARKETPLACE_URL, data, {
        contentType: ContentType.JSON,
        headers: {
          Authorization: "token .bpsIkEY2m867Ee2aAZOv35Mx-RofSrNvyy4MI1KXzJYm2yA61bsV5lnUo4Ddn6V76g7OMhdCZTEb8iVRL_TNWtWpMSMZqPUnwxEw7ArofAElyVCex6lleo9jqmivjorZgHTRVKEUDr-FSBvRwNuWv0JHcCbyfJWc2ee5CAup9FNjZV22VnPxH8GiQgoCRTmeqJE5R_LRI59FbbmECu224F"
        }
      })
    },
    {
      onSuccess(data, variables, context) {
        if (data?.data?.urlAccess)
          setBuzzebeesUrlAccess(data.data.urlAccess)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    }
  )
}