import { api } from '../../utils/api'
import {
  GetTipsProps,
  TipsType,
  TipsWithPaging,
  GetTipsDetailProps,
} from './well-being-types'
import {
  useQuery,
  useInfiniteQuery
} from 'react-query'

export const BASE_WELL_BEING_URL = '/api/well_being'

const ALL_HEALTH_TIPS_URL = `${BASE_WELL_BEING_URL}/all_health_tips`
const HEALTH_TIPS_URL = `${BASE_WELL_BEING_URL}/health_tip`


export const useGetHealth = (params: GetTipsProps) => {
  const { size } = params
  return useInfiniteQuery<TipsWithPaging>(
    [ALL_HEALTH_TIPS_URL],
    async ({ pageParam = 0 }) => {
      const response = await api.belive.get<Array<TipsType>>(ALL_HEALTH_TIPS_URL, {
        page: pageParam,
        size: size
      })
      const responseWithPaging: TipsWithPaging = {
        data: response,
        paging: {
          page: pageParam,
          nextPage: pageParam + 1,
        },
      }
      return responseWithPaging
    },
    {
      getNextPageParam: (lastGroup: TipsWithPaging) => {
        const { nextPage } = lastGroup.paging
        return lastGroup.data.length < size ? null : nextPage
      },
    },
  )
}


export const useGetTipsDetail = (props: GetTipsDetailProps) => {
  const { health_tip_id } = props
  return useQuery(
    [HEALTH_TIPS_URL, health_tip_id],
    async () => {
      const response = await api.belive.get<TipsType>(HEALTH_TIPS_URL, {
        health_tip_id,
      })
      return Promise.resolve(response)
    },
  )
}
