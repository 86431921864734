import styled from '@emotion/styled'
import Badge from '../../components/common/Badge'
import Avatar from '../../components/common/Avatar'
import image from '../../assets/images/base/test-avatar.png'
const Layout = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px;
`

const ExampleBadge = () => {
  return (
    <>
      <Layout>
        <Badge count={'1'} />
      </Layout>
      <Layout>
        <Badge count={'5'} />
      </Layout>
      <Layout>
        <Badge dot={true} />
      </Layout>
      <Layout>
        <Badge count={'4'}>
          <Avatar src={image} size={40} />
        </Badge>
      </Layout>
      <Layout>
        <Badge count={'4'} dot={true}>
          <Avatar src={image} size={40} />
        </Badge>
      </Layout>
    </>
  )
}
export default ExampleBadge
