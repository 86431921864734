import React from 'react'
import styled from '@emotion/styled'
import Breadcrumb from '../../components/common/Breadcrumb'
import { home, notification } from '../../constant/path'
import Text from '../../components/common/Text'
import List from './List'
import { useTranslation } from 'react-i18next'
import { mobile } from '../../utils/responsive-helper'

const Layout = styled.div`
  > * {
    margin-top: 24px;
  }
  margin-bottom: 40px;
  text-align: center;
`

const BreadcrumbStyled = styled<typeof Breadcrumb>(Breadcrumb)`
  ${mobile} {
    margin : 24px 16px;
  }
`

const Notification = () => {
  const { t } = useTranslation()
  const title = t('notification.title')
  return (
    <Layout>
      <BreadcrumbStyled
        overide={(paths) => {
          paths[0] = {
            label: 'Home',
            path: home(),
          }
          paths[1] = {
            label: title,
            path: notification(),
          }
          return paths
        }}
      />

      <Text className="title-font" size={28} type="Bold">
        {title}
      </Text>
      <List />
    </Layout>
  )
}

export default Notification
