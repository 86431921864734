import { Collapse } from 'antd'
import color from '../../constant/colors'
import Text from '../../components/common/Text'

import { CSSProperties, ReactNode } from 'react'
import styled from '@emotion/styled'

const CustomCollapse = styled(Collapse)`
  border-radius: 8px;
  border: none;
  background-color: ${color.WHITE};
  box-shadow: '0px 0px 6px 6px rgba(242, 222, 223, 0.4)';
  padding: 0;
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-collapse.ant-collapse-icon-position-left {
    padding: 0 !important;
  }

  .ant-collapse-item {
    padding: 0 !important;
    border-bottom: none;
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content {
    border-top: none;
  }
`

type CollapeProps = {
  children: ReactNode
  style?: CSSProperties
  onChange?: (key: string | string[]) => void
}

const CollapseWellbeing = (props: CollapeProps) => {
  const { children, onChange, style } = props
  return (
    <CustomCollapse
      expandIcon={() => null}
      style={style}
      onChange={onChange}>
      {children}
    </CustomCollapse>
  )
}
export default CollapseWellbeing
