import styled from '@emotion/styled'

const Layout = styled.div`
  overflow: auto;
`

const Promotion = () => {
  const hidePromotion = undefined
  return <Layout>{hidePromotion && <></>}</Layout>
}

export default Promotion
