import React from 'react'
import Text from '../../components/common/Text'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import Breadcrumb from '../../components/common/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { mobile, useScreen } from '../../utils/responsive-helper'

const BreadcrumbLayout = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  ${mobile} {
    margin : 24px 16px;
  }
`

const ContentLayout = styled.div`
  background-color: ${color.WHITE};
  margin-bottom: 10px;
  padding: 32px;
  border-radius: 8px;
`

const CenterLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  /* border: 1px solid #000000; */
  margin-bottom: 24px;
`

const WhiteLine = styled.div`
  height: 20px;
`

const About = () => {
  const { t } = useTranslation()
  const { isMobile } = useScreen()

  return (
    <>
      <BreadcrumbLayout>
        <Breadcrumb
          overide={(paths) => {
            paths = [
              { label: 'Home', path: '/' },
              { label: t('about.header'), path: '/about' },
            ]
            return paths
          }}
        />
      </BreadcrumbLayout>
      <CenterLayout>
        <Text size={28} type="Bold">
          {t('about.header')}
        </Text>
      </CenterLayout>
      <ContentLayout>
        <div style={{ display: 'flex' }}>
          <Text
            size={20}
            type="Bold"
            color={color.FONT_BLACK}
            style={{ marginRight: 4, minWidth: 30 }}
          >
            {t('about.content_1B')}
          </Text>
          <Text size={20} type="Medium" color={color.FONT_BLACK}>
            {t('about.content_1')}
          </Text>
        </div>
        <div style={{ display: 'flex' }}>
          <Text size={20} type="Bold" color={color.FONT_BLACK} style={{ marginRight: 4 }}>
            {t('about.content_2B')}
          </Text>
          <Text size={20} type="Medium" color={color.FONT_BLACK}>
            {t('about.content_2')}
          </Text>
        </div>

        <div style={{ display: isMobile ? 'initial' : 'flex' }}>
          <Text size={20} type="Bold" color={color.FONT_BLACK} style={{ marginRight: 4 }}>
            {t('about.content_3B')}
          </Text>
          <Text size={20} type="Medium" color={color.FONT_BLACK}>
            {t('about.content_3')}
          </Text>
        </div>

        <WhiteLine />
        <Text size={20} type="Medium" color={color.FONT_BLACK}>
          {t('about.content_4')}
        </Text>
      </ContentLayout>
    </>
  )
}

export default About
