import styled from '@emotion/styled'
import { Upload } from 'antd'
import Form from 'antd/lib/form/Form'
import { RcFile, UploadChangeParam } from 'antd/lib/upload'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Avatar from '../../components/common/Avatar'
import Button from '../../components/common/Button'
import OutlineButton from '../../components/common/OutlineButton'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { mobile, useScreen } from '../../utils/responsive-helper'

const FormLayout = styled(Form)`
  width: 752px;
  height: fit-content;
  padding: 48px;

  ${mobile} {
    width: calc(100vw - 32px);
    padding: 16px;
    .header {
      padding-right: 32px;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .phoneNumber {
    max-width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .email {
    max-width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .address {
    flex: 100%;
    margin-bottom: 48px;
  }

  .phoneNumber,
  .email,
  .address {
    align-items: baseline;
    > div {
      :nth-of-type(1) {
        white-space: nowrap;
      }
    }
  }
  ${mobile} {
    flex-flow: column;
    justify-content: flex-start;

    .phoneNumber,
    .email,
    .address {
      width: unset;
      max-width: unset;
      margin-bottom: 16px;
    }

    .address {
      margin-bottom: 24px;
    }
  }
`

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  ${mobile} {
    flex-direction: column;
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const FlexRowContent = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const CustomeStyleAvatar = styled(Avatar)`
  margin: 0 auto;
  ${mobile} {
    margin: auto 0;
  }
`

type UploadProfileFormProps = { onSubmit: (v: string) => void, show: () => void, previewImg?: string | undefined }

const UploadProfileForm = (props: UploadProfileFormProps) => {
  const { previewImg, onSubmit, show } = props
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const [selectedFile, setSelectedFile] = useState<any>('')
  const [preview, setPreview] = useState('')
  const [selectedFileName, setSelectedFileName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isFile, setIsFile] = useState(false)
  const maximumFileSize = 2

  useEffect(() => {
    if (!selectedFile) {
      setPreview('')
      return
    }

    setPreview(selectedFile)

    if (!selectedFile.includes('data:image') && selectedFileName === '' && selectedFile.substr(0, 8) !== 'https://') {
      setSelectedFileName(`file: ${selectedFile.split('/')[1].split(';')[0]}`)
    }

    if (['pdf'].includes(selectedFile.split('.').pop())) {
      setIsFile(true)
    }
  }, [selectedFile, selectedFileName])

  const getBase64 = useCallback(async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])


  const onSelectedFile = useCallback(
    async (params: UploadChangeParam) => {
      const { file: image } = params
      const isLarge = image.size / 1024 / 1024 > maximumFileSize
      const isImage = image.type.includes("image")
      console.log(image);



      if (!isLarge) {
        if (!isImage) {
          setErrorMessage('ใช้ได้แค่ไฟล์รูปภาพ')
        } else {
          const imageBase64 = (await getBase64(image)) as string
          setSelectedFile(imageBase64)

          if (!image.type.includes('image')) {
            setSelectedFileName(image?.name)
          }
        }
      }
    },
    [getBase64, maximumFileSize]
  )

  const validateUploadFile = useCallback(
    (image: RcFile) => {
      const isLarge = image.size / 1024 / 1024 > maximumFileSize
      if (isLarge) {
        show()
        setPreview("")
        setSelectedFile('')
        setErrorMessage('ขนาด ภาพ/ไฟล์ ใหญ่เกินไป')
      } else {
        setErrorMessage('')
      }
    },
    [maximumFileSize]
  )

  const beforeUpload = useCallback(
    (image: RcFile) => {
      validateUploadFile(image)
      return false
    },
    [validateUploadFile],
  )

  const onRemoveClick = useCallback(
    (e) => {
      e.preventDefault()
      setPreview('')
      setSelectedFile('')
      setSelectedFileName('')
    },
    [],
  )

  return (
    <FormLayout>
      <Text className="header" size={30} type="Bold" color={color.FONT_BLACK}>
        แก้ไขรูปโปรไฟล์
      </Text>
      <Content>
        <FlexRowContent className="previewProfile">
          <CustomeStyleAvatar
            src={preview || previewImg}
            isBorder={true}
            size={!isMobile ? 250 : 230}
            borderWidth={isMobile ? 12 : 7}
          />
        </FlexRowContent>

        <FlexRowContent className="uploadProfile">
          {!selectedFile ?
            <Upload
              action=""
              listType="text"
              accept=".jpg,.png"
              onChange={onSelectedFile}
              style={{ width: '100% ' }}
              beforeUpload={beforeUpload}
              showUploadList={false}
              disabled={false}
            >
              <OutlineButton disabled={false}>+ เพิ่มรูปโปรไฟล์</OutlineButton>
            </Upload>
            :
            <div>
              <OutlineButton
                disabled={false}
                onClick={onRemoveClick}
              >
                - ลบรูปโปรไฟล์
              </OutlineButton>
            </div>
          }
        </FlexRowContent>

        <FlexRowContent className="errorMessage">
          <Text>{errorMessage}</Text>
        </FlexRowContent>
      </Content>
      <FlexRow>
        <Button onClick={() => onSubmit(selectedFile)}>{t('setting.submitButton')}</Button>
      </FlexRow>
    </FormLayout >
  )
}

export default UploadProfileForm

