import styled from '@emotion/styled'
import Breadcrumb from '../../components/common/Breadcrumb'
import Text from '../../components/common/Text'
import { useGetEClaimById } from '../../services/e-claim/e-claim-query'
import { useRouter } from '../../utils/helper'
import EClaimForm from './EClaimForm'
import EClaimInfo from './EClaimInfo'

import { useMemo } from 'react'
import { useHistory } from 'react-router'
import Button from '../../components/common/Button'
import Card from '../../components/common/Card'
import Loading from '../../components/common/Loading'
import color from '../../constant/colors'
import { eClaim, eClaimDetail, home, relative } from '../../constant/path'
import { mobile, useScreen } from '../../utils/responsive-helper'

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  z-index: 1;
  
  ${mobile} {
    margin: 24px 16px;
  }
`
const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  width: 100%;
  min-height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-height: 266px;
  margin: 22px auto 0 auto;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`

const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding :16px;
  box-shadow: none;
`

const ConditionInfoContainer = styled.div`
  padding: 0px 16px;
  z-index: 1;
`

const ButtonStyled = styled(Button)`
  border-color: ${color.PRIMARY};
  margin-left: auto;
  max-width: 180px;
  height: 33px;
  border: 1px solid #034780;

  ${mobile} {
    max-width: 160px;
    margin :auto;
  }
`

const EClaimDetail = () => {
  const { query } = useRouter<{ eClaimId: number }>()
  const { isMobile } = useScreen()
  const { data: eClaimItem, isLoading } = useGetEClaimById({ claimId: query.eClaimId })
  const initialValue = useMemo(() => { return { relativeTypeId: 0, } }, [])
  const history = useHistory()

  return (
    <Loading loading={isLoading}>
      <Layout>
        <BackgroundHeader />
        <FlexRow>
          <BreadcrumbContainer>
            <Breadcrumb
              overide={(paths) => {
                paths[0] = {
                  label: 'Home',
                  path: home(),
                }
                paths[1] = {
                  label: 'D-Claims',
                  path: eClaim(),
                }
                paths[2] = {
                  label: `${eClaimItem?.claimName}`,
                  path: eClaimDetail(),
                }
                return paths
              }}
            />
          </BreadcrumbContainer>

          <EClaimInfo eClaimItem={eClaimItem} />
          <CustomStyleCard>
            {eClaimItem?.claimTypeGroupId === 3 &&
              // <FlexRow style={{ justifyContent: 'center' }}>
              <ButtonStyled
                background={color.WHITE}
                textProps={{ color: color.PRIMARY }}
                onClick={() => history.push(relative())}
              >
                {/* <FlexRow> */}
                <Text size={14} type='Bold' color={'#60607D'}>
                  เพิ่ม/แก้ไขรายชื่อครอบครัว
                </Text>
                {/* </FlexRow> */}
              </ButtonStyled>
              // </FlexRow>
            }
            <ConditionInfoContainer>
              <Text style={{ zIndex: 1 }}>
                <Text type="Bold" size={20} style={{ zIndex: 1 }}>เงื่อนไขการใช้สวัสดิการนี้: </Text>
                <br />
                <div dangerouslySetInnerHTML={{ __html: eClaimItem?.claimDetail || '' }}></div>
              </Text>
            </ConditionInfoContainer>

            <EClaimForm
              eClaimItem={eClaimItem}
              initialValues={initialValue}
              allpayStatus={false} />
          </CustomStyleCard>

        </FlexRow>
      </Layout>
    </Loading>
  )
}

export default EClaimDetail
