import React from 'react'

import Button from '../../components/common/Button'
import Image from '../../components/common/Image'
import SuccessImage from '../../assets/images/base/infographic-reward-success.svg'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

const MainLayout = styled.div`
  width: 100%;
`
const SubLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
`
type SuccessPageProps = {
  onSuccessOkClick: () => void
}
const SuccessPage = (props: SuccessPageProps) => {
  const { onSuccessOkClick } = props
  const { t } = useTranslation()

  return (
    <MainLayout>
      <SubLayout>
        <Image src={SuccessImage} />
      </SubLayout>
      <SubLayout>
        <Button onClick={onSuccessOkClick}>{t('shopRewardClaim.confirm')}</Button>
      </SubLayout>
    </MainLayout>
  )
}

export default SuccessPage
