import { aboutTextEn, aboutTextTh } from './about-us'
import { enrollment, healthCheckup } from './modalPrivacy'
import {
  changeTermTextEn,
  changeTermTextTh,
  lawTextEn,
  lawTextTh,
  subHeaderEn,
  subHeaderTh,
  webAccessTextEn,
  webAccessTextTh,
} from './term-and-condition'

export const languages = [
  {
    label: 'ภาษาไทย',
    value: 'th',
  },
  {
    label: 'English',
    value: 'en',
  },
]

export const getTranslation = (key: 'en' | 'th') => {
  const text = (en: string, th: string): string => ({ en, th }[key])
  return {
    [key]: {
      translation: {
        app: {
          point: text('Point', 'คะแนน'),
          coin: text('Coin', 'เหรียญ'),
          baht: text('Baht', 'บาท'),
          sync: text('', 'ซิงค์ล่าสุด'),
          remain: text('REMAIN', 'คงเหลือ'),
          coinRemain: text('Coin remain', 'เหรียญคงเหลือ'),
          coinCanUse: text('Coins you earn from changing plans will be displayed and can be used in', 'เหรียญที่คุณได้จากการเปลี่ยนแผนจะโชว์ยอดและสามารถใช้ได้ในปี'),
          payAdditional: text('Pay Additional', 'มีค่าใช้จ่ายเพิ่มเติม'),
          continue: text('Continue', 'ดำเนินการต่อ'),
          photoUnit: text('photo', 'ภาพ'),
          ConvertedToMoney: text('Valued', 'คิดเป็นเงิน'),
          upload: {
            photo: text('Upload photo', 'อัพโหลดรูปภาพ'),
            gallery: text('Upload from Gallery', 'อัปโหลดจากคลังภาพ'),
            success: text('คุณได้ Upload รูปสำเร็จและได้รับเหรียญเรียบร้อยแล้ว', 'คุณได้ Upload รูปสำเร็จและได้รับเหรียญเรียบร้อยแล้ว'),
            successFullSlot: text('คุณได้ Upload รูปสำเร็จเรียบร้อยแล้ว', 'คุณได้ Upload รูปสำเร็จเรียบร้อยแล้ว'),
          },
          menubar: {
            home: text('Home', 'Home'),
            newsAndPlay: text('News And Play', 'ข่าวสารและกิจกรรม'),
            news: text('News', 'News'),
            play: text('Play', 'Play'),
            application: text('Application', 'แอปพลิเคชั่น'),
            wellBeing: text('Well-Being', 'Well-Being'),
            insurance: text('Insurance', 'Insurance'),
            healthCheckup: text('Health Checkup', 'Health Checkup'),
            claims: text('D-Claims', 'D-Claims'),
            setting: text('Setting', 'ตั้งค่า'),
            notification: text('Notification', 'แจ้งเตือน'),
            marketplace: text('Marketplace', 'Marketplace'),
          },
          navbar: {
            profile: text('Profile', 'Profile'),
            app: text('App', 'App'),
            termConditions: text('Terms and Conditions', 'Terms and Conditions'),
            about: text('About Belive', 'เกี่ยวกับ Belive'),
            contact: text('Contact us', 'ติดต่อเรา'),
            language: text('Language', 'ภาษา'),
            version: text('Version', 'เวอร์ชัน'),
            logout: text('Log Out', 'ออกจากระบบ'),
          },
          breadcrumb: {
            insurance: text('Benefit adjustment', 'ปรับเปลี่ยนสวัสดิการ'),
            seeInsurance: text('ดูสวัสดิการ', 'ดูสวัสดิการ'),
            myInsurance: text('Your insurance plan', 'ประกันของคุณ'),
            myWelFare: text('Your benefits', 'สวัสดิการของคุณ'),
            changeInsurance: text('Change insurance plan', 'ปรับเปลี่ยนแผนประกัน'),
            myHealthCheckup: text('แผนตรวจสุขภาพของคุณ', 'แผนตรวจสุขภาพของคุณ'),
            changeHealthCheckup: text('Select Hospitals', 'เลือกโรงพยาบาล​')
          },
          coinNow: text('You have', 'ปัจจุบันคุณมีเหรียญ'),
          expiredOn: text('Expired on', 'หมดอายุในวันที่'),
          coinsAvailable: text('Coins available for current year', 'เหรียญที่มีอยู่ปีปัจจุบัน'),
        },
        profileCard: {
          availableCoin: text('Available coin', 'เหรียญที่มีอยู่'),
          titleJob: text('Position', 'ตำแหน่งงาน'),
          noJob: text('No job', 'ไม่มีอาชีพ'),
          coinHistory: text('Coins usage history', 'ประวัติการใช้เหรียญ'),
        },
        home: {
          subtitle: text('May today be a good day for you.', 'ขอให้วันนี้เป็นวันที่ดีของคุณ'),
          module: text('Module', 'โมดูล'),
          activity: text('Activity News', 'ข่าวสารกิจกรรม'),
          category: {
            myApp: {
              title: text('Module', 'โมดูล'),
              insurance: {
                title: text('Insurance', 'Insurance'),
                description: text('Your Health Insurance Plan', 'ประกันสุขภาพ'),
              },
              eclaim: {
                title: text('D-Claims', 'D-Claims'),
                description: text('เบิกค่ารักษาพยาบาล', 'เบิกค่ารักษาพยาบาล'),
              },
              marketplace: {
                title: text('Marketplace', 'ร้านค้า'),
                description: text('Online Shopping', 'ช้อปปิ้งออนไลน์'),
              },
              play: {
                title: text('Play', 'Play'),
                description: text('Earn coins by joining activities.', 'เข้าร่วมกิจกรรม'),
              },
              healthCheckup: {
                title: text('Health Checkup', 'Health Checkup'),
                description: text('Annual Health Check Up', 'ตรวจสุขภาพประจำปี'),
              },
              news: {
                title: text('News', 'News'),
                description: text('Company News & Updates', 'ข่าวสารภายในองค์กร'),
              },
              wellBeing: {
                title: text('Well-being', 'Well-being'),
                description: text('Take care of your health', 'ดูแลสุขภาพของคุณ'),
              }
            },
          },
        },
        play: {
          title: text('Hi', 'สวัสดี'),
          subtitle: text('Activities that support you to live the way you like.', 'กิจกรรมที่สนับสนุนให้คุณได้ใช้ชีวิตในแบบที่ชอบ'),
          events: text('Events', 'กิจกรรม'),
          myevent: text('My Events', 'กิจกรรมที่เข้าร่วมแล้ว'),
          rewardRemain: text('Reward remain', 'รางวัลคงเหลือ'),
          cardList: {
            button: text('Activity details', 'รายละเอียดกิจกรรม')
          },
          upload: {
            gallery: text('Upload photo for activity', 'Upload ภาพเพื่อเข้าร่วมกิจกรรม'),
          },
          joinEvent: text('Join Event', 'เข้าร่วมกิจกรรม'),
          eventFull: text('Sorry, complementary coin is fully redeemed.', 'ขออภัย จำนวนผู้เข้าร่วมกิจกรรมและได้เหรียญครบกำหนดแล้ว'),
          eventExpire: text('Sorry, activity has expired.', 'ขออภัย กิจกรรมหมดอายุ'),
          eventDetail: text('Activity details', 'รายละเอียดกิจกรรม'),
          modalConfirmJoin: {
            title: text('ยืนยันการเข้าร่วมกิจกรรม', 'ยืนยันการเข้าร่วมกิจกรรม'),
            subtitle: text('การเข้าร่วมกิจกรรมจะต้องมีการ Upload รูปร่วมด้วย คุณยืนยันที่จะเข้าร่วมหรือไม่', 'การเข้าร่วมกิจกรรมจะต้องมีการ Upload รูปร่วมด้วย คุณยืนยันที่จะเข้าร่วมหรือไม่'),
            submit: text('Submit', 'ยืนยัน'),
            cancel: text('Cancel', 'ยกเลิก'),
            success: text('คุณได้เข้าร่วมกิจกรรมเรียบร้อยแล้ว กรุณา upload รูป', 'คุณได้เข้าร่วมกิจกรรมเรียบร้อยแล้ว กรุณา upload รูป'),
          }
        },
        footer: {
          link: {
            contactUs: text('Contact us', 'ติดต่อเรา'),
            privacyAndTerms: text('Privacy and terms', 'ข้อกำหนดและเงื่อนไข'),
          },
        },
        login: {
          input: {
            username: {
              label: text('Email', 'อีเมล'),
              placeholder: text('Please enter email address', 'ระบุอีเมล'),
            },
            password: {
              label: text('Password', 'รหัสผ่าน'),
              placeholder: text('Please enter password', 'ระบุรหัสผ่าน'),
            },
          },
          button: {
            submit: text('Login', 'เข้าสู่ระบบ'),
            submitDentsu: text('Login with dentsu account', 'เข้าสู่ระบบ ด้วย dentsu account'),
          },
          content: {
            flexBenefits: text('Flex Benefits', 'Flex Benefits'),
            content1: text(
              'ปรับเปลี่ยนสวัสดิการได้อย่างอิสระ',
              'ปรับเปลี่ยนสวัสดิการได้อย่างอิสระ',
            ),
            content2: text('สามารถทำได้เหมือนกับ Application', 'สามารถทำได้เหมือนกับ Application'),
            content3: text('เข้าสู่ระบบเลย!', 'เข้าสู่ระบบเลย!'),
          },
          formError: {
            username: text('Please input username', 'ระบุชื่อผู้ใช้'),
            password: text('Please input password', 'ระบุรหัสผ่าน'),
          },
        },
        news: {
          header: {
            news: text("News", "ข่าวสาร"),
            event: text("Event", "กิจกรรม"),
          },
          recommend: text('Recommend', 'แนะนำ'),
          more: text('Load More', 'เพิ่มเติม'),
        },
        newsDetail: {
          readButton: text('Accept', 'อ่านแล้ว'),
          modalText: text(
            'ยินดีด้วย คุณได้รับเหรียญแล้ว',
            'ยินดีด้วย คุณได้รับเหรียญแล้ว',
          ),
          modalTextNoCoin: text('คุณได้อ่านข่าวแล้ว', 'คุณได้อ่านข่าวแล้ว'),
          modalTextAnswer: text('เราได้เก็บรวบรวมคำตอบของคุณไว้แล้ว', 'เราได้เก็บรวบรวมคำตอบของคุณไว้แล้ว'),
          modalButtonText: text('Confirm', 'ตกลง'),
          modalTextFaildQuiz: text('กรุณาเลือกคำตอบทุกข้อ ก่อนส่งคำตอบ', 'กรุณาเลือกคำตอบทุกข้อ ก่อนส่งคำตอบ'),
          modalTextFaildQuizAlreadyAnswer: text('คุณได้ทำการส่งคำตอบข้อนี้ไปแล้ว', 'คุณได้ทำการส่งคำตอบข้อนี้ไปแล้ว'),
          modalTextFaildVote: text('ไม่สามารถโหวตได้', 'ไม่สามารถโหวตได้'),
          modalTextFaildQuizAnswer: text('เสียใจด้วย คุณไม่ได้รับเหรียญ', 'เสียใจด้วย คุณไม่ได้รับเหรียญ'),
          modalTextFaildVoteAnswer: text('เสียใจด้วย คุณไม่ได้รับเหรียญ', 'เสียใจด้วย คุณไม่ได้รับเหรียญ'),
          modalTextFaildVoteSecond: text('กรุณาเลือก Option สำหรับโหวต', 'กรุณาเลือก Option สำหรับโหวต'),
          sentAnswer: text('ส่งคำตอบ', 'ส่งคำตอบ'),
          vote: text('Vote', 'โหวต'),
          register: text('Register', 'ลงทะเบียน'),
          join: text('Join', 'เข้าร่วม'),
        },
        error: {
          required: text('Required', 'จำเป็นต้องกรอก'),
          numberic: text('Numberic', 'ข้อมูลต้องเป็นตัวเลขเท่านั้น'),
          moreMaxValue: text('More max value', 'จำนวนเหรียญมากกว่าที่สามารถจะใช้ได้'),
          amountMoreThanAmountClaim: text('Amount more than amount claim', 'จำนวนเงินที่เบิกมากว่าจำนวนเงินในใบเสร็จ'),
          zero: text('Must more 0', 'จำนวนเหรียญต้องมากว่า 0 '),
        },
        setting: {
          header: text('Personal Information', 'ข้อมูลส่วนตัว'),
          contactHeader: text('Contact Information', 'ข้อมูลการติดต่อ'),
          email: text('Email', 'อีเมล'),
          address: text('Address', 'ที่อยู่'),
          phoneNumber: text('Tel', 'เบอร์โทรศัพท์'),
          editButton: text('Edit Profile', 'แก้ไขข้อมูลการติดต่อ'),
          editRelative: text('Add/Edit Family List', 'เพิ่ม/แก้ไข รายชื่อครอบครัว'),
          familyWelfareHeader: text('Family Information', 'ข้อมูลครอบครัวที่มีสิทธิ์ใช้สวัสดิการ'),
          familyWelfareNote: text(
            'หากต้องการเพิ่ม / แก้ไขรายชื่อคนในครอบครัว กรุณาติดต่อกับ HR ของบริษัท',
            'หากต้องการเพิ่ม / แก้ไขรายชื่อคนในครอบครัว กรุณาติดต่อกับ HR ของบริษัท',
          ),
          citizenId: text('Citizen Id', 'บัตรประชาชน'),
          age: text('Age', 'อายุ'),
          year: text('year', 'ปี'),
          editContactHeader: text('Edit Contact Information', 'แก้ไขข้อมูลการติดต่อ'),
          contactAddress: {
            country: text('country', 'ประเทศ'),
            province: text('province', 'จังหวัด'),
            district: text('district', 'เขต/อำเภอ'),
            subdistrict: text('subdistrict', 'แขวง/ตำบล'),
            postcode: text('postcode', 'ไปรษณีย์'),
          },
          confirmbutton: text('Confirm', 'ยืนยัน'),
          submitButton: text('Save', 'บันทึก'),
          changeLang: text('Change Language', 'เปลี่ยนภาษา'),
          version: text('Version', 'เวอร์ชั่น'),
        },
        shop: {
          redeem: text('Redeem', 'ใช้เหรียญเพื่อแลก'),
          expired: text('Expired', 'หมดเขตวันที่'),
          seeMore: text('See More', 'เพิ่มเติม'),
        },
        termAndCondition: {
          header: text('Terms and Conditions', 'ข้อกำหนดและเงื่อนไข'),
          subHeader: text(subHeaderEn, subHeaderTh),
          webAccessHeader: text('1. Access to Our Website', '1. การเข้าใช้งานเว็บไซต์ของเรา'),
          webAccessText: text(webAccessTextEn, webAccessTextTh),
          changeTermHeader: text('2. Change to Terms', '2. การเปลี่ยนแปลงข้อกำหนด'),
          changeTermText: text(changeTermTextEn, changeTermTextTh),
          lawHeader: text('3. Governing Laws', '3. กฎหมายที่ใช้บังคับ'),
          lawText: text(lawTextEn, lawTextTh),
        },
        about: {
          header: text('About Belive', 'เกี่ยวกับ Belive'),
          content_1B: text(`BE`, `BE`),
          content_1: text(
            ` The Power to choose and take action by self`,
            ` The Power to choose and take action by self`,
          ),
          content_2B: text(`LIVE `, `LIVE `),
          content_2: text(` Our promise to people`, ` Our promise to people`),
          content_3B: text(`BENEFIT OF LIVING`, `BENEFIT OF LIVING`),
          content_3: text(
            ` Believe in the pursuit of lifestyle for life fulfillment`,
            ` Believe in the pursuit of lifestyle for life fulfillment`,
          ),
          content_4: text(aboutTextEn, aboutTextTh),
        },
        contact: {
          header: text('Contact Us', 'ติดต่อเรา'),
          phoneLabel: text('Tel', 'เบอร์โทรศัพท์'),
          emailLabel: text('Email', 'อีเมล'),
          addressLabel: text('Address', 'ที่อยู่'),
        },
        comment: {
          header: text('COMMENT', 'COMMENT'),
          placeholder: text('Comment...', 'ความคิดเห็น...'),
          placeholderFull: text('Your Comment ...', 'แสดงความคิดเห็น...'),
          submitButton: text('Add Comment', 'แสดงความคิดเห็น'),
          commentCount: text('Comments', 'ความคิดเห็น'),
          commentEmpty: text('No Comment', 'ไม่มีการแสดงความคิดเห็น'),
          loading: text('Loading Comment...', 'กำลังโหลดคอมเมนต์...'),
        },
        appShortcut: {
          pinToHome: text('เพิ่มไปยังหน้าแรก', 'เพิ่มไปยังหน้าแรก'),
          pinToHomeShort: text('หน้าแรก', 'หน้าแรก'),
          unPinToHome: text('เพิ่มไปยังหน้าแรกแล้ว', 'เพิ่มไปยังหน้าแรกแล้ว'),
          unPinToHomeShort: text('เพิ่มแล้ว', 'เพิ่มแล้ว'),
          pinFaildShort: text('ไม่สามารถเพิ่มได้', 'ไม่สามารถเพิ่มได้'),
          pinShortCut: text('App Shortcut', 'App Shortcut'),
          confirm: text('ยืนยัน', 'ยืนยัน'),
          ok: text('ตกลง', 'ตกลง'),
          pinModal: {
            title: text('เพิ่มไปยังหน้าแรก', 'เพิ่มไปยังหน้าแรก'),
            messageStart: text('ต้องการเพิ่ม App ', 'ต้องการเพิ่ม App '),
            messageEnd: text(' ไปยังหน้าแรก', ' ไปยังหน้าแรก'),
          },
          unPinModal: {
            title: text('นำออกจากหน้าแรก', 'นำออกจากหน้าแรก'),
            messageStart: text('ต้องการนำ App ', 'ต้องการนำ App '),
            messageEnd: text(' ออกจากหน้าแรก', ' ออกจากหน้าแรก'),
          },
        },
        shopRewardClaim: {
          header: text('ยืนยันการหักเหรียญ / ชำระส่วนต่าง', 'ยืนยันการหักเหรียญ / ชำระส่วนต่าง'),
          successHeader1: text('คุณได้ทำรายการ', 'คุณได้ทำรายการ'),
          successHeader2: text('หักเหรียญสำเร็จแล้ว', 'หักเหรียญสำเร็จแล้ว'),
          currentUserPoint: text('เหรียญที่มีอยู่', 'เหรียญที่มีอยู่'),
          pointOfProduct: text('หักเหรียญที่มีอยู่', 'หักเหรียญที่มีอยู่'),
          pointLeft: text('เหรียญคงเหลือ', 'เหรียญคงเหลือ'),
          claimButtonText: text('Redeem with Belive Coin', 'ใช้เหรียญเพื่อแลก'),
          confirm: text('Confirm', 'ตกลง'),
        },
        pointHistory: {
          seeMore: text('See More', 'เพิ่มเติม'),
          title: text('Transaction History', 'ประวัติการใช้เหรียญ'),
          coin: text('เหรียญ', 'เหรียญ'),
          dealDate: text('แลกเมื่อ', 'แลกเมื่อ'),
        },
        notification: {
          seeMore: text('See More', 'เพิ่มเติม'),
          title: text('Notification', 'การแจ้งเตือน'),
        },
        enrollment: {
          insurance: text('Insurance Plan', 'แผนประกัน'),
          myInsurance: text('Insurance', 'ประกันของตัวเอง'),
          titleInsurance: text('You can adjust your insurance plan that suit your lifestyle from', 'ท่านสามารถปรับเปลี่ยนแผนประกันที่เหมาะสมกับไลฟ์สไตล์ของคุณได้ตั้งแต่วันที่'),
          coinOFYear: text('Annual coin', 'เหรียญประจำปี'),
          changeAnnualPlan: text('You get it from changing plans', 'ที่คุณได้จากการเปลี่ยนแผน'),
          changeAnnualPlanYear: text('You get it from changing plans in', 'ที่คุณได้จากการเปลี่ยนแผนปี'),
          insuranceList: text('List of Adjustable Benefits', 'สวัสดิการบริษัทที่สามารถปรับเปลี่ยนได้'),
          nextButton: text('Next', 'ถัดไป'),
          selfBenefits: {
            title: text('Your insurance plan', 'ประกันของคุณ'),
            insuranceSelected: text('Your selected insurance plan', 'แผนประกันที่คุณเลือก'),
            selected: text('ที่คุณเลือก', 'ที่คุณเลือก'),
            changeInsurance: text('Change your insurance plan', 'เปลี่ยนแผนใหม่'),
            addInsurance: text('Choose your own insurance plan', 'เลือกแผนประกันตนเอง'),
            modalInsuranceDetail: {
              detail: text(`พนักงานสามารถตรวจสอบรายละเอียดกรรมธรรม์ของแผนประกันสำหรับตนเองได้ตามไฟล์ PDF ด้านล่าง`,
                `พนักงานสามารถตรวจสอบรายละเอียดกรรมธรรม์ของแผนประกันสำหรับตนเองได้ตามไฟล์ PDF ด้านล่าง`),
              title: text('แผนประกันสุขภาพและอุบัติเหตุประจำปี', 'แผนประกันสุขภาพและอุบัติเหตุประจำปี'),
              notShowAgain: text('ไม่ต้องแสดงอีก', 'ไม่ต้องแสดงอีก'),
            },
          },
          editSelfBenefits: {
            header: text('Select your insurance plan', 'เลือกแผนประกันของตนเอง'),
            select: text('Select insurance plan', 'เลือกแผนประกัน'),
            notification: {
              error: {
                noChange: text('ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกแผนประกัน', 'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกแผนประกัน'),
                detail: text('ไม่สามารถบันทึกข้อมูลได้', 'ไม่สามารถบันทึกข้อมูลได้'),
              }
            },
            summary: {
              selected: text('แผนประกันใหม่ที่คุณเลือก', 'แผนประกันใหม่ที่คุณเลือก'),
              title: text('Summary of coverage options', 'สรุปการเลือกความคุ้มครอง'),
              totalCoin: text('total used coins', 'รวมใช้เหรียญ'),
              payAdditional: text('ค่าใช้จ่ายส่วนต่างที่พนักงานต้องชำระเพิ่ม', 'ค่าใช้จ่ายส่วนต่างที่พนักงานต้องชำระเพิ่ม'),
              coinAdditonal: text('เหรียญที่ได้รับจากการเปลี่ยนแผน', 'เหรียญที่ได้รับจากการเปลี่ยนแผน')
            }
          },
          modalAcceptPrivacy: {
            header: text('Letter of Consent', 'หนังสือยินยอม'),
            accept: text('I confirm that I select this insurance plan with my consideration.', 'คุณยืนยันเลือกแบบแผนประกันนี้ด้วยตนเอง'),
            confirm: text('Confirm', 'ตกลง'),
            modalOne: {
              title: text(enrollment.modalPrivacyOne.en.title, enrollment.modalPrivacyOne.th.title),
              one: text(enrollment.modalPrivacyOne.en.one, enrollment.modalPrivacyOne.th.one),
              two: text(enrollment.modalPrivacyOne.en.two, enrollment.modalPrivacyOne.th.two),
              three: text(enrollment.modalPrivacyOne.en.three, enrollment.modalPrivacyOne.th.three),
              four: text(enrollment.modalPrivacyOne.en.four, enrollment.modalPrivacyOne.th.four),
              five: text(enrollment.modalPrivacyOne.en.five, enrollment.modalPrivacyOne.th.five),
              six: text(enrollment.modalPrivacyOne.en.six, enrollment.modalPrivacyOne.th.six),
              seven: text(enrollment.modalPrivacyOne.en.seven, enrollment.modalPrivacyOne.th.seven),
              footer: text(enrollment.modalPrivacyOne.en.footer, enrollment.modalPrivacyOne.th.footer),
            },
            modalTwo: {
              content: text(enrollment.modalPrivacyTwo.en, enrollment.modalPrivacyTwo.th),
            }
          },
          condition: {
            header: text('เงื่อนไขและข้อกำหนด', 'เงื่อนไขและข้อกำหนด'),
            list1: text("พนักงานที่มีสิทธิ์เลือกแผนประกันที่มีความคุ้มครองที่สูงขึ้นกว่าแผนประกันตามสิทธิ์ที่แสดงในระบบ คือ พนักงานที่ผ่านทดลองก่อนวันที่ 1 มกราคม 2567", "พนักงานที่มีสิทธิ์เลือกแผนประกันที่มีความคุ้มครองที่สูงขึ้นกว่าแผนประกันตามสิทธิ์ที่แสดงในระบบ คือ พนักงานที่ผ่านทดลองก่อนวันที่ 1 มกราคม 2567"),
            list2: text("กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2567 ทางบริษัทฯ จะเลือกแผนประกันตามสิทธิ์ (default) ให้โดยอัตโนมัติเมื่อท่านผ่านทดลองงาน", "กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2567 ทางบริษัทฯ จะเลือกแผนประกันตามสิทธิ์ (default) ให้โดยอัตโนมัติเมื่อท่านผ่านทดลองงาน"),
            list3: text("พนักงานที่ประสงค์จะเลือกแผนความคุ้มครองที่สูงขึ้น จะต้องรับผิดชอบค่าใช้จ่ายส่วนต่าง โดยจะถูกเรียกเก็บผ่าน payroll เดือนมีนาคม 2567 ", "พนักงานที่ประสงค์จะเลือกแผนความคุ้มครองที่สูงขึ้น จะต้องรับผิดชอบค่าใช้จ่ายส่วนต่าง โดยจะถูกเรียกเก็บผ่าน payroll เดือนมีนาคม 2567 "),
            list4: text("ในระยะเวลาของการเปิดให้เลือกปรับเปลี่ยนแผนประกัน พนักงานสามารถเลือกเปลี่ยนแผนประกันกี่ครั้งก็ได้ แต่ระบบจะยึดแผนประกันสุดท้ายที่ท่านเลือกก่อนปิดระบบ วันที่ 29 กุมภาพันธ์ 2567 เป็นแผนประกันที่พนักงานจะสามารถใช้สิทธิ์นั้นได้", "ในระยะเวลาของการเปิดให้เลือกปรับเปลี่ยนแผนประกัน พนักงานสามารถเลือกเปลี่ยนแผนประกันกี่ครั้งก็ได้ แต่ระบบจะยึดแผนประกันสุดท้ายที่ท่านเลือกก่อนปิดระบบ วันที่ 29 กุมภาพันธ์ 2567 เป็นแผนประกันที่พนักงานจะสามารถใช้สิทธิ์นั้นได้"),
            list5: text("เมื่อระบบการให้เลือกแผนประกันใหม่ปิด พนักงานจะไม่สามารถปรับแปลี่ยนแผนประกันระหว่างปีได้", "เมื่อระบบการให้เลือกแผนประกันใหม่ปิด พนักงานจะไม่สามารถปรับแปลี่ยนแผนประกันระหว่างปีได้"),
            list6: text("ความคุ้มครองของแผนประกันใหม่ที่ท่านเลือกจะมีผลตั้งแต่วันที่ 1 มีนาคม - 31 ธันวาคม 2567", "ความคุ้มครองของแผนประกันใหม่ที่ท่านเลือกจะมีผลตั้งแต่วันที่ 1 มีนาคม - 31 ธันวาคม 2567"),
          }
        },
        healthCheckup: {
          healthCheckup: text('Hospital', 'โรงพยาบาล'),
          myHealthCheckup: text('Health Checkup Hospitals', 'โรงพยาบาลของตนเอง'),
          titleHealthCheckup: text('You can adjust your health checkup hospital that suit your lifestyle from', 'ท่านสามารถเลือกโรงพยาบาลตรวจสุขภาพที่เหมาะสมกับไลฟ์สไตล์ของคุณได้ตั้งแต่วันที่'),
          coinOFYear: text('Annual coin', 'เหรียญประจำปี'),
          changeAnnualPlan: text('You get it from changing plans', 'ที่คุณได้จากการเปลี่ยนแผน'),
          changeAnnualPlanYear: text('You get it from changing plans in', 'ที่คุณได้จากการเปลี่ยนแผนปี'),
          insuranceList: text('List of available flexible benefit', 'สวัสดิการบริษัทที่สามารถปรับเปลี่ยนได้'),
          nextButton: text('Next', 'ถัดไป'),
          selfBenefits: {
            title: text('My Hospital', 'โรงพยาบาลของคุณ'),
            healthCheckupSelected: text('Selected Hospitals', 'โรงพยาบาลที่คุณเลือก'),
            selected: text('ที่คุณเลือก', 'ที่คุณเลือก'),
            changeHealthCheckup: text('Change health checkup hospitals', 'เลือกโรงพยาบาล'),
            addHealthCheckup: text('Select your health checkup hospitals', 'เลือกโรงพยาบาลตนเอง'),
            modalInsuranceDetail: {
              detail: text(`พนักงานสามารถตรวจสอบข้อมูลรายการตรวจสุขภาพได้โดยคลิกที่ PDF ด้านล่าง`,
                `พนักงานสามารถตรวจสอบข้อมูลรายการตรวจสุขภาพได้โดยคลิกที่ PDF ด้านล่าง`),
              title: text('แผนประกันสุขภาพและอุบัติเหตุประจำปี', 'แผนประกันสุขภาพและอุบัติเหตุประจำปี'),
              notShowAgain: text('ไม่ต้องแสดงอีก', 'ไม่ต้องแสดงอีก'),
            },
          },
          editSelfBenefits: {
            header: text('Select health checkup hospital', 'เลือกโรงพยาบาลตรวจสุขภาพ'),
            select: text('Select Hospital', 'เลือกโรงพยาบาล'),
            notification: {
              error: {
                noChange: text('ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกโรงพยาบาล', 'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกโรงพยาบาล'),
                detail: text('ไม่สามารถบันทึกข้อมูลได้', 'ไม่สามารถบันทึกข้อมูลได้'),
              }
            },
            summary: {
              selected: text('Your new hospital of choice', 'โรงพยาบาลใหม่ที่คุณเลือก'),
              title: text('Summary of hospital selection', 'สรุปการเลือกโรงพยาบาล'),
              totalCoin: text('total used coins', 'รวมใช้เหรียญ'),
              coinAdditional: text('Coins received from changing hospitals', 'เหรียญที่ได้รับจากการเปลี่ยนโรงพยาบาล'),
            }
          },
          modalAcceptPrivacy: {
            header: text('Consent letter', 'หนังสือยินยอม'),
            accept: text('You confirm selecting this hospital for your health checkup.', 'คุณยืนยันเลือกโรงพยาบาลนี้สำหรับตรวจสุขภาพ'),
            confirm: text('Confirm', 'ตกลง'),
            modalOne: {
              title: text(healthCheckup.modalPrivacyOne.en.title, healthCheckup.modalPrivacyOne.th.title),
              one: text(healthCheckup.modalPrivacyOne.en.one, healthCheckup.modalPrivacyOne.th.one),
              two: text(healthCheckup.modalPrivacyOne.en.two, healthCheckup.modalPrivacyOne.th.two),
              three: text(healthCheckup.modalPrivacyOne.en.three, healthCheckup.modalPrivacyOne.th.three),
              four: text(healthCheckup.modalPrivacyOne.en.four, healthCheckup.modalPrivacyOne.th.four),
              five: text(healthCheckup.modalPrivacyOne.en.five, healthCheckup.modalPrivacyOne.th.five),
              six: text(healthCheckup.modalPrivacyOne.en.six, healthCheckup.modalPrivacyOne.th.six),
              seven: text(healthCheckup.modalPrivacyOne.en.seven, healthCheckup.modalPrivacyOne.th.seven),
              footer: text(healthCheckup.modalPrivacyOne.en.footer, healthCheckup.modalPrivacyOne.th.footer),
            },
          },
          condition: {
            header: text('เงื่อนไขและข้อกำหนด', 'เงื่อนไขและข้อกำหนด'),
            list1: text("ระยะเวลาในการเข้ารับการตรวจสุขภาพประจำปีตั้งแต่วันที่ 1 เมษายน – 30 มิถุนายน 2567", "ระยะเวลาในการเข้ารับการตรวจสุขภาพประจำปีตั้งแต่วันที่ 1 เมษายน – 30 มิถุนายน 2567"),
            list2: text("พนักงานที่มีสิทธิ์ตรวจสุขภาพประจำปี 2567 คือ พนักงานที่ผ่านการทดลองงานภายใน 30 มิถุนายน 2567 ", "พนักงานที่มีสิทธิ์ตรวจสุขภาพประจำปี 2567 คือ พนักงานที่ผ่านการทดลองงานภายใน 30 มิถุนายน 2567 "),
            list3: text("พนักงานที่ผ่านทดลองงานก่อนวันที่ 1 มกราคม 2567  สามารถเลือกโรงพยาบาลใน Dentsu Joy ได้", "พนักงานที่ผ่านทดลองงานก่อนวันที่ 1 มกราคม 2567  สามารถเลือกโรงพยาบาลใน Dentsu Joy ได้"),
            list4: text("ในระยะเวลาของการเปิดให้เลือกโรงพยาบาล พนักงานสามารถเลือกเปลี่ยนโรงพยาบาลกี่ครั้งก็ได้ แต่ระบบจะยึดโรงพยาบาลสุดท้ายที่ท่านเลือกก่อนปิดระบบ (วันที่ 29 กุมภาพันธ์ 2567) เป็นโรงพยาบาลที่พนักงานจะสามารถใช้สิทธิ์ไปตรวจสุขภาพได้", "ในระยะเวลาของการเปิดให้เลือกโรงพยาบาล พนักงานสามารถเลือกเปลี่ยนโรงพยาบาลกี่ครั้งก็ได้ แต่ระบบจะยึดโรงพยาบาลสุดท้ายที่ท่านเลือกก่อนปิดระบบ (วันที่ 29 กุมภาพันธ์ 2567) เป็นโรงพยาบาลที่พนักงานจะสามารถใช้สิทธิ์ไปตรวจสุขภาพได้"),
            list5: text("หากพนักงานไปใช้สิทธิ์นอกวัน-เวลาที่บริษัทฯ กำหนด พนักงานจะต้องชำระค่าใช้จ่ายเอง", "หากพนักงานไปใช้สิทธิ์นอกวัน-เวลาที่บริษัทฯ กำหนด พนักงานจะต้องชำระค่าใช้จ่ายเอง"),
            list6: text("บริษัทใช้โรงพยาบาลสมิติเวชเป็นโรงพยาบาลหลักในการตรวจ หากท่านเลือกโรงพยาบาลที่มีค่าใช้จ่ายต่ำกว่าโรงพยาบาลสมิติเวช ท่านจะได้รับเหรียญใน dentsu JOY ตามส่วนต่างของโปรแกรมค่าตรวจ โดยเหรียญส่วนต่างจะสามารถใช้ได้ตั้งแต่ วันที่ 1 มีนาคม 2567 เป็นต้นไป", "บริษัทใช้โรงพยาบาลสมิติเวชเป็นโรงพยาบาลหลักในการตรวจ หากท่านเลือกโรงพยาบาลที่มีค่าใช้จ่ายต่ำกว่าโรงพยาบาลสมิติเวช ท่านจะได้รับเหรียญใน dentsu JOY ตามส่วนต่างของโปรแกรมค่าตรวจ โดยเหรียญส่วนต่างจะสามารถใช้ได้ตั้งแต่ วันที่ 1 มีนาคม 2567 เป็นต้นไป"),
            list7: text("ทางบริษัทฯ ได้ติดต่อขอราคาพิเศษในการตรวจสุขภาพเพิ่มเติมสำหรับพนักงานและครอบครัวไว้แล้ว โดยสามารถไปแจ้งว่าเป็นครอบครัวของพนักงาน Dentsu กับทางโรงพยาบาลได้เลย แต่พนักงานต้องรับผิดชอบค่าใช้จ่ายส่วนนี้เอง", "ทางบริษัทฯ ได้ติดต่อขอราคาพิเศษในการตรวจสุขภาพเพิ่มเติมสำหรับพนักงานและครอบครัวไว้แล้ว โดยสามารถไปแจ้งว่าเป็นครอบครัวของพนักงาน Dentsu กับทางโรงพยาบาลได้เลย แต่พนักงานต้องรับผิดชอบค่าใช้จ่ายส่วนนี้เอง"),
          }
        },
        eClaim: {
          claimPriceError: text('Cannot greather than claim price', 'ต้องไม่มากกว่า Claim Price'),
          coinError: text(
            'Cannot greather than User coin',
            'จำนวนที่ขอเบิกต้องไม่มากกว่าเหรียญที่มี',
          ),
          name: text('name', 'ชื่อ'),
          status: text('status', 'สถานะ'),
          date: text('date', 'วันที่อนุมัติ/ปฏิเสธ'),
        },
      },
    },
  }
}
