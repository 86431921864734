import styled from '@emotion/styled'
import { notification } from 'antd'
import { useCallback } from 'react'
import { Form, InjectedFormProps, reduxForm } from 'redux-form'
import Button from '../../components/common/Button'
import {
  InputField,
  SelectField,
  DatePickerField,
  UploadfileField,
} from '../../components/common/fields'
import Input from '../../components/common/Input'
import moment, { Moment } from 'moment'
import { mobile } from '../../utils/responsive-helper'
import icon from '../../assets/images/icons-id-card-48-px.svg'

const Layout = styled.div`
  .layout-upload {
    ${mobile} {
      width: 311px;
    }
  }
`
const Label = styled.div`
  font-weight: 900;
`

type LoginFormProps = InjectedFormProps<{}> & {}
const enchancer = reduxForm({
  form: 'LoginForm',
})
const tempOpt = [
  {
    label: 'นาย',
    value: 'นาย',
  },
  {
    label: 'นาง',
    value: 'นาง',
  },
  {
    label: 'นางสาว',
    value: 'นางสาว',
  },
  {
    label: 'เด็กชาย',
    value: 'เด็กชาย',
  },
  {
    label: 'เด็กหญิง',
    value: 'เด็กหญิง',
  },
]

const requiredDate = (value: Moment) => {
  const x = moment(value).isValid() ? undefined : 'error.required'
  return x
}

const LoginForm = enchancer((props: LoginFormProps) => {
  const { handleSubmit } = props
  const onSubmit = useCallback((form) => {
    notification.info({
      message: JSON.stringify(form),
    })
  }, [])
  return (
    <Form>
      <Label>- Login Form</Label>
      <InputField name="อีเมล" label="อีเมล" placeholder="ระบุอีเมล" />
      <InputField name="รหัสผ่าน" label="รหัสผ่าน" placeholder="ระบุรหัสผ่าน" />
      <SelectField
        name="title"
        options={[...tempOpt]}
        placeholder="ระบุคำนำหน้า"

        // value={valueObject.title}
        // onSelect={(key: string | number) => onSelect(key, 'title')}
      />
      <UploadfileField
        name="image"
        icon={'idCardIcon'}
        title={'1. รูปถ่ายบัตรประจำตัวประชาชนของคุณ'}
        desc={'(jpg, png ขนาดไม่เกิน 1 MB)'}
      />
      <DatePickerField name="วันลาออกนายก" validate={requiredDate} />
      <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
    </Form>
  )
})

const ExampleInput = () => {
  return (
    <Layout>
      <Label>- With Label</Label>
      <Input label="Example lebal" />
      <Label>- With Placeholder</Label>
      <Input placeholder="Example Placeholder" />
      <Label>- Disable</Label>
      <Input defaultValue="defaultValue" disabled style={{ width: 150 }} />
      <Input value="disabled with value" disabled />
      <LoginForm />
    </Layout>
  )
}
export default ExampleInput
