import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { useGetPeriodDate } from '../../services/enrollment/enrollment-query'
import { useGetUserCoinBenefitYear } from '../../services/user/user-query'
import { numberWithCommas } from '../../utils/helper'


const PointLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .top {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`
const PointCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  background: ${color.WHITE};
  padding: 4px 8px;
  border-radius: 8px;
`

const ExpireDate = styled(Text)`
  align-self: flex-end;
`

const Point = () => {
  const { data: enrollmentPeriod } = useGetPeriodDate()
  const { endPeriodDate, benefitYearId = 0 } = enrollmentPeriod || {}
  const { t, i18n } = useTranslation()
  // const { data: userCoin } = useGetUserCoin()
  const { data: userCoin } = useGetUserCoinBenefitYear(benefitYearId)

  const getPoint = useMemo((code = 'dentsu_coin') => {
    if (!userCoin || userCoin.length === 0) return 0
    const coin = userCoin.find(v => v.code === code)

    if (!coin?.balance) return 0;

    return numberWithCommas(coin?.balance)
  }, [userCoin])

  return (
    <PointLayout>
      <div className='top'>
        <Text style={{ wordBreak: 'initial' }}>{t('app.coinNow')}</Text>
        <PointCard>
          <Text style={{ wordBreak: 'initial' }} size={18} type="SemiBold">{getPoint}</Text>
          <Image width={20} height={20} imageName='IconDentsuCoin' />
        </PointCard>
      </div>
      <ExpireDate size={12}>{t('app.expiredOn')} {dayjs(endPeriodDate).locale(i18n.language).format(i18n.language === 'th' ? 'DD MMM BBBB' : 'DD MMM YYYY')}</ExpireDate>
    </PointLayout>
  )
}

export default Point