import styled from '@emotion/styled'
import { CSSProperties, ReactNode } from 'react'
import React from 'react'
import { RightOutlined } from '@ant-design/icons'
import color from '../../constant/colors'
import Loading from './Loading'
import Text from './Text'
import { hideScrollStyle, mobile, mobileVerySmall } from '../../utils/responsive-helper'
import { Link } from 'react-router-dom'
import Image from './Image'
import colors from '../../constant/colors'

const Layout = styled.div``
const HeaderLayout = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`
const TextStyle = styled(Text)`
  color: ${color.BLACK};
`
const ViewMoreLayout = styled(Link)`
  display: flex;
  flex-flow: row;
  align-items: center;
`
const ViewMoreStyle = styled(Text)`
  color: ${color.BLACK};
`

const ContentLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  overflow-y: hidden;
  overflow-x: scroll;

  ${mobileVerySmall} {
    grid-template-columns: repeat(1, 1fr);
  }

  /* > *:not(:first-of-type) {
    margin-left: 24px;
  } */

  > {
    background-color: red;
  }
  ${hideScrollStyle}
`

export type CategoryGroupProps = {
  label: string
  children?: ReactNode
  style?: CSSProperties
  contentStyle?: CSSProperties
  headerStyle?: CSSProperties
  path?: string
  viewMoreText?: string
  loading?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const CategoryGroup = (props: CategoryGroupProps) => {
  const { label, children, loading = false, path, viewMoreText, contentStyle, headerStyle, ...restProps } = props

  return (
    <Layout {...restProps}>
      <HeaderLayout style={headerStyle}>
        <TextStyle size={16} type="Bold">
          {label}
        </TextStyle>
        {path && (
          <ViewMoreLayout to={path} style={{ cursor: loading ? 'default' : 'pointer' }}>
            <ViewMoreStyle size={16} type="Medium">
              {viewMoreText || 'View More'}
            </ViewMoreStyle>
            {/* <RightOutlined style={{ marginLeft: 4, color: color.PRIMARY }} /> */}
            <Image imageName="iconNext" width={24} height={24} />
          </ViewMoreLayout>
        )}
      </HeaderLayout>
      <Loading loading={loading}>
        <ContentLayout style={contentStyle}>{children}</ContentLayout>
      </Loading>
    </Layout>
  )
}

export default CategoryGroup
