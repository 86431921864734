import React, { useMemo } from 'react'
import ImageSlider, { ImageProps } from '../../components/common/ImageSlider'
import Recommend from './Recommend'
import styled from '@emotion/styled'
import { mobile, tablet } from '../../utils/responsive-helper'
import { useGetHilightNews } from '../../services/digital-news/digital-news-query'
import { NewsType, HeaderImage } from '../../services/digital-news/digital-news-types'
import { useGetUser } from '../../services/user/user-query'
import { useHistory } from 'react-router'
import config from '../../config'
import * as paths from '../../constant/path'

const HeaderLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  overflow: hidden;
  padding: 32px 0;
  .ant-carousel {
    ${tablet} {
      width: 100%;
    }
  }
  .image-slider {
    object-fit: contain;
    height: 420px;
    width: 124vh;

    ${tablet} {
      width: 100%;
      height: 420px;
      margin-right: 0px;
    }

    ${mobile} {
      width: 100%;
      height: 192px;
      margin-right: 0px;
    }
  }
`

const News = () => {
  const history = useHistory()
  const { data: user } = useGetUser()
  const { userId } = user || {}
  const { data } = useGetHilightNews({ userId: userId })

  const images = useMemo(() => {
    const tempImage: ImageProps[] = []
    data?.forEach((news: NewsType) => {
      tempImage.push({
        imagePath: news?.digitalNews?.thumbnailPath?.indexOf('http') > -1 ? news?.digitalNews?.thumbnailPath : `${config.apiHost}${news?.digitalNews?.thumbnailPath}`,
        title: news.digitalNews.title,
        onClick: () =>
          history.push(paths.newsDetails({ routeParam: { newsId: news.digitalNews.id } })),
      })
    })

    return tempImage
  }, [data])

  return (
    <HeaderLayout className="news-header">
      <ImageSlider images={images} />
      {/* <Recommend /> */}
    </HeaderLayout>
  )
}

export default News
