const Breadcrumb = () => (
  <div>
    <p>
      <div>{'<Breadcrumb /> เอาวางไว้ที่หน้าที่จะใช้ได้เลย'}</div>
      <div>{'ปกติจะเอาชื่อ path มาใช้ เช่น shop/1/reward จะได้เป็น Shop > 1 > Reward'}</div>
      <div>{'ถ้ามี _ หรือ - จะถูกเปลี่ยนเป็นเว้นวรรค'}</div>
    </p>
    <p>
      <div>{'ในกรณีที่ต้องการแก้ ให้ใส่ props overide'}</div>
      <div>{'Example'}</div>
      <pre>{`<Breadcrumb overide={(paths) => {
  // paths = [{ label: string, path: string }, ...]
  path[2].label = "Custom Label"
  return path
}}/>`}</pre>
    </p>
    <p>
      <div>สามารถเข้าไปแก้ label ได้ที่ components/common/Breadcrumb</div>
      <div>ที่ตัวแปร customLable</div>
      <div>{`Example`}</div>
      <pre>{`customLabel = {
  home: 'Custom Home Label'
}`}</pre>
    </p>
  </div>
)
export default Breadcrumb
