import { Switch, Route } from 'react-router'

import * as paths from '../../../constant/path'

import Benefits from '../Benefits'
import EditSelfBenefits from '../Benefits/EditBenefits/EditSelfBenefits'
import HealthCheckupDetail from './HealthCheckupDetail'

const HealthCheckupDetailRoute = () => {
  return (
    <Switch>
      <Route path={paths.healthCheckupEditSelfBenefits()} component={EditSelfBenefits} />
      <Route path={paths.healthCheckupBenefits()} component={Benefits} />
      <Route exact path={paths.healthCheckup()} component={HealthCheckupDetail} />
    </Switch>
  )
}

export default HealthCheckupDetailRoute
