import { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import PointHistoryCard from './PointHistoryCard'
import { useGetCoinTransaction } from '../../services/point-history/point-history-query'
import Button from '../../components/common/Button'
import Loading from '../../components/common/Loading'
import { useTranslation } from 'react-i18next'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`

const LoadingStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`

const List = () => {
  const { t } = useTranslation()
  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } = useGetCoinTransaction()
  const onClickLoading = useCallback(() => {
    fetchNextPage()
  }, [fetchNextPage])

  const contents = useMemo(() => {
    return data?.pages.map(({ content: contents }) => {
      return contents.map((content: any, index: number) => {
        const { imagePath = '', type, coin, isAdd, createdDate, refTitle } = content
        return (
          <PointHistoryCard
            key={index}
            imagePath={imagePath}
            type={type}
            coin={coin}
            isAdd={isAdd}
            createdDate={createdDate}
            refTitle={refTitle}
          />
        )
      })
    })
  }, [data])
  
  return (
    <Layout>
      <Loading style={{ height: 100 }} loading={isLoading}>
        {contents}
        <LoadingStyle>
          <Loading loading={!isLoading && isFetching}>
            <Button disabled={!hasNextPage} onClick={onClickLoading}>
              {t('pointHistory.seeMore')}
            </Button>
          </Loading>
        </LoadingStyle>
      </Loading>
    </Layout>
  )
}

export default List
