import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { Radio } from 'antd'
import styled from '@emotion/styled'

import Text from './Text'
import Image from './Image'

import color from '../../constant/colors'

const StyledGroup = styled(Radio.Group)`
  width: 100%;
`
const StyledRadio = styled(Radio)`
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  margin-bottom: 8px;

  span.ant-radio + * {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`
const IconImage = styled(Image)`
  width: 31px;
  height: 31px;
`
type RadioCompOption = { image?: any } & BaseOptionType
interface RadioCompProps {
  options: RadioCompOption[]
  value?: string
  setValue: Dispatch<SetStateAction<string>>
}

const RadioComp = (props: RadioCompProps) => {
  const { options, setValue } = props
  const onChange = useCallback(
    (e) => {
      setValue(e.target.value)
    },
    [options, setValue, props.value],
  )
  return (
    <StyledGroup value={Number(props.value)}>
      {options.map((option) => (
        <StyledRadio value={option.value} key={option.value} onClick={onChange}>
          <Text
            size={16}
            type="SemiBold"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {option.label}
          </Text>
          <div style={{ flex: 1 }} />
          {option.image && <IconImage src={option.image} />}
        </StyledRadio>
      ))}
    </StyledGroup>
  )
}

export default RadioComp
