import { useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { useRouter } from '../../utils/helper'
import { mobile, iphoneXLandscape } from '../../utils/responsive-helper'
import { setToken } from '../../utils/helper'
import { useGetUser } from '../../services/user/user-query'
import Loading from '../../components/common/Loading'

const Layout = styled.div`
  display: flex;
  height: 100vh;

  ${mobile}, ${iphoneXLandscape} {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`

const AuthCallback = (props: any) => {
  const { data: user } = useGetUser()
  const { query } = useRouter<{ token?: any }>()

  useEffect(() => {
    handleSetToken(query.token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.token])

  const getUser = useCallback(() => {
    console.log(user)
  }, [user])

  function handleSetToken(token: any) {
    setToken(token).then((res) => {
      getUser()
    })
  }

  return (
    <Layout>
      <Loading loading={true} >
        isLoading
      </Loading>
    </Layout>
  )
}

export default AuthCallback
