import React from 'react'
import styled from '@emotion/styled'
import ImageSlider from './ImageSlider'
import Category from './Category'
import Text from '../../../components/common/Text'
import Breadcrumb from '../../../components/common/Breadcrumb'
import ProfileCard from '../../../components/ProfileCard'
import { mobile, tablet } from '../../../utils/responsive-helper'
import { useRouter } from '../../../utils/helper'
import { shop } from '../../../constant/path'

const Layout = styled.div`
  > * {
    margin-top: 24px;
  }
  margin-bottom: 40px;
  .image-slider {
    width: 752px;
    height: 420px;
    object-fit: contain;
    margin-right: 24px;

    ${tablet} {
      max-width: unset;
      width: 100%;
    }
    ${mobile} {
      height: 192px;
      .title-font {
        font-size: 24px;
      }
    }
  }

  .profile-card {
    ${tablet} {
      margin-bottom: 16px;
    }
  }

  .shop-card {
    margin-bottom: 24px;
  }
`

const FlexRow = styled.div`
  display: flex;
  ${tablet} {
    flex-direction: column-reverse;
  }
`
const BreadcrumbStyled = styled<typeof Breadcrumb>(Breadcrumb)`
  ${mobile} {
    margin : 24px 16px;
  }
`

const ShopBrand = () => {
  const { brandId, title: brandName } = useRouter().query

  return (
    <Layout>
      <BreadcrumbStyled
        overide={(paths) => {
          paths[0] = {
            label: 'Shop',
            path: shop(),
          }
          paths[1].label = brandName
          return paths
        }}
      />
      <FlexRow>
        <ImageSlider brandId={brandId} />
        <ProfileCard size="medium" />
      </FlexRow>
      <Text className="title-font" size={32} type="Bold">
        {brandName}
      </Text>
      <Category brandId={brandId} brandName={brandName} />
    </Layout>
  )
}

export default ShopBrand
