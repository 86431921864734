import styled from '@emotion/styled'

import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import { useGetProductDetail } from '../../services/shop/shop-query'
import { useRouter } from '../../utils/helper'
import { useTranslation } from 'react-i18next'

const LeftCard = styled(Card)`
  flex: 1;
  padding: 16px 24px 16px 24px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin-bottom: 24px;
`
const LeftCardImage = styled(Image)`
  width: 107px;
  height: 107px;
  border-radius: 8px;
  margin-right: 16px;
`
const LeftCardPointLayout = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`

const LeftCardComp = () => {
  const { query } = useRouter()
  const { t } = useTranslation()
  const { data: productDetail }: any = useGetProductDetail(query.rewardId)

  return (
    <LeftCard>
      <LeftCardImage src={productDetail?.thumbnailImage} />
      <div>
        <Text size={20} type="Medium" style={{ marginTop: 12 }}>
          {productDetail?.title}
        </Text>
        <LeftCardPointLayout>
          <Image
            imageName="iconCoinPrimaryWithBg"
            style={{ borderRadius: 8, width: 33, height: 33 }}
          />
          <Text size={20} type="Bold" style={{ marginTop: 6, marginLeft: 12 }}>
            {
              productDetail?.specialCoin !== null ? productDetail?.specialCoin.toLocaleString(undefined, { maximumFractionDigits: 2 }) : productDetail?.normalCoin.toLocaleString(undefined, { maximumFractionDigits: 2 })
            }
          </Text>
          <Text size={16} style={{ marginTop: 10, marginLeft: 4 }}>
            {t('app.coin')}
          </Text>
        </LeftCardPointLayout>
      </div>
    </LeftCard>
  )
}

export default LeftCardComp
