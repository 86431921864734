import styled from '@emotion/styled'
import Card from './common/Card'
import Image from './common/Image'
import Text from './common/Text'
import { Tooltip } from 'antd'
import { mobile, mobileVerySmall, useScreen } from '../utils/responsive-helper'
import color from '../constant/colors'

const LayoutCard = styled(Card)`
  cursor: pointer !important;
  padding: 16px;

  ${mobile} {
    height: 100%;
    width: 100%;
    margin: 2px;
  }
  
  ${mobileVerySmall} {
    height: 100%;
    width: 100%;
    margin: 8px;
  }
`
const LayoutFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const LayoutBody = styled.div`
  display: flex;
  flex-direction: row;
`

const LayoutRowImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  img {
    border-radius: 8px;
  }
`

const LayoutRowTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 8px 0 8px;;
`

const TextEllipsis = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  
  ${mobile} {
    -webkit-line-clamp: 2;
  }
  
  ${mobileVerySmall} {
    -webkit-line-clamp: 2;
  }
`

const IconImage = styled(Image)`
  object-fit: scale-down;
`

type CustomCardProps = {
  height?: number
  icon: string
  title: string
  maxClaimablePrice?: number
  availableAmount?: number
  description?: string
  onClick?: () => void
}

const WellBeingCardScg = (props: CustomCardProps) => {
  const { onClick, icon, title, description } = props

  const { isMobile } = useScreen()
  const fontSize = !isMobile ? 20 : 16
  
  return (
    <LayoutCard className="well-being-card" onClick={onClick}>
      <LayoutFlexColumn>
        <LayoutBody>
          <LayoutRowImage>
            {icon ? (
              <IconImage src={icon} width={50} height={50} />
            ) : (
              <IconImage imageName="iconNews" width={50} height={50} />
            )}
          </LayoutRowImage>
          <Tooltip title={title}>
            <LayoutRowTitle>
              <TextEllipsis size={16} type={'Bold'} >
                {title}
              </TextEllipsis>
            </LayoutRowTitle>

            <LayoutRowTitle>
              <TextEllipsis size={16}>
                {description}
              </TextEllipsis>
            </LayoutRowTitle>
          </Tooltip>
        </LayoutBody>
      </LayoutFlexColumn>

    </LayoutCard>
  )
}

export default WellBeingCardScg
