import styled from '@emotion/styled'
import ImageSlider from '../../components/common/ImageSlider'
import image from '../../assets/images/base/test-avatar.png'
import image2 from '../../assets/images/base/test-avatar2.jpg'
import image3 from '../../assets/images/base/test-avatar3.jpg'
import { mobile, tablet } from '../../utils/responsive-helper'

const Layout = styled.div`
  .image-slider {
    width: 752px;
    height: 420px;
    object-fit: contain;
    ${tablet} {
      width: 100%;
      height: 420px;
    }

    ${mobile} {
      height: 192px;
    }
  }
`

const ExampleImageSlider = () => {
  const images1 = [
    { src: image, onClick: () => console.log('Hello World!') },
    { src: image2, onClick: () => console.log('Hello World2!') },
    { src: image3, onClick: () => console.log('Hello World3!') },
    { src: image2, onClick: () => console.log('Hello World4!') },
    { src: image, onClick: () => console.log('Hello World5!') },
  ]
  return (
    <Layout>
      <ImageSlider images={images1} />
    </Layout>
  )
}

export default ExampleImageSlider
