import dayjs, { Dayjs } from 'dayjs'
import { CSSProperties, useState } from 'react';
import DatePicker from '../../components/common/DatePicker'

export type DatePickerProps = {
  label?: string
  value?: string | Dayjs
  onChange?: (v: string) => void
  style?: CSSProperties
  className?: string
  disabled?: boolean
  placeholder?: string
  minDate?: Dayjs
  maxDate?: Dayjs
}


const ExampleDatePicker = (props: DatePickerProps) => {
  return (
    <div>
      <DatePicker label="เลือกวันที่ลุงตู่จะลาออก" style={{ width: 500 }} />
    </div>
  )
}

export default ExampleDatePicker