import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'

dayjs.extend(buddhistEra)

export enum DateFormat {
  FORMAT_1 = '1', // 01 May 2020,
  FORMAT_1TH = '1th', // 01 พ.ค 2563,
}

const months_th = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]

const months_th_mini = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
]

const months_en = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const months_en_mini = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]

export const convertDateFormatTH = (date: Date, format: any) => {
  const dayJs = dayjs(date)
  // const dayJsLocal = dayJs(date.toLocaleDateString('th-TH', {
  //   year:'numeric',
  //   month: 'long',
  //   day:'numeric'
  // }))
  let dateTH = ''

  date = new Date(date)
  switch (format) {
    case DateFormat.FORMAT_1TH:
      // console.log('th---->', date)
      // console.log('th----> mm ', date.getMonth())
      const month = date.getMonth()
      const monthTH = months_th_mini[month]
      dateTH = dayJs.format('D MMMM BBBB')
      dateTH = dateTH.replace(months_th[month], monthTH)

      break
  }
  return dateTH
}

export const convertDateFormat = (date: Date, format: any) => {
  date = new Date(date)
  const dayJs = dayjs(date)
  console.log('en---->', date)
  switch (format) {
    case DateFormat.FORMAT_1:
      return dayJs.format('DD MMM YYYY')
  }
  // if (format == DateFormat.FORMAT_1) return
}
