import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { NotificationType, NotificationWithPaging } from './notitication-typed'

const NOTIFICATION_READ_URL = '/api/digital_news/noti/user_read_news'
const NOTIFICATION_READ_ALL_URL = '/api/digital_news/noti/user_read_all_news'
const NOTIFITCATION_BASE_URL = '/api/digital_news'
const NOTIFICATION_LIST_URL = `${NOTIFITCATION_BASE_URL}/list_user_notification`
const NOTIFICATION_COUNT_URL = `${NOTIFITCATION_BASE_URL}/getNotiCount`
const NOTIFICATION_COUNT_READ_DATE_URL = `${NOTIFITCATION_BASE_URL}/getNotiCountByReadDate`

export const useGetNotificationCount = () => {
  return useQuery<number>(NOTIFICATION_COUNT_URL, () =>
    api.belive.get<number>(NOTIFICATION_COUNT_URL),
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useGetNotificationCountByReadDate = () => {
  return useQuery<number>(NOTIFICATION_COUNT_READ_DATE_URL, () =>
    api.belive.get<number>(NOTIFICATION_COUNT_READ_DATE_URL),
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useGetNotification = (
  userId?: number,
  params = {
    page: 0,
    size: 3,
  },
) => {
  return useInfiniteQuery(
    [NOTIFICATION_LIST_URL, { userId }],
    async ({ pageParam = 0 }) => {
      const response = await api.belive.get<NotificationType[]>(NOTIFICATION_LIST_URL, {
        page: pageParam,
        pageSize: params.size,
        userId,
      })

      const responseWithPaging: NotificationWithPaging = {
        data: response,
        paging: {
          page: pageParam,
          nextPage: pageParam + 1,
        },
      }

      return responseWithPaging
    },
    {
      getNextPageParam: (lastGroup: NotificationWithPaging) => {
        const { data, paging } = lastGroup
        const { nextPage } = paging
        return data.length >= params.size ? nextPage : null
      },
    },
  )
}

export const useReadNotification = () => {
  const mutate = useMutation(
    async (id: number) => {
      return api.belive.post<number>(NOTIFICATION_READ_URL, {
        notificationId: id,
      })
    },
    {
      // onSuccess: (data) => {
      //   console.log('==== success ===', data)
      // },
      onError: (e: any) => {
        console.log('===== error ====', e)
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}

export const useReadNotificationAll = () => {
  const mutate = useMutation(
    async () => {
      return api.belive.post<number>(NOTIFICATION_READ_ALL_URL)
    },
    {
      onError: (e: any) => {
        console.log('===== error ====', e)
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}
