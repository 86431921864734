import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import Breadcrumb from '../../components/common/Breadcrumb'
import Card from '../../components/common/Card'
import { home } from '../../constant/path'
import { RootState } from '../../redux-store'
import { useGetEClaimById } from '../../services/e-claim/e-claim-query'
import { useRouter } from '../../utils/helper'
import { mobile, useScreen } from '../../utils/responsive-helper'
import EClaimForm from '../EClaimDetail/EClaimForm'
import EClaimInfo from '../EClaimDetail/EClaimInfo'

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  z-index: 1;
  ${mobile} {
    margin: 24px 16px;
  }
`

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  width: 100%;
  min-height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-height: 266px;
  margin: 22px auto 0 auto;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`
const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding :16px;
  box-shadow: none;
`

const EClaimSummary = () => {
  const { query } = useRouter<{ eClaimId: number }>()
  const { isMobile } = useScreen()
  const { data: eClaimItem } = useGetEClaimById({ claimId: query.eClaimId })

  const eClaimFormValue = useSelector((state: RootState) => state.eClaim.claimInfo)

  return (
    <Layout>
      <BackgroundHeader />
      <FlexRow>
        {!isMobile && (
          <BreadcrumbContainer>
            <Breadcrumb
              overide={(paths) => {
                paths.unshift({
                  label: 'Home',
                  path: home(),
                })
                // pullAt(paths, 2)
                paths[1].label = 'D-Claims';
                paths[2].label = `${eClaimItem?.claimName}`;
                paths[3].label = 'ยื่นคำขอ';

                return paths
              }}
            />
          </BreadcrumbContainer>
        )}

        <EClaimInfo eClaimItem={eClaimItem} hideDetail />
        <CustomStyleCard>
          <EClaimForm
            eClaimItem={eClaimItem}
            eClaimFormValue={eClaimFormValue}
            editable={false}
            isPreview={true}
          />
        </CustomStyleCard>
      </FlexRow>
    </Layout>
  )
}

export default EClaimSummary
