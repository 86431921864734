import styled from '@emotion/styled'
import Text from '../../components/common/Text'
import { EClaimItem } from '../../services/e-claim/e-claim-types'
import { useScreen } from '../../utils/responsive-helper'

const TitleContainer = styled.div`
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
`

type EClaimInfoProps = {
  eClaimItem?: EClaimItem
  hideDetail?: boolean
}

const EClaimInfo = (props: EClaimInfoProps) => {
  const { isMobile } = useScreen()
  const { eClaimItem, hideDetail = false } = props

  return (
    <div style={{ zIndex: 1 }}>
      <TitleContainer style={{ zIndex: 1 }}>
        <Text type="Bold" size={isMobile ? 20 : 24} style={{ zIndex: 1, textAlign: 'center' }}>
          {eClaimItem?.claimName}
        </Text>
      </TitleContainer>
    </div >
  )
}

export default EClaimInfo
