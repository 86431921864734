import { useCallback } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { eClaimHistoryDetail } from '../../constant/path'
import { useGetEClaimListHistoryScg } from '../../services/e-claim/e-claim-query'
import { EClaimHistory } from '../../services/e-claim/e-claim-types'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'

const CardContainer = styled(Card)`
  padding: 12px;
  display: flex;
  width: 100%;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid #C6C6C;
  background: #FFF;
  position: relative;

  ${tablet} {
    padding: 18px;
  }

  ${mobile} {
    padding: 5px 5px 15px 5px;
  }
`

const ImageStyle = styled(Image)`
  margin: 10px;
  margin-top: 0px;
  border-radius: 6px;
  margin-right: 12px;
  ${mobile} {
    align-self: center;
  }
`

const FlexText = styled(Text)`
  display: flex;
  ${mobile} {
    flex-direction: column;
  }

  div {
    flex: 1;
  }
`

const DetailContents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  > div:nth-of-type(1) {
    flex: 4;
    display: flex;
    flex-direction: column;
  }
  
  > div:nth-of-type(2) {
    flex: 1;
    text-align: end;
    white-space: nowrap;
  }
`

const TextEllipsis = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  
  ${mobile} {
    -webkit-line-clamp: 2;
  }
`


const ListContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

const EClaimStatusContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 2px 6px;
  float: right;
  margin-bottom: 10px;

  div {
    float: right;
  }
`

const DateContainer = styled.div`
  position: absolute;
  padding-top: 56px;

  ${mobile} {
    padding-top: 0px;
    bottom: 0;
  }
`

const EClaimListComponentScg = () => {
  const { data: eclaimList } = useGetEClaimListHistoryScg()
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const fontSizeTitle = !isMobile ? 20 : 16
  const fontSizeDescription = !isMobile ? 16 : 12

  const statusCode = useCallback((text: string) => {
    let statusCode = ''
    switch (text) {
      case 'Approved':
        statusCode = '#98CF6D'
        break;
      case 'Rejected':
        statusCode = '#DF795E'
        break;
      case 'Waiting for Approve':
        statusCode = '#F8F2DB'
        break;
      default: statusCode = text
        break;
    }
    return statusCode
  }, [])

  const TextColor = useCallback((text: string) => {
    let TextColor = ''
    switch (text) {
      case 'Approved':
        TextColor = '#FFF'
        break;
      case 'Rejected':
        TextColor = '#FFF'
        break;
      case 'Waiting for Approve':
        TextColor = '#DFC35F'
        break;
      default: TextColor = text
        break;
    }
    return TextColor
  }, [])

  return (
    <ListContainer>
      {eclaimList?.map((item: EClaimHistory, index) => {
        return (
          <Link
            key={`${index}-${item.claimId}-${item.claimName}`}
            to={eClaimHistoryDetail({
              routeParam: {
                id: item.id,
              },
            })}
          >
            <CardContainer>
              {/* <div> */}
              {item?.claimIcon ? (
                <ImageStyle src={item?.claimIcon} width={40} height={40} />
              ) : (
                <ImageStyle imageName="iconNews" width={40} height={40} />
              )}
              <DetailContents>

                <div>
                  <TextEllipsis size={14} color={color.PRIMARY}>
                    {item.claimName}
                  </TextEllipsis>
                </div>
                <div>
                  <EClaimStatusContainer backgroundColor={statusCode(item.acceptName)}>
                    <Text size={fontSizeDescription} color={TextColor(item.acceptName)}>{item.acceptName}</Text>
                  </EClaimStatusContainer>

                  <Text size={fontSizeDescription} maxLine={1} type={'Medium'} style={{ paddingRight: '3px', float: 'right' }}>
                    - {item.exchangeCoin} เหรียญ
                  </Text>
                </div>
              </DetailContents>
              {/* </div> */}
              <DateContainer>
                <Text size={12} color={'#717171'}>
                  วันที่ทำรายการเบิก : {dayjs(item?.createdDate).format('DD MMM YYYY')}
                </Text>
              </DateContainer>
            </CardContainer>
          </Link>
        )
      })}
    </ListContainer>
  )
}

export default EClaimListComponentScg
