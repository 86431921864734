import React from 'react'
import styled from '@emotion/styled'
import Text from '../../components/common/Text'
import HealthDetails from './HealthDetails'

const Layout = styled.div`
  > * {
    margin-top: 24px;
  }
  margin-bottom: 40px;
`

const HeaderText = styled(Text)`
  text-align: center;
`


const HealthProfile = () => {
  return (
    <Layout>
      <HeaderText className="title-font" size={28} type="Bold">
        Health Profile
      </HeaderText>

      <HealthDetails />
    </Layout>
  )
}

export default HealthProfile
