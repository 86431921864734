import React from 'react'
import styled from '@emotion/styled'
import TagRemain from '../../components/common/TagRemain'
const Layout = styled.div``
const LayoutNormal = styled.div`
  .tag-remain {
    width: 88px;
    height: 19px;
  }
`
const LayoutSmall = styled.div`
  .tag-remain {
    width: 108px;
    height: 24px;
  }
`

const ExampleTagRemain = () => {
  return (
    <Layout>
      <LayoutNormal>
        <TagRemain remain={10} />
      </LayoutNormal>
      <LayoutSmall>
        <TagRemain remain={12} />
      </LayoutSmall>
    </Layout>
  )
}
export default ExampleTagRemain
