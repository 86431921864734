import React, { ComponentProps, useEffect, useMemo, useState } from 'react'
import { Layout, Drawer } from 'antd'
import { ComponentType } from 'react'
import styled from '@emotion/styled'

import ExampleInput from './ExampleInput'
import ExampleCategoryGroup from './ExampleCategoryGroup'
import ExampleModal from './ExampleModal'
import ExampleComment from './ExampleComment'
import ExampleButton from './ExampleButton'
import ExampleCard from './ExampleCard'
import ExampleAvatar from './ExampleAvatar'
import ExampleSteps from './ExampleSteps'
import ExampleImageSlider from './ExampleImageSlider'
import ExampleTag from './ExampleTag'
import ExampleBadge from './ExampleBadge'
import ExamplePointLabel from './ExamplePointLabel'
import ExampleProfileCard from './ExampleProfileCard'
import ExampleShopCard from './ExampleShopCard'
import humps from 'humps'
import color from '../../constant/colors'
import { useScreen } from '../../utils/responsive-helper'
import ExampleText from './ExampleText'
import ExampleTabs from './ExampleTabs'
import ExampleSelect from './ExampleSelect'
import ExampleBreadcrumb from './ExampleBreadcrumb'
import ExampleTagRemain from './ExampleTagRemain'
import ExampleLoading from './ExampleLoading'
import ExampleDatePicker from './ExampleDatePicker'
import ExampleWellBeingCard from './ExampleWellBeingCard'
import ExampleUploadFile from './ExampleUploadFile'

const { Sider: AntdSider, Content } = Layout

const WikiLayout = styled(Layout)`
  /* max-width: 1280px; */
  margin: auto;
`

const SectionLayout = styled.section`
  margin-bottom: 50px;
`
const SectionHeader = styled.div`
  padding: 20px;
  font-weight: 900;
  color: ${color.BACKGROUND};
  border-radius: 12px;
  border-bottom: 2px solid rgba(180, 180, 180, 0.6);
  border-right: 2px solid rgba(180, 180, 180, 0.6);
  font-size: 38px;

  background: linear-gradient(130deg, rgba(255, 4, 161, 1) 35%, rgba(196, 0, 255, 1) 77%);

  /* width: fit-content; */
  /* margin-left: 10px; */
  /* background: -webkit-linear-gradient(130deg, rgba(255, 4, 161, 1) 15%, rgba(196, 0, 255, 1) 77%); */
  /* background-clip: text;
  -webkit-text-fill-color: transparent; */
`
const SectionBody = styled.section`
  padding: 20px;
  margin: 20px 0;
  border: 1px solid gray;
  background-color: ${color.BACKGROUND};
`
const LinkLayout = styled.div`
  margin-top: 50px;
  padding: 30px 0;
  background-color: white;
  border-left: 1px solid rgba(140, 140, 140, 0.2);
`
const Link = styled.div<{ href: string }>`
  cursor: pointer;
  padding: 7px 20px;
  font-weight: bold;
  font-size: 16px;
  color: ${color.FONT_BLACK};
  opacity: 0.8;
  :hover,
  .active {
    color: ${color.SECONDARY};
  }
`
const Sider = styled(AntdSider)`
  border-width: 0px;
  border-right-width: 2px;
  border-color: rgb(0, 21, 41);
  border-style: solid;
`

const MenuFixed = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: ${color.WHITE};
`

export const Wiki = () => {
  const { isDesktop } = useScreen()
  const [sideMenuVisible, setSideMenuVisible] = useState(false)
  const WikiComponents = useMemo(() => {
    return [
      {
        id: 'input',
        component: () => <ExampleInput />,
      },
      {
        id: 'breadcrumb',
        component: () => <ExampleBreadcrumb />,
      },
      {
        id: 'categoryGroup',
        component: () => <ExampleCategoryGroup />,
      },
      {
        id: 'exampleModal',
        component: () => <ExampleModal />,
      },
      {
        id: 'Comment',
        component: () => <ExampleComment />,
      },
      {
        id: 'button',
        component: ExampleButton,
      },
      {
        id: 'card',
        component: ExampleCard,
      },
      {
        id: 'avatar',
        component: ExampleAvatar,
      },
      {
        id: 'text',
        component: ExampleText,
      },
      {
        id: 'Tabs',
        component: ExampleTabs,
      },
      {
        id: 'select',
        component: ExampleSelect,
      },
      {
        id: 'steps',
        component: ExampleSteps,
      },
      {
        id: 'Image Slider',
        component: ExampleImageSlider,
      },
      {
        id: 'Tag',
        component: ExampleTag,
      },
      {
        id: 'TagRemain',
        component: ExampleTagRemain,
      },
      {
        id: 'Badge',
        component: ExampleBadge,
      },
      {
        id: 'Point Label',
        component: ExamplePointLabel,
      },
      {
        id: 'Profile Card',
        component: ExampleProfileCard,
      },
      {
        id: 'Shop Card',
        component: ExampleShopCard,
      },
      {
        id: 'DatePicker',
        component: ExampleDatePicker,
      },
      {
        id: 'Loading',
        component: ExampleLoading,
      },
      {
        id: 'Well-Being Card',
        component: ExampleWellBeingCard,
      },
      {
        id: 'Upload File',
        component: ExampleUploadFile,
      },
    ]
  }, [])

  const renderMenu = (id: string) => {
    return (
      <Link key={id} className={'ant-anchor-link-title'} href={`#${id}`}>
        {id}
      </Link>
    )
  }
  const renderComponent = (id: string, Component: ComponentType<ComponentProps<any>>) => {
    return (
      <SectionLayout id={id} key={id}>
        <SectionHeader>{`# ${id}`}</SectionHeader>
        <SectionBody>
          <Component />
        </SectionBody>
      </SectionLayout>
    )
  }

  useEffect(() => {
    // Smooth scrolling for browsers that don't support CSS smooth scrolling
    const links = document.querySelectorAll('div[href^="#"]')
    links.forEach((internalLink) => {
      const href = internalLink.getAttribute('href') || ''
      const targetElement = document.querySelector(href)
      if (targetElement && internalLink && href.length) {
        internalLink.addEventListener('click', (e) => {
          targetElement.scrollIntoView({
            behavior: 'smooth',
          })
          e.preventDefault()
        })
      }
    })
  }, [])

  return (
    <WikiLayout style={{ height: '100vh' }}>
      {isDesktop && (
        <Sider width={250}>
          <LinkLayout>{WikiComponents.map(({ id }) => renderMenu(humps.pascalize(id)))}</LinkLayout>
        </Sider>
      )}
      {!isDesktop && (
        <>
          <MenuFixed
            onClick={() => {
              setSideMenuVisible(true)
            }}
          >
            <div>Menu</div>
          </MenuFixed>
          <Drawer
            visible={sideMenuVisible}
            title="Menu"
            placement="left"
            closable={true}
            onClose={() => {
              setSideMenuVisible(false)
            }}
          >
            <LinkLayout>
              {WikiComponents.map(({ id }) => renderMenu(humps.pascalize(id)))}
            </LinkLayout>
          </Drawer>
        </>
      )}
      <Content style={{ padding: 50, background: 'white' }}>
        {WikiComponents.map(({ id, component }) => renderComponent(humps.pascalize(id), component))}
      </Content>
    </WikiLayout>
  )
}
