import React from 'react'
import styled from '@emotion/styled'
import { AiOutlineLeft } from 'react-icons/ai'
import { useRouter } from '../../../utils/helper'
import { useScreen } from '../../../utils/responsive-helper'
import TitleHeader from '../../../components/app-layout/AppHeader/TitleHeader'
import ProductCard from '../../../components/ProductCard'
import * as paths from '../../../constant/path'
import CouponCardSlider from '../CouponCardSlider'
import PromotionCategoryImage from '../PromotionCategoryImage'

const Layout = styled.div`
  margin-top: 16px;
`

const ProductLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
`

const PromotionCategoryDetail = () => {
  const { isMobile } = useScreen()
  const { promotionId } = useRouter().query

  return (
    <>
      {isMobile && (
        <TitleHeader
          title="Lorem Ipsum is simply dummy text"
          icon={<AiOutlineLeft />}
          path={paths.promotion()}
        />
      )}
      <Layout>
        <PromotionCategoryImage image="https://cf.shopee.co.th/file/6fbbca0aeb1a29ebda5688be92c1c2bd" />
        <CouponCardSlider
          //TODO: Need to update promotionId
          path={paths.promotionCategoryDetail({
            routeParam: { promotionId: 2 },
          })}
        />
        <ProductLayout>
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
        </ProductLayout>
      </Layout>
    </>
  )
}

export default PromotionCategoryDetail
