import styled from '@emotion/styled'
import React from 'react'

const Container = styled.ul`
  padding: 4px auto;
  
  .correct {
    // font-weight: bold;
    // padding-left: 10px;
  }
`

const Item = styled.li`
  margin: auto;
`


type NewsItemsProps = {
  quizItems: QuizItemsProps
}

type QuizItemsProps = {
  label: number
  value: string
  score: number
  isCorrect: boolean
}

function NewsItems(props: NewsItemsProps) {
  const { quizItems } = props
  const { label, value, score, isCorrect } = quizItems

  return (
    <Container>
      <Item className={isCorrect ? 'correct' : ''}>
        {label}
      </Item>
    </Container>
  )
}

export default NewsItems