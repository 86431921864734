import styled from '@emotion/styled'
import Avatar from '../../components/common/Avatar'
import image from '../../assets/images/base/test-avatar.png'

const Layout = styled.div``

const ExampleAvatar = () => {
  return (
    <Layout>
      <h1>Avatar</h1>
      <Avatar src={image} size={40} />
      <Avatar src={image} size={167} />
      <h1>Avatar with isBorder and borderWidth</h1>
      <Avatar src={image} size={100} isBorder={true} borderWidth={6} />
      <Avatar src={image} size={100} isBorder={true} borderWidth={12} />
      <h1>Avatar with isBorder (default borderWidth)</h1>
      <Avatar src={image} size={167} isBorder={true} />
      <Avatar src={image} size={300} isBorder={true} />
    </Layout>
  )
}

export default ExampleAvatar
