import styled from '@emotion/styled'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { FaCoins } from 'react-icons/fa'
import color from '../constant/colors'
import { mobile } from '../utils/responsive-helper'
import Button from './common/Button'
import Image from './common/Image'
import Text from './common/Text'
import StarRating from './StarRating'

const Card = styled.div`
  width: calc(25% - 8px);
  margin: 4px;
  padding: 8px;
  background-color: ${color.WHITE};

  ${mobile} {
    width: calc(50% - 8px);
  }
`

const ProductImageStyle = styled(Image)`
  display: block;
  width: 100%;
  height: inherit;
`

const PriceDetailStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
`

const PriceStyle = styled.div``

const CoinStyle = styled.div``

const DetailStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ProductDetailStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  flex: 1;
  min-width: 0;
`

const ProductNameStyle = styled(Text)`
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ProductRatingStyle = styled.div`
  display: flex;
  align-items: center;
`

const CartButtonStyle = styled(Button)`
  width: auto;
  height: auto;
  padding: 12px;
  border: none;
`

const ProductCard = () => {
  const formatPrice = (price: number) => {
    return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}฿`
  }
  return (
    <Card>
      <ProductImageStyle src="https://cf.shopee.co.th/file/4ee1c7815927f75ded3ae2eeacd901ea_tn" />
      <PriceDetailStyle>
        <PriceStyle>
          <Text size={14} color={color.MEDIUM_GRAY} style={{ textDecoration: 'line-through' }}>
            {formatPrice(10001)}
          </Text>
          <Text size={24} color={color.ORANGE} type="Bold">
            {formatPrice(1000)}
          </Text>
        </PriceStyle>
        <CoinStyle>
          <Text type="SemiBold">
            <FaCoins /> 000
          </Text>
        </CoinStyle>
      </PriceDetailStyle>
      <DetailStyle>
        <ProductDetailStyle>
          <ProductNameStyle size={18} type="SemiBold" color={color.FONT_DARK_GRAY}>
            ตู้เย็น 2 ประตู ขนาดใหญ่
          </ProductNameStyle>
          <StarRating rating={'5.0'} />
        </ProductDetailStyle>
        <CartButtonStyle background={color.LIGHT_BLUE}>
          <Text size={16} color={color.WHITE} style={{ display: 'flex' }}>
            <AiOutlineShoppingCart />
          </Text>
        </CartButtonStyle>
      </DetailStyle>
    </Card>
  )
}

export default ProductCard
