import styled from '@emotion/styled'
import color from '../constant/colors'
import Text from './common/Text'
import { AiFillStar } from 'react-icons/ai'

const RatingStyle = styled.div`
  display: flex;
  align-items: center;
`

type StarRatingProps = {
  rating: string
}

const StarRating = (props: StarRatingProps) => {
  return (
    <RatingStyle>
      {props?.rating && (
        <>
          <Text size={14} color={color.YELLOW_STAR} style={{ lineHeight: '16px', paddingRight: 4 }}>
            <AiFillStar />{' '}
          </Text>
          <Text color={color.MEDIUM_GRAY} style={{ lineHeight: '16px' }}>
            {props.rating}
          </Text>
        </>
      )}
    </RatingStyle>
  )
}
export default StarRating
