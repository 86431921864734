import React from 'react'
import styled from '@emotion/styled'

import { mobile } from '../../utils/responsive-helper'
import Profile from './Profile'
import Menu from './Menu'
import Activity from './Activity'
import Comment from './Comment'
import { Tabs } from 'antd'
import color from '../../constant/colors'

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  > * {
    margin-top: 24px;
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const WidthContent = styled.div`
  max-width: 752px;
  margin: 0 auto;
`

const TabsLayout = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 600;
    color: ${color.PRIMARY};
  }

  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 600;
    color: ${color.PRIMARY};
  }
 
  .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: ${color.PRIMARY} !important;
  }
`


const WellBeing = () => {
  return (
    <Layout>
      <WidthContent>
        <h2>Well Being</h2>

        <Profile />
        <Menu />
        {/* <Activity />

        <TabsLayout>
          <Tabs>
            <Tabs.TabPane tab="Me" key="item-1">
              <Comment />
            </Tabs.TabPane>
            <Tabs.TabPane tab="เพื่อน" key="item-2">
              <Comment />
            </Tabs.TabPane>
            <Tabs.TabPane tab="ข่าว" key="item-3">
              <Comment />
            </Tabs.TabPane>
          </Tabs>
        </TabsLayout> */}

      </WidthContent>
    </Layout>
  )
}

export default WellBeing
