import React from 'react'
import styled from '@emotion/styled'
import ImageSlider from './ImageSlider'
import Category from './Category'
import Text from '../../../components/common/Text'
import Breadcrumb from '../../../components/common/Breadcrumb'
import { mobile } from '../../../utils/responsive-helper'
import { useRouter } from '../../../utils/helper'
import { shop } from '../../../constant/path'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  > * {
    margin-top: 24px;
  }

  .image-slider {
    width: 1141px;
    height: 637px;
    object-fit: contain;

    ${mobile} {
      height: 192px;
      width: 100%;
      .title-font {
        font-size: 24px;
      }
    }
  }
  .shop-card {
    margin-bottom: 24px;
  }
`
const BreadcrumbStyled = styled<typeof Breadcrumb>(Breadcrumb)`
  ${mobile} {
    margin : 24px 16px;
  }
`

const ShopCategory = () => {
  const { categoryId, title: categoryName } = useRouter().query

  return (
    <Layout>
      <BreadcrumbStyled
        overide={(paths) => {
          paths[0] = {
            label: 'Shop',
            path: shop(),
          }
          paths[1].label = categoryName
          return paths
        }}
      />
      <ImageSlider categoryId={categoryId} />
      <Text className="title-font" size={32} type="Bold">
        {categoryName}
      </Text>

      <Category categoryId={categoryId} categoryName={categoryName} />
    </Layout>
  )
}

export default ShopCategory
