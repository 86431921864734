import styled from '@emotion/styled'

import color from '../../constant/colors'
import LogoFooter from '../../assets/images/base/logo-with-name-footer.svg'
import Link from '../common/TextLink'
import Text from '../common/Text'
import { mobile } from '../../utils/responsive-helper'
import { useTranslation } from 'react-i18next'
import * as path from '../../constant/path'
import { isScg } from '../../utils/helper'
import dentsuIcon from '../../assets/images/base/dentsu-loco.png'

const Layout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 90px;
  background-color: ${color.WHITE};
  border-top: 1px solid ${color.BORDER};

  ${mobile} {
    min-height: 105px;
    height: 100%;
    padding-bottom: 6px;
  }
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 1172px;
  padding: 0 16px;

  ${mobile} {
    flex-direction: column;
    justify-content: center;
    padding: 0;
    .footer-text {
      font-size: 12px;
      :nth-last-of-type(1) {
        margin-bottom: 4px;
      }
    }
  }
`
const Logo = styled.img`
  margin-top: -4px;
  width: 124px;
  height: auto;
  padding-top: 8px;

  ${mobile} {
    width: 124px;
    height: auto;
    margin-bottom: 11px;
    margin-top: 4px;
  }
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const LinkLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  > *:nth-last-of-type(1) {
    margin-left: 32px;
  }
  ${mobile} {
    justify-content: center;
    > *:nth-last-of-type(1) {
      margin-left: 16px;
      margin-bottom: 8px;
    }
  }
`
const Copyright = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90vw;
`
const FooterContentLayout = styled(Layout)`
  border-top: unset;
  min-height: unset;
  height: unset;
  ${mobile} {
    border-top: unset;
    min-height: unset;
    height: unset;
    padding-bottom: unset;
  }
`
const FooterContent = styled.div`
  width: 100%;
  max-width: 1172px;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

const AppFooter = () => {
  const { t } = useTranslation()
  const currentLogo = dentsuIcon

  return (
    <div style={{ marginTop: 'auto' }}>
      <FooterContentLayout>
        <FooterContent id="footer-content" />
      </FooterContentLayout>
      <Layout>
        <Footer>
          <Logo src={currentLogo} />
          <FlexColumn>
            {/* <LinkLayout>
              <Link
                className="footer-text"
                type="Medium"
                color={color.FONT_BLACK}
                path={path.termAndCondition()}
              >
                {t('footer.link.privacyAndTerms')}
              </Link>
              <Link
                className="footer-text"
                type="Medium"
                color={color.FONT_BLACK}
                path={path.contact()}
              >
                {t('footer.link.contactUs')}
              </Link>
            </LinkLayout> */}
            <Copyright
              style={{ marginTop: 6 }}
              className="footer-text"
              type="Medium"
              color={color.LIGHT_GRAY}
              size={10}
            >
              Copyright © Benefit of Living Company Limited. All rights reserved
            </Copyright>
          </FlexColumn>
        </Footer>
      </Layout>
    </div>
  )
}

export default AppFooter
