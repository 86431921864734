import styled from '@emotion/styled'
import Image, { ImageNameKeys } from '../../../components/common/Image'
import Text from '../../../components/common/Text'
import color from '../../../constant/colors'

const IconStyle = styled.div`
  padding-right: 16px;
`

const LayoutStyle = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 26px;
  width: 100%;
`
const LayoutContent = styled.div`
  display: flex;
  text-align: start;
  position: relative;
  flex-direction: column;
  width: 100%;
`

type WelfareProps = {
  iconPath: ImageNameKeys
  title: string
  descriptions: string[]
}

const Welfare = (props: WelfareProps) => {
  const { iconPath, title, descriptions } = props

  return (
    <div>
      <LayoutStyle>
        <IconStyle>
          <Image imageName={iconPath} width={32} height={32} />
        </IconStyle>
        <LayoutContent>
          <Text size={24} type="Bold">
            {title}
          </Text>

          {descriptions.map((description, index) => (
            <Text key={`${description}-${index}`} size={20} color={color.FONT_LIGHT_GRAY}>
              <li style={{ color: color.FONT_LIGHT_GRAY }}>{description}</li>
            </Text>
          ))}
        </LayoutContent>
      </LayoutStyle>
    </div>
  )
}

export default Welfare
