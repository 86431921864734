import styled from '@emotion/styled'
import Card from '../../components/common/Card'
const Layout = styled.div``

const LayoutCard = styled(Card)`
  width: 170px;
  height: 167px;
`
const ExampleCard = () => {
  return (
    <Layout>
      <LayoutCard />
      <Card style={{ width: 267, height: 203, borderRadius: 9.9 }} />
      <Card style={{ width: 364, height: 435 }} />
    </Layout>
  )
}
export default ExampleCard
