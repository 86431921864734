import styled from '@emotion/styled'
import { useMemo } from 'react'

import dayjs from 'dayjs'
import { useGetPeriodDate } from '../../services/enrollment/enrollment-query'
import { isScg } from '../../utils/helper'
import { mobile, useScreen } from '../../utils/responsive-helper'
import LifeStyle from './LifeStyle'
import MyApp from './MyApp'
import MyAppScg from './MyAppScg'
import News from './News'
import NewsSlide from './NewsSlide'
import Profile from './Profile'

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  width: 100%;
  min-height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
  min-height: 266px;
  margin: 22px auto 0 auto;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;

  > *:not(:first-of-type) {
    margin-top: 24px;
  }
    
    ${mobile} {
      max-width: unset;
    }
`

const Home = () => {
  const { isMobile } = useScreen()
  const { data: periodDate } = useGetPeriodDate()
  const { startDate, endDate } = periodDate || {}

  const canEditEnrollment = useMemo(() => {
    const today = dayjs().endOf('day')
    const _startDate = dayjs(startDate).endOf('day')
    const _endDate = dayjs(endDate).endOf('day')
    if (_startDate.isValid() && _endDate.isValid()) {
      return today.isAfter(_startDate) && today.isBefore(_endDate)
    } else {
      return false
    }
  }, [endDate, startDate])

  return (
    <Layout>
      <BackgroundHeader />
      {/* {!isMobile && canEditEnrollment && <Welfare />} */}
      <FlexRow>
        {/* <ProfileCard /> */}
        <Profile />
        <NewsSlide />
        <FlexColumn>
          {/* <MyWork /> */}
          {isScg ? <MyAppScg /> : <MyApp />}
        </FlexColumn>
        <News />
      </FlexRow>
      {/* <UpcomingEvents /> */}
      {isScg ? null : (
        <>
          <News />
          <LifeStyle />
        </>
      )}
    </Layout>
  )
}

export default Home
