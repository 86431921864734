import { useInfiniteQuery } from 'react-query'
import { api } from '../../utils/api'
import { CoinTransansaction } from './point-history-typed'

export const COIN_TRANSACTION_URL = '/app/user/getCoinTransaction'

export const useGetCoinTransaction = () => {
  const size = 3
  return useInfiniteQuery(
    COIN_TRANSACTION_URL,
    ({ pageParam = 0 }) =>
      api.belive.get<CoinTransansaction>(COIN_TRANSACTION_URL, {
        page: pageParam,
        size,
      }),
    {
      getNextPageParam: (lastGroup: CoinTransansaction) => {
        const { totalPages, number } = lastGroup
        const curPage = number + 1
        const nextPage = number + 1
        return curPage < totalPages ? nextPage : null
      },
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  )
}
