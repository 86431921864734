import { Switch, Route } from 'react-router'

import * as paths from '../../../constant/path'

import Benefits from '../../../pages/Enrollment/Benefits'
// import EnrollmentLeave from '../../../pages/Enrollment/Benefits/EnrollmentLeave'
import EditSelfBenefits from '../../../pages/Enrollment/Benefits/EditBenefits/EditSelfBenefits'
// import EditRelativeBenefits from '../../../pages/Enrollment/Benefits/EditBenefits/EditRelativeBenefits'
// import EnrollmentInsuranceFamily from '../../../pages/Enrollment/Benefits/EnrollmentInsuranceFamily'
// import RelativeForm from '../../../pages/Enrollment/Benefits/EnrollmentInsuranceFamily/RelativeForm'
// import WellBeing from '../../../pages/Enrollment/Benefits/WellBeing'
// import EditWellBeing from '../../../pages/Enrollment/Benefits/WellBeing/EditWellBeing'
import EnrollmentDetail from './EnrollmentDetail'

const EnrollmentDetailRoute = () => {
  return (
    <Switch>
      {/* <Route path={paths.enrollmentWellBeingEdit()} component={EditWellBeing} /> */}
      {/* <Route path={paths.enrollmentWellBeing()} component={WellBeing} /> */}
      {/* <Route path={paths.insuranceFamilyForm()} component={RelativeForm} />
      <Route path={paths.enrollmentInsuranceFamily()} component={EnrollmentInsuranceFamily} />
      <Route path={paths.editRelativeBenefits()} component={EditRelativeBenefits} /> */}
      <Route path={paths.editSelfBenefits()} component={EditSelfBenefits} />
      {/* <Route path={paths.enrollmentLeave()} component={EnrollmentLeave} /> */}
      <Route path={paths.benefits()} component={Benefits} />
      <Route exact path={paths.enrollment()} component={EnrollmentDetail} />
    </Switch>
  )
}

export default EnrollmentDetailRoute
