import styled from '@emotion/styled'
import WellBeingCard from '../../components/WellBeingCard'
import icon from '../../assets/images/base/icons-train-green-48-px.svg'
const Layout = styled.div``

const ExampleWellBingCard = () => {
  return (
    <Layout>
      <WellBeingCard
        title="เบิกค่ารถไฟฟ้า"
        icon={icon}
        onClick={() => console.log('hello world!!')}
      />

      <WellBeingCard title="เบิกค่าอุปกรณ์ท่องเที่ยว" icon={icon} />
    </Layout>
  )
}
export default ExampleWellBingCard
