import styled from '@emotion/styled'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import Point from '../../components/common/Point'
import Text from '../../components/common/Text'
import MyPlayCardList from '../../components/play/MyPlayCardList'
import PlayCardList from '../../components/play/PlayCardList'
import color from '../../constant/colors'
import { useGetUser } from '../../services/user/user-query'
import { mobile, mobileVerySmall } from '../../utils/responsive-helper'

const Layout = styled.div`
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;

    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 60px;
  margin: 0 auto;
  z-index: inherit;  
  min-height: 266px;
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  
  ${mobile} {
    min-height: 200px;
    padding: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  ${mobileVerySmall} {
    row-gap: 16px;
    align-self: center;
    flex-direction: column;
  }

  .left {
    max-width: 70%;
    display: flex;
    flex-direction: column;
    
    ${mobile} {
      margin-right: auto;
      max-width: 100%;
    }
  }
  
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    
    ${mobile} {
      margin-left: auto;
      max-width: 100%;
    }
  }
`
const TabsLayout = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 600;
    color: ${color.PRIMARY};
  }

  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 600;
    color: ${color.PRIMARY};
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
    margin: 0 60px;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: ${color.PRIMARY} !important;
  }

  ${mobile} {
    .ant-tabs-nav {
      margin: 0 0;
    }
  }
`

const Play = () => {
  const { data: user } = useGetUser()
  const { t } = useTranslation()
  return (
    <Layout>
      <FlexRow>
        <div className='left'>
          <Text size={32} type="Bold">{`${t('play.title')} ${user?.firstName} ${user?.lastName}`}</Text>
          <Text size={14} type="Bold">{t('play.subtitle')}</Text>
        </div>
        <div className='right'>
          <Point />
        </div>
      </FlexRow>

      <TabsLayout>
        <Tabs>
          <Tabs.TabPane tab={t('play.events')} key="item-1">
            <PlayCardList />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('play.myevent')} key="item-2">
            <MyPlayCardList />
          </Tabs.TabPane>
        </Tabs>
      </TabsLayout>
    </Layout>
  )
}

export default Play
