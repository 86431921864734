import styled from '@emotion/styled'


import { useHistory } from 'react-router'
import HealthBackground from '../../assets/images/base/ExerciseStatistics@2x.png'
import HealthProfileBackground from '../../assets/images/base/HealthProfile.jpg'
import HealthTipsBackground from '../../assets/images/base/HealthTips@2x.png'
import LogoDentsuHeartcare from '../../assets/images/base/LogoDentsuHeartcare.png'
import ShopBackground from '../../assets/images/base/Well-BeingShop@2x.png'
import { heartCare } from '../../constant/path'
import { mobile } from '../../utils/responsive-helper'


const MenuLayout = styled.div`
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  > div {
    display: flex; 
    flex-basis: calc(50% - 10px);  
    justify-content: center;
    flex-direction: column;
    
    ${mobile} {
      flex-basis: calc(100% - 10px);  
    }
  }

  > div > div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-size: 32px;
    font-weight: 600;
  }

  .box { 
    margin: 5px;
    height: 100%;
    min-height: 150px;
    cursor: pointer;
    position: relative;
  }

  .disabled {
    cursor: not-allowed;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    pointer-events: unset;
    user-select: none; 
    background-color:rgba(255, 255, 255, 0.75);
  }

  .disabled-text {
    transform: rotate(-30deg);
    font-size: 26px;
    font-weight: 700;
  }

  .box1 { 
    cursor: pointer;
    background-repeat: round !important;
    background: url(${HealthBackground});
  }
  .box2 { 
    cursor: pointer;
    background-repeat: round !important;
    background: url(${ShopBackground});
  }
  .box3 { 
    cursor: not-allowed !important;
    background-repeat: round !important;
    background: url(${HealthTipsBackground});
  }
  .box4 { 
    cursor: pointer;
    background-repeat: round !important;
    background: url(${HealthProfileBackground});
  }
  .box5 { 
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    background-repeat: round !important;
    background: url(${LogoDentsuHeartcare});
  }
`

const Menu = () => {
  const history = useHistory()

  return (
    <MenuLayout>
      {/* <div className="box box1"><div>Exercise Statistics</div></div>

      <div className="box box2"><div>Shop</div></div>

      <div className="box box3" onClick={() => history.push(healthTips())}>
        <div>
          Health Tips
        </div>
      </div>

      <div className="box box4" onClick={() => history.push(healthProfile())}><div>Health Profile</div></div> */}

      <div className="box box5" onClick={() => history.push(heartCare())}></div>

      <div className="box box3">
        <div>
          Health Tips
        </div>
        <div className='disabled'>
          <div className='disabled-text'>Coming Soon</div>
        </div>
      </div>

    </MenuLayout>
  )
}

export default Menu