import { useState } from 'react'
import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineExclamationCircle } from 'react-icons/ai'
import styled from '@emotion/styled'
import color from '../constant/colors'
import Text from '../components/common/Text'
import Button from '../components/common/Button'
import toast, { Toaster } from 'react-hot-toast'
import { useScreen } from '../utils/responsive-helper'

const CardStyle = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  margin-top: 16px;
  margin-bottom: 24px;
  background: linear-gradient(270deg, #ffb292 0%, ${color.ORANGE} 100%);
  border-radius: 4px;
`

const DotLeftStyle = styled.div`
  position: relative;
  top: calc(50% - 8px);
  left: -8px;
  width: 16px;
  height: 16px;
  background: ${color.BACKGROUND};
  border-radius: 50%;
  display: inline-flex;
`

const DotRightStyle = styled.div`
  position: relative;
  top: calc(50% - 8px);
  left: calc(100% - 8px - 16px);
  width: 16px;
  height: 16px;
  background: ${color.BACKGROUND};
  border-radius: 50%;
  display: inline-flex;
`

const ContentStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-right: 16px;
  flex-direction: column;
  justify-content: center;
`

const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderTextStyle = styled(Text)`
  font-size: 28px;
  line-height: 32px;
`

const BodyStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

const DetailStyle = styled.div``

const DetailTextStyle = styled(Text)`
  font-size: 16px;
  line-height: 18px;
`

const CouponDetailStyle = styled.div``

const DetailSectionStyle = styled.div`
  padding-bottom: 16px;
`

const HeaderText = styled(Text)`
  font-size: 20px;
  line-height: 22px;
`

const ConfirmButton = styled(Button)`
  max-width: none;
  margin-bottom: 16px;
`

const SuccessToastStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${color.WHITE};
  background: #53b64b;
  border-radius: 4px;
  padding: 8px;
`

const FailToastStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${color.WHITE};
  background: #c11d1a;
  border-radius: 4px;
  padding: 8px;
`

const ContentToastStyle = styled(Text)`
  color: ${color.WHITE};
  padding: 0 24px 0 8px;
`

const UseCouponToastStyle = styled(Text)`
  color: ${color.WHITE};
  padding: 0;
  padding-right: 8px;
`

const CouponInformation = () => {
  const { isMobile } = useScreen()
  //TODO: check status from api
  const [couponSuccess, setCouponSuccess] = useState(false)

  const notify = () => {
    couponSuccess
      ? toast.custom((t) => (
          <SuccessToastStyle>
            <AiOutlineCheckCircle />
            <ContentToastStyle>คุณได้รับคูปองแล้ว</ContentToastStyle>
            <UseCouponToastStyle size={14}>ใช้คูปองเลย</UseCouponToastStyle>
            <AiOutlineClose onClick={() => toast.dismiss(t.id)} />
          </SuccessToastStyle>
        ))
      : toast.custom((t) => (
          <FailToastStyle>
            <AiOutlineExclamationCircle />
            <ContentToastStyle>เกินจำนวนที่กำหนด</ContentToastStyle>
            <AiOutlineClose onClick={() => toast.dismiss(t.id)} />
          </FailToastStyle>
        ))
  }

  return (
    <>
      <CardStyle>
        <DotLeftStyle />
        <DotRightStyle />
        <ContentStyle>
          <HeaderStyle>
            <HeaderTextStyle color={color.WHITE} type="Bold">
              Free Delivery
            </HeaderTextStyle>
          </HeaderStyle>
          <BodyStyle>
            <DetailStyle>
              <DetailTextStyle color={color.WHITE}>Min. spend ฿1000</DetailTextStyle>
              <DetailTextStyle color={color.WHITE}>Valid until 30/06/2021</DetailTextStyle>
            </DetailStyle>
          </BodyStyle>
        </ContentStyle>
      </CardStyle>
      <CouponDetailStyle>
        <DetailSectionStyle>
          <HeaderText type="SemiBold">คูปอง</HeaderText>
          <Text size={18}>มาไวได้ไว รีบใช้ก่อนจะหมด! โค้ดส่งฟรี</Text>
        </DetailSectionStyle>
        <DetailSectionStyle>
          <HeaderText type="SemiBold">เวลาที่ใช้ได้</HeaderText>
          <Text size={18}>01.11.2021 00:00 - 30.11.2021 23:59</Text>
        </DetailSectionStyle>
        <DetailSectionStyle>
          <HeaderText type="SemiBold">อุปกรณ์</HeaderText>
          <Text size={18}>Android, iOS</Text>
        </DetailSectionStyle>
        <DetailSectionStyle>
          <HeaderText type="SemiBold">การชำระเงิน</HeaderText>
          <Text size={18}>บัตรเครดิต/บัตรเดบิต - ตัดบัญชีธนาคาร</Text>
        </DetailSectionStyle>
        <DetailSectionStyle>
          <HeaderText type="SemiBold">รายละเอียดเพิ่มเติม</HeaderText>
          <Text size={18}>
            โค้ดส่งฟรีประจำเดือนพฤศจิกายน ขั้นต่ำ 49 บาท - สำหรับการชำระผ่าน ShopeePay เท่านั้น -
            ใช้ได้กับร้านค้า Free Shipping Special Shops เท่านั้น - จำกัดการใช้โค้ด 1
            ครั้ง/คน/เครื่อง และซื้อผ่านแอปเท่านั้น - Shopee สนับสนุนค่าจัดส่งสูงสุด 40 บาท
            เมื่อจัดส่งผ่าน Standard Delivery หรือ Shopee Express, Kerry, J&T Express, Ninja Van,
            Best Express, DHL Domestic, Flash Express หรือ Shopee Express Bulky, DB Schenker Bulky,
            Best Express Bulky, Flash Express Bulky และสำหรับ International Express
            สนับสนุนค่าจัดส่งสูงสุด 30 บาท *หากค่าจัดส่งเกินจำนวนที่ Shopee สนับสนุนผู้ซื้อต้อง
            รับผิดชอบค่าจัดส่งที่เหลือ* - ไม่สามารถใช้ร่วมกับโค้ดส่วนลดจาก Shopee ได้ -
            เงื่อนไขเป็นไปตามที่บริษัทฯกำหนด
          </Text>
        </DetailSectionStyle>
        <ConfirmButton onClick={notify}>ตกลง</ConfirmButton>
        <Toaster position={isMobile ? 'bottom-center' : 'top-center'} />
      </CouponDetailStyle>
    </>
  )
}

export default CouponInformation
